import React, { useState, useEffect } from 'react';
import styles from '../App.module.scss';
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import closeButton from '../assets/closeButton.png'
import { doc, addDoc, collection } from 'firebase/firestore';
import { db } from "../";
import { useAppSelector } from '../redux/hooks';

export default function SupportModal() {

    const [showSupportModal, setShowSupportModal] = useState(false);

    const toggleModal = () => {
        setShowSupportModal(!showSupportModal);
      };

      const currentUserEmail = useAppSelector((state) => state.userData.userData?.email);

      // Initialize the email state with the email fetched from the Redux store
      const [email, setEmail] = useState(currentUserEmail || '');
  
      // Update the email state if the Redux store's email changes
      useEffect(() => {
          setEmail(currentUserEmail || '');
      }, [currentUserEmail]);
    const [isSendHovered, setIsSendHovered] = useState(false);

    const [message, setMessage] = useState('');

    const handleCryptoDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
      };

      const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
      };

    const handleAndroidMouseEnter = () => {
        setIsSendHovered(true);
    };

    const handleAndroidMouseLeave = () => {
        setIsSendHovered(false);
    };
      const [errorMessage, setErrorMessage] = useState('');

      const removeWhitespace = (str: string) => {
        return str.replace(/\s/g, '');
      };

      const handleSendButtonClick = async () => {
        const cleanedEmail = removeWhitespace(email);
        if (cleanedEmail === '' || message === '') {
            setErrorMessage('Please fill in all fields');
          } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cleanedEmail)) {
              setErrorMessage('Please enter a valid email address');
          } else {
            


            try {
                const currentDate = new Date();
                const currentDateInSeconds = Math.ceil(currentDate.getTime() / 1000);
                
                const originationDate = currentDate.toLocaleString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                  hour12: true,
                  timeZoneName: 'short',
                });
            
                const newSupportRequest = {
                  OriginationDate: originationDate,
                  OriginatorEmail: cleanedEmail,
                  Message: message,
                };
            
                const supportCollectionRef = collection(db, 'supportRequests');
                
                const newSupportRequestDocRef = addDoc(supportCollectionRef, newSupportRequest);
            
                
                  await Promise.all([newSupportRequestDocRef]);
                  const newOrderId = (await newSupportRequestDocRef).id;
            
                  setMessage('');
                  setEmail('');
                  setErrorMessage(`New support request with ID: ${newOrderId}`);
                } catch (error) {
                console.error('Update error:', error);
                setErrorMessage('We are having connection issues please try again later');
              }

          }
      };
      

      const errorLabelText = () => {
        if (errorMessage) {
            if (errorMessage.startsWith('New support request')) {
            return (
            <label style={{ display: 'flex', justifyContent: 'center', 
            alignItems: 'center', margin: '0 auto', marginTop: '30px',
            color: '#4CD964' }}>{errorMessage}</label>
            );
          }
          return (
          <label className={styles['error-label']} style={{ display: 'flex', justifyContent: 'center', 
          alignItems: 'center', margin: '0 auto', marginTop: '30px', }}>{errorMessage}</label>
          );
        } else {
          return (<div style={{ visibility: 'hidden' }}>
                  <label className={styles['error-label']} style={{ display: 'flex', justifyContent: 'center', 
          alignItems: 'center', margin: '0 auto', marginTop: '30px', }}>$</label>
          </div>);
        }
      };

    return (

        <div>

            <a onClick={toggleModal} style={{ 
            color: 'white', cursor: 'pointer', fontSize: '25px',
            borderRadius: '10px',
            textAlign: 'center',
            width: '250px',
            border: '1px solid #FFA14A',
            padding: '10px',

            
             }}>Request Support</a>

        {showSupportModal && (
            <div className={styles['modal-container']}>
              <div className={styles['overlay']} onClick={toggleModal}></div>
              <div className={styles['crypto-modal']} style={{height: '70vh'}}>
                <div className={styles['crypto-modal-header']}>
                  <h3>How can we help?</h3>
                  <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={toggleModal} style={{width: '24px', height: '24px'}}></img>
                </div>
                <div className={styles['crypto-modal-content']} style= {{ display: 'flex', flexDirection: 'column', 
                    alignItems: 'center', gap: '30px',}}>
                    
                    <div className={styles['crypto-modal-textfields']}>
                    <div className={styles['crypto-modal-textfield']}>
                        <label htmlFor="email">Email</label>
                        <input id="email" type="text" value={email} onChange={handleEmailChange} />
                    </div>
                    </div>

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <label htmlFor="crypto-description" style={{ textAlign: 'center', marginBottom: '5px' }}>Message</label>
              <textarea
                id="crypto-description"
                style={{
                  width: '400px',
                  height: '180px',
                  backgroundColor: '#222222',
                  color: 'white',
                  border: '1px solid white',
                  borderRadius: '10px',
                  resize: 'none',
                  fontFamily: 'Arial, sans-serif', 
                }}
                
                onChange={handleCryptoDescriptionChange}
              />
            </div>

                </div>
                
                <div className={styles['crypto-modal-footer']} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {errorLabelText()}
                  
                  <button
                    style={{
                    backgroundColor: isSendHovered ? '#4CD964' : '#222222',
                    color: isSendHovered ? '#222222' : '#4CD964',
                    display: 'inline-block',
                    padding: '10px 20px',
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    borderRadius: '10px',
                    border: '1px solid #4CD964',
                    cursor: 'pointer',
                    width: '150px',
                    }}
                    onMouseEnter={handleAndroidMouseEnter}
                    onMouseLeave={handleAndroidMouseLeave}
                    onClick={handleSendButtonClick}
                >
                Send
                </button>


                </div>
                </div>
              </div>


          )}

    </div>
    )};