
import React, { useState, useEffect } from 'react';

function formatDollarNumber(num: number) {
    if (num >= 10000) {
        let str = num.toFixed(0); // Convert to string with sufficient decimal places
        str = str.replace(/\.0+$/, ""); // Remove decimal point if all zeros
        return parseFloat(str).toLocaleString();
    } else if (num >= 1000) {
        let str = num.toFixed(0); // Convert to string with sufficient decimal places
        str = str.replace(/\.0+$/, ""); // Remove decimal point if all zeros
        return parseFloat(str).toLocaleString();
    } else if (num >= 100) {
        let str = num.toFixed(2); // Convert to string with sufficient decimal places
        str = str.replace(/(\.\d*?[1-9])0+$/, "$1"); // Remove trailing zeros
        str = str.replace(/\.0+$/, ""); // Remove decimal point if all zeros
        return str;
    } else if (num >= 10) {
        let str = num.toFixed(3); // Convert to string with sufficient decimal places
        str = str.replace(/(\.\d*?[1-9])0+$/, "$1"); // Remove trailing zeros
        str = str.replace(/\.0+$/, ""); // Remove decimal point if all zeros
        return str;
    } else if (num >= 1) {
        let str = num.toFixed(4); // Convert to string with sufficient decimal places
        str = str.replace(/(\.\d*?[1-9])0+$/, "$1"); // Remove trailing zeros
        str = str.replace(/\.0+$/, ""); // Remove decimal point if all zeros
        return str;
    } else {
        let str = num.toFixed(5); // Convert to string with sufficient decimal places
        str = str.replace(/(\.\d*?[1-9])0+$/, "$1"); // Remove trailing zeros
        str = str.replace(/\.0+$/, ""); // Remove decimal point if all zeros
        return str;
    }
  }



export {formatDollarNumber};