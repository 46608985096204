import { doc, getDoc, setDoc, collection, addDoc } from 'firebase/firestore';
import { db } from '..';
import { functions } from "..";
import { httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";



interface UTXOResponse {
    notice: string;
    unspent_outputs: UTXO[];
  };
  
  interface UTXO {
    confirmations: number;
    script: string;
    tx_hash: string;
    tx_hash_big_endian: string;
    tx_index: number;
    tx_output_n: number;
    value: number;
    value_hex: string;
  };

  const getUTXOS = async (address: string): Promise<UTXO[]> => {
    const url = `https://blockchain.info/unspent?active=${address}`;
    const response = await fetch(url);
  
    if (response.ok) {
      const data: UTXOResponse = await response.json();
      const { unspent_outputs } = data;
  
      if (unspent_outputs && unspent_outputs.length > 0) {
        const utxos: UTXO[] = unspent_outputs.map((utxoData: UTXO) => {
          const {
            confirmations,
            script,
            tx_hash,
            tx_hash_big_endian,
            tx_index,
            tx_output_n,
            value,
            value_hex,
          } = utxoData;
  
          return {
            confirmations,
            script,
            tx_hash,
            tx_hash_big_endian,
            tx_index,
            tx_output_n,
            value,
            value_hex,
          };
        });
  
        return utxos;
      } else {
        return [];
      }
    } else {
      throw new Error('Failed to retrieve UTXOs');
    }
  };

  const aggregateBitcoin = async (utxo: UTXO, bitcoinAddress: string, bitcoinKey: string): Promise<void> => {
    console.log('aggregating bitcoin');
    const requestData = {
      txId: utxo.tx_hash_big_endian,
      outputIndex: utxo.tx_output_n,
      address: bitcoinAddress,
      script: utxo.script,
      satoshis: utxo.value,
      privateKey: bitcoinKey,
    };
  
    try {
      const aggregateBitcoinFn = httpsCallable(functions, 'aggregateBitcoin');
      const result = await aggregateBitcoinFn(requestData);
      const rawTx = result.data as string;
      console.log('aggregate bitcoin cloud function response: ', rawTx);
      broadcastTx(rawTx);
    } catch (error) {
      console.error('Error calling aggregateBitcoin:', error);
    }
  };

  const broadcastTx = (rawTx: string) => {
    const jsonData = JSON.stringify({ tx: rawTx });
  
    fetch('https://api.blockcypher.com/v1/btc/main/txs/push', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: jsonData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error('Error broadcasting transaction:', error);
      });
  };

  export const ListenForBitcoinDeposits = async (address: string, key: string, userID: string, 
    bitcoinBalanceAvailable: number, currentUserEmail: string): Promise<void> => {

    let depositHashArray = [''];
    const depositsDocRef = doc(db, 'bitcoinDepositTxHashes', userID);

    try {
        
        const docSnap = await getDoc(depositsDocRef);
    
        if (docSnap.exists()) {
          const data = docSnap.data();
          // Get the Volume map from the Volume field of [seconds since 1970, amount in bitcoin]
          const depositHashArrayRecord: string[] = data?.recordedTransactionHashArray || [];
    
          depositHashArray = depositHashArrayRecord;
        } else {
            console.error('No Deposits saved for this user');
        };

        const utxos = await getUTXOS(address);

        if (utxos) {
          for (const transaction of utxos) {
            if (depositHashArray.includes(transaction.tx_hash)) {
              // This tx has already been saved
            } else {
              // This tx has not been saved
              console.error('Found new transaction, saving');

              if (depositHashArray.length === 1 && depositHashArray[0] === '') {
                depositHashArray = [transaction.tx_hash]
              } else {
                depositHashArray.push(transaction.tx_hash); // Append the new hash to the array
              }
              
          
              //update hash array
              await setDoc(depositsDocRef, {
                recordedTransactionHashArray: depositHashArray,
                }, { merge: true });

                saveTransaction(transaction.value)
              //update balance
              let depositInBitcoin = (transaction.value)*0.00000001
              const newBalanceAvailable = depositInBitcoin+bitcoinBalanceAvailable;
              const btcBalanceDocRef = doc(db, 'cryptos', 'BTC');
              await setDoc(btcBalanceDocRef, {
                [currentUserEmail]: newBalanceAvailable,
                }, { merge: true });

              if (transaction.value < 2100) {
                console.log('dust do not aggregate')
              } else {
                aggregateBitcoin(transaction, address, key);
              }
            }
          };
        };

      } catch (error) {
        console.error('Error retrieving deposits data:', error);
      };
};


const saveTransaction = async (amount: number, transctionType = 'userSentBitcoinOnChain', transctionStatus = 'COMPLETE') => {

  const auth = getAuth();
  const currentUserID = auth.currentUser?.uid
  const currentUserEmail = auth.currentUser?.email

  try {
  const collectionRef = collection(db, 'transactions');
  const updateUserData = addDoc(collectionRef, {
      Type: transctionType,
      Receiver: currentUserID,
      TransactionTime: new Date().toISOString(),
      ReceiverEmail: currentUserEmail,
      AssetSent: 'BTC',
      Sender: '',
      AmountSent: amount,
      PlatformUsed: 'WEB', 
      Status: transctionStatus
  });
  await Promise.all([updateUserData]);
  } catch {
      console.log('Error updating transactions')
  }
}