import { doc, getDoc } from 'firebase/firestore';
import { db } from '..';

export const getVolumeData = async (ticker: string): Promise<{ [key: number]: number }> => {
  try {
    const cryptoDocRef = doc(db, 'priceHistories', ticker);
    const docSnap = await getDoc(cryptoDocRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      // Get the Volume map from the Volume field of [seconds since 1970, amount in bitcoin]
      const volumeMap: { [key: number]: number } = data?.Volume || {};

      return volumeMap;
    } else {
      return {}; // Return an empty map if the document doesn't exist
    }
  } catch (error) {
    console.error('Error retrieving volume data:', error);
    return {}; // Return an empty map in case of an error
  }
};




