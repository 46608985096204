import React, { useState, useEffect } from 'react';
import { BrowserRouter, HashRouter, Routes, Route as Router, Route } from 'react-router-dom';
import Home from './pages/Home';
import BrowseCryptos from './pages/BrowseCryptos';
import NoPage from './pages/NoPage';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Trade from './pages/Trade';
import Explorer from './pages/Explorer';
import Dashboard from './pages/Dashboard';
import Learn from './pages/Learn';
import AntiMoneyLaunderingPolicy from './pages/policies/AntiMoneyLaunderingPolicy';
import PrivacyPolicy from './pages/policies/PrivacyPolicy';
import SecuritiesPolicy from './pages/policies/SecuritiesPolicy';
import TermsOfService from './pages/policies/TermsOfService';
import LogInToVerifyEmail from './pages/LogInToVerifyEmail';
import Notifications from './pages/Notification';
import RedirectFromPayPalCreateNewAsset from './pages/RedirectFromPayPalCreateNewAsset';
import RedirectFromPayPalBuyBitcoin from './pages/RedirectFromPayPalBuyBitcoin';

function App() {
  return (
    <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home/:affiliate" element={<Home />} />
            <Route path="/browse-cryptos" element={<BrowseCryptos />} />
            <Route path="*" element={<NoPage />} />
            <Route path="/trade/:ticker/" element={<Trade />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/learn" element={<Learn />} />
            <Route path="/anti-money-laundering-policy" element={<AntiMoneyLaunderingPolicy />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/securities-policy" element={<SecuritiesPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/log-in/:email" element={<LogInToVerifyEmail />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/redirect-from-paypal-create-new-asset" element={<RedirectFromPayPalCreateNewAsset />} />
            <Route path="/redirect-from-paypal-buy-bitcoin" element={<RedirectFromPayPalBuyBitcoin />} />
            <Route path="/explorer/:ticker/" element={<Explorer />} />
          </Routes>
        </BrowserRouter>
    </div>
  )
}

export default App;
