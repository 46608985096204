import React, { useState, useEffect } from 'react';
import styles from '../../App.module.scss';
import { getAuth, RecaptchaVerifier, PhoneMultiFactorGenerator, PhoneAuthProvider, multiFactor, 
    updatePhoneNumber } from "firebase/auth";
import { getFirestore, updateDoc, doc } from 'firebase/firestore';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { setPhoneVerified } from '../../redux/ephemeralUserData';
import closeButton from '../../assets/closeButton.png';

export default function VerifyPhoneModal() { 

  const db = getFirestore();
  const dispatch = useAppDispatch();
  const [showVerifyPhoneModal, setShowVerifyPhoneModal] = useState(true);
  const [stateVerificationId, setStateVerificationId] = useState<string | undefined>(undefined);

    const [code, setCode] = useState('');
    const [resendTextButtonPressed, setResendTextButtonPressed] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [reCAPTCHAVerified, setReCAPTCHAVerified] = useState(false);
    const auth = getAuth();
    const userData = useAppSelector((state) => state.userData.userData);
    const uid = auth.currentUser?.uid;
    const phoneNumber = userData?.phoneNumber;
    
    auth.languageCode = 'en';

    // Create a phone auth provider
    const phoneAuthProvider = new PhoneAuthProvider(auth);

    // Initialize the reCAPTCHA verifier
    // Use use effect so that the html
    // in this file loads and the recaptcha
    // container is visible
    let recaptchaVerifier: RecaptchaVerifier | undefined;
    let verificationId: string | undefined; 

    useEffect(() => {
        if (reCAPTCHAVerified === false) {
        // Initialize the reCAPTCHA verifier here
        recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'normal',
            'callback': (response: string) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
            setReCAPTCHAVerified(true)
            console.log('calling send verification text');
            sendVerificationText();
            
            },
            'expired-callback': () => {
            // Response expired. Ask the user to solve reCAPTCHA again.
            // ...
            setReCAPTCHAVerified(false);
            }
        }, auth);

            // Render the reCAPTCHA widget manually
            recaptchaVerifier.render().then(() => {
                console.log("reCAPTCHA widget rendered");
            }).catch((error: any) => {
                console.error("Error rendering reCAPTCHA widget", error);
            });
        }
      }, []);

    // Verify the phone number
    const sendVerificationText = () => {
        if (recaptchaVerifier && phoneNumber) {
        phoneAuthProvider
            .verifyPhoneNumber(phoneNumber!, recaptchaVerifier)
            .then((verificationIdResult: string) => { // Modify this line
            // Save the verification ID for later use
            verificationId = verificationIdResult; // Modify this line
            setStateVerificationId(verificationId);
            console.log("Verification code sent verificationId:", verificationId);
            
            // ...
            })
            .catch((error) => {
            // Handle error
            console.log("Error sending verification text to ", phoneNumber, error);
            setErrorMessage("Too many requests, please try again later");
            setReCAPTCHAVerified(false);
            });
        } else {
        console.log("Error with either reCAPTCHAVerified:", reCAPTCHAVerified,
            " OR recaptchaVerifier:", recaptchaVerifier)
        setErrorMessage("Please solve the recaptcha");
        }
    };
        
      const toggleVerifyPhoneModal = () => {
        setShowVerifyPhoneModal(!showVerifyPhoneModal);
      };
      
      const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCode(event.target.value);
      };

      const removeWhitespace = (str: string) => {
        return str.replace(/\s/g, '');
      };

      const handleContinueClick = async () => {
        const cleanedCode = removeWhitespace(code);
        const sixDigitNumberRegex = /^\d{6}$/;
        if (cleanedCode === '') {
          setErrorMessage('Please fill in all fields');
        } else if (!sixDigitNumberRegex.test(cleanedCode)) {
          setErrorMessage('Invalid code');
        } else {
          setErrorMessage('');

          console.log("Trying to verify phone number with reCAPTCHAVerified:", reCAPTCHAVerified,
          " stateVerificationId:", stateVerificationId, " cleanedCode:", cleanedCode)

          try {
            if (stateVerificationId && cleanedCode) {
                const credential = PhoneAuthProvider.credential(stateVerificationId, cleanedCode);
                
                const userDocRef = doc(db, 'users', uid!);
                updateDoc(userDocRef, {
                  PhoneVerified: true,
                })
                  .then(() => {
                    console.log('User document updated successfully');
                    dispatch(setPhoneVerified(true));
                  })
                  .catch((error) => {
                    console.error('Error updating user document:', error);
                  });

                await updatePhoneNumber(auth.currentUser!, credential);
 
            } 
          } catch (error) {
            console.error('Error updating user credentials:', error);
            if (error === 'auth/code-expired') {
              setErrorMessage('The verification code has expired. Please try again.');
            } else {
              setErrorMessage('Invalid code');
            }
          }
        }
      };
      
      const handleResendTextClick = async () => {
        setResendTextButtonPressed(true);

    };

    return (
        <div>

          {showVerifyPhoneModal && (
            <div className={styles['modal-container']}>
              <div className={styles['overlay']} onClick={toggleVerifyPhoneModal}></div>
              <div className={styles['crypto-modal']}>
                <div className={styles['crypto-modal-header']}>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h3>Create Your Account</h3>
                    { reCAPTCHAVerified ? (
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <span>
                        Sending Verification Code To
                        </span>
                        <div>
                        <span>
                                {phoneNumber}
                        </span>
                        </div>
                        </div>
                    ) : (
                        <div>Please solve the reCaptcha</div>
                    )}

                  </div>
                  <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={toggleVerifyPhoneModal} style={{width: '24px', height: '24px'}}></img>

                </div>
                <div className={styles['crypto-modal-content']}>

                <div id='recaptcha-container' style={{ display: reCAPTCHAVerified ? 'none' : 'block' }}></div>

                { reCAPTCHAVerified && (
                        <div className={styles['crypto-modal-textfields']}>
                        <div className={styles['crypto-modal-textfield']}>
                            <label htmlFor="code">Verification Code</label>
                            <input id="code" type="text" value={code} onChange={handleCodeChange} />
                        </div>
                        </div>
                )}
                        
                </div>
                <div className={styles['crypto-modal-footer']} style={{ display: 'flex', flexDirection: 'column', 
                justifyContent: 'center', alignItems: 'center' }}>
                  <label className={styles['error-label']} style={{ textAlign: 'center', marginRight: '10px', 
                  marginBottom: '5px' }}>{errorMessage}</label>

                    { reCAPTCHAVerified && (
                    <button style={{ 
                        backgroundColor: '#007AFF',
                        color: '#222222',
                        display: 'inline-block',
                        padding: '10px 20px',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        borderRadius: '15px',
                        border: 'none',
                        cursor: 'pointer',
                        marginBottom: '10px'
                        }} onClick={handleResendTextClick}>{resendTextButtonPressed ? 'Sent!' : 'Resend Text'}</button>
                    )}
                  <button className={styles['crypto-modal-continue-button']} 
                    style={{ flexGrow: 1 }} onClick={handleContinueClick}>Continue</button>
                </div>
              </div>
            </div>
          )}

    </div>
    );
}