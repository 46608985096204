import React, { useEffect, useState } from 'react';
import rocketImage from '../assets/rocket.png';

// Define the prop types for the component
interface RocketSpinnerProps {
  loading: boolean;
  type: 'small' | 'large';
}

const RocketSpinner: React.FC<RocketSpinnerProps> = ({ loading, type }) => {
  const [rotation, setRotation] = useState<number>(0);

  useEffect(() => {
    let animationFrameId: number;

    const animate = () => {
      setRotation(prevRotation => (prevRotation + 2) % 360);
      animationFrameId = window.requestAnimationFrame(animate);
    };

    if (loading) {
      animate();
    }

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [loading]);

  if (type === 'small') {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', }}>
      <img
        src={rocketImage}
        alt="Loading..."
        style={{
          height: '108px',
          width: '40px',
          marginBottom: '500px',
          transform: `rotate(${rotation}deg)`
        }}
      />
      </div>
      // ... your other content when not loading
    );
  } else {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', }}>
    <img
      src={rocketImage}
      alt="Loading..."
      style={{
        height: '216px',
        width: '80px',
        marginBottom: '500px',
        transform: `rotate(${rotation}deg)`
      }}
    />
    </div>
    // ... your other content when not loading
  );
    }
};

export default RocketSpinner