import React, { useState, useEffect } from 'react';
import styles from '../App.module.scss';
import { isCryptoNameAvailableOffPlatform } from '../Helpers/AssetCreationManager';
import { isCryptoTickerAvailableOffPlatform } from '../Helpers/AssetCreationManager';
import { isCryptoTickerAvailableOnPlatform } from '../Helpers/AssetCreationManager';
import { isABadWord } from '../Helpers/AssetCreationManager';
import rocketImage from '../assets/rocket.png';
import rocketWithFireImage from '../assets/RocketWithFire.png';
import { getAuth } from "firebase/auth";
import { cryptoForTradingData } from './CurrentUserInfo';
import { getFirestore, doc, setDoc, addDoc, collection, getDoc } from 'firebase/firestore';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { createNewCryptoTestLaunch, setUnsavedCryptoData } from '../redux/ephemeralCrypto';
import { sethomeStarAnimationState } from '../redux/animationControl';
import { setNumCryptoAlloted } from '../redux/ephemeralUserData';
import { useNavigate } from 'react-router-dom';
import closeButton from '../assets/closeButton.png';
import backButton from '../assets/backButton.png';
import { httpsCallable } from "firebase/functions";
import { auth, functions } from "..";
import RocketAnimation from '../components/RocketSpinAnimation';
import { formatDollarNumber } from '../Helpers/FormatNumbers';
import { getPSTDate } from '../Helpers/DateAndTime';

interface CreateCryptoModalsProps {
  buttonType: 'tryItFree' | 'createNewCrypto' | 'Begin';
  buttonTextColor: string;
  buttonBackgroundColor: string;
}

export default function CreateCryptoModals(props: CreateCryptoModalsProps) {

  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const db = getFirestore();

  let currentUserCreationDate = useAppSelector((state) => state.userData.userData?.creationDate);
  let currentUserUsername = useAppSelector((state) => state.userData.userData?.userName);
  let currentUserIsTestUser = useAppSelector((state) => state.userData.userData?.testUser);
  let currentUserEmail = String(useAppSelector((state) => state.userData.userData?.email));
  let numCoinOriginated = useAppSelector((state) => state.userData.userData?.CryptosOriginated);
  let numCoinAlloted = useAppSelector((state) => state.userData.userData?.CryptosAlloted);
  const currentUserBitcoinAddress = useAppSelector((state) => state.userData.userData?.bitcoinDepositAddress)

  const [priceToCreateNewAssetInUSD, setpriceToCreateNewAssetInUSD] = useState(120);
  const [priceToCreateNewAssetInUSDLoading, setpriceToCreateNewAssetInUSDLoading] = useState(true);
  const [currentUserBTCBalanceAvailable, setcurrentUserBTCBalanceAvailable] = useState(0.0);
  const [bitcoinAddressCopied, setbitcoinAddressCopied] = useState(false);
  const [paymentProcessing, setpaymentProcessing] = useState(false);

  const [showCryptoModal, setShowCryptoModal] = useState(false);
  const [showCrypto2Modal, setShowCrypto2Modal] = useState(false);
  const [showCrypto3Modal, setShowCrypto3Modal] = useState(false);
  const [showSmoke, setShowSmoke] = useState(false);
  const [cryptoName, setCryptoName] = useState('');
  const [cryptoTicker, setCryptoTicker] = useState('');
  const [cryptoSupply, setCryptoSupply] = useState('');
  const [cryptoPrice, setCryptoPrice] = useState('');
  const [cryptoInitialAmountForSale, setCryptoInitialAmountForSale] = useState('');
  const [cryptoValuationInUSD, setCryptoValuationInUSD] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageColor, setErrorMessageColor] = useState('');

  const [isStartButtonHovered, setisStartButtonHovered] = useState(false);

  const [launchButtonActive, setLaunchButtonActive] = useState(false);
  const [testLaunchActivated, settestLaunchActivated] = useState(false);

  const [loadingPayPalData, setloadingPayPalData] = useState(false);
  const [showPayPalLinkPopup, setShowPayPalLinkPopup] = useState(false);
  const [payPalLink, setPayPalLink] = useState('');

  const startLaunchButtonTimer = () => {
    setTimeout(() => {
       setLaunchButtonActive(true);
    }, 2000);
 };

  const getPriceToCreateNewAsset = async () => {
    try {
      const fetchPriceToCreateNewAsset = httpsCallable(functions, "getPriceToCreateNewAsset");
      const response = await fetchPriceToCreateNewAsset({});
  
      setpriceToCreateNewAssetInUSD(response.data as number);
      setpriceToCreateNewAssetInUSDLoading(false);

    } catch (error) {
      console.error(error);
    }
  };

  const getCurrentUserBTCBalanceAvailable = async () => {
    try {
      const cryptoDocRef = doc(db, 'cryptos', 'BTC');
      const docSnap = await getDoc(cryptoDocRef);
  
      if (docSnap.exists()) {
        const data = docSnap.data();
        const balanceAvailableData = currentUserEmail;
  
        const balanceAvailable = data?.[balanceAvailableData] || 0.0;
  
        setcurrentUserBTCBalanceAvailable(balanceAvailable)

      } else {
        throw new Error(`Ticker document does not exist.`);
      }
    } catch (error) {
      console.error('Error retrieving user balances:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (currentUserCreationDate) {
      getPriceToCreateNewAsset();
      getCurrentUserBTCBalanceAvailable();
    }
  }, [currentUserCreationDate]);

 useEffect(() => {
  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    if (scrollPosition >= 270) {
      // Do something when the scroll is 150px from the top
      setShowCryptoModal(false);
      setShowCrypto2Modal(false);
      setShowCrypto3Modal(false);
    }
  };

  // Add event listener
  window.addEventListener('scroll', handleScroll);

  // Remove event listener on cleanup
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

  const handleStartButtonMouseEnter = () => {
    setisStartButtonHovered(true);
  };

  const handleStartButtonMouseLeave = () => {
    setisStartButtonHovered(false);
  };

  const uid = getAuth().currentUser?.uid;

  const bitcoinPriceInUSD = useAppSelector((state) => state.bitcoinPriceData.value)


  const handleModalCloseClick = () => {
    setShowCryptoModal(false);
    setShowCrypto2Modal(false);
    setShowCrypto3Modal(false);
    setErrorMessage('');
    setbitcoinAddressCopied(false);
    settestLaunchActivated(false)
    //dispatch(sethomeStarAnimationState(true));
   };


    const handleBeginButtonClick = () => {
      setShowCryptoModal(true);
      window.scrollTo(0, 0);
    };
  
    const handleCrypto2ModalBackClick = () => {
      
      setShowCrypto2Modal(false);
      setShowCryptoModal(true);
    };
  
    const handleCrypto3ModalBackClick = () => {
      setShowCrypto3Modal(false);
      setShowCrypto2Modal(true);
      setLaunchButtonActive(false);
    };

    const handleCryptoNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCryptoName(event.target.value);
      let error = validateFields1(event.target.value, cryptoTicker)
      if (error) {
        setErrorMessage(error)
        setErrorMessageColor("white")
      } else {
        setErrorMessage('')
      }
    };
  
    const handleCryptoTickerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value
      setCryptoTicker(newValue.toUpperCase().substring(0, 6));
      let error = validateFields1(cryptoName, event.target.value)
      if (error) {
        setErrorMessage(error)
        setErrorMessageColor("white")
      } else {
        setErrorMessage('')
      }


    };
  
  
    const handleCryptoSupplyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let newAmount = event.target.value
      setCryptoSupply(newAmount);
      let error = validateFields2(newAmount, cryptoPrice, cryptoInitialAmountForSale)
      if (error) {
        setErrorMessage(error)
        setErrorMessageColor("white")
      } else {
        setErrorMessage('')
      }

    };
  
    const handleCryptoInitialAmountForSaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let newAmount = event.target.value
      setCryptoInitialAmountForSale(newAmount);
      let error = validateFields2(cryptoSupply, cryptoPrice, newAmount)
      if (error) {
        setErrorMessage(error)
        setErrorMessageColor("white")
      } else {
        setErrorMessage('')
      }
    };
  
    const handleCryptoPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let newPrice = event.target.value
      setCryptoPrice(event.target.value);
      let error = validateFields2(cryptoSupply, newPrice, cryptoInitialAmountForSale)
      if (error) {
        setErrorMessage(error)
        setErrorMessageColor("white")
      } else {
        setErrorMessage('')
      }
    };
  
    const handleContinueButtonClick = () => {
      // Handle continue button click logic here
      handleCryptoModalSubmit();
    };
  
    const handleContinue2ButtonClick = () => {
      // Handle continue button click logic here
      handleCrypto2ModalSubmit();
    };
    
    const handleCryptoPrice10DollarClick = () => {
      let newPrice = (10.0 * (1.0 / bitcoinPriceInUSD)).toFixed(8).replace(/\.?0+$/, '')
      setCryptoPrice(newPrice);
      let error = validateFields2(cryptoSupply, newPrice, cryptoInitialAmountForSale)
      if (error) {
        setErrorMessage(error)
        setErrorMessageColor("white")
      } else {
        setErrorMessage('')
      }
    };
    
    const handleCryptoPrice1DollarClick = () => {
      let newPrice = (1.0 * (1.0 / bitcoinPriceInUSD)).toFixed(8).replace(/\.?0+$/, '')
      setCryptoPrice(newPrice);
      let error = validateFields2(cryptoSupply, newPrice, cryptoInitialAmountForSale)
      if (error) {
        setErrorMessage(error)
        setErrorMessageColor("white")
      } else {
        setErrorMessage('')
      }
    };
    
    const handleCryptoPrice1TenthDollarClick = () => {
      let newPrice = (0.1 * (1.0 / bitcoinPriceInUSD)).toFixed(8).replace(/\.?0+$/, '')
      setCryptoPrice(newPrice);
      let error = validateFields2(cryptoSupply, newPrice, cryptoInitialAmountForSale)
      if (error) {
        setErrorMessage(error)
        setErrorMessageColor("white")
      } else {
        setErrorMessage('')
      }
    };
    
    const handleCryptoPrice1HundrethDollarClick = () => {
      let newPrice = (0.01 * (1.0 / bitcoinPriceInUSD)).toFixed(8).replace(/\.?0+$/, '')
      setCryptoPrice(newPrice);
      let error = validateFields2(cryptoSupply, newPrice, cryptoInitialAmountForSale)
      if (error) {
        setErrorMessage(error)
        setErrorMessageColor("white")
      } else {
        setErrorMessage('')
      }
    };


    const handleDashboardClicked = () => {
      handleModalCloseClick()
      window.location.href = '/dashboard';
    }

    interface PaymentResponse {
      id: string;
      links: Array<{ href: string; rel: string; method: string }>;
    }
    
    interface MyCallableResult {
      paymentResponse: PaymentResponse;
    }


    const handleBuyWithCardButtonPressed = async () => {
      setloadingPayPalData(true);
      try {
        const newPayPalOrderLink = httpsCallable(functions, "authenticateAndCreatePayPalOrder");

        const redirectURL = "https://finfriend.com/redirect-from-paypal-create-new-asset"
        const amount = priceToCreateNewAssetInUSD
        newPayPalOrderLink({ redirectURL, amount })
        .then(async (result) => {
          const typedResult = result.data as MyCallableResult;
  
          // Extract ID
          const paymentId = typedResult.paymentResponse.id;

          await saveNewOrder(paymentId);
          // Find the payer action link
          const payerActionLink = typedResult.paymentResponse.links.find(link => link.rel === 'payer-action');
  
          setloadingPayPalData(false);
          if (payerActionLink && payerActionLink.href) {
            // Redirect the user to the payer action URL in a new tab
            askToOpenPayPalLink(payerActionLink.href);
          } else {
            // Handle the case where the payer action link is not found
            console.error('Payer action link not found');
          }
        })
        .catch((error) => {
          setloadingPayPalData(false);
        });
      } catch (error) {
        console.error(error);
      }
      
    };


    const saveNewOrder = async (orderId: string) => {
      // Save new order
      const paymentCollectionRef = doc(db, 'createNewAssetPurchases', orderId);
      const updatePayments = setDoc(paymentCollectionRef, {
        PaymentType: "Credit Card",
        PlatformUsed: "WEB",
        PricePayedInUSD: Number(priceToCreateNewAssetInUSD.toFixed(2)),
        PurchaserEmail: currentUserEmail,
        TransactionTime: new Date().toISOString(),
        UserID: uid,
        Status: "UNAPPROVED"
      }, {merge: true}); 

      await Promise.all([updatePayments]);
}

const askToOpenPayPalLink = (link: string) => {
  setPayPalLink(link);
  setShowPayPalLinkPopup(true);
};

    const handleBuyWithBitcoinButtonPressed = async () => { 

      if (!paymentProcessing) {
        console.log("currentUserBTCBalanceAvailable", currentUserBTCBalanceAvailable)
        if (currentUserBTCBalanceAvailable > (priceToCreateNewAssetInUSD/bitcoinPriceInUSD)) {
          //The user has adequate funding
          console.log("Handling purchase")

          await handleBitcoinPurchase();
        } else {
          handleCopyBitcoin()
        }
      }
    };

    const handleTestLaunchPressed = async () => {
      settestLaunchActivated(true)
      setShowCryptoModal(true)
    }

    const handleCopyBitcoin= () => {
      
      if (currentUserBitcoinAddress) {
        navigator.clipboard.writeText(currentUserBitcoinAddress)
          .then(() => {
            console.log('Bitcoin address copied to clipboard');
            setbitcoinAddressCopied(true);
            setErrorMessage("");
          })
          .catch(err => {
            console.error('Error in copying text: ', err);
            setErrorMessage("Error with copy clipboard");
          });
      } else {
        console.log('No Bitcoin address to copy');
        // Handle the case where there is no Bitcoin address
        setErrorMessage("Error getting your data please try again later.");
      }
    };
    
    const handleBitcoinPurchase = async () => {

      //start animation while payment processing

      setpaymentProcessing(true);

      await getCurrentUserBTCBalanceAvailable()
      if (currentUserBTCBalanceAvailable < priceToCreateNewAssetInUSD/bitcoinPriceInUSD) {
        setErrorMessage('Insufficient balance');
        setpaymentProcessing(false);
      } else {
        const newUserBTCBalance = currentUserBTCBalanceAvailable - (priceToCreateNewAssetInUSD/bitcoinPriceInUSD);

        setErrorMessage('');

        try {

            const transactionCollectionRef = collection(db, 'transactions');
            const updateBitcoinTransactionData = addDoc(transactionCollectionRef, {
                Type: 'createNewAssetPurchase',
                Receiver: 'FinFriend',
                Sender: uid,
                TransactionTime: new Date().toISOString(),
                ReceiverEmail: 'finfriend@finfriend.com',
                SenderEmail: currentUserEmail,
                AssetSent: 'BTC',
                AmountSent: (priceToCreateNewAssetInUSD/bitcoinPriceInUSD),
                PlatformUsed: 'WEB'
            });

          const cryptoDocRef = doc(db, 'cryptos', 'BTC');
          const updateBalance = setDoc(cryptoDocRef, {
            [currentUserEmail!]: Number((newUserBTCBalance).toFixed(8))
          }, { merge: true });

          const paymentCollectionRef = collection(db, 'createNewAssetPurchases');
          const updatePayments = addDoc(paymentCollectionRef, {
            PaymentType: "Bitcoin",
            PlatformUsed: "WEB",
            PricePayedInBTC: Number((priceToCreateNewAssetInUSD/bitcoinPriceInUSD).toFixed(8)),
            PricePayedInUSD: Number(priceToCreateNewAssetInUSD.toFixed(2)),
            PurchaserEmail: currentUserEmail,
            TransactionTime: getPSTDate(),
            UserID: uid,
            Status: "COMPLETED"
          });

          let updatedNumCoinAlloted;

          try {
            if (typeof numCoinAlloted === 'number') {
                updatedNumCoinAlloted = numCoinAlloted + 1;

                
                const userDocRef = doc(db, 'users', uid!);
                const updateUserData = setDoc(userDocRef, {
                  CryptosAlloted: updatedNumCoinAlloted
                }, { merge: true });
            
                await Promise.all([updateBalance, updatePayments, updateUserData, updateBitcoinTransactionData]);
      
                dispatch(setNumCryptoAlloted(updatedNumCoinAlloted));
                
                setpaymentProcessing(false);
      
                setcurrentUserBTCBalanceAvailable(newUserBTCBalance);

            } else {
              setErrorMessage('We could not process your payment please try again later');
              setpaymentProcessing(false);
            }
          } catch {
            setErrorMessage('We could not process your payment please try again later');
            setpaymentProcessing(false);
          }


        } catch {
          setErrorMessage('Network error please contact support');
          setpaymentProcessing(false);
        }

        
      }
    };

    function validateFields1(cryptoName: string, cryptoTicker: string) {
      const alphanumericRegex = /^[a-zA-Z0-9 ]+$/;
      if (cryptoName === '' || cryptoTicker === '') {
        return 'Please fill in all fields';
      } else if (cryptoTicker.length > 6) {
        return 'Your ticker must be less than 7 characters';
      } else if (cryptoName.length > 16) {
        return 'The name must be less than 17 characters';
      } else if (!isCryptoNameAvailableOffPlatform(cryptoName)) {
        return 'Please choose a name that does not belong to a well known organization';
      } else if (!isCryptoTickerAvailableOffPlatform(cryptoTicker.toUpperCase())){
        return 'Please choose a ticker that is not already a successful business or crypto'
      } else if (!alphanumericRegex.test(cryptoName) || !alphanumericRegex.test(cryptoTicker)) {
        return 'No special characters or emojis allowed';
      } else if (isABadWord(cryptoName)) {
        return 'Offensive string detected in the name'
      } else if (isABadWord(cryptoTicker)) {
        return 'Offensive string detected in the ticker'
      } else if (!isCryptoTickerAvailableOffPlatform(cryptoTicker.toUpperCase())){
          setErrorMessage('Please choose a ticker that is not already a successful business or crypto')
        } 
      return null;
    }

    function validateFields2(supplyString: string, priceString: string, initialAmountForSale: string) {
      if (supplyString === '' || priceString === '' || initialAmountForSale === '') {
        return 'Please fill in all fields';
      } 
      const supplyInt = parseInt(supplyString.replace(/,/g, ''));
      const priceFloat = parseFloat(priceString.replace(/,/g, ''));
      const initialAmountForSaleFloat = parseFloat(initialAmountForSale.replace(/,/g, ''));
      if (isNaN(supplyInt) || supplyInt <= 0 || !Number.isInteger(supplyInt)) {
        return ('Please enter a valid whole number for your total supply');
      } else if (isNaN(priceFloat) || priceFloat < 0.00000001) {
        return ('Please enter a price greater than 0.00000001');
      } else if (isNaN(initialAmountForSaleFloat) || initialAmountForSaleFloat <= 0) {
        return ('Please enter a valid positive number for your Initial Amount for Sale');
      } else if (supplyInt > 100000000000) {
        return ('Total supply must be less than 100 billion');
      } else if (supplyInt < initialAmountForSaleFloat) {
        return ('You are selling more than the supply');
      } else if (priceFloat > 1) {
        return ('Please make the initial price less than 1 Bitcoin');
      } else if (supplyInt*priceFloat*bitcoinPriceInUSD > 10000000000) {
        return ('Total valuation must be less than $10 billion');
      } else if (supplyInt*priceFloat*bitcoinPriceInUSD < 99) {
        return ('Total valuation must be more than $100');
      } else {
    return null
    }
  }

    const handleCryptoModalSubmit = async () => {

      let error = validateFields1(cryptoName, cryptoTicker)
      if (error) {
        setErrorMessage(error)
        setErrorMessageColor("#FF3B30")
      } else {
        const isTickerAvailableOnPlatform = await isCryptoTickerAvailableOnPlatform(cryptoTicker.toUpperCase());
        if (!isTickerAvailableOnPlatform) {
          setErrorMessage('This ticker symbol is already taken');
        } else {
          setErrorMessage('');
          setCryptoTicker(cryptoTicker.toUpperCase());
          setShowCryptoModal(false);
          setShowCrypto2Modal(true);
        }
      }
    };
  
    const handleCrypto2ModalSubmit = () => {

      let error = validateFields2(cryptoSupply, cryptoPrice, cryptoInitialAmountForSale)
      if (error) {
        setErrorMessage(error)
        setErrorMessageColor("#FF3B30")
      } else {

        const supplyInt = parseInt(cryptoSupply.replace(/,/g, ''));
        const priceFloat = parseFloat(cryptoPrice.replace(/,/g, ''));
        const initialAmountForSaleFloat = parseFloat(cryptoInitialAmountForSale.replace(/,/g, ''));
          
      
        startLaunchButtonTimer();

        setErrorMessage('');

        const formattedValue = (supplyInt*priceFloat*bitcoinPriceInUSD).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).replace(/\.?0+$/, '');

        setCryptoValuationInUSD(formattedValue);
        setCryptoSupply(supplyInt.toString()); // set the validated value of cryptoSupply
        setCryptoInitialAmountForSale((initialAmountForSaleFloat.toFixed(8).replace(/\.?0+$/, '')).toString()); // set the validated value of amountForSale
        setCryptoPrice((priceFloat.toFixed(8).replace(/\.?0+$/, '')).toString()); // set the validated value of cryptoPrice
        // handle successful submission
        setShowCrypto2Modal(false);
      setShowCrypto3Modal(true);
      dispatch(sethomeStarAnimationState(false));
      }
    };

    const handleCryptoLaunchButtonClick = async () => {

      console.log("launch button clicked with testLaunchActivated", testLaunchActivated)
      if (launchButtonActive) {
      if (showSmoke) {
        return;
      }

      
      setShowSmoke(true);

      const currentDate = new Date();
      const currentDateInSeconds = Math.ceil(currentDate.getTime() / 1000);
      
      const originationDate = currentDate.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
        timeZoneName: 'short',
      });


      if (currentUserCreationDate && !testLaunchActivated) {
        const currentUserEmailORDER = `${getAuth().currentUser?.email}ORDER`;
      
        const newCryptoForSaving = {
          Ticker: cryptoTicker,
          Name: cryptoName,
          Supply: Number(cryptoSupply),
          OriginationDate: new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
          OriginatorUsername: String(currentUserUsername),
          isUsingCustomIcon: false,
          Price: Number(cryptoPrice),
          Description: '',
          IsTestCrypto: currentUserIsTestUser,
          OriginatorUID: uid,
          InitialAmountForSale: Number(cryptoInitialAmountForSale),
          InitialPrice: Number(cryptoPrice),
          [currentUserEmailORDER]: Number(cryptoInitialAmountForSale),
          [currentUserEmail]: (Number(cryptoSupply)-Number(cryptoInitialAmountForSale))
        };
      
        const newOrder = {
          Ticker: cryptoTicker,
          Originator: currentUserEmail,
          Unfilled: Number(cryptoInitialAmountForSale),
          Filled: 0,
          Price: Number(cryptoPrice),
          OriginationDate: originationDate,
          SellOrder: true

        };
      
        const userDocRef = doc(db, 'users', uid!);
        const cryptoDocRef = doc(db, 'cryptos', cryptoTicker);
        const orderCollectionRef = collection(db, 'orders');
        const priceHistoryDocRef = doc(db, 'priceHistories', cryptoTicker);
      
        if (numCoinOriginated) {
          numCoinOriginated++;
        } else {
          numCoinOriginated = 1
        }
        
        try {
          const updateUserData = setDoc(userDocRef, {
            CryptosOriginated: numCoinOriginated
          }, { merge: true });
      
          const saveCrypto = setDoc(cryptoDocRef, newCryptoForSaving);
      
          const newOrderDocRef = addDoc(orderCollectionRef, newOrder);
      
          const savePriceHistory = setDoc(priceHistoryDocRef, {
            OriginationDate: currentDateInSeconds,
            OriginationPrice: Number(cryptoPrice)
          });
      
          await Promise.all([updateUserData, saveCrypto, newOrderDocRef, savePriceHistory]);
      
          console.log('All database operations completed successfully');
          // Continue with any further logic or actions
          //navigate(`/trade/${cryptoTicker}`);

        } catch (error) {
          console.error('Error performing database operations:', error);
          // Handle the error
        }
      } else {

        //The user has not finished setting up account
        const newEphemeralCryptoData: cryptoForTradingData = {
          Ticker: cryptoTicker,
          Name: cryptoName,
          Supply: Number(cryptoSupply),
          OriginationDate: new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
          OriginatorUsername: 'Satoshi Nakamoto',
          isUsingCustomIcon: false,
          Price: Number(cryptoPrice),
          Description: '',
          RecentlyViewedBy: [''],
          LikedBy: [],
          OriginatorUID: '',
        }
        dispatch(setUnsavedCryptoData(newEphemeralCryptoData));

        //Dont save crypto
        dispatch(createNewCryptoTestLaunch());

        //navigate(`/trade/${cryptoTicker}`);

        //increment num cryptos

        // UNCOMMENT BEFORE GOING LIVE
        const docRef = doc(db, 'offlineCryptosMade', 'offlineCryptosMade');

        
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            const updatedValue = (data?.offlineCryptosMadeWeb || 0) + 1;
            await setDoc(docRef, {
              offlineCryptosMadeWeb: updatedValue,
            }, { merge: true });
            console.log('Field updated successfully!');
          } else {
            console.log('Document does not exist.');
          }
        } catch (error) {
          console.error('Error updating field:', error);
        }

        
      }
      

      setTimeout(() => {
        setShowCrypto3Modal(false);
        dispatch(sethomeStarAnimationState(true));
        navigate(`/trade/${cryptoTicker}`);
      }, 3000);
    }
    };

    const renderGrayCircles = () => {
      if (showSmoke) {
        const circles = Array.from({ length: 100 }).map((_, index) => {
          const circleSize = Math.floor(Math.random() * 11) + 10;
          const circleDuration = Math.random() * 3 + 1;
          const translateX = Math.random() * 1000 - 550; // Adjust the range of movement in the X-axis
          const translateY = Math.random() * 600 - 300;
    
          const style: React.CSSProperties = {
            position: 'absolute',
            opacity: 1, // Start with full opacity
            width: `${circleSize}px`,
            height: `${circleSize}px`,
            transform: `translate(-50%, -50%)`,
            animation: `floatingAnimation${index} ${circleDuration}s forwards linear, fadeOutAnimation${index} ${circleDuration}s forwards linear`,
            background: 'gray',
            borderRadius: '50%',
            top: '80.67%', // Adjust the vertical position based on your needs
            left: '50%',
          };
    
          const keyframes = `
            @keyframes floatingAnimation${index} {
              0% {
                transform: translate(-50%, -50%);
              }
              100% {
                transform: translate(${translateX}%, ${translateY}%);
              }
            }
    
            @keyframes fadeOutAnimation${index} {
              0% {
                opacity: 1;
              }
              100% {
                opacity: 0;
              }
            }
          `;
    
          // Inject keyframes into the document dynamically
          const styleSheet = document.createElement('style');
          styleSheet.innerText = keyframes;
          document.head.appendChild(styleSheet);
    
          return (
            <div
              key={index}
              className={`floating-animation${index}`}
              style={style}
            />
          );
        });
    
        return (
          <div
            style={{
              position: 'absolute',
              top: '66.67%', // Adjust the vertical position based on your needs
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {circles}
          </div>
        );
      }
    
      return null;
    };

    const rocketStyle = {
      maxWidth: '100%',
      maxHeight: '100%',
      width: '70px',
      height: '200px',
      transition: 'transform 1s linear',
      transform: showSmoke ? 'translateY(-70vh)' : 'translateY(0)',
    };
    
    const errorLabelText = () => {
      if (errorMessage) {
        return <label className={styles['error-label']} style={{ textAlign: 'center', 
        marginRight: '10px', marginBottom: '10px', color: errorMessageColor, }}>{errorMessage}</label>
      } else {
        return <div className={styles['error-label']} style={{ visibility: 'hidden', marginBottom: '10px',  }}>$</div>;
      }
    };

    let startingButton;
      switch (props.buttonType) {
        case 'Begin':
          startingButton = (
            <div>
              <button 
                style={{
                  backgroundColor: isStartButtonHovered ? 'transparent' : `${props.buttonBackgroundColor}`,
                  color: isStartButtonHovered ? 'white' : `${props.buttonTextColor}`,
                  border: isStartButtonHovered ? `2px solid ${props.buttonBackgroundColor}` : `2px solid ${props.buttonBackgroundColor}`,
                  display: 'inline-block',
                  padding: '10px 20px',
                  fontSize: '32px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  borderRadius: '10px',
                  marginRight: '10px',
                  cursor: 'pointer',
                  textShadow: isStartButtonHovered ?  '2px 2px 2px rgba(0, 0, 0, 0.5)' : '',
                }}              
              onClick={handleBeginButtonClick}
              onMouseEnter={handleStartButtonMouseEnter}
              onMouseLeave={handleStartButtonMouseLeave}>Try it free</button>
            </div>
          );
          break;
          case 'tryItFree':
            startingButton = (
              <div>
                <button 
                style={{
                  backgroundColor: isStartButtonHovered ? 'transparent' : `${props.buttonBackgroundColor}`,
                  color: isStartButtonHovered ? 'white' : `${props.buttonTextColor}`,
                  border: isStartButtonHovered ? `2px solid ${props.buttonBackgroundColor}` : `2px solid ${props.buttonBackgroundColor}`,
                  display: 'inline-block',
                  padding: '10px 20px',
                  fontSize: '32px', 
                  fontWeight: 'bold',
                  textAlign: 'center',
                  borderRadius: '10px',
                  marginRight: '10px',
                  cursor: 'pointer',
                  textShadow: isStartButtonHovered ?  '2px 2px 2px rgba(0, 0, 0, 0.5)' : '',
                }}
                onClick={handleBeginButtonClick}
                onMouseEnter={handleStartButtonMouseEnter}
                onMouseLeave={handleStartButtonMouseLeave}>Try it free</button>
              </div>
            );
            break;
          case 'createNewCrypto':
            startingButton = (
              <div>
              <button
                onClick={handleBeginButtonClick}
                style={{
                  backgroundColor: isStartButtonHovered ? 'transparent' : '#4CD964',
                  color: isStartButtonHovered ?  '#4CD964' : '#222222',
                  display: 'inline-block',
                  padding: '10px 20px',
                  fontSize: '18px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  borderRadius: '10px',
                  border: isStartButtonHovered ? '2px solid #4CD964' : '2px solid #4CD964',
                  width: '185px',
                  cursor: 'pointer',
                }}
                onMouseEnter={handleStartButtonMouseEnter}
                onMouseLeave={handleStartButtonMouseLeave}
              >
                New Asset
              </button>
            </div>
            );
      }
    if (currentUserCreationDate) {
      if (!currentUserIsTestUser) {
        if ((numCoinOriginated! >= numCoinAlloted!) && !testLaunchActivated) {

          //Set up for creating a live asset

          return (

            <div>        
                      {startingButton}
              {showCryptoModal && (
            <div>
            <div className={styles['overlay']} onClick={handleModalCloseClick}></div>
            <div className={styles['crypto-modal']} style={{height: '68vh', overflowY: 'hidden' }}>
            <div className={styles['crypto-modal-header']}>
                <h4 style={{fontSize: '30px',}}>Create A New Asset</h4>



                <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={handleModalCloseClick} style={{width: '24px', height: '24px'}}></img>
              </div>

                {paymentProcessing ? (
                  <>
                   <div className={styles['crypto-modal-content']} style={{ textAlign: 'center', fontSize: '18px', width: '300px' }}>

                   <div style={{ backgroundColor: '#222222' }}>
                    <RocketAnimation loading={true} type={'small'}/>
                    </div>
                    </div>
                  </>
                ) : (
                  <>
                  
                  {priceToCreateNewAssetInUSDLoading ? (
                  <>
                   <div className={styles['crypto-modal-content']} style={{ textAlign: 'center', fontSize: '18px', width: '300px' }}>

                   <div style={{ backgroundColor: '#222222' }}>
                    <RocketAnimation loading={true} type={'small'}/>
                    </div>
                    </div>
                  </>
                ) : (

                  <div className={styles['crypto-modal-content']} style={{ textAlign: 'center', fontSize: '18px', width: '300px' }}>

                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                      <div style={{ fontSize: '20px' }}>$</div>
                      <div style={{ fontSize: '40px', marginLeft: '5px' }}>{priceToCreateNewAssetInUSD}</div>
                    </div>

                    <div style={{ marginLeft: '10px' }}>
                      <div>One time</div>
                      <div>purchase</div>
                    </div>

                  </div>

                  </div>)}
                  </>
                )}

              
              <div className={styles['crypto-modal-footer']}>
                
                {errorLabelText()}

                  <div>

                    {bitcoinAddressCopied ? (
                      


                      <div style={{
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center', 
                        borderRadius: '10px',
                        color: 'white', 
                        textAlign: 'center', 
                        border: 'none', 
                        padding: '4px 8px',
                        marginBottom: '42px',
                        gap: '15px'
                      }}>

                      <div style={{
                        width: '270px',
                        height: '2px',
                        backgroundColor: '#CCCCCC'
                    }}></div>

                      <div style={{ fontSize: '24px' }}>
                        Copied✓
                      </div>
                      <div style={{ fontSize: '14px' }}>
                        {currentUserBitcoinAddress}
                      </div>

                      {(currentUserBTCBalanceAvailable > 0.0000001) ? (
                      <div style={{ fontSize: '24px' }}>
                      Send ${(priceToCreateNewAssetInUSD-(currentUserBTCBalanceAvailable*bitcoinPriceInUSD)).toFixed(2)} more<br></br>
                      Bitcoin!
                    </div>
                      ) : (
                        <div style={{ fontSize: '24px' }}>
                        Send ${priceToCreateNewAssetInUSD} in <br></br>
                        Bitcoin!
                      </div>
                      )}
                    </div>

                    ) : (
                      <>


                      { loadingPayPalData ? (
                        <>
                        
                        <div style={{ backgroundColor: '#222222'}}>

                        <div style={{color: 'white', fontSize: '20px', marginBottom: '30px'}}>
                      Waiting for PayPal
                    </div>
                    
                    <RocketAnimation loading={true} type={'small'}/>
                    </div>
                        </>

                      ) : (
<>


{showPayPalLinkPopup ? (
  <div style={{display: 'flex', 
  flexDirection: 'column', alignItems: 'center', 
  justifyContent: 'center', gap: '20px'}}>
    <p>Continue on PayPal?</p>
    <button
      onClick={() => {
        window.open(payPalLink, '_blank');
        setShowPayPalLinkPopup(false);
      }}
      style={{
        backgroundColor: 'white', 
        color: '#222222', 
        padding: '10px 20px', 
        border: 'none', 
        borderRadius: '5px',
        fontSize: '25px',
        width: '200px',
        cursor: 'pointer'
      }}
    >
      Open PayPal
    </button>
    <button
      onClick={() => setShowPayPalLinkPopup(false)}
      style={{
        backgroundColor: 'transparent', 
        color: 'white', 
        padding: '10px 20px', 
        border: '1px solid white', 
        borderRadius: '5px',
        fontSize: '25px',
        width: '200px',
        cursor: 'pointer'
      }}
    >
      Cancel
    </button>
  </div>
) : (
<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

<button 
    onClick={handleBuyWithCardButtonPressed}
    style={{
        color: '#222222', 
        fontSize: '25px', 
        background: '#E9E9E9', 
        borderRadius: '5px', 
        border: 'none', 
        textAlign: 'center',
        width: '250px',
        cursor: 'pointer', 
        height: '45px'
    }}>
    Buy With Card
</button>

<button 
    onClick={handleBuyWithBitcoinButtonPressed}
    style={{
        color: '#222222', 
        fontSize: '25px', 
        background: '#E9E9E9', 
        borderRadius: '5px', 
        border: 'none', 
        textAlign: 'center',
        width: '250px',
        cursor: 'pointer', 
        height: '45px',
        marginTop: '30px'
    }}>
    Buy With Bitcoin
</button>

<hr style={{
width: '270px', 
height: '1.5px', 
border: 'none', 
backgroundColor: '#444444',
marginTop: '35px'
}} />

<button 
    onClick={handleTestLaunchPressed}
    style={{
        color: 'white', 
        fontSize: '25px', 
        background: '#222222', 
        borderRadius: '5px', 
        border: 'none', 
        textAlign: 'center',
        width: '250px',
        cursor: 'pointer', 
        height: '45px',
        marginTop: '0px'
    }}>
    Test Launch
</button>

</div>
)}



</>
                        
                      )}

                       </>
                    )}





                </div>
              </div>
            </div>
          </div>        
          )}

          </div>

          )
        }
      }
    }
  
    return (
        <div>

        {startingButton}

        {showCryptoModal && (
          <div className={styles['modal-container']}>
            <div className={styles['overlay']} onClick={handleModalCloseClick}></div>
            <div className={styles['crypto-modal']}>
              <div className={styles['crypto-modal-header']}>
                <h3 style={{textAlign: 'center', fontSize: '30px'}}>Create An Asset</h3>
                {
                    currentUserCreationDate && (
                      (numCoinOriginated! < numCoinAlloted!) && (
                        (!testLaunchActivated) && (
                        <>
                        <div style={{color: 'white', fontSize: '16px', textAlign: 'center'}}>Thanks for your purchase.</div>
                        <div style={{color: 'white', fontSize: '16px', textAlign: 'center'}}>This one's live! </div>
                        </>
                      )
                    )
                    )
                }
                
                <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={handleModalCloseClick} style={{width: '24px', height: '24px'}}></img>
              </div>
              <div className={styles['crypto-modal-content']}>
              <div className={styles['crypto-modal-textfields']}>
                  <div className={styles['crypto-modal-textfield']}>
                    <label>Name</label>
                    <input id="crypto-name" type="text" value={cryptoName} onChange={handleCryptoNameChange} autoComplete="off" />
                  </div>
                  <div className={styles['crypto-modal-textfield']}>
                    <label>Ticker (Unique Symbol)</label>
                    <input id="crypto-ticker" type="text" value={cryptoTicker} onChange={handleCryptoTickerChange} autoComplete="off" />
                  </div>
                </div>
              </div>
              <div className={styles['crypto-modal-footer']}>
              {errorLabelText()}
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <button className={styles['crypto-modal-continue-button']} onClick={handleContinueButtonClick}>Continue</button>
                </div>
              </div>
            </div>
          </div>
        )}
  
        {showCrypto2Modal && (
  <div>
    <div className={styles['overlay']} onClick={handleModalCloseClick}></div>
    <div className={styles['crypto-modal']} style={{height: '72vh'}}>
      <div className={styles['crypto-modal-header']}>
        <h3 style={{textAlign: 'center', fontSize: '30px'}}>Create An Asset</h3>
        <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={handleModalCloseClick} style={{width: '24px', height: '24px'}}></img>
        <img src={backButton} className={styles['crypto-modal-back-button']} alt="Back" onClick={handleCrypto2ModalBackClick} style={{width: '24px', height: '24px'}}></img>
      </div>
      <div className={styles['crypto-modal-content']}>
          <div style={{marginTop: '-15px'}}>
              <div className={styles['crypto-modal-textfield']}>
              <label>Total Supply</label>
              <input type="text" value={cryptoSupply} onChange={handleCryptoSupplyChange} />
              </div>
              <div className={styles['crypto-modal-textfield']}>
              <label>Initial Amount For Sale</label>
              <input type="text" value={cryptoInitialAmountForSale} onChange={handleCryptoInitialAmountForSaleChange} />
              </div>
              <div className={styles['crypto-modal-textfield']}>
              <label>Initial Price In Bitcoin</label>
              <input type="text" value={cryptoPrice} onChange={handleCryptoPriceChange} />
              </div>
              <div className={styles['bitcoin-price-button-row']}>
        <button onClick={handleCryptoPrice10DollarClick}>$10</button>
        <button onClick={handleCryptoPrice1DollarClick}>$1</button>
        <button onClick={handleCryptoPrice1TenthDollarClick}>$0.1</button>
        <button onClick={handleCryptoPrice1HundrethDollarClick}>$0.01</button>
      </div>
          </div>
      </div>
          <div className={styles['crypto-modal-footer']}>
            <div style={{marginTop: '10px'}}>
          {errorLabelText()}
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <button className={styles['crypto-modal-continue-button']} onClick={handleContinue2ButtonClick}>Continue</button>
          </div>
          
          </div>
        </div>
  </div>
  )}
  
  {showCrypto3Modal && (
  <div>
    <div className={styles['overlay']} onClick={handleModalCloseClick}></div>
      <div className={styles['crypto-modal']} style={{height: '77vh'}}>
        <div className={styles['crypto3-modal-header']}>
          <div
          style={{
            visibility: showSmoke ? 'hidden' : 'visible',
            fontSize: '25px',
            fontWeight: 'bold',
          }}
        >
          {cryptoName}
        </div>   
          <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={handleModalCloseClick} style={{width: '24px', height: '24px'}}></img>
          <img src={backButton} className={styles['crypto-modal-back-button']} alt="Back" onClick={handleCrypto3ModalBackClick} style={{width: '24px', height: '24px'}}></img>
        </div>
        <div style={{ fontSize: '16px'}}>

          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', 
            alignItems: 'center', marginBottom: '20px', visibility: showSmoke ? 'hidden' : 'visible' }}>
              
              <div>
          <label>Ticker: {cryptoTicker}</label>
        </div>

            {currentUserEmail && currentUserEmail !== "undefined" && (
        <div>
          <label>Owner: {currentUserEmail}</label>
        </div>
      )}
            <div>
              <label>Supply: {parseFloat(cryptoSupply).toLocaleString('en-US')}</label>
            </div>
            <div>
              <label>Initial amount for sale: {parseFloat(cryptoInitialAmountForSale).toLocaleString('en-US')}</label>
            </div>
            <div>
            <label>
              Price per unit: ${formatDollarNumber(Number(cryptoPrice) * bitcoinPriceInUSD)}
            </label>
          </div>
          <div>
            <label>Valuation: ${cryptoValuationInUSD}</label>
          </div>
        </div> 

        <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>


        <div style={{ position: 'relative', width: '70px', height: '200px', marginTop: '50px' }}>

          { showSmoke ? (

          <div>
          <img
            src={showSmoke ? rocketWithFireImage : rocketImage}
            alt="Rocket"
            style={rocketStyle}
          />
          </div>
          ) : (
            <div>
            <img
            src={rocketImage}
            alt="Rocket"
            style={{ maxWidth: '100%', maxHeight: '100%', width: '70px', height: '200px' }}
          />
          </div>

          )}


            {renderGrayCircles()}
        </div>


      </div>
    </div>


  </div>
  <div className={styles['crypto-modal-footer' ]}>
  <button
      className={styles['crypto-modal-launch-button']}
      onClick={handleCryptoLaunchButtonClick}
      style={{
        backgroundColor: '#FF3B30',
        color: '#222222',
        border: '1px solid #FF3B30',
        opacity: launchButtonActive ? 1 : 0.4,
        display: 'inline-block',
        padding: '10px 20px',
        fontSize: '25px',
        marginTop: '5px',
        fontWeight: 'bold',
        textAlign: 'center',
        borderRadius: '10px',
        
        cursor: 'pointer',
      }}
    >
      Launch
    </button>
  </div>
</div>
  </div>
    )}

    </div>
    );
}
