import React, { useState, useEffect, useContext } from 'react';
import styles from '../App.module.scss';
import { functions } from "..";
import { httpsCallable } from "firebase/functions";
import HeaderNav from '../components/Authentication/HeaderNav';
import { setBitcoinPriceData } from '../redux/ephemeralBitcoinPrice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import AssetsDashboardTile from '../components/DashboardComponents/Assets';
import FavoritesDashboardTile from '../components/DashboardComponents/Favorites';
import OrdersDashboardTile from '../components/DashboardComponents/Orders';
import TransactionsTile from '../components/DashboardComponents/Transactions';
import HeaderLeft from '../components/headerLeft';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import SmallScreenLanding from '../components/SmallScreenLanding';
import Header from '../components/Header';

function Dashboard() {

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const getBitcoinPrice = async () => {
    try {
      const fetchBTCPriceInUSD = httpsCallable(functions, "fetchBTCPriceInUSD");
      const response = await fetchBTCPriceInUSD({});

      dispatch(setBitcoinPriceData(1.0/(response.data as number)));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBitcoinPrice();
  }, []);

  const isSmallScreen = window.innerWidth <= 800;

  let currentUserFirstName = useAppSelector((state) => state.userData.userData?.firstName);

  if (isSmallScreen) {
    return (
    <div>
        
    {<SmallScreenLanding/>}

  </div>
    )
  };

    return (

      <div className={styles.App} style={{ backgroundColor: '#222222' }}>
        <div className={styles['dark-gray-background']}>

            <Header/>
          </div>
          <div className={styles['darker-gray-background']} style={{ display: 'flex', 
          justifyContent: 'center', alignItems: 'center', 
          flexDirection: 'column', paddingTop: '70px' }}>

      <div
        style={{
          width: '85vw',
          padding: '20px',
          minHeight: '220px',
          borderRadius: '10px',
          backgroundColor: '#222222',
          marginTop: '50px',
          marginBottom: '30px',
        }}
      >
        <AssetsDashboardTile/>
      </div>
      <div
        style={{
          width: '85vw',
          padding: '20px',
          minHeight: '220px',
          borderRadius: '10px',
          backgroundColor: '#222222',
          marginBottom: '30px',
        }}
      >
        <OrdersDashboardTile/>
      </div>


      <div
        style={{
          width: '85vw',
          padding: '20px',
          minHeight: '220px',
          borderRadius: '10px',
          backgroundColor: '#222222',
          marginBottom: '30px',
        }}
      >
        <TransactionsTile/>
      </div>
      
      <div
        style={{
          width: '85vw',
          padding: '20px',
          minHeight: '220px',
          borderRadius: '10px',
          backgroundColor: '#222222',
          marginBottom: '30px',
        }}
      >
        <FavoritesDashboardTile/>
      </div>
    </div>
    <Footer />
      </div>
    );
    
}


  export default Dashboard;