import React, { useState, useEffect } from 'react';
import styles from '../../App.module.scss';
import { doc, getDocs, collection, updateDoc } from 'firebase/firestore';
import { db } from "../..";
import { cryptoForTradingData } from '../CurrentUserInfo';
import { useAppSelector } from '../../redux/hooks';

interface OrderBookTradeTileProps {
  cryptoData: cryptoForTradingData | null;
}

function OrderBookTradeTile({ cryptoData }: OrderBookTradeTileProps) {

  const bitcoinPriceInDollars = useAppSelector((state) => state.bitcoinPriceData.value)

  const [buyOrders, setBuyOrders] = useState<Order[]>([]);
  const [sellOrders, setSellOrders] = useState<Order[]>([]);

    class Order {
    filled: number;
    originDate: string;
    originatorEmail: string;
    price: number;
    sellOrder: boolean;
    ticker: string;
    unfilled: number;
    orderID: string;
    canceled: number;
  
    constructor(
        filled: number,
        originDate: string,
        originatorEmail: string,
        price: number,
        sellOrder: boolean,
        ticker: string,
        unfilled: number,
        orderID: string,
        canceled: number,
    ) {
      this.filled = filled;
      this.originDate= originDate;
      this.originatorEmail = originatorEmail;
      this.price = price;
      this.sellOrder= sellOrder;
      this.ticker= ticker;
      this.unfilled= unfilled;
      this.orderID= orderID;
      this.canceled=canceled;
    }
  }

  useEffect(() => {
    const getOrderData = async () => {
    const querySnapshot = await getDocs(collection(db, "orders"));
    const data = await Promise.all(querySnapshot.docs.map(async (doc) => {
    const { Filled, OriginationDate, Originator, Price, SellOrder, Ticker, Unfilled, Canceled  } = doc.data();

    //console.log(`Crypto name: ${Name}, icon URL: ${iconUrl}`);

    return new Order(
      Filled,
      OriginationDate,
      Originator,
      Price, 
      SellOrder, 
      Ticker, 
      Unfilled,
      doc.id,
      Canceled
    );
  }).filter((order) => order !== null)); // remove null values

  const filteredBuyOrderData = data
  .filter((order) => !order.sellOrder && order.ticker === cryptoData!.Ticker && (order.unfilled > 0))
  .sort((a, b) => b.price - a.price); // Replace 'price' with the appropriate field

  setBuyOrders(filteredBuyOrderData.filter((order) => order !== null) as Order[]);

  // Sort Sell Orders in Ascending Order
  const filteredSellOrderData = data
    .filter((order) => order.sellOrder && order.ticker === cryptoData!.Ticker && (order.unfilled > 0))
    .sort((a, b) => a.price - b.price); // Replace 'price' with the appropriate field

  setSellOrders(filteredSellOrderData.filter((order) => order !== null) as Order[]);
      
    };
    getOrderData();
}, []);

  return (
        <div>
          <h3 style={{ color: 'white', margin: '4px' }}>Order Book</h3>
          <div style={{display: 'flex', gap: '45px', alignItems: 'center', justifyContent: 'center', marginTop: '20px'}}>
          <h3 style={{color: 'white'}}>Buy</h3>
          <h3 style={{color: 'white'}}>Sell</h3>
        </div>

        <div style={{ display: 'flex', height: `${buyOrders.length * 60}px`, margin: '5px' }}>
        <div style={{backgroundColor: 'rgba(0, 255, 0, 0.25)', width: '50%'}}>
          <div>
            {buyOrders && bitcoinPriceInDollars && buyOrders.length > 0 ? (
            buyOrders.map((order, index) => (
                                
              <div style={{margin: '5px',  borderBottom: '1px solid #888888', color: 'white', fontSize: '15px'}}>
                <div>
                  {(order.unfilled).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 8,
                    }).replace(/\.?0+$/, '')} {cryptoData?.Ticker} 
                </div>
                <div>@ {order.price} BTC ${(order.price*bitcoinPriceInDollars).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 4,
                  }).replace(/\.?0+$/, '')}</div>
              </div>

            ))
            ) : (
              <div style={{ color: 'white', margin: '20px', fontSize: '20px', textAlign: 'center', }}>No buy orders</div> 
            )}
          </div>
        </div>
        <div style={{backgroundColor: 'rgba(255, 0, 0, 0.3)', width: '50%'}}>
        <div>
            {sellOrders && bitcoinPriceInDollars && sellOrders.length > 0 ? (
            sellOrders.map((order, index) => (
                                
              <div style={{margin: '5px',  borderBottom: '1px solid #888888', color: 'white', fontSize: '15px'}}>
                <div>
                  {(order.unfilled).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 8,
                    }).replace(/\.?0+$/, '')} {cryptoData?.Ticker} 
                </div>
                <div>@ {order.price} BTC ${(order.price*bitcoinPriceInDollars).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 4,
                  }).replace(/\.?0+$/, '')}</div>
              </div>

            ))
            ) : (
              <div style={{ color: 'white', margin: '20px', fontSize: '20px', textAlign: 'center', }}>No sell orders</div> 
            )}
          </div>
        </div>
      </div>
    
    </div>

  )

}

export default OrderBookTradeTile;