import React, {useState, useEffect} from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import { ArcElement, CategoryScale, Title, Tooltip } from 'chart.js';


Chart.register(ArcElement, CategoryScale, Title, Tooltip);


interface CustomArc {
  _model?: {
    backgroundColor?: string;
    borderColor?: string;
  };
  _datasetIndex?: number;
  _index?: number;
  custom?: {
    backgroundColor?: string;
    borderColor?: string;
  };
}

interface PieChartProps {
    chartData: Record<string, number>; // Specify more detailed type based on your data structure
    totalSupply: number;
    options?: any;  // Optional, specify more detailed type based on your configuration needs
  }

const PieChartComponent = ({ chartData, totalSupply }: PieChartProps) => {
  // Assume data is defined here


  interface ProcessedChartData {
    labels: string[];
    dataPoints: number[];
}
  

const [processedData, setProcessedData] = useState<ProcessedChartData>({
    labels: [],
    dataPoints: []
});

useEffect(() => {
    const threshold = 0.05 * totalSupply;
    let otherTotal = 0;
    let newData: [string, number][] = [];

    const sortedData = Object.entries(chartData)
        .sort((a, b) => b[1] - a[1]);  // Sort descending by the value

    sortedData.forEach(([label, value]) => {
        if (value < threshold) {
            otherTotal += value;
        } else {
            newData.push([label, value]);
        }
    });

    if (otherTotal > 0) {
        newData.push(['Other', otherTotal]);
    }

    setProcessedData({
        labels: newData.map(entry => entry[0]),
        dataPoints: newData.map(entry => entry[1])
    });

}, [chartData, totalSupply]);


    let backgroundColors: string[] = [];

    backgroundColors.push('#BAE1FF'); // Pastel blue
    backgroundColors.push('#FFB3BA'); // Pastel red
    backgroundColors.push('#BAFFC9'); // Pastel green
    backgroundColors.push('#FFDFBA'); // Pastel orange
    backgroundColors.push('#FFFFBA'); // Pastel yellow
    backgroundColors.push('#D9BAFF'); // Pastel violet
    backgroundColors.push('#FFBAF8'); // Pastel pink
    backgroundColors.push('#B5BAFF'); // Pastel indigo


    const data = {
        labels: processedData.labels,
        datasets: [
          {
            label: 'Amount',
            data: processedData.dataPoints,
            backgroundColor: backgroundColors,
            borderColor: [
              'rgba(255, 99, 132, 0)',
              // ... other border colors
            ]
          }
        ]
      };


  const options = {
    responsive: true,
    legend: {
      display: true,
      position: 'right',
      labels: {
        generateLabels: function (chart: Chart) {
          const data = chart.data;
          if (data.labels?.length && data.datasets.length) {
            return data.labels.map((label, i) => {
              const meta = chart.getDatasetMeta(0);
              const ds = data.datasets[0];
              const arc = meta.data[i] as unknown as CustomArc;
              const arcOpts = chart.options.elements!.arc;
              const custom = arc?.custom || {};
              const fill = custom.backgroundColor || arc?._model?.backgroundColor || arcOpts?.backgroundColor || 'defaultColor';
              const stroke = custom.borderColor || arc?._model?.borderColor || arcOpts?.borderColor || 'defaultColor';

              // Use nullish coalescing to avoid null/undefined issues
              const value = chart.config.data.datasets[arc?._datasetIndex ?? 0]?.data[arc?._index ?? 0] ?? 0;

              const total = ds.data.reduce((acc: number, val: unknown) => acc + (typeof val === 'number' ? val : 0), 0);
              const percentage = ((value as number / total) * 100).toFixed(2) + '%';

              return {
                text: `${label} : ${percentage}`,
                fillStyle: fill,
                strokeStyle: stroke,
                lineWidth: 2,
                hidden: isNaN(ds.data[i] as number) || (meta.data[i] as any).hidden, // Type assertion here
                index: i
              };
            });
          }
          return [];
        }
      }
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {  // Type assertion if specific types are not available
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const total = dataset.data.reduce((previousValue: number, currentValue: number) => previousValue + currentValue, 0);
          const currentValue = dataset.data[tooltipItem.index];
          const percentage = ((currentValue / total) * 100).toFixed(2);
          return `${data.labels[tooltipItem.index]} : ${percentage}%`;
        }
      }
    }
  };

  return (<div>


      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',
      marginTop: '13px',color: '#333333', flexDirection: window.innerWidth < 600 ? 'column' : 'row' }}>
  <div style={{ width: '40%', aspectRatio: '1/1', position: 'relative', zIndex: 0 }}>
      <Pie data={data} options={options} />
  </div>

  




  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', marginLeft: '40px' }}>
            {processedData.labels.map((label, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
                    <span style={{
                        display: 'inline-block',
                        width: '20px',
                        height: '20px',
                        backgroundColor: backgroundColors[index % backgroundColors.length],
                        marginRight: '8px'
                    }}></span>
                    <span style={{ fontSize: '16px', fontWeight: 'bold', color: 'white' }}>{label}: {(processedData.dataPoints[index]/totalSupply*100).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 4 })}%</span>
                </div>
            ))}
        </div>

  </div>
  </div>
    )} 


   

export default PieChartComponent;