import React, { useState, useEffect } from 'react';
import styles from '../../App.module.scss';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { FirebaseError } from '@firebase/util';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { currentUserData } from '../CurrentUserInfo'
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setUserData } from '../../redux/ephemeralUserData';
import closeButton from '../../assets/closeButton.png';

interface SignUpModalProps {
  buttonType: 'signUpSuggest' | 'headerNav' | 'auto' | 'headerNavHome';
}

export default function SignUpModal(props: SignUpModalProps) {

  const db = getFirestore();
  const dispatch = useAppDispatch();
  const firestore = getFirestore();

    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const [userCompletedSignUp, setuserCompletedSignUp] = useState(true);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('+1 ');
    const [password, setPassword] = useState('');

    const [signUpHovered, setsignUpHovered] = useState(false);
    const handleSignUpEnter = () => {
        setsignUpHovered(true);
      };
      const handleSignUpLeave = () => {
        setsignUpHovered(false);
      };

    useEffect(() => {
      if (props.buttonType === 'auto') {
        setShowSignUpModal(true);
      }
    }, [props.buttonType]);

    const toggleSignUpModal = () => {
        setShowSignUpModal(!showSignUpModal);
      };

      const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
      };
  
      const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
      };

      const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(event.target.value);
      };

      const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
      };

      const [errorMessage, setErrorMessage] = useState('');


      const removeWhitespacesDashesParanthesis = (str: string) => {
        return str.replace(/[\s-()]/g, '');
      };
      const removeWhitespace = (str: string) => {
        return str.replace(/\s/g, '');
      };

      const handleSignUpSubmitButtonClick = async () => {
        const cleanedEmail = removeWhitespace(email);
        const cleanedUsername = removeWhitespace(username);
        const cleanedPassword = removeWhitespace(password);
        const cleanedPhoneNumber = removeWhitespacesDashesParanthesis(phoneNumber);
        if (cleanedEmail === '' || cleanedPassword === '' ||  cleanedPhoneNumber === '' || cleanedUsername == '') {
          setErrorMessage('Please fill in all fields');
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cleanedEmail)) {
            setErrorMessage('Please enter a valid email address');
        } else if (!/^(\+1)?\d{10}$/.test(cleanedPhoneNumber)) {
          setErrorMessage('Please enter a valid phone number');
        } else {
          try {
            
            const isTestUser= false; //set to true for building

            const auth = getAuth();
            const userCredential = await createUserWithEmailAndPassword(auth, cleanedEmail, cleanedPassword)
            .then( async (userCredential) => {
                // Access the UID from the user credential
                const uid = userCredential.user.uid;
              interface newUser {
                Username: string;
                Email: string;
                PhoneNumber: string;
                TestUser: boolean;
                CryptosOriginated: number,
                CryptosAlloted: number,
                uid: string,
                signedUpOn: string
              }
              // New user created successfully
              // Create a new user object
              const newUser: newUser = {
                uid: uid,
                Username: cleanedUsername,
                Email: cleanedEmail,
                PhoneNumber: cleanedPhoneNumber,
                TestUser: isTestUser,
                CryptosOriginated: 0,
                CryptosAlloted: 0,
                signedUpOn: "Browser"
              };

              const newEphemeralUserData: currentUserData = {
                testUser: isTestUser,
                userName: cleanedUsername,
                bitcoinDepositAddress: '',
                email: cleanedEmail,
                bitcoinKey: '',
                CryptosOriginated: 0,
                CryptosAlloted: 0,
                hasUnreadNotifications: false,
                phoneNumber: '',
                firstName: '',
                address: '',
                phoneVerified: false,
                creationDate: '',
                username: ''
              }

              dispatch(setUserData(newEphemeralUserData));

              // Save the user document to Firestore
              const userDocRef = doc(firestore, "users", uid);
              await setDoc(userDocRef, newUser);
              console.log('New user document ID:', newUser);
              
              const actionCodeSettings = {
                url: `https://finfriend.com/log-in/${email}`,
                handleCodeInApp: true,
              };
              
              sendEmailVerification(userCredential.user, actionCodeSettings)
              .then(() => {
                // The link was successfully sent. Inform the user.
                // Save the email locally so you don't need to ask the user for it again
                // if they open the link on the same device.
                window.localStorage.setItem('emailForSignIn', email);
                // ...
                console.log('Success sending link to: ', email);
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('Error sending email', errorMessage);
                // ...
              });
              
              setErrorMessage('');
              setShowSignUpModal(false);


            });
          } catch (error: any) {
              // Handle error during sign up
              const errorCode = (error as FirebaseError).code;
              const errorMessage = (error as FirebaseError).message;
              console.log(errorCode)
              console.log(errorMessage)
              setErrorMessage('Invalid sign up try a different email');
            }
        }
      };

      const handleComplianceInfoClick = () => {

      };


      let startingButton;
      switch (props.buttonType) {
          case 'headerNavHome': 
          startingButton = (
            <div>
            <button style={{
                  backgroundColor: signUpHovered ? '#FFA14A' : 'white',
                  color: '#111111',
                  display: 'inline-block',
                  padding: '10px 20px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  borderRadius: '10px',
                  border: 'none',
                  marginRight: '10px',
                  cursor: 'pointer'
            }} onClick={toggleSignUpModal}
            
            onMouseEnter={handleSignUpEnter}
            onMouseLeave={handleSignUpLeave}>Sign Up</button>
          </div>
          )
          break;
          case 'headerNav':
            startingButton = (
              <div>
                <button style={{
                      backgroundColor: signUpHovered ? '#FFA14A' : '#FFA14A',
                      color: '#111111',
                      display: 'inline-block',
                      padding: '10px 20px',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      borderRadius: '10px',
                      border: 'none',
                      marginRight: '10px',
                      cursor: 'pointer'
                }} onClick={toggleSignUpModal}
                onMouseEnter={handleSignUpEnter}
                onMouseLeave={handleSignUpLeave}>Sign Up</button>
              </div>
            );
            break;
          case 'signUpSuggest':
            startingButton = (
              <div>
                  <button className={styles['crypto-modal-white-button']} onClick={toggleSignUpModal}>Sign Up</button>
            </div>
            );
      }

    return (
        <div>

        {startingButton}
        {(showSignUpModal) && (
            <div className={styles['modal-container']}>
              <div className={styles['overlay']} onClick={toggleSignUpModal}></div>
              <div className={styles['crypto-modal']} style={{height: '70vh'}}>
                <div className={styles['crypto-modal-header']}>
                  <h3>Create Your Account</h3>
                  <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={toggleSignUpModal} style={{width: '24px', height: '24px'}}></img>

                </div>
                <div className={styles['crypto-modal-content']}>
                  <div className={styles['crypto-modal-textfields']}>
                  <div className={styles['crypto-modal-textfield']}>
                        <label htmlFor="username">Public Username</label>
                        <input id="username" type="text" value={username} onChange={handleUsernameChange} />
                    </div>
                    <div className={styles['crypto-modal-textfield']}>
                        <label htmlFor="email">Email</label>
                        <input id="email" type="text" value={email} onChange={handleEmailChange} />
                    </div>
                    <div className={styles['crypto-modal-textfield']}>
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input id="phoneNumber" type="text" value={phoneNumber} onChange={handlePhoneNumberChange} />
                    </div>
                    <div className={styles['crypto-modal-textfield']}>
                        <label htmlFor="password">Password</label>
                        <input id="password" type="text" value={password} onChange={handlePasswordChange} />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{ textAlign: 'center', maxWidth: '250px' }}>
                      By using FinFriend you agree to our{' '}
                      <a
                        href="/terms-of-service" // Replace with your actual terms of service URL
                        target="_blank" // Open link in a new tab/window
                        rel="noopener noreferrer" // Recommended for security reasons
                        style={{ color: 'inherit', textDecoration: 'underline' }}
                      >
                        Terms Of Service
                      </a>
                    </div>
                  </div>
                </div>
                <div className={styles['crypto-modal-footer']} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <label className={styles['error-label']} style={{ textAlign: 'center', marginRight: '10px', marginBottom: '10px' }}>{errorMessage}</label>
                  <button className={styles['crypto-modal-continue-button']} style={{ flexGrow: 1 }} onClick={handleSignUpSubmitButtonClick}>Continue</button>
                </div>
              </div>
            </div>
          )}

    </div>
    );
}