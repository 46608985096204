import React, { useState } from 'react';
import styles from '../App.module.scss';
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import closeButton from '../assets/closeButton.png'
import { useNavigate } from 'react-router-dom';
import DownloadModal from '../components/DownloadModal';
import FinFriendRocket from '../assets/FinFriendRocketLightOrange.png'
import BuyBitcoinModal from './BuyBitcoinModal';

interface HeaderProps {
  isHomePage?: boolean; // Now this prop is optional
  // Add any additional props here
}

const HeaderLeft: React.FC<HeaderProps> = ({ isHomePage = false }) => {

    const navigate = useNavigate();

    const [isFinFriendHovered, setisFinFriendHovered] = useState(false);
    const [isLearnHovered, setisLearnHovered] = useState(false);
    const [isBrowseCryptosHovered, setisBrowseCryptosHovered] = useState(false);
    const handleFinFriendEnter = () => {
        setisFinFriendHovered(true);
      };
      const handleFinFriendLeave = () => {
        setisFinFriendHovered(false);
      };
    const handleLearnEnter = () => {
      setisLearnHovered(true);
    };
    const handleLearnLeave = () => {
      setisLearnHovered(false);
    };
    const handleBrowseCryptosEnter = () => {
      setisBrowseCryptosHovered(true);
    };
    const handleBrowseCryptosLeave = () => {
      setisBrowseCryptosHovered(false);
    };

    return (


        <div>

<div className={styles['header-left']}>

  <img src={FinFriendRocket}
  style={{height: '45px', width: 'auto', marginRight: '15px'}}/>
              <h1 style={{ color: !isHomePage ? '#FFA14A' : isFinFriendHovered ? '#FFA14A' : 'white', 
              marginRight: '35px', cursor: 'pointer', fontSize: '30px', fontFamily: 'Arial, sans-serif' }}
              onClick={() => {
                navigate('/');
                window.scrollTo(0, 0); // Reset scroll position to the top of the page
              }}
              onMouseEnter={handleFinFriendEnter}
              onMouseLeave={handleFinFriendLeave}>FinFriend</h1>

{<BuyBitcoinModal/>}

<a style={{
                  marginRight: '25px',
                  fontSize: '22px',
                  color: isBrowseCryptosHovered ? '#FFA14A' : 'white',
                  textDecoration: 'none',
                  cursor: 'pointer'
              }} 
              onMouseEnter={handleBrowseCryptosEnter}
              onMouseLeave={handleBrowseCryptosLeave}
              onClick={() => {
                navigate('/browse-cryptos');
                window.scrollTo(0, 0); // Reset scroll position to the top of the page
              }}
              >Markets</a>

{<DownloadModal/>}
              <a style={{
                  marginRight: '25px',
                  fontSize: '22px',
                  color: isLearnHovered ? '#FFA14A' : 'white',
                  textDecoration: 'none',
                  cursor: 'pointer'
              }} onClick={() => {navigate('/learn');
              window.scrollTo(0, 0);}}
              onMouseEnter={handleLearnEnter}
              onMouseLeave={handleLearnLeave}
              >About</a>
              

            </div>

    </div>
    )};

    export default HeaderLeft;