import React, { useEffect, useState } from 'react';
import TradeTile from './TradeTile';
import styles from '../App.module.scss';
import { db } from '..';
import { collection, getDocs } from 'firebase/firestore';
import { cryptoForTradingData } from './CurrentUserInfo';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import CreateCryptoModals from '../components/CreateCryptoModals';
import { FirebaseError } from '@firebase/util';
import closeButton from '../assets/closeButton.png';
import { currentUserData } from './CurrentUserInfo'
import { setUserData } from '../redux/ephemeralUserData';
import yellowWarningCircle from '../assets/yellowWarningCircle.png';

async function fetchCryptoData(ticker: string): Promise<cryptoForTradingData | null> {
  try {
    const querySnapshot = await getDocs(collection(db, 'cryptos'));
    const cryptoData = querySnapshot.docs
      .map((doc) => doc.data() as cryptoForTradingData)
      .find((data) => data.Ticker === ticker);

    return cryptoData || null;
  } catch (error) {
    console.error('Error retrieving data:', error);
    return null;
  }
}

function TradeGridSystem({ ticker = '' }: { ticker?: string }) {

  const firestore = getFirestore();

  const [cryptoData, setCryptoData] = useState<cryptoForTradingData | null>(null);
  const screenSize = window.innerWidth;
  const comingFromCreateCryptoWithTestLaunch = useAppSelector((state) => state.comingFromCreateToTrade.value);
  const currentUserCreationDate = useAppSelector((state) => state.userData.userData?.creationDate)
  const dispatch = useAppDispatch();
  const localCryptoData = useAppSelector((state) => state.unsavedCryptoData.cryptoData);
  const [saveCryptoWarning, setSaveCryptoWarning] = useState('');
  const [uid, setUid] = useState('');
  const auth = getAuth();
  const [loading, setLoading] = useState(true);
  const [showSignInSuggestModal, setShowSignInSuggestModal] = useState(false);
  const toggleSignInSuggestModal = () => {
    setShowSignInSuggestModal(!showSignInSuggestModal);
  };

  const [showSignInModal, setShowSignInModal] = useState(false);
  const toggleSignInModal = () => {
    setShowSignInModal(!showSignInModal);
    toggleSignInSuggestModal();
  };

  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const toggleSignUpModal = () => {
    setShowSignUpModal(!showSignUpModal);
    toggleSignInSuggestModal();
  };

  const [currentUserEmail, setEmail] = useState(useAppSelector((state) => state.initialTrialUserEmailSlice.value));
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('+1 ');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const [errorMessage, setErrorMessage] = useState('');

  const handleSignInSubmitButtonClick = async () => {
    if (currentUserEmail === '' || password === '') {
      setErrorMessage('Please fill in all fields');
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(currentUserEmail)) {
        setErrorMessage('Please enter a valid email address');
    } else {
      signInWithEmailAndPassword(getAuth(), currentUserEmail, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        setErrorMessage('');
        setShowSignInModal(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setErrorMessage('Invalid log in');
      });
    }
  };


    const removeWhitespacesAndDashes = (str: string) => {
      return str.replace(/[\s-]/g, '');
    };

    const removeWhitespace = (str: string) => {
      return str.replace(/\s/g, '');
    };

    const handleSignUpSubmitButtonClick = async () => {
      const cleanedEmail = removeWhitespace(currentUserEmail);
      const cleanedUsername = removeWhitespace(username);
      const cleanedPassword = removeWhitespace(password);
      const cleanedPhoneNumber = removeWhitespacesAndDashes(phoneNumber);
      if (cleanedEmail === '' || cleanedPassword === '' ||  cleanedPhoneNumber === '' || cleanedUsername == '') {
        setErrorMessage('Please fill in all fields');
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cleanedEmail)) {
          setErrorMessage('Please enter a valid email address');
      } else if (!/^(\+1)?\d{10}$/.test(cleanedPhoneNumber)) {
        setErrorMessage('Please enter a valid phone number');
      } else {
        try {
          
          const isTestUser= false; //set to true for building

          const auth = getAuth();
          const userCredential = await createUserWithEmailAndPassword(auth, cleanedEmail, cleanedPassword)
          .then( async (userCredential) => {
              // Access the UID from the user credential
              const uid = userCredential.user.uid;
            interface newUser {
              Username: string;
              Email: string;
              PhoneNumber: string;
              TestUser: boolean;
              CryptosOriginated: number,
              CryptosAlloted: number,
              uid: string,
            }
            // New user created successfully
            // Create a new user object
            const newUser: newUser = {
              uid: uid,
              Username: cleanedUsername,
              Email: cleanedEmail,
              PhoneNumber: cleanedPhoneNumber,
              TestUser: isTestUser,
              CryptosOriginated: 0,
              CryptosAlloted: 0,
            };

            const newEphemeralUserData: currentUserData = {
              testUser: isTestUser,
              userName: cleanedUsername,
              bitcoinDepositAddress: '',
              email: cleanedEmail,
              bitcoinKey: '',
              CryptosOriginated: 0,
              CryptosAlloted: 0,
              hasUnreadNotifications: false,
              phoneNumber: '',
              firstName: '',
              address: '',
              phoneVerified: false,
              creationDate: '',
              username: ''
            }

            dispatch(setUserData(newEphemeralUserData));

            // Save the user document to Firestore
            const userDocRef = doc(firestore, "users", uid);
            await setDoc(userDocRef, newUser);
            console.log('New user document ID:', newUser);
            
            //send email
            const actionCodeSettings = {
              url: `https://finfriend.com/log-in/${currentUserEmail}`,
              handleCodeInApp: true,
            };
            
            sendEmailVerification(userCredential.user, actionCodeSettings)
            .then(() => {
              // The link was successfully sent. Inform the user.
              // Save the email locally so you don't need to ask the user for it again
              // if they open the link on the same device.
              window.localStorage.setItem('emailForSignIn', cleanedEmail);
              // ...
              console.log('Success sending link to: ', cleanedEmail);
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log('Error sending email', errorMessage);
              // ...
            });
            
            setErrorMessage('');
            setShowSignUpModal(false);


          });
        } catch (error: any) {
            // Handle error during sign up
            const errorCode = (error as FirebaseError).code;
            const errorMessage = (error as FirebaseError).message;
            console.log(errorCode)
            console.log(errorMessage)
            setErrorMessage('Invalid sign up info');
          }
      }
    };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setUid(uid);
      } else {
        setUid('');
      }
    });

    return () => {
      unsubscribe(); // Unsubscribe the listener when the component unmounts
    };
  }, [auth]);

  useEffect(() => {

    if (comingFromCreateCryptoWithTestLaunch) {

      setSaveCryptoWarning("Test launches are unsaved.")

      setCryptoData(localCryptoData);
      setLoading(false);
      if (!(auth.currentUser?.uid)) {
      const timer = setTimeout(() => {
        if (!showSignInModal && !showSignUpModal) {
        setShowSignInSuggestModal(true);
      };
      }, 10000);
  
      return () => {
        clearTimeout(timer);
      };
    }

    } else {
      const fetchCryptoDataAndSet = async (ticker: string) => {
        try {
          const data = await fetchCryptoData(ticker);
          console.log('Setting crypto data to', data);
          setCryptoData(data);
          setLoading(false);
          window.scrollTo(0, 0);
        } catch (error) {
          console.error('Error retrieving data:', error);
          setLoading(false);
          window.scrollTo(0, 0);
        }
      };
      fetchCryptoDataAndSet(ticker);
    }
  }, [comingFromCreateCryptoWithTestLaunch, localCryptoData, setCryptoData, dispatch, ticker]);

  const handleContinueToLogInButtonPress = async () => {
    toggleSignInSuggestModal();
    toggleSignInModal();

  };

  const handleContinueToSignUpButtonPress = async () => {

  };

  if (!ticker) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '90vh',
          color: 'white',
          fontSize: '2rem',
        }}
      >
        Please navigate to the crypto you want to trade {ticker}
      </div>
    );
  }

  if (loading) {
return (
  <div
  style={{
    alignItems: 'center',
    height: '90vh',
    color: 'white',
    fontSize: '2rem',
  }}
>
  <div style={{    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '200px'}}>
  <div style={{
    border: '16px solid #161616', // Light grey border
    borderTop: '16px solid #FFA14A', // Orange color for the spinner part
    borderRadius: '50%',
    width: '120px',
    height: '120px',
    animation: 'spin 1.0s linear infinite'
  }} />
  <style>
    {`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}
  </style>
  </div>
</div>
)

  } else if (!cryptoData) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '90vh',
          color: 'white',
          fontSize: '2rem',
        }}
      >
        
        <div>
          We could not find data for the ticker: {ticker}

        </div>

        <div
          style={{
            marginTop: '1rem',
            color: 'white',
            fontSize: '1.5rem',
          }}
        >
          You should create {ticker} now!
        </div>
        <div style={{
            marginTop: '1rem'}}>
          <>
            <CreateCryptoModals buttonType={'tryItFree'} buttonTextColor={'#222222'} buttonBackgroundColor={'#FFA14A'}/>
          </>
        </div>
      </div>
    );
  } else if (comingFromCreateCryptoWithTestLaunch) { 

    return (
      <div>
        
        <div>
        {saveCryptoWarning && (
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '50px', marginBottom: '-20px' }}>
            <img
            src={yellowWarningCircle}
            alt=""
            style={{ maxWidth: '100%', maxHeight: '100%', width: '20px', height: '20px' }}
          />
            <p style={{color: 'white', marginLeft: '10px'}}>{saveCryptoWarning}</p>
            {/* You can add more content or actions here */}
          </div>
        )}
      </div>

          {showSignInSuggestModal && (
            <div className={styles['modal-container']}>
              <div className={styles['overlay']} onClick={toggleSignInSuggestModal}></div>
              <div className={styles['crypto-modal']}>
                <div className={styles['crypto-modal-header']}>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ fontSize: '22px', width: '300px', textAlign: 'center' }}>Easy right? </div>
                <div style={{ fontSize: '22px', width: '300px', textAlign: 'center' }}>Want to save it next time?</div>
                  <p></p>
                  <div style={{ fontSize: '18px', width: '300px', textAlign: 'center' }}>Create an account to save the assets that you make.</div>
                  </div>
                  <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={toggleSignInSuggestModal} style={{width: '24px', height: '24px'}}></img>
                </div>
                <div className={styles['crypto-modal-content']}>
                  <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <div>
                  <>
                  <button className={styles['crypto-modal-white-button']} onClick={toggleSignUpModal}>Sign Up</button>
                </>
                </div>
                  </div>
                </div>
                <div className={styles['crypto-modal-footer']}>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                  <h3>Already have an account?</h3>

                  <div>
                  <button className={styles['crypto-modal-black-button']} onClick={toggleSignInModal}>Log In</button>
                </div>

                  </div>
                </div>
              </div>
            </div>
          )}
        <div>

          {showSignInModal && (
            <div className={styles['modal-container']}>
              <div className={styles['overlay']} onClick={toggleSignInModal}></div>
              <div className={styles['crypto-modal']}>
                <div className={styles['crypto-modal-header']}>
                  <h3>Log In</h3>
                  <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={toggleSignInModal} style={{width: '24px', height: '24px'}}></img>

                </div>
                <div className={styles['crypto-modal-content']}>
                  <div className={styles['crypto-modal-textfields']}>
                    <div className={styles['crypto-modal-textfield']}>
                        <label htmlFor="email">Email</label>
                        <input id="email" type="text" value={currentUserEmail} onChange={handleEmailChange} />
                    </div>
                    <div className={styles['crypto-modal-textfield']}>
                        <label htmlFor="password">Password</label>
                        <input id="password" type="text" value={password} onChange={handlePasswordChange} />
                    </div>
                  </div>
                </div>
                <div className={styles['crypto-modal-footer']}>
                <div className={styles['crypto-modal-footer']} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <label className={styles['error-label']} style={{ textAlign: 'center', marginRight: '10px' }}>{errorMessage}</label>
                  <button className={styles['crypto-modal-continue-button']} onClick={handleSignInSubmitButtonClick}>Log In</button>
                </div>
                </div>
              </div>
            </div>
          )}


          {showSignUpModal && (
            <div className={styles['modal-container']}>
              <div className={styles['overlay']} onClick={toggleSignUpModal}></div>
              <div className={styles['crypto-modal']}>
                <div className={styles['crypto-modal-header']}>
                  <h3>Create Your Account</h3>
                  <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={toggleSignUpModal} style={{width: '24px', height: '24px'}}></img>

                </div>
                <div className={styles['crypto-modal-content']}>
                  <div className={styles['crypto-modal-textfields']}>
                  <div className={styles['crypto-modal-textfield']}>
                        <label htmlFor="username">Username For Others To Recognize You</label>
                        <input id="username" type="text" value={username} onChange={handleUsernameChange} />
                    </div>
                    <div className={styles['crypto-modal-textfield']}>
                        <label htmlFor="email">Email</label>
                        <input id="email" type="text" value={currentUserEmail} onChange={handleEmailChange} />
                    </div>
                    <div className={styles['crypto-modal-textfield']}>
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input id="phoneNumber" type="text" value={phoneNumber} onChange={handlePhoneNumberChange} />
                    </div>
                    <div className={styles['crypto-modal-textfield']}>
                        <label htmlFor="password">Password</label>
                        <input id="password" type="text" value={password} onChange={handlePasswordChange} />
                    </div>
                  </div>
                </div>
                <div className={styles['crypto-modal-footer']} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <label className={styles['error-label']} style={{ textAlign: 'center', marginRight: '10px', marginBottom: '10px' }}>{errorMessage}</label>
                  <button className={styles['crypto-modal-continue-button']} style={{ flexGrow: 1 }} onClick={handleSignUpSubmitButtonClick}>Continue</button>
                </div>
              </div>
            </div>
          )}
      </div>

      <div style={local_styles.tile_container}>
      <TradeTile type="graph" cryptoData={cryptoData} />
      <TradeTile type="buySell" cryptoData={cryptoData} />
      <TradeTile type="cryptoInfo" cryptoData={cryptoData} />
      <TradeTile type="orderBook" cryptoData={cryptoData} />
    </div>
    </div>
    )

    
  } else {

    return (
      
      <div style={local_styles.tile_container}>
        <TradeTile type="graph" cryptoData={cryptoData} />
        <TradeTile type="buySell" cryptoData={cryptoData} />
        <TradeTile type="cryptoInfo" cryptoData={cryptoData} />
        <TradeTile type="orderBook" cryptoData={cryptoData} />
      </div>
    );
  };
}


const screenSize = window.innerWidth;

const local_styles = {
        tile_container: {
            margin: '0 auto',
            padding: 10,
            width: '90vw',
            maxWidth: 1300,
            backgroundColor: 'darker-gray-background',
            left: '50%',
            display: 'grid',
            gridTemplateColumns: '2fr 1fr',  //max-content max-content;
            gap: '20px',
            justifyContent: 'center',
        }
}

const local_styles_2 = {
  tile: {
    margin: '15px 10px',
    padding: '0 10px',
    borderRadius: '15px',
    backgroundColor: '#222222',
  },
  graph: {
    gridRowEnd: 'span 30',
    gridColumnEnd: 'span 1',
  },
  buySell: {
    gridRowEnd: 'span 30',
    gridColumnEnd: 'span 1',
  },
  cryptoInfo: {
    gridRowEnd: 'span 30',
    gridColumnEnd: 'span 1',
  },
  orderBook: {
    gridRowEnd: 'span 30',
    gridColumnEnd: 'span 1',
  },
};

export default TradeGridSystem;