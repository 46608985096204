import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import styles from '../App.module.scss';
import { db } from "../";
import { doc, getDoc, collection, setDoc, addDoc, query, where } from 'firebase/firestore';
import { useAppSelector } from '../redux/hooks';
import { getAuth } from "firebase/auth";
import CreateCryptoModals from '../components/CreateCryptoModals';
import { getStorage, ref, getDownloadURL, FirebaseStorage } from 'firebase/storage';
import { Link } from 'react-router-dom';
import closeButton from '../assets/closeButton.png';


interface DepositBTCDialogProps {
  // Add any additional props here
}

const DepositBTCDialog: React.FC<DepositBTCDialogProps> = () => {

    const navigate = useNavigate();


    const bitcoinPriceInDollars = useAppSelector((state) => state.bitcoinPriceData.value)
    const currentUserCreationDate = useAppSelector((state) => state.userData.userData?.creationDate)
    const currentUserBitcoinAddress = useAppSelector((state) => state.userData.userData?.bitcoinDepositAddress)
    console.log(`currentUserCreationDate ${currentUserCreationDate}:`);
    
    const [showDepositModal, setShowDepositModal] = useState(true);

    const [balanceAvailable, setBalanceAvailable] = useState(0.0);
    const [balanceOrder, setBalanceOrder] = useState(0.0);
  
    const auth = getAuth();
    const uid = auth.currentUser?.uid;
    const email = auth.currentUser?.email;
    const emailOrder = `${email}ORDER`;

    
    useEffect(() => {
        if (uid && email) {
          const getBalances = async () => {
            try {
              const docRef = doc(db, "cryptos", "BTC");
              const docSnap = await getDoc(docRef);
    
              if (docSnap.exists()) {
                const data = docSnap.data();
                if (data[email] && data[email] > 0.0) {
                setBalanceAvailable(data[email]); // Assuming the field name is the user's email
                }
                if (data[emailOrder] && data[emailOrder] > 0.0) {
                setBalanceOrder(data[emailOrder]); // Assuming the field name is the user's email with 'ORDER' appended
                }
              } else {
                console.log("No such document!");
              }
            } catch (error) {
              console.error("Error getting document:", error);
            }
          };
    
          getBalances();
        }
      }, [uid, email]);


    const handleModalCloseClick = () => {
        setShowDepositModal(false);
       };

       const CopyToClipboardButton = ({ text }: { text: string }) => {
        const [copied, setCopied] = useState(false);
      
        const copyToClipboard = async () => {
          try {
            await navigator.clipboard.writeText(text);
            setCopied(true);
          } catch (error) {
            console.error('Failed to copy text:', error);
          }
        };
      
        return (
          <div>
            <span style={{ fontSize: '15px', border: '1px solid #777777', borderRadius: '5px', 
            paddingLeft: '5px', paddingRight: '5px', paddingBottom: '3px' }}>{text}</span>
            <button onClick={copyToClipboard} style={{ fontWeight: 'bold', color: '#222222', 
                    borderRadius: '10px', border: 'none', cursor: 'pointer', width: '65px' }}>
              {copied ? 'Copied!' : 'Copy'}
            </button>
          </div>
        );
      };

  return (
    <div>


{showDepositModal && (  
          
          <div>
          {currentUserCreationDate && (
                <div className={styles['modal-container']}>
                <div className={styles['overlay']} onClick={handleModalCloseClick}></div>
                <div className={styles['crypto-modal']}>
                  <div className={styles['crypto-modal-header']}>
                    <h3>Deposit Bitcoin</h3>
                    <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={handleModalCloseClick} style={{width: '24px', height: '24px'}}></img>
    
                  </div>
    
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    
                    <img
                        style={{ borderRadius: '50%', width:'100px', height:'100px', justifyContent: 'center', alignItems: 'center' }}
                        src={
                            require(`../assets/CryptoIcons/BTC.png`)
                        }
                        alt={''}
                        />
                    <div>Balance </div>
                    <div style={{fontWeight: 'bold', fontSize: '22px'}}>{(balanceAvailable+balanceOrder).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 8,
                                }).replace(/\.?0+$/, '')}</div>
                    <div>${((balanceAvailable+balanceOrder)*bitcoinPriceInDollars).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                                }).replace(/\.?0+$/, '')}</div>
    
                    <p></p>
                    <div style={{textAlign: 'center'}}>
                    Carefully send Bitcoin to this address: 
                    </div>
                    <br/>
                    { currentUserBitcoinAddress && (
                        <div>
                        <CopyToClipboardButton text={currentUserBitcoinAddress} />
                        </div>
                    )
                    }
                    <p></p>
                  </div>
    
                  
                  <div className={styles['crypto-modal-footer']}>
                    <button className={styles['crypto-modal-continue-button']} onClick={handleModalCloseClick}>Done</button>
                  </div>
                </div>
                </div>
          )}


    </div>
)}
    </div>
)}

export default DepositBTCDialog;