import React, { useState } from 'react';
import styles from '../App.module.scss';
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import closeButton from '../assets/closeButton.png'
import BTCImage from '../assets/CryptoIcons/BTC.png';
import calculatorImage from '../assets/calculatorImage.png';
import poweredByPayPalImage from '../assets/poweredByPayPalWhite.png';
import { httpsCallable } from "firebase/functions";
import { functions } from "..";
import { getFirestore, doc, setDoc, addDoc, collection, getDoc } from 'firebase/firestore';
import { getPSTDate } from '../Helpers/DateAndTime';
import { useAppDispatch, useAppSelector } from '../redux/hooks';


export default function BuyBitcoinModal() {

    const [showDownloadModal, setDownloadModal] = useState(false);
    const [amount, setAmount] = useState('');
    const [orderTotal, setorderTotal] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageColor, setErrorMessageColor] = useState('');
    const [waitingForPayPal, setwaitingForPayPal] = useState(false);
    const [payPalLink, setPayPalLink] = useState('');
    const uid = getAuth().currentUser?.uid;
    const db = getFirestore();
    const [showPayPalLinkPopup, setShowPayPalLinkPopup] = useState(false);
    const bitcoinPriceInUSD = useAppSelector((state) => state.bitcoinPriceData.value)

    const percentageFee = 0.04

    const [isBuyBitcoinHovered, setisDownloadHovered] = useState(false);
    const handleDownloadEnter = () => {
      setisDownloadHovered(true);
    };
    const handleDownloadLeave = () => {
      setisDownloadHovered(false);
    };
    const toggleDownloadModal = () => {
        setDownloadModal(!showDownloadModal);
      };
    const [submitButtonHovered, setsubmitButtonHovered] = useState(false);
    const handlesubmitButtonEnter = () => {
        setsubmitButtonHovered(true);
    };
    const handlesubmitButtonLeave = () => {
        setsubmitButtonHovered(false);
    };
  

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setAmount(event.target.value);
      let error = validateFields(event.target.value)
      let newAmount = event.target.value
      if (error) {
        setErrorMessage(error)
        setErrorMessageColor("white")
        setorderTotal('')
      } else {
        setErrorMessage('')
        const amountFloat = parseFloat(newAmount.replace(/[, $]/g, ''));
        setorderTotal("Total: " + new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2
        }).format(amountFloat + amountFloat * 0.04));
      }
    };

      const handleHotKey1Click = () => {
        let newAmount = "$ 20"
        setAmount(newAmount)
        setErrorMessage('')
        setorderTotal('Total: $ 20.80')
      };

      const handleHotKey2Click = () => {
        let newAmount = "$ 100"
        setAmount(newAmount)
        setErrorMessage('')
        setorderTotal('Total: $ 104')
      };

      const handleHotKey3Click = () => {
        let newAmount = "$ 500"
        setAmount(newAmount)
        setErrorMessage('')
        setorderTotal('Total: $ 520')
      };

      const handleHotKey4Click = () => {
        let newAmount = "$ 1,000"
        setAmount(newAmount)
        setErrorMessage('')
        setorderTotal('Total: $ 1,040')
      };
      

      function validateFields(amount: string) {
        if (amount === '') {
          return 'Please enter an amount';
        } 
        const amountFloat = parseFloat(amount.replace(/[, $]/g, ''));

        if (isNaN(amountFloat) || amountFloat <= 0 || !Number.isInteger(amountFloat)) {
          return ('Please enter a valid number');
        } else if (amountFloat > 3000) {
          return ('Maximum $3,000');
        } else if (amountFloat < 20) {
          return ('Minimum $20');
        } else {
      return null
      }
    }

    const errorLabelText = () => {
        if (errorMessage) {
          return (<label className={styles['error-label']} style={{ textAlign: 'center', 
          marginRight: '10px', marginBottom: '10px', color: errorMessageColor, }}>{errorMessage}</label>)
        } else {
          return <div className={styles['error-label']} style={{ visibility: 'hidden', marginBottom: '10px',  }}>$</div>;
        }
      };

      const orderTotalText = () => {
        if (orderTotal) {
            return <div style={{fontSize: '22', marginBottom: '10px', 
            fontWeight: 'bold', color: 'white'}}>{orderTotal}</div>
        } else {
          return <div style={{ visibility: 'hidden', fontSize: '22', 
          fontWeight: 'bold', marginBottom: '10px',  }}>$</div>;
        }
      };

      const statusLabelText = () => {
        if (waitingForPayPal) {
            return <div style={{fontSize: '22', marginBottom: '10px', 
            fontWeight: 'bold', color: 'white'}}>Waiting for PayPal</div>
        } else {
          return <div style={{ visibility: 'hidden', fontSize: '22', 
          fontWeight: 'bold', marginBottom: '10px',  }}>$</div>;
        }
      };
      
      interface PaymentResponse {
        id: string;
        links: Array<{ href: string; rel: string; method: string }>;
      }
      
      interface MyCallableResult {
        paymentResponse: PaymentResponse;
      }

      const handleSubmitButtonClick = async () => {

        const amountString = amount

        let error = validateFields(amount)
        if (error) {
          setErrorMessage(error)
          setErrorMessageColor("#FF3B30")
        } else {

            if (!uid || uid === '') {
                setErrorMessage('Please log in to use this feature')
                setErrorMessageColor('white')
            } else {
          const amountBeforeFee = parseFloat(amountString.replace(/[, $]/g, ''));
          const amount = percentageFee*amountBeforeFee + amountBeforeFee
          setwaitingForPayPal(true)
          setAmount('')
          
          try {
            const newPayPalOrderLink = httpsCallable(functions, "authenticateAndCreatePayPalOrder");
    
            const redirectURL = "https://finfriend.com/redirect-from-paypal-buy-bitcoin"
            newPayPalOrderLink({ redirectURL, amount })
            .then(async (result) => {
              const typedResult = result.data as MyCallableResult;
      
              // Extract ID
              const paymentId = typedResult.paymentResponse.id;
    
              await saveNewPurchase(paymentId, amount);
              // Find the payer action link
              const payerActionLink = typedResult.paymentResponse.links.find(link => link.rel === 'payer-action');
      
              setwaitingForPayPal(false);
              if (payerActionLink && payerActionLink.href) {
                // Redirect the user to the payer action URL in a new tab
                askToOpenPayPalLink(payerActionLink.href);
              } else {
                // Handle the case where the payer action link is not found
                console.error('Payer action link not found');
                setwaitingForPayPal(false);
              }
            })
            .catch((error) => {
              setwaitingForPayPal(false);
              setErrorMessage(`Network error please try again later ${error}`)
            });
          } catch (error) {
            console.error(error);
          }
        }
        }}

        const saveNewPurchase = async (orderId: string, amountFloat: number) => {
            // Save new order
            const paymentCollectionRef = doc(db, 'buyBitcoinOrders', orderId);
            const updatePayments = setDoc(paymentCollectionRef, {
                UserID: uid,
                TransactionTime: new Date().toISOString(),
                PlatformUsed: "WEB",
                PricePaidInUSD: amountFloat,
                AmountInBitcoin: amountFloat/bitcoinPriceInUSD,
                Status: 'UNAPPROVED'
            }, {merge: true}); 
      
            await Promise.all([updatePayments]);
      }

      const askToOpenPayPalLink = (link: string) => {
        setPayPalLink(link);
        setShowPayPalLinkPopup(true);
      };

      /*
                  "UserID": self.user!.uid,
            "TransactionTime": OriginationDate,
            "PurchaserEmail": "\(HomeViewController.currentUserAddresses.currentUserEmailAddress)",
            "PlatformUsed": "IPHONE",
            "PricePaidInUSD": amountInUSD,
            "AmountInBitcoin": Double(amountInBTC.to8FractionDigits())!,
            "Status": status
      */
    return (


        <div>

            <a style={{
                  marginRight: '20px',
                  fontSize: '22px',
                  color: isBuyBitcoinHovered ? 'orange' : 'white',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap'
              }} onClick={toggleDownloadModal}
                onMouseEnter={handleDownloadEnter}
                onMouseLeave={handleDownloadLeave}>Buy Bitcoin</a>



        {showDownloadModal && (
            <div >
              <div className={styles['overlay']} onClick={toggleDownloadModal}></div>
              <div className={styles['crypto-modal']} style={{height: '75vh'}}>
                <div className={styles['crypto-modal-header']}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <img src={BTCImage} style={{width: '55px', height: 'auto'}}/>
                  </div>
                  <h3 style={{textAlign: 'center', fontSize: '30px'}}>Buy Bitcoin</h3>

                  <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={toggleDownloadModal} style={{width: '24px', height: '24px'}}></img>
                </div>
                <div className={styles['crypto-modal-content']}>


<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
{statusLabelText()}
{orderTotalText()}

<div className={styles['crypto-modal-textfield']}>
              <label></label>
              <input type="text" value={amount} onChange={handleAmountChange} />
              </div>

              </div>
              <div className={styles['bitcoin-price-button-row']}>
        <button onClick={handleHotKey1Click}>$20</button>
        <button onClick={handleHotKey2Click}>$100</button>
        <button onClick={handleHotKey3Click}>$500</button>
        <button onClick={handleHotKey4Click}>$1,000</button>
      </div>


      <div style={{display: 'flex', marginTop: '30px', gap: '10px', alignItems: 'center'}}>

        <img src={calculatorImage} style={{width: '35px', height: 'auto'}}/>

        <div>Fee: 4%</div>

        
      </div>

      </div>
      <div className={styles['crypto-modal-footer']}>




      {showPayPalLinkPopup ? (
  <div style={{display: 'flex', 
  flexDirection: 'column', alignItems: 'center', 
  justifyContent: 'center', gap: '20px'}}>
    <p>Continue on PayPal?</p>
    <button
      onClick={() => {
        window.open(payPalLink, '_blank');
        setShowPayPalLinkPopup(false);
      }}
      style={{
        backgroundColor: 'white', 
        color: '#222222', 
        padding: '10px 20px', 
        border: 'none', 
        borderRadius: '5px',
        fontSize: '25px',
        width: '200px',
        cursor: 'pointer'
      }}
    >
      Open PayPal
    </button>
    <button
      onClick={() => setShowPayPalLinkPopup(false)}
      style={{
        backgroundColor: 'transparent', 
        color: 'white', 
        padding: '10px 20px', 
        border: '1px solid white', 
        borderRadius: '5px',
        fontSize: '25px',
        width: '200px',
        cursor: 'pointer'
      }}
    >
      Cancel
    </button>
  </div>
) : (<>



<div style={{marginTop: '10px'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          {errorLabelText()}
          </div>
          </div>

          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <img src={poweredByPayPalImage} style={{height: '20px', width: 'auto'}}/>
          </div>

          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <button style={{paddingLeft: '35px', paddingRight: '35px', paddingTop: '10px', 
          paddingBottom: '10px', marginTop: '20px',
           fontWeight: 'bold', fontSize: '22px', borderRadius: '10px', border: '1.5px solid white', cursor: 'pointer',
        background: submitButtonHovered ? '#222222' : 'white', 
        color: submitButtonHovered ? 'white': '#222222'}}
          onClick={handleSubmitButtonClick}
          onMouseEnter={handlesubmitButtonEnter}
          onMouseLeave={handlesubmitButtonLeave}>Continue</button>
          </div>


</>)}


          
          </div>
        </div>

              </div>
          )}

    </div>
    )};