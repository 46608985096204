import React from 'react';

import { useNavigate } from 'react-router-dom';

import SupportModal from './support';

import encryptionImage from '../assets/encryptionImage.png';

import depositImage from '../assets/depositImage.png';
import xtwitterImage from '../assets/xtwitter.png';
import instagramImage from '../assets/instagram.png';
import youtubeImage from '../assets/youtube.png';
import redditImage from '../assets/reddit.png';

interface FooterProps {
  // Add any additional props here
}

const Footer: React.FC<FooterProps> = () => {

    const navigate = useNavigate();

    const isSmallScreen = window.innerWidth <= 800;

    if (isSmallScreen) {
      return (
        <div style={{ backgroundColor: '#1a1a1a', paddingTop: '20px' }}>




<div style={{ fontSize: '26px', textAlign: 'center', color: 'white', }}>
  Questions? Need help? <br/> Email us: <br/> <br/>
  <span style={{ backgroundColor: 'white', borderRadius: '20px', padding: '10px', 
  color: '#333333', margin: '5px' }}>finfriend@finfriend.com</span>
</div>



<div style={{ backgroundColor: '#1a1a1a', display: 'flex', 
flexDirection: 'column', alignItems: 'center', gap: '10px', paddingTop: '50px'}}>

<div style={{  }}>
        
        <a href="https://twitter.com/FinFriendDotCom" target="_blank" rel="noopener noreferrer">
          <img src={xtwitterImage} alt="Twitter" style={{ marginRight: '20px', width: '50px', height: '50px', cursor: 'pointer' }} />
          </a>
          <a href="https://www.instagram.com/finfrienddotcom/" target="_blank" rel="noopener noreferrer">
          <img src={instagramImage} alt="Instagram" style={{ marginRight: '20px', width: '50px', height: '50px', cursor: 'pointer' }} />
          </a>
          <a href="https://www.youtube.com/@FinFriendDotCom" target="_blank" rel="noopener noreferrer">
          <img src={youtubeImage} alt="YouTube" style={{ marginRight: '20px', width: '50px', height: '50px', cursor: 'pointer' }} />
          </a>
          <a href="https://www.reddit.com/r/FinFriend/" target="_blank" rel="noopener noreferrer">
          <img src={redditImage} alt="YouTube" style={{ marginRight: '20px', width: '45px', height: '45px', marginBottom: '3px', cursor: 'pointer' }} />
          </a>
        </div>

  </div>


<div style={{ backgroundColor: '#1a1a1a', display: 'flex', 
flexDirection: 'column', alignItems: 'center', gap: '10px', paddingTop: '50px'}}>
<div style={{color: '#FFA14A', fontSize: '25px'}}>Legal</div>
<a onClick={() => { navigate('/privacy-policy'); window.scrollTo(0, 0); }} style={{ textDecoration: 'underline', textDecorationColor: '#AAAAAA', color: 'white', cursor: 'pointer' }}>
  Privacy Policy
</a>
<a onClick={() => { navigate('/terms-of-service'); window.scrollTo(0, 0); }} style={{ textDecoration: 'underline', textDecorationColor: '#AAAAAA', color: 'white', cursor: 'pointer' }}>
  Terms Of Service
</a>
<a onClick={() => { navigate('/anti-money-laundering-policy'); window.scrollTo(0, 0); }} style={{ textDecoration: 'underline', textDecorationColor: '#AAAAAA', color: 'white', cursor: 'pointer' }}>
  Anti Money Laundering
</a>
<a onClick={() => { navigate('/securities-policy'); window.scrollTo(0, 0); }} style={{ textDecoration: 'underline', textDecorationColor: '#AAAAAA', color: 'white', cursor: 'pointer' }}>
  Securities And Investing
</a>
</div>

<div style={{ textAlign: 'center', color: 'white', paddingTop: '20px', paddingBottom: '20px' }}>
      Copyright © {new Date().getFullYear()} FinFriend - All Rights Reserved.
      </div>

        </div>
      )
    }
  return (
    <footer style={{ backgroundColor: '#1a1a1a', color: '#EC770F', paddingBottom: '20px' }}>

<div style={{ fontSize: '26px', textAlign: 'center', color: 'white', paddingTop: '60px' }}>
  Questions? Need help? Email us: 
  <span style={{ backgroundColor: 'white', borderRadius: '20px', padding: '10px', 
  color: '#333333', margin: '5px' }}>finfriend@finfriend.com</span>
</div>



<div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: '60px',
          justifyContent: 'space-between',
          marginLeft: 'auto', // Add this line to create equal margin on the right
          marginRight: 'auto', // Add this line to create equal margin on the left
          width: window.innerWidth > 1000 ? '70%' : '90%', // Adjust the width as needed
        }}
      >

<div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '40px',
        }}
      >

<img src={depositImage} style={{width: '200px', height: '50px'}}></img>

<img src={encryptionImage} style={{width: '200px', height: '66px'}}></img>
</div>


<div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '50px',
          alignItems: 'center'
        }}
      >
<SupportModal/>
<a onClick={() => { navigate('/learn'); window.scrollTo(0, 0); }} style={{ 
            color: 'white', cursor: 'pointer', fontSize: '25px',
            borderRadius: '10px',
            border: '1px solid #FFA14A',
            padding: '10px',
            width: '290px',
            textAlign: 'center',
             }}>
          Learn About FinFriendship
        </a>
    </div>


      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '20px',
        }}
      >

<div style={{color: '#FFA14A', fontSize: '25px'}}>Legal</div>
<a onClick={() => { navigate('/privacy-policy'); window.scrollTo(0, 0); }} style={{ textDecoration: 'underline', textDecorationColor: '#AAAAAA', color: 'white', cursor: 'pointer' }}>
  Privacy Policy
</a>
<a onClick={() => { navigate('/terms-of-service'); window.scrollTo(0, 0); }} style={{ textDecoration: 'underline', textDecorationColor: '#AAAAAA', color: 'white', cursor: 'pointer' }}>
  Terms Of Service
</a>
<a onClick={() => { navigate('/anti-money-laundering-policy'); window.scrollTo(0, 0); }} style={{ textDecoration: 'underline', textDecorationColor: '#AAAAAA', color: 'white', cursor: 'pointer' }}>
  Anti Money Laundering
</a>
<a onClick={() => { navigate('/securities-policy'); window.scrollTo(0, 0); }} style={{ textDecoration: 'underline', textDecorationColor: '#AAAAAA', color: 'white', cursor: 'pointer' }}>
  Securities And Investing
</a>


<p></p>
      </div>
      </div>
      <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'white',
        paddingTop: '10px',
        paddingLeft: '60px',
        paddingRight: '60px',
        paddingBottom: '10px'
      }}
    >
      <div style={{  }}>
        
      <a href="https://twitter.com/FinFriendDotCom" target="_blank" rel="noopener noreferrer">
        <img src={xtwitterImage} alt="Twitter" style={{ marginRight: '20px', width: '50px', height: '50px', cursor: 'pointer' }} />
        </a>
        <a href="https://www.instagram.com/finfrienddotcom/" target="_blank" rel="noopener noreferrer">
        <img src={instagramImage} alt="Instagram" style={{ marginRight: '20px', width: '50px', height: '50px', cursor: 'pointer' }} />
        </a>
        <a href="https://www.youtube.com/@FinFriendDotCom" target="_blank" rel="noopener noreferrer">
        <img src={youtubeImage} alt="YouTube" style={{ marginRight: '20px', width: '50px', height: '50px', cursor: 'pointer' }} />
        </a>
        <a href="https://www.reddit.com/r/FinFriend/" target="_blank" rel="noopener noreferrer">
        <img src={redditImage} alt="YouTube" style={{ marginRight: '20px', width: '45px', height: '45px', marginBottom: '3px', cursor: 'pointer' }} />
        </a>
      </div>
      <div style={{ textAlign: 'right' }}>
      Copyright © {new Date().getFullYear()} FinFriend - All Rights Reserved.
      </div>
    </div>
    </footer>
  );
};

export default Footer;