import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import styles from '../../App.module.scss';
import { functions, db } from "../..";
import { httpsCallable } from "firebase/functions";
import { doc, getDocs, collection } from 'firebase/firestore';
import { cryptoForTradingData } from '../CurrentUserInfo';
import { useAppSelector } from '../../redux/hooks';
import { getAuth } from "firebase/auth";
import CreateCryptoModals from '../../components/CreateCryptoModals';
import { getStorage, ref, getDownloadURL, FirebaseStorage } from 'firebase/storage';
import { Link } from 'react-router-dom';
import { getVolumeData } from '../../Helpers/getVolumeData';
import heartIcon from '../../assets/lightGrayIcons/heartIconLightGray.png';

function FavoritesDashboardTile() {

  const bitcoinPriceInDollars = useAppSelector((state) => state.bitcoinPriceData.value)

  const [loading, setLoading] = useState(true);
  const [cryptoData, setCryptoData] = useState<Crypto[]>([]);

  const storage: FirebaseStorage = getStorage();

  const auth = getAuth();
  const currentUserEmail = auth.currentUser?.email
  const currentUserEmailORDER: string = `${String(getAuth().currentUser?.email)}ORDER`;
  const currentUserID = auth.currentUser?.uid


  class Crypto {
    name: string;
    ticker: string;
    price: number;
    iconUrl: string;
    isUsingCustomIcon: boolean;
    originatorUsername: string;
    volumeData: { [key: number]: number };
    supply: number;
    likedBy: string[];
  
    constructor(
      name: string,
      ticker: string,
      price: number,
      iconUrl: string,
      isUsingCustomIcon: boolean,
      originatorUsername: string,
      volumeData: { [key: number]: number },
      supply: number,
      likedBy: string[]
    ) {
      this.name = name;
      this.ticker= ticker;
      this.price = price;
      this.iconUrl = iconUrl;
      this.isUsingCustomIcon = isUsingCustomIcon;
      this.originatorUsername= originatorUsername;
      this.volumeData= volumeData;
      this.supply= supply;
      this.likedBy = likedBy

    }
  }
      
  useEffect(() => {
    const getCryptoData = async () => {
      const querySnapshot = await getDocs(collection(db, "cryptos"));
      const data = await Promise.all(querySnapshot.docs.map(async (doc) => {
        const { Ticker, isUsingCustomIcon, Name, Price, OriginatorUsername, Supply, LikedBy  } = doc.data();
        let iconUrl = `../../assets/CryptoIcons/${Name.charAt(0).toUpperCase()}.png`;
        if (isUsingCustomIcon) {
          const storageRef = ref(storage, `cryptoIcons/${Ticker}.png`);
          try {
            const url = await getDownloadURL(storageRef);
            iconUrl = url.toString();
          } catch (error) {
            console.log(`Error getting custom icon for ${Ticker}:`, error);
            iconUrl = `../../assets/CryptoIcon/X.png`;
          }
        } else {

            if (Name === 'Bitcoin') {
                iconUrl = `../../assets/CryptoIcons/BTC.png`;
            }
            iconUrl = `../../assets/CryptoIcons/${Name.charAt(0).toUpperCase()}.png`;
        }

        //console.log(`Crypto name: ${Name}, icon URL: ${iconUrl}`);
  
        const volumeData = await getVolumeData(Ticker);

        return new Crypto(
          Name,
          Ticker,
          Price, 
          iconUrl,
          isUsingCustomIcon,
          OriginatorUsername,
          volumeData,
          Supply,
          LikedBy
        );
      }).filter((crypto) => crypto !== null)); // remove null values


const filteredData = data.filter(crypto => crypto.likedBy && crypto.likedBy.includes(currentUserID!));
setCryptoData(filteredData.filter(crypto => crypto !== null) as Crypto[]);

      

      setLoading(false);
    };
  
    
    getCryptoData();
}, [currentUserEmail, currentUserEmailORDER, bitcoinPriceInDollars]);



const VolumeText = (volume: { [key: number]: number }) => {
    if (!volume) {
      return "$0"; // Display 0 if volumeData is falsy
    }
  
    const currentDate = Date.now()/1000;
    const oneMonthAgo = currentDate - 30 * 24 * 60 * 60; // Calculate timestamp for one month ago
  
    let monthlyVolume = 0;
    Object.entries(volume).forEach(([timestamp, amount]) => {
      const timestampNumber = parseFloat(timestamp);
      if (timestampNumber >= oneMonthAgo) {
        monthlyVolume += amount; // Add amounts within one month to monthlyVolume
      }
    });
  
    return "$" + (bitcoinPriceInDollars*monthlyVolume).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).replace(/\.?0+$/, ''); // Display the calculated monthly volume
  };

  
    return (
        <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: 'white', margin: '4px', fontSize: '26px' }}>Favorite Assets</div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '12px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: 'white' }}>
              &nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </div>

        {loading ? (
        <p></p> // Display loading indicator or animation
        ) : (

            <div>
            {cryptoData && bitcoinPriceInDollars && cryptoData.length > 0 ? (
                <div>
                  <div className={styles['asset-crypto-list-container']}>
                    

                <img className={styles['crypto-property-img']} style={{opacity: '0'}} alt={''} />
                <h2 className={styles['crypto-property-favorite-dashboard']}>Name</h2>
                <p className={styles['crypto-property-favorite-dashboard']}>Ticker</p>
                <p className={styles['crypto-property-favorite-dashboard']}>Price</p>
                <p className={styles['crypto-property-favorite-dashboard']}>Monthly Volume</p>
                <p className={styles['crypto-property-favorite-dashboard']}>Valuation</p>
                <p className={styles['crypto-property-favorite-dashboard']}>Supply</p>
                </div>
                </div>
              ) : null}

            

            <div>
                {cryptoData && bitcoinPriceInDollars && cryptoData.length > 0 ? (
                cryptoData.map((crypto, index) => (

                  <div onClick={() => window.scrollTo(0, 0)} style={{ cursor: 'pointer' }}>
                    <Link to={`/trade/${crypto.ticker}`} style={{ textDecoration: 'none' }}>
                    <div className={styles['asset-crypto-list-container']}>
                    <img
                className={styles['crypto-property-img']}
                src={
                crypto.isUsingCustomIcon
                    ? crypto.iconUrl
                    : require(`../../assets/CryptoIcons/${crypto.name.charAt(0).toUpperCase()}.png`)
                }
                alt={`${crypto.name}`}
            />
                    <h2 className={styles['crypto-property-favorite-dashboard']}>{crypto.name}</h2>
                    <p className={styles['crypto-property-favorite-dashboard']}>{crypto.ticker}</p>

                    <div className={styles['crypto-property-favorite-dashboard']}>

                    <p className={styles['crypto-property-favorite-dashboard']}>${(crypto.price*bitcoinPriceInDollars).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 3,
                            }).replace(/\.?0+$/, '')}</p>
                    <p className={styles['crypto-property-favorite-dashboard']}>{(crypto.price).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 8,
                            }).replace(/\.?0+$/, '')} BTC</p>

                    </div>

                    <p className={styles['crypto-property-favorite-dashboard']}>{VolumeText(crypto.volumeData)}</p>
                    <p className={styles['crypto-property-favorite-dashboard']}>${(crypto.supply*crypto.price*bitcoinPriceInDollars).toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }).replace(/\.?0+$/, '')}</p>

                    <p className={styles['crypto-property-favorite-dashboard']}>{crypto.supply.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).replace(/\.?0+$/, '')}</p>
                            
                </div>
                </Link>
                </div>
                ))
                ) : (
                <div style={{ color: 'white', margin: '20px', fontSize: '45px', textAlign: 'center', maxWidth: '500px' }}>
                                <img src={heartIcon} alt="Close" style={{width: '84px', height: '84px'}}></img>
                </div> // Display a message when no crypto data is available
                )}
            </div>
        </div>
        )}


    </div>
);
}

export default FavoritesDashboardTile;