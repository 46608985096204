import React, { useState, useEffect } from 'react';
import styles from '../App.module.scss';
import { functions } from "..";
import { httpsCallable } from "firebase/functions";
import HeaderLeft from '../components/headerLeft';
import HeaderNav from '../components/Authentication/HeaderNav';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setBitcoinPriceData } from '../redux/ephemeralBitcoinPrice';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import SmallScreenLanding from '../components/SmallScreenLanding';
import rocketHittingMoonImage from '../assets/rocketHittingMoon.png';
import Header from '../components/Header';

function Learn() {

    const navigate = useNavigate();

  let currentUserCreationDate = useAppSelector((state) => state.userData.userData?.creationDate);
  const dispatch = useAppDispatch();
  const [showQuestion1, setShowQuestion1] = useState(false);
  const [showQuestion2, setShowQuestion2] = useState(false);
  const [showQuestion3, setShowQuestion3] = useState(false);
  const [showQuestion4, setShowQuestion4] = useState(false);

  const getBitcoinPrice = async () => {
    try {
      const fetchBTCPriceInUSD = httpsCallable(functions, "fetchBTCPriceInUSD");
      const response = await fetchBTCPriceInUSD({});

      dispatch(setBitcoinPriceData(1.0/(response.data as number)));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBitcoinPrice();
  }, []);

  const toggleQuestion1 = () => {
    setShowQuestion1(!showQuestion1);
  };

  const toggleQuestion2 = () => {
    setShowQuestion2(!showQuestion2);
  };

  const toggleQuestion3 = () => {
    setShowQuestion3(!showQuestion3);
  };

  const toggleQuestion4 = () => {
    setShowQuestion4(!showQuestion4);
  };

  
  const isSmallScreen = window.innerWidth <= 800;

  const screenWidth = window.innerWidth

  if (isSmallScreen) {
    return (
    <div>
        
    {<SmallScreenLanding/>}

  </div>
    )
  };



  return (
      
    
    <div className={styles.App} style={{ backgroundColor: '#222222' }}>
      
      <div>

        <Header/>


        </div>
        <div className={styles['darker-gray-background']} style={{ display: 'flex', justifyContent: 'center', 
         flexDirection: 'column' }}>

<div style={{backgroundColor: '#1a1a1a', height: '100vh'}}>


<div style={{display: 'flex', flexDirection:'row', 
 justifyContent: 'space-around', 
alignItems:'center', marginBottom: '100px',}}>

  <div style={{marginTop: '70px',
    textAlign: 'left',
    fontSize: '45px',
    color: 'white',
    height: '70vh',
    }}>
        <div   
        style={{
    textAlign: 'left',
    fontSize: '45px',
    color: 'white',
    height: '20vh',
    
    width: '55vw',
    maxWidth: '600px',
    fontWeight: 'bold',

    minWidth: '540px',
    marginTop: '100px',
    
  }}>Everything You Need To Start, Trade, And Send A Digital Asset And Nothing You Don't</div>

<div   
        style={{
    textAlign: 'left',
    fontSize: '28px',
    color: 'white',
    height: '20vh',
    marginTop: '120px',
    width: '55vw',
    maxWidth: '600px',
    
  }}>Discover the future of digital currency with our platform, where you can work without the complexity of blockchain technology. Experience a user-friendly, innovative alternative for digital transactions and currency creation.</div>
  </div>
  <div style={{marginTop: '170px'}}>
  <img src={rocketHittingMoonImage} style={{width:'auto', height: '30vw'}}></img>
  </div>
  </div>

      </div>




      <div className={styles['darker-gray-background']} style={{ display: 'flex', justifyContent: 'center', 
        alignItems: 'center', flexDirection: 'column' }}>
    <div
      style={{
        width: '85vw',
        padding: '20px',
        borderRadius: '10px',
        backgroundColor: '#222222',
        marginBottom: '40px', cursor: 'pointer' }} onClick={toggleQuestion1}
    >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: 'white', margin: '12px', fontSize: '28px'}}>What is FinFriend?</div>
            
          </div>
        </div>
        {showQuestion1 && (
  <div style={{ color: 'white' }}>
    FinFriend is a service that lets you make your own currencies fast and easily. 
    We manage your assets' ledger in our database and handle trading between it and Bitcoin. 
    You can 
    customize the total supply, price, icon and so much more! You can send your assets to anyone at the tap of a finger for free!
    <br/>
    <br/>
    Your email address is the only address you need to transact with. You do not even need to have an account with us to receive a transaction, and we will always send an email letting you know you have money on FinFriend.
    <br/>
    <br/>
Trading and managing your digital assets with FinFriend is a social and straightforward experience. Connect with friends, family, or colleagues, and engage in secure, hassle-free asset exchanges. Our intuitive platform ensures that keeping track of your assets and their performance is as simple as possible.
<br/>
    <br/>
Whether it's for personal use or a new open source project, your digital asset creation is just a few clicks away. From creating a whole new digital asset in seconds to sending it instantly. We make every blockchain operation more reliable, easier and better.
  </div>
)}
    </div>
    <div
      style={{
        width: '85vw',
        padding: '20px',
        borderRadius: '10px',
        backgroundColor: '#222222',
        marginBottom: '40px', cursor: 'pointer' }}
        onClick={toggleQuestion2}
    >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: 'white', margin: '12px', fontSize: '28px'}}>What can I do with my currency?</div>
            
          </div>
        </div>

        {showQuestion2 && (
        <div style={{color: 'white'}}>Feel free to spread the word about your currency creation! 
        Share the link with your friends or send it directly to their email addresses.  They will 
        receive an email notifying them that they have currency on FinFriend. Your email address is the 
        address for all FinFriend created assets. Once your currency is 
        live, any FinFriend user can buy and sell it, potentially influencing its price.
        <br/>
        <br/>
The possibilities for your currency are endless—it's entirely up to you! We can't even begin to 
imagine all the creative ways you'll use it. Maybe it represents your time, or maybe you have some commodity
to back it like gold. So go ahead, let your imagination run wild, and 
enjoy the boundless potential of your custom digital currency on FinFriend!</div>
        )}
    </div>
        
    <div
      style={{
        width: '85vw',
        padding: '20px',
        borderRadius: '10px',
        backgroundColor: '#222222',
        marginBottom: '40px', cursor: 'pointer' }} 
        onClick={toggleQuestion3}
    >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: 'white', margin: '12px', fontSize: '28px'}}>What can I not do with my currency?</div>
            
          </div>
        </div>

        {showQuestion3 && (
        <div style={{color: 'white'}}>
One important restriction to keep in mind is that your currency cannot be promoted or marketed as an investment opportunity. 
Such promotion would classify it as a security, subject to complex regulatory requirements. By refraining from positioning your 
crypto as an investment, you help maintain compliance and legal integrity.</div>
        )}
    </div>


    <div
      style={{
        width: '85vw',
        padding: '20px',
        borderRadius: '10px',
        backgroundColor: '#222222',
        marginBottom: '40px', cursor: 'pointer' }} 
        onClick={toggleQuestion4}
    >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: 'white', margin: '12px', fontSize: '28px'}}>How can I remain compliant?</div>
            
          </div>
        </div>

<br/>


{showQuestion4 && (
    <div>
<div style={{color: 'white'}}>

<div style={{fontSize: '22px'}}>No Assured Financial Gain:</div>
To ensure transparency and fairness, it's crucial not to make promises or guarantees of financial gain to potential buyers or 
investors of your currency. The value and performance of any digital asset, including your custom currency, are subject to market 
fluctuations and user demand. Encouraging realistic expectations helps foster a responsible and sustainable currency ecosystem.
</div>

<br/>
<div style={{color: 'white'}}>
<div style={{fontSize: '22px'}}>
Currencies, Not Securities and Not Commodities:</div>
FinFriend operates within the framework of supporting pure currencies rather than securities or commodities. Securities are investments
or shares in a business. Commodities refer to fungible goods or 
services, including digital assets, that hold inherent utility or value. These tradable financial 
instruments subject to specific regulations. By promoting your asset as a currency rather than a security or commodity, you align with our 
terms of service and ensure compliance.
</div>

<br/>

<div style={{color: 'white'}}>
<div style={{fontSize: '22px'}}>
Focus on Utility:</div>
Emphasize the unique and practical applications of your currency, showcasing the benefits it provides to users within a specific 
context or niche. By highlighting its utility and functionality, you can attract users who appreciate the value your custom currency 
brings to their lives. Building a strong and engaged user base based on genuine utility strengthens the overall credibility 
and success of your currency venture.
</div>

<br/>

<div style={{color: 'white'}}>
<div style={{fontSize: '22px'}}>
Compliance with Regulations:</div>
While we encourage innovation and creativity, it is essential to adhere to applicable laws and regulations governing the 
creation and usage of currencies. Familiarize yourself with the legal requirements specific to your jurisdiction and 
seek guidance from legal professionals when necessary. Complying with regulations protects your users, your reputation, and 
the broader crypto community.
</div>

<br/>

<div style={{color: 'white'}}>
By respecting these guidelines and utilizing your asset within these bounds, you can create a safe and compliant environment,
fostering trust and longevity for your custom asset project. Should you have any questions or need further clarification, our 
support team is always ready to assist you in navigating this exciting journey. Together, we can build a vibrant and responsible 
asset ecosystem for all.</div>
</div>
)}

    </div>

</div>


<div style={{backgroundColor: '#1a1a1a', height: '100vh'}}>
  
  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', 
  paddingTop: '200px', flexDirection: 'column', gap: '0px'}}>

<div style={{fontSize: '25px', color: 'white'}}>Want a decentralized platform?</div>

<div style={{fontSize: '25px', color: 'white', marginTop: '25px'}}>We understand! Please try our competitor but be warned, it</div>
<div style={{fontSize: '25px', color: 'white'}}>can get pretty hectic on here. We are not endorsing this platform:</div>

<a href="https://pump.fun/board" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
<div style={{backgroundColor: '#FFA14A', borderRadius: '10px', padding: '20px', 
color: '#1a1a1a', cursor: 'pointer', fontWeight: 'bold', fontSize: '20px', marginTop: '25px'}}>
  FinFriend's Decentralized Competitor
</div>
</a>

<div style={{fontSize: '35px', color: 'white', marginTop: '25px'}}>We won't judge you if you come back.</div>
  </div>


   </div>
  </div>
  <Footer />
    </div>
  );
}

export default Learn;
