import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import styles from '../../App.module.scss';
import { functions, db } from "../..";
import { httpsCallable } from "firebase/functions";
import { doc, getDocs, collection, updateDoc, setDoc } from 'firebase/firestore';
import { cryptoForTradingData } from '../CurrentUserInfo';
import { useAppSelector } from '../../redux/hooks';
import { getAuth } from "firebase/auth";
import CreateCryptoModals from '../../components/CreateCryptoModals';
import { getStorage, ref, getDownloadURL, FirebaseStorage } from 'firebase/storage';
import { Link } from 'react-router-dom';
import { getVolumeData } from '../../Helpers/getVolumeData';
import closeButton from '../../assets/closeButton.png';
import graphIcon from '../../assets/lightGrayIcons/graphIconLightGray.png';
import { getUserBalances } from '../../Helpers/GetBalance';
import RocketAnimation from '../../components/RocketSpinAnimation';

function OrdersDashboardTile() {

  const bitcoinPriceInDollars = useAppSelector((state) => state.bitcoinPriceData.value)

  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState<Order[]>([]);

  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCancelReceiptModal, setShowCancelReceiptModal] = useState(false);
  const handleModalCloseClick = () => {
    setShowCancelModal(false);
    setShowCancelReceiptModal(false);
   };

  const storage: FirebaseStorage = getStorage();

  const auth = getAuth();
  const currentUserEmail = auth.currentUser?.email
  const currentUserID = auth.currentUser?.uid

  class Order {
    filled: number;
    originDate: string;
    originatorEmail: string;
    price: number;
    sellOrder: boolean;
    ticker: string;
    unfilled: number;
    orderID: string;
    canceled: number;
  
    constructor(
        filled: number,
        originDate: string,
        originatorEmail: string,
        price: number,
        sellOrder: boolean,
        ticker: string,
        unfilled: number,
        orderID: string,
        canceled: number,
    ) {
      this.filled = filled;
      this.originDate= originDate;
      this.originatorEmail = originatorEmail;
      this.price = price;
      this.sellOrder= sellOrder;
      this.ticker= ticker;
      this.unfilled= unfilled;
      this.orderID= orderID;
      this.canceled=canceled;
    }
  }
      
  useEffect(() => {
    const getOrderData = async () => {
      const querySnapshot = await getDocs(collection(db, "orders"));
      const data = await Promise.all(querySnapshot.docs.map(async (doc) => {
        const { Filled, OriginationDate, Originator, Price, SellOrder, Ticker, Unfilled, Canceled  } = doc.data();

        //console.log(`Crypto name: ${Name}, icon URL: ${iconUrl}`);

        return new Order(
          Filled,
          OriginationDate,
          Originator,
          Price, 
          SellOrder, 
          Ticker, 
          Unfilled,
          doc.id,
          Canceled
        );
      }).filter((order) => order !== null)); // remove null values


const filteredData = data.filter(order => order.originatorEmail===currentUserEmail);
setOrderData(filteredData.filter(order => order !== null) as Order[]);

      

      setLoading(false);
    };
  
    
    getOrderData();
}, [currentUserEmail, bitcoinPriceInDollars]);



const BuySellText = (isSellOrder: Boolean) => {
    if (isSellOrder) {
        return <div>Sell</div>
    } else {
        return <div>Buy</div>
    }
  };

  const CancelModalTitleText = (order: Order) => {
    if (order.sellOrder) {
        return <div>Cancel your SELL order for {order.ticker}</div>
    } else {
        return <div>Cancel your BUY order for {order.ticker}</div>
    }
  };


  const handleCancelSelection = (order: Order) => {
    // Function logic here
    setSelectedOrder(order)

    console.log('selected order', order?.orderID)
    setShowCancelModal(true);
  };


  const handleCancelOrderClick = async () => {
    // Function logic here

    setLoading(true)
    if ((selectedOrder?.unfilled)) {
      const amountToReturn = selectedOrder?.unfilled
    try {
        console.log('updating order: ', selectedOrder!.orderID )
        // Update order document
        const orderDocRef = doc(db, 'orders', selectedOrder!.orderID);
        await updateDoc(orderDocRef, {
          Unfilled: 0,
          Canceled: selectedOrder?.unfilled
        });

        //update balances
        const currentUserEmailORDER: string = `${String(getAuth().currentUser?.email)}ORDER`;
        const emailString: string = currentUserEmail!

        if (selectedOrder!.sellOrder) {

          const userBalances = await getUserBalances(emailString, selectedOrder!.ticker)
        
          console.log('Got user balances for cancel', userBalances);
          const updateAvailableBalance = userBalances.balanceAvailable + amountToReturn
          const updateOrderBalance = userBalances.balanceOrder - amountToReturn
  
          console.log('Updating with updateAvailableBalance', updateAvailableBalance);
          console.log('Updating with updateOrderBalance', updateOrderBalance);
  
          const balanceDocRef = doc(db, 'cryptos', selectedOrder!.ticker);
          await setDoc(balanceDocRef, {
            [currentUserEmailORDER]: Number(updateOrderBalance),
            [currentUserEmail!]: (Number(updateAvailableBalance))
          }, { merge: true });

        } else {

          const userBalances = await getUserBalances(emailString, 'BTC')
        
          console.log('Got user balances for cancel', userBalances);
          const updateAvailableBalance = userBalances.balanceAvailable + (amountToReturn * selectedOrder!.price)
          const updateOrderBalance = userBalances.balanceOrder - (amountToReturn * selectedOrder!.price)
  
          console.log('Updating with updateAvailableBalance', updateAvailableBalance);
          console.log('Updating with updateOrderBalance', updateOrderBalance);
  
          const balanceDocRef = doc(db, 'cryptos', 'BTC');
          await setDoc(balanceDocRef, {
            [currentUserEmailORDER]: Number(updateOrderBalance),
            [currentUserEmail!]: (Number(updateAvailableBalance))
          }, { merge: true });

        }

        setLoading(false);
        setShowCancelModal(false);
        setShowCancelReceiptModal(true);
        refreshPage();

      } catch (error) {
        console.error('Error updating order document:', error);
        setLoading(false)
      }
    } else {
        console.log('nope')
        setLoading(false)
    }


  };

  const refreshPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000); // Wait for 2000 milliseconds before refreshing
  };
  
    return (
        <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: 'white', margin: '4px', fontSize: '26px' }}>Trade Orders</div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '12px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: 'white' }}>
              &nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </div>

        {loading ? (
        <div>
            <div style={{ backgroundColor: '#222222' }}>
              <RocketAnimation loading={true} type={'small'}/>
            </div>
        </div>
        ) : (

            <div>
            {orderData && bitcoinPriceInDollars && orderData.length > 0 ? (
                <div>
                  <div className={styles['asset-crypto-list-container']}>
                    <h2 className={styles['crypto-property-favorite-dashboard']}>Ticker</h2>
                    <p className={styles['crypto-property-favorite-dashboard']}>Amount</p>
                    <p className={styles['crypto-property-favorite-dashboard']}>Type</p>
                    <p className={styles['crypto-property-favorite-dashboard']}>Price</p>
                    <p className={styles['crypto-property-favorite-dashboard']}>% Filled</p>
                    <p className={styles['crypto-property-favorite-dashboard']}></p>
                  </div>
                </div>
              ) : null}
            

            <div>
                {orderData && bitcoinPriceInDollars && orderData.length > 0 ? (
                orderData.map((order, index) => (

                    <div className={styles['asset-crypto-list-container']}>

                <Link className={styles['crypto-property-favorite-dashboard']} to={`/trade/${order.ticker}`} style={{ textDecoration: 'none', 
                color: 'white' }}>
                    <h2 onClick={() => window.scrollTo(0, 0)}>{order.ticker}</h2>
                    </Link>

                    <div className={styles['crypto-property-favorite-dashboard']}>
                    <p className={styles['crypto-property-favorite-dashboard']}>{(order.filled+order.unfilled).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 8,
                        }).replace(/\.?0+$/, '')}</p>


                <div>
                {order.canceled ? (
                    <p className={styles['crypto-property-favorite-dashboard']}>
                    {/* Display this if order is canceled */}
                    {(order.canceled).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 8,
                    }).replace(/\.?0+$/, '') + ' Canceled'}
                    </p>
                ) : null}
                </div>
                    </div>

                
                    <p className={styles['crypto-property-favorite-dashboard']} style={{fontWeight: 'bold', fontSize:'20px'}}>{BuySellText(order.sellOrder)}</p>
                    <p className={styles['crypto-property-favorite-dashboard']}>At {order.price} BTC</p>

                    <p className={styles['crypto-property-favorite-dashboard']}>
                    {order.filled
                        ? (
                        ((order.filled / (order.filled + order.unfilled)) * 100).toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }) + '%'
                        )
                        : "0%"
                    }
                    </p>
                    
                    <button className={styles['crypto-property-favorite-dashboard']} style={{ color: '#222222', 
                    borderRadius: '7px', border: 'none', cursor: 'pointer', height:'35px' }} onClick={(event) => handleCancelSelection(order)}>
                                Cancel
                    </button>   
                </div>


                ))
                ) : (
                <div style={{ color: 'white', margin: '20px', fontSize: '45px', textAlign: 'center', maxWidth: '500px' }}>
                  <img src={graphIcon} alt="Close" style={{width: '84px', height: '84px'}}></img>
                </div> // Display a message when no crypto data is available
                )}
            </div>
        </div>
        )}


        {showCancelModal && (
          <div className={styles['modal-container']}>
            <div className={styles['overlay']} onClick={handleModalCloseClick}></div>
            <div className={styles['crypto-modal']}>
              <div className={styles['crypto-modal-header']}>
                <h3>{CancelModalTitleText(selectedOrder!)}</h3>
                <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={handleModalCloseClick} style={{width: '24px', height: '24px'}}></img>

              </div>


              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

              <div>
                {selectedOrder?.unfilled
                    ? `${selectedOrder.filled + selectedOrder.unfilled} ${selectedOrder.ticker} at the price ${selectedOrder.price} BTC`
                    : "This order has already filled or been canceled."
                }
                </div>


              </div>

              
              <div className={styles['crypto-modal-footer']}>
                <button className={styles['crypto-modal-continue-button']} onClick={handleCancelOrderClick}>Cancel</button>
              </div>
            </div>
          </div>
        )}

{showCancelReceiptModal && (
          <div className={styles['modal-container']}>
            <div className={styles['overlay']} onClick={handleModalCloseClick}></div>
            <div className={styles['crypto-modal']}>
              <div className={styles['crypto-modal-header']}>
                <h3>Done!</h3>
                <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" style={{width: '24px', height: '24px'}}></img>

              </div>

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

              <div>Your {selectedOrder?.ticker} order has been canceled</div>
                <br/>
              <div>
                {selectedOrder?.sellOrder ? (
<>{selectedOrder?.unfilled} {selectedOrder?.ticker} has been made available again</>

                ) : (
<>{selectedOrder!.unfilled * selectedOrder!.price} BTC has been made available again</>
                )}
                
                </div>
              </div>

              <div className={styles['crypto-modal-footer']}>
                
              </div>
            </div>
          </div>
        )}
    </div>
);
}

export default OrdersDashboardTile;