import React from 'react';
import styles from '../App.module.scss';
import {formatDollarNumber} from '../Helpers/FormatNumbers';

interface CryptoProps {
  name: string;
  ticker: string;
  price: number;
  priceInDollars: number;
  originatorUsername: string;
  volumeData: { [key: number]: number };
  valuation: number;
  iconUrl: string;
  supply: number;
  isUsingCustomIcon: boolean;
  isTestCrypto: boolean;
}

const VolumeText = (cryptopriceInUSD: number, cryptoPrice: number, volume: { [key: number]: number }) => {
  if (!volume) {
    return "0"; // Display 0 if volumeData is falsy
  }

  //spaghetti code alert
  //infer Bitcoin's price in USD from the cryptos price and price in USD
  const bitcoinPriceInUSD = cryptopriceInUSD/cryptoPrice;

console.log(bitcoinPriceInUSD)
  const currentDate = Date.now()/1000;
  const oneMonthAgo = currentDate - 30 * 24 * 60 * 60; // Calculate timestamp for one month ago

  console.log('one month ago',  oneMonthAgo)
  let monthlyVolume = 0;
  Object.entries(volume).forEach(([timestamp, amount]) => {
    const timestampNumber = parseFloat(timestamp);
    /*
    if (timestampNumber >= oneMonthAgo) {
      monthlyVolume += amount; // Add amounts within one month to monthlyVolume
    }
    */
    monthlyVolume += amount;
  });


  return "$" + (monthlyVolume * bitcoinPriceInUSD).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).replace(/\.?0+$/, ''); // Display the calculated monthly volume
};

const iconImage = (name: string, isUsingCustomIcon: boolean, customIconLink: string) => {
  const firstChar = name.charAt(0);
  console.log('Testing first char')
  if (/^[^A-Za-z]+$/.test(firstChar)) {
    console.log('If branch: load from first char')
    return (
      <div>
        <img
          className={styles['crypto-property-img']}
          src={
            isUsingCustomIcon
              ? customIconLink
              : require(`../assets/CryptoIcons/X.png`)
          }
          alt={` `}
        />
      </div>
    );
  } else {
    console.log('Else branch: load from X.png')
      return (
        <img
          className={styles['crypto-property-img']}
          src={
            isUsingCustomIcon
              ? customIconLink
              : require(`../assets/CryptoIcons/${(name).charAt(0).toUpperCase()}.png`)
          }
          alt={` `}
        />
    );
  }
};

const CryptoCard: React.FC<CryptoProps> = ({
  name,
  ticker,
  price,
  priceInDollars,
  originatorUsername,
  volumeData,
  valuation,
  iconUrl,
  supply,
  isUsingCustomIcon,
  isTestCrypto,

}) => {
  return (
    <div className={styles['browse-crypto-list-container']}>

<div style={{width: '280px'}}>
  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px'}}>
    <div style={{width: '65px'}}>
 {iconImage(name, isUsingCustomIcon, iconUrl)}
 </div>
        <div style={{fontWeight: 'bold', fontSize: '18px'}}>

        {name}
        
        </div>

        <div style={{color: '#666666'}}>{ticker}</div>
        </div>
        </div>

      <div className={styles['crypto-property']}>

      <p className={styles['crypto-property']}>${formatDollarNumber(priceInDollars)}</p>

{/*
      <p className={styles['crypto-property']}>{price.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 8,
                            }).replace(/\.?0+$/, '')} Bitcoin</p>
                          */}
      </div>

      <p className={styles['crypto-property']}>{originatorUsername}</p>

      { window.innerWidth > 1100 && 
      <p className={styles['crypto-property']}>{VolumeText(priceInDollars, price, volumeData)}</p>
}

<p className={styles['crypto-property']}>${valuation.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}</p>

      <p style={{width: '160px', marginRight: '20px'}}>{supply.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).replace(/\.?0+$/, '')} {ticker}</p>

    </div>
  );
};

export default CryptoCard;