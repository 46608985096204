import React, { useState, useRef, useEffect } from 'react';
import styles from '../../App.module.scss';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { FirebaseError } from '@firebase/util';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import closeButton from '../../assets/closeButton.png';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { setFirstName } from '../../redux/ephemeralUserData';

export default function SignUp2Modal() {

  const db = getFirestore();
  const dispatch = useAppDispatch();
    const [showSignUp2Modal, setShowSignUp2Modal] = useState(true);

    const [showTooltip, setShowTooltip] = useState(false);

    const [firstName, setUncleanedFirstName] = useState('');
    const [lastName, setUncleanedLastName] = useState('');
    const [last4SSN, setUncleaned4SSN] = useState('');
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const [errorMessage, setErrorMessage] = useState('');

      const toggleSignUp2Modal = () => {
        setShowSignUp2Modal(!showSignUp2Modal);
      };


      const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUncleanedFirstName(event.target.value);
      };
  
      const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUncleanedLastName(event.target.value);
      };


      const handleDateChange = (date: Date) => {
        setSelectedDate(date);
        const currentDate = new Date();
        const age = currentDate.getFullYear() - date.getFullYear();
        const isUnder18 = age < 18;
        if (isUnder18) {
          setErrorMessage('You must be at least 18 years old to sign up');
        } else {
            setErrorMessage('');
        }
      };

      const handleLast4SSNChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUncleaned4SSN(event.target.value);
      };


      const removeWhitespacesAndDashes = (str: string) => {
        return str.replace(/[\s-]/g, '');
      };

      const removeWhitespace = (str: string) => {
        return str.replace(/\s/g, '');
      };

      const handleSignUpSubmitButtonClick = async () => {
        const cleanedFirst = removeWhitespace(lastName);
        const cleanedLast = removeWhitespace(firstName);
        const cleaned4SSN = removeWhitespacesAndDashes(last4SSN);
      
        // Regular expression pattern for a 4-digit number
        const fourDigitNumberRegex = /^\d{4}$/;
      
        if (cleanedFirst === '' || cleanedLast === '' || cleaned4SSN === '' || selectedDate === null) {
            setErrorMessage('Please fill in all fields');
          } else if (!fourDigitNumberRegex.test(cleaned4SSN)) {
            setErrorMessage('Please enter a valid 4-digit number for the last 4 SSN');
          } else {
            // Calculate age based on date of birth
            const dateOfBirth = selectedDate;
            const currentDate = new Date();
            const age = currentDate.getFullYear() - dateOfBirth.getFullYear();
            const isUnder18 = age < 18;
            if (isUnder18) {
              setErrorMessage('You must be at least 18 years old to sign up');
            } else {
              // SAVE to users doc
              setErrorMessage('');
              try {
                // Update user document
                const uid = getAuth().currentUser?.uid;
                const userDocRef = doc(db, 'users', uid!); // Replace 'userId' with the actual user ID
                await updateDoc(userDocRef, {
                  FirstName: firstName,
                  LastName: lastName,
                  Last4SSN: last4SSN,
                  DateOfBirth: dateOfBirth.toLocaleDateString('en-US'),
                });
                dispatch(setFirstName(cleanedFirst));
              } catch (error) {
                console.error('Error updating user document:', error);
              }

            }
          }
        };
        
        
        

      interface CustomDatePickerInputProps {
        value?: string;
        onClick?: () => void;
      }

      const CustomDatePickerInput: React.FC<CustomDatePickerInputProps> = ({ value, onClick }) => (
        <div
          style={{
            width: '300px',
            padding: '3px',
            fontSize: '17px',
            marginTop: '4px',
            backgroundColor: '#222222',
            height: '20px',
            color: 'white',
            border: '1px solid lightgray',
            borderRadius: '8px',
          }}
          onClick={onClick}
        >
          {value}
        </div>
      );

    return (
        
        <div>

          {showSignUp2Modal && (
            <div className={styles['modal-container']}>
              <div className={styles['overlay']} onClick={toggleSignUp2Modal}></div>
              <div className={styles['crypto-modal']} style={{height: '70vh'}}>
                <div className={styles['crypto-modal-header']}>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h3>Create Your Account</h3>
                    <div>
                        <span
                            style={{ cursor: 'pointer' }}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                        >
                            <span style={{ color: 'white' }}>Compliance Info</span>
                            <span style={{ color: '#007AFF', marginLeft: '5px' }}>
                            What Is This?
                            </span>
                        </span>
                        </div>
                  </div>
                  <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={toggleSignUp2Modal} style={{width: '24px', height: '24px'}}></img>

                </div>

                <div className={styles['crypto-modal-content']}>


                {showTooltip ? (
                    <div
                    style={{
                        backgroundColor: '#222222',
                        color: '#fff',
                        borderRadius: '6px',
                        padding: '5px',
                        marginTop: '10px',
                        width: '300px',
                        textAlign: 'center',
                    }}
                    >
                    FinFriend is a licensed Money Services Business with FinCEN. This means that we must collect Know Your Customer data, 
                    report suspicious activity, and operate with the utmost integrity. <br/><br/>In order to remain compliant, 
                    we must uphold our Anti Money Laundering Policy. You may learn more about our AML and Privacy Policies on the "Learn" page.
                    </div>
                ) : (
                    <div className={styles['crypto-modal-textfields']}>
                    <div className={styles['crypto-modal-textfield']}>
                          <label htmlFor="firstName">Legal First Name</label>
                          <input id="firstName" type="text" value={firstName} onChange={handleFirstNameChange} />
                      </div>
                      <div className={styles['crypto-modal-textfield']}>
                          <label htmlFor="lastName">Legal Last Name</label>
                          <input id="lastName" type="text" value={lastName} onChange={handleLastNameChange} />
                      </div>
                      <div className={styles['crypto-modal-textfield']}>
                          <label htmlFor="dateOfBirt">Date Of Birth</label>
                              <DatePicker
                              selected={selectedDate}
                              onChange={handleDateChange}
                              customInput={<CustomDatePickerInput />}
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown
                              showYearDropdown
                              maxDate={new Date(2020, 11, 30)}
                              />
                      </div>
                      <div className={styles['crypto-modal-textfield']}>
                          <label htmlFor="last4SSN">Last 4 of Social Security No.</label>
                          <input id="last4SSN" type="text" value={last4SSN} onChange={handleLast4SSNChange} />
                      </div>
                    </div>
                )}

                </div>
                <div className={styles['crypto-modal-footer']} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <label className={styles['error-label']} style={{ textAlign: 'center', marginRight: '10px', marginBottom: '10px' }}>{errorMessage}</label>
                  <button className={styles['crypto-modal-continue-button']} style={{ flexGrow: 1 }} onClick={handleSignUpSubmitButtonClick}>Continue</button>
                </div>

                </div>

              </div>
          )}

    </div>
    );
}

