import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import styles from '../../App.module.scss';
import { functions, db } from "../..";
import { httpsCallable } from "firebase/functions";
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { cryptoForTradingData } from '../CurrentUserInfo';
import { useAppSelector } from '../../redux/hooks';
import { getAuth } from "firebase/auth";
import {
    Chart as ChartJS,
    LineElement,
    TimeScale, // x axis
    LinearScale, // y axis 
    PointElement, 
    ChartOptions

} from 'chart.js';
import 'chartjs-adapter-moment';
import HeartIcon from '../../assets/heart_icon.png';
import HeartFillIcon from '../../assets/heart_fill_icon.png';
import { formatDollarNumber } from '../../Helpers/FormatNumbers';

ChartJS.register(
    LineElement,
    TimeScale, // x axis
    LinearScale, // y axis 
    PointElement
)

interface GraphTradeTileProps {
  cryptoData: cryptoForTradingData | null;
}

function GraphTradeTile({ cryptoData }: GraphTradeTileProps) {

  const bitcoinPriceInDollars = useAppSelector((state) => state.bitcoinPriceData.value)
  const comingFromCreateCrypto = useAppSelector((state) => state.comingFromCreateToTrade.value)
  const currentUserCreationDate = useAppSelector((state) => state.userData.userData?.creationDate)
  const [loading, setLoading] = useState(true);

  //liked logic
  const [cryptoIsLiked, setCryptoIsLiked] = useState<boolean>(false);
  const likedByArray = cryptoData?.LikedBy;
  const uid = getAuth().currentUser?.uid;

  //load like
  useEffect(() => {
    if (uid && likedByArray && likedByArray.includes(uid)) {
      setCryptoIsLiked(true);
    }
  }, [uid, likedByArray]);

  const toggleLike = () => {
    setCryptoIsLiked(!cryptoIsLiked);

    try {


    const cryptoDocRef = doc(db, 'cryptos', cryptoData!.Ticker);

    if (uid && currentUserCreationDate) {


        if (cryptoIsLiked) {
          // User is unliking the crypto
          if (likedByArray) {
            const updatedLikedArray = likedByArray.filter((id) => id !== uid);
    
            console.log('updating database: ', updatedLikedArray);
            updateDoc(cryptoDocRef, {
              LikedBy: updatedLikedArray
            });
          } else {
            // Array doesn't exist, no need to update
          }
        } else {
        // User is liking the crypto
        if (likedByArray) {
          const updatedLikedArray = [...likedByArray, uid];
  
          console.log('updating database: ', updatedLikedArray);
          updateDoc(cryptoDocRef, {
            LikedBy: updatedLikedArray
          });
        } else {
          console.log('updating database: ', [uid]);
          updateDoc(cryptoDocRef, {
            LikedBy: [uid]
          });
        }
      }



    } else {
      // User is not authenticated
      window.alert("Like this asset? Complete your account with us to save it!");
    }

    } catch (error) {
      console.error('Error updating the liked status: ', error);
    }
  };

  const [chartDataHour, setChartDataHour] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      fill: boolean;
      borderColor: string;
      tension: 0.0;
    }[];
  } | null>(null);

  const [chartDataDay, setChartDataDay] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      fill: boolean;
      borderColor: string;
      tension: 0.0;
    }[];
  } | null>(null);

  const [chartDataWeek, setChartDataWeek] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      fill: boolean;
      borderColor: string;
      tension: 0.0;
    }[];
  } | null>(null);

  const [chartDataMonth, setChartDataMonth] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      fill: boolean;
      borderColor: string;
      tension: 0.0;
    }[];
  } | null>(null);

  const [chartDataYear, setChartDataYear] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      fill: boolean;
      borderColor: string;
      tension: 0.0;
    }[];
  } | null>(null);

  const [chartDataAll, setChartDataAll] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      fill: boolean;
      borderColor: string;
      tension: 0.0;
    }[];
  } | null>(null);

  const [optionsAll, setOptionsAll] = useState<ChartOptions<'line'>>({});
  const [optionsHour, setOptionsHour] = useState<ChartOptions<'line'>>({});
  const [optionsMonth, setOptionsMonth] = useState<ChartOptions<'line'>>({});
  const [optionsWeek, setOptionsWeek] = useState<ChartOptions<'line'>>({});
  const [optionsDay, setOptionsDay] = useState<ChartOptions<'line'>>({});
  const [optionsYear, setOptionsYear] = useState<ChartOptions<'line'>>({});




  useEffect(() => {
    console.log('chartDataHour: ', chartDataHour);
  }, [chartDataHour]);

  async function fetchPriceHistories(ticker: string) {
    try {
      console.log('Fetching price histories for ticker:', ticker);
      const docRef = doc(db, 'priceHistories', ticker);
      const docSnapshot = await getDoc(docRef);
  
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
  
        const originationDate = (data.OriginationDate as number) * 1000; // Multiply by 1000 to convert to milliseconds
        const originationPrice = data.OriginationPrice as number;
        const priceChanges = data.PriceChanges as Record<string, number>;
  
        // Combine origination date and price with price changes

        let prices = [];

        if (priceChanges) {
          prices = [
            { time: originationDate, price: originationPrice },
            ...Object.entries(priceChanges).map(([time, price]) => ({
              time: parseInt(time, 10) * 1000,
              price: price,
            })),
          ];
        } else {
          prices = [{ time: originationDate, price: originationPrice }];
        }
  
        // Sort prices by time (ascending order)
        prices.sort((a, b) => a.time - b.time);
  
        console.log('Retrieved price histories:', prices);
  
        const currentTimeInMilliSeconds = Date.now(); // Current time in milliseconds

        const fiveMinutesAgoInMilliSeconds = currentTimeInMilliSeconds - 300000.0;
        const oneHourAgoInMilliSeconds = currentTimeInMilliSeconds - 3600000.0;
        const oneDayAgoInMilliSeconds = currentTimeInMilliSeconds - 86400000.0;
        const oneWeekAgoInMilliSeconds = currentTimeInMilliSeconds - 604800000.0;
        const oneMonthAgoInMilliSeconds = currentTimeInMilliSeconds - 2592000000.0;
        const oneYearAgoInMilliSeconds = currentTimeInMilliSeconds - 31540000000.0;
    
        //Find the format of the times
        if (fiveMinutesAgoInMilliSeconds <= originationDate) {
          setOptionsAll(optionsMinScale)
          setOptionsMonth(optionsMinScale)
          setOptionsDay(optionsMinScale)
          setOptionsWeek(optionsMinScale)
          setOptionsHour(optionsMinScale)
          setOptionsYear(optionsMinScale)
        } else if (oneDayAgoInMilliSeconds <= originationDate) {
          setOptionsHour(optionsSmallScale)
          setOptionsDay(optionsSmallScale)
          setOptionsWeek(optionsSmallScale)
          setOptionsMonth(optionsSmallScale)
          setOptionsYear(optionsSmallScale)
          setOptionsAll(optionsSmallScale)
        } else if (oneWeekAgoInMilliSeconds <= originationDate) {
          setOptionsHour(optionsSmallScale)
          setOptionsDay(optionsSmallScale)
          setOptionsWeek(optionsMidScale)
          setOptionsMonth(optionsMidScale)
          setOptionsYear(optionsMidScale)
          setOptionsAll(optionsMidScale)
        } else {
          setOptionsHour(optionsSmallScale)
          setOptionsDay(optionsSmallScale)
          setOptionsWeek(optionsMidScale)
          setOptionsMonth(optionsMidScale)
          setOptionsYear(optionsMaxScale)
          setOptionsAll(optionsMaxScale)
        }
        
        //Probe the data to find the first point on the graph
        let hourTimeProbe = 0;
        let hourPriceProbe = 0;
        let dayTimeProbe = 0;
        let dayPriceProbe = 0;
        let weekTimeProbe = 0;
        let weekPriceProbe = 0;
        let monthTimeProbe = 0;
        let monthPriceProbe = 0;
        let yearTimeProbe = 0;
        let yearPriceProbe = 0;


        for (let i = 0; i < prices.length; i++) {
          const time = prices[i].time;
        
          if (time <= oneHourAgoInMilliSeconds && time > hourTimeProbe) {
            hourTimeProbe = time;
            hourPriceProbe = prices[i].price
          }

          if (time <= oneDayAgoInMilliSeconds && time > dayTimeProbe) {
        
            dayTimeProbe = time;
            dayPriceProbe = prices[i].price
          }

          if (time <= oneWeekAgoInMilliSeconds && time > weekTimeProbe) {

            weekTimeProbe = time;
            weekPriceProbe = prices[i].price
          }

          if (time <= oneYearAgoInMilliSeconds && time > yearTimeProbe) {

            yearTimeProbe = time;
            yearPriceProbe = prices[i].price
          }

          if (time <= oneMonthAgoInMilliSeconds && time > monthTimeProbe) {
        
            monthTimeProbe = time;
            monthPriceProbe = prices[i].price
          }

        };

        //Hour

        const chartDataForHour = [];
        const labelsHour = [];

        if (hourTimeProbe !== 0) {
          labelsHour.push(new Date(oneHourAgoInMilliSeconds).toISOString());
          chartDataForHour.push(hourPriceProbe*bitcoinPriceInDollars);
        } else {
          // There is no data older than one hour, use the origination date and price
          const originationDate = new Date(prices[0].time);
          labelsHour.push(new Date(originationDate).toISOString());
          chartDataForHour.push(prices[0].price*bitcoinPriceInDollars);
        }

        //Get every point in between

        const pricesFilteredByHour = prices.filter((entry) => entry.time >= oneHourAgoInMilliSeconds);

        // Add the filtered data to the labels and chart data
        pricesFilteredByHour.forEach((entry) => {
          const date = new Date(entry.time);
          labelsHour.push(date.toISOString());
          chartDataForHour.push(entry.price*bitcoinPriceInDollars);
        });

        // Add the current time and price to the labels and chart data

        labelsHour.push(new Date(currentTimeInMilliSeconds).toISOString());
        chartDataForHour.push((cryptoData?.Price || 0)*bitcoinPriceInDollars);

        setChartDataHour({
          labels: labelsHour,
          datasets: [
            {
              label: 'Price',
              data: chartDataForHour,
              fill: false,
              borderColor: 'white',
              tension: 0.0,
            },
          ],
        });


        //Day

        // Find the closest price greater than one hour ago and its corresponding time

        const chartDataForDay = [];
        const labelsDay = [];

        if (dayTimeProbe !== 0) {
          //Get the probe price and the time limit for the first point
          labelsDay.push(new Date(oneDayAgoInMilliSeconds).toISOString());
          chartDataForDay.push(dayPriceProbe*bitcoinPriceInDollars);
        } else {
          // There is no data older than one hour, use the origination date and price
          const originationDate = new Date(prices[0].time);
          labelsDay.push(new Date(originationDate).toISOString());
          chartDataForDay.push(prices[0].price*bitcoinPriceInDollars);
        }


        //Get every point in between
        const pricesFilteredByDay = prices.filter((entry) => entry.time >= oneDayAgoInMilliSeconds);

        // Add the filtered data to the labels and chart data
        pricesFilteredByDay.forEach((entry) => {
          const date = new Date(entry.time);
          labelsDay.push(date.toISOString());
          chartDataForDay.push(entry.price*bitcoinPriceInDollars);
        });

        labelsDay.push(new Date(currentTimeInMilliSeconds).toISOString());
        chartDataForDay.push((cryptoData?.Price || 0)*bitcoinPriceInDollars);

        setChartDataDay({
          labels: labelsDay,
          datasets: [
            {
              label: 'Price',
              data: chartDataForDay,
              fill: false,
              borderColor: 'white',
              tension: 0.0,
            },
          ],
        });


        //Week

        // Find the closest price greater than one hour ago and its corresponding time

        const chartDataForWeek = [];
        const labelsWeek = [];

        if (weekTimeProbe !== 0) {
          //Get the probe price and the time limit for the first point
          labelsWeek.push(new Date(oneWeekAgoInMilliSeconds).toISOString());
          chartDataForWeek.push(weekPriceProbe*bitcoinPriceInDollars);
        } else {
          // There is no data older than one hour, use the origination date and price
          const originationDate = new Date(prices[0].time);
          labelsWeek.push(new Date(originationDate).toISOString());
          chartDataForWeek.push(prices[0].price*bitcoinPriceInDollars);
        }

        //Get every point in between
        const pricesFilteredByWeek = prices.filter((entry) => entry.time >= oneWeekAgoInMilliSeconds);

        // Add the filtered data to the labels and chart data
        pricesFilteredByWeek.forEach((entry) => {
          const date = new Date(entry.time);
          labelsWeek.push(date.toISOString());
          chartDataForWeek.push(entry.price*bitcoinPriceInDollars);
        });

        labelsWeek.push(new Date(currentTimeInMilliSeconds).toISOString());
        chartDataForWeek.push((cryptoData?.Price || 0)*bitcoinPriceInDollars);

        setChartDataWeek({
          labels: labelsWeek,
          datasets: [
            {
              label: 'Price',
              data: chartDataForWeek,
              fill: false,
              borderColor: 'white',
              tension: 0.0,
            },
          ],
        });


        //Month

        // Find the closest price greater than one hour ago and its corresponding time

        const chartDataForMonth = [];
        const labelsMonth = [];

        if (monthTimeProbe !== 0) {
          //Get the probe price and the time limit for the first point
          labelsMonth.push(new Date(oneMonthAgoInMilliSeconds).toISOString());
          chartDataForMonth.push(monthPriceProbe*bitcoinPriceInDollars);
        } else {
          // There is no data older than one hour, use the origination date and price
          const originationDate = new Date(prices[0].time);
          labelsMonth.push(new Date(originationDate).toISOString());
          chartDataForMonth.push(prices[0].price*bitcoinPriceInDollars);
        }

        //Get every point in between
        const pricesFilteredByMonth = prices.filter((entry) => entry.time >= oneMonthAgoInMilliSeconds);

        // Add the filtered data to the labels and chart data
        pricesFilteredByMonth.forEach((entry) => {
          const date = new Date(entry.time);
          labelsMonth.push(date.toISOString());
          chartDataForMonth.push(entry.price*bitcoinPriceInDollars);
        });

        labelsMonth.push(new Date(currentTimeInMilliSeconds).toISOString());
        chartDataForMonth.push((cryptoData?.Price || 0)*bitcoinPriceInDollars);

        setChartDataMonth({
          labels: labelsMonth,
          datasets: [
            {
              label: 'Price',
              data: chartDataForMonth,
              fill: false,
              borderColor: 'white',
              tension: 0.0,
            },
          ],
        });

        //Year

        // Find the closest price greater than one hour ago and its corresponding time

        const chartDataForYear = [];
        const labelsYear = [];

        if (yearTimeProbe !== 0) {
          //Get the probe price and the time limit for the first point
          labelsYear.push(new Date(oneYearAgoInMilliSeconds).toISOString());
          chartDataForYear.push(yearPriceProbe*bitcoinPriceInDollars);
        } else {
          // There is no data older than one hour, use the origination date and price
          const originationDate = new Date(prices[0].time);
          labelsYear.push(new Date(originationDate).toISOString());
          chartDataForYear.push(prices[0].price*bitcoinPriceInDollars);
        }

        //Get every point in between
        const pricesFilteredByYear = prices.filter((entry) => entry.time >= oneYearAgoInMilliSeconds);

        // Add the filtered data to the labels and chart data
        pricesFilteredByYear.forEach((entry) => {
          const date = new Date(entry.time);
          labelsYear.push(date.toISOString());
          chartDataForYear.push(entry.price*bitcoinPriceInDollars);
        });

        labelsYear.push(new Date(currentTimeInMilliSeconds).toISOString());
        chartDataForYear.push((cryptoData?.Price || 0)*bitcoinPriceInDollars);

        console.log('chartDataForYear ', chartDataForYear);
        console.log('labelsYear ', labelsYear);

        setChartDataYear({
          labels: labelsYear,
          datasets: [
            {
              label: 'Price',
              data: chartDataForYear,
              fill: false,
              borderColor: 'white',
              tension: 0.0,
            },
          ],
        });

        //All
        const labelsAll = prices.map((entry) => {
          const date = new Date(entry.time);
          return date.toISOString();
        });
  
        const chartDataAll = prices.map((entry) => entry.price*bitcoinPriceInDollars);
  
        labelsAll.push(new Date(currentTimeInMilliSeconds).toISOString());
        chartDataAll.push((cryptoData?.Price || 0)*bitcoinPriceInDollars);

        setChartDataAll({
          labels: labelsAll,
          datasets: [
            {
              label: 'Price',
              data: chartDataAll,
              fill: false,
              borderColor: 'white',
              tension: 0.0,
            
            },
          ],
        });

        setLoading(false);
      } else if (comingFromCreateCrypto) {
        //There is no Price History data becuase this is an anonymous crypto

        const chartDataAnonymous = [];
        const labelsAnonymous = [];

        const currentTimeInMilliSeconds = Date.now();

        labelsAnonymous.push(new Date(currentTimeInMilliSeconds-5000).toISOString());
        chartDataAnonymous.push((cryptoData?.Price || 0)*bitcoinPriceInDollars);

        labelsAnonymous.push(new Date(currentTimeInMilliSeconds).toISOString());
        chartDataAnonymous.push((cryptoData?.Price || 0)*bitcoinPriceInDollars);

        
        setChartDataAll({
          labels: labelsAnonymous,
          datasets: [
            {
              label: 'Price',
              data: chartDataAnonymous,
              fill: false,
              borderColor: 'white',
              tension: 0.0,
            
            },
          ],
        });
        setChartDataHour({
          labels: labelsAnonymous,
          datasets: [
            {
              label: 'Price',
              data: chartDataAnonymous,
              fill: false,
              borderColor: 'white',
              tension: 0.0,
            
            },
          ],
        });
        setChartDataDay({
          labels: labelsAnonymous,
          datasets: [
            {
              label: 'Price',
              data: chartDataAnonymous,
              fill: false,
              borderColor: 'white',
              tension: 0.0,
            
            },
          ],
        });
        setChartDataWeek({
          labels: labelsAnonymous,
          datasets: [
            {
              label: 'Price',
              data: chartDataAnonymous,
              fill: false,
              borderColor: 'white',
              tension: 0.0,
            
            },
          ],
        });
        setChartDataYear({
          labels: labelsAnonymous,
          datasets: [
            {
              label: 'Price',
              data: chartDataAnonymous,
              fill: false,
              borderColor: 'white',
              tension: 0.0,
            
            },
          ],
        });
        setChartDataMonth({
          labels: labelsAnonymous,
          datasets: [
            {
              label: 'Price',
              data: chartDataAnonymous,
              fill: false,
              borderColor: 'white',
              tension: 0.0,
            
            },
          ],
        });

        setOptionsAll(optionsMinScale);
        setOptionsHour(optionsMinScale);
        setOptionsDay(optionsMinScale);
        setOptionsWeek(optionsMinScale);
        setOptionsMonth(optionsMinScale);
        setOptionsYear(optionsMinScale);


      } else {
        console.log('No price history data available for ticker:', ticker);
        //coming from create

      }
    } catch (error) {
      console.error('Error fetching price histories:', error);
    }
  }

  useEffect(() => {
    if (cryptoData && bitcoinPriceInDollars) {
      fetchPriceHistories(cryptoData.Ticker);
    }
  }, [cryptoData, bitcoinPriceInDollars]);

  const optionsMaxScale: ChartOptions<'line'> = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MMM D YYYY',
          },
        },
        ticks: {
          maxTicksLimit: 6,
          color: 'white',
        },
      },
      y: {
        beginAtZero: false,
        ticks: {
          precision: 10,
          color: 'white',
        },
      },
    },
    elements:{
      line: {
        tension: 0.0,
        cubicInterpolationMode: 'default',
      },
      point:{
        radius: 0,
      }
    }
  };

  const optionsMidScale: ChartOptions<'line'> = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MMM DD',
          },
        },
        ticks: {
          maxTicksLimit: 6,
          color: 'white',
        },
      },
      y: {
        beginAtZero: false,
        ticks: {
          precision: 10,
          color: 'white',
        },
      },
    },
    elements:{
      point:{
        radius: 0,
      }
    }
  };

  const optionsSmallScale: ChartOptions<'line'> = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'minute',
          displayFormats: {
            day: 'h:mm A',
          },
        },
        ticks: {
          maxTicksLimit: 6,
          color: 'white',
        },
      },
      y: {
        beginAtZero: false,
        ticks: {
          precision: 10,
          color: 'white',
        },
      },
    },
    elements:{
      point:{
        radius: 0,
      }
    }
  };

  const optionsMinScale: ChartOptions<'line'> = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'second',
          displayFormats: {
            minute: 'h:mm:ss',
          },
        },
        ticks: {
          maxTicksLimit: 6,
          color: 'white',
        },
      },
      y: {
        beginAtZero: false,
        ticks: {
          precision: 10,
          color: 'white',
        },
      },
    },
    elements:{
      point:{
        radius: 0,
      }
    }
  };


      const [selectedTimeframeButton, setSelectedTimeframeButton] = useState('all');


      const handleHourButtonClick = () => {
        setSelectedTimeframeButton('hour');
      };
      
      const handleDayButtonClick = () => {
        setSelectedTimeframeButton('day');
      };
      
      const handleWeekButtonClick = () => {
        setSelectedTimeframeButton('week');
      };
      
      const handleMonthButtonClick = () => {
        setSelectedTimeframeButton('month');
      };
      const handleYearButtonClick = () => {
        setSelectedTimeframeButton('year');
      };
      const handleAllButtonClick = () => {
        setSelectedTimeframeButton('all');
      };
    
      if (!cryptoData) {
        return <div>Loading...</div>; // You can show a loading indicator until the data is fetched
      }

      
      function formatBTCNumber(num: Number) {
        let str = num.toFixed(8); // Convert to string with sufficient decimal places
        str = str.replace(/(\.\d*?[1-9])0+$/, "$1"); // Remove trailing zeros
        str = str.replace(/\.0+$/, ""); // Remove decimal point if all zeros
        return str;
      }

    return (
        <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h3 style={{ color: 'white', margin: '4px' }}>{cryptoData.Ticker}</h3>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={toggleLike}>
            { cryptoIsLiked ? (
              <div>
                <img src={HeartFillIcon} alt="heart icon" style={{ width: '20px', height: '20px' }} />
              </div>
            ) : (
              <div>
                <img src={HeartIcon} alt="heart icon" style={{ width: '20px', height: '20px' }} />
              </div>
            )}
          
            <div style={{ color: '#ffffff', fontSize: '14px', marginLeft: '5px' }}>Favorite</div>
            
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '12px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: 'white' }}>
              <span style={{ fontSize: '20px' }}>$</span>
              <span style={{ fontSize: '36px' }}>{formatDollarNumber(cryptoData.Price * bitcoinPriceInDollars)}</span> 
              &nbsp;&nbsp;&nbsp;
              <span style={{ fontSize: '20px' }}>
              {formatBTCNumber(cryptoData.Price)} BTC
              </span>
            </div>
          </div>
          <div className={styles['trade-timeframe-button-row']}>
            <button className={selectedTimeframeButton === 'hour' ? styles['trade-timeframe-button-selected-button'] : ''}
            onClick={handleHourButtonClick}>1H</button>
            <button className={selectedTimeframeButton === 'day' ? styles['trade-timeframe-button-selected-button'] : ''}
            onClick={handleDayButtonClick}>1D</button>
            <button className={selectedTimeframeButton === 'week' ? styles['trade-timeframe-button-selected-button'] : ''}
            onClick={handleWeekButtonClick}>1W</button>
            <button className={selectedTimeframeButton === 'month' ? styles['trade-timeframe-button-selected-button'] : ''}
            onClick={handleMonthButtonClick}>1M</button>
            <button className={selectedTimeframeButton === 'year' ? styles['trade-timeframe-button-selected-button'] : ''}
            onClick={handleYearButtonClick}>1Y</button>
            <button className={selectedTimeframeButton === 'all' ? styles['trade-timeframe-button-selected-button'] : ''}
            onClick={handleAllButtonClick}>All</button>
          </div>
        </div>

        <div style={{
    marginTop: window.innerWidth >= 1400 
                ? '10px' 
                : window.innerWidth >= 1200 
                    ? '50px' 
                    : '70px'
}}>
        {selectedTimeframeButton === 'all' && chartDataAll && (
      <Line data={chartDataAll} options={optionsAll} />
    )}

    {selectedTimeframeButton === 'hour' && chartDataHour && (
      <Line data={chartDataHour} options={optionsHour} />
    )}

    {selectedTimeframeButton === 'day' && chartDataDay && (
      <Line data={chartDataDay} options={optionsDay} />
    )}

    {selectedTimeframeButton === 'week' && chartDataWeek && (
      <Line data={chartDataWeek} options={optionsWeek} />
    )}

    {selectedTimeframeButton === 'month' && chartDataMonth && (
      <Line data={chartDataMonth} options={optionsMonth} />
    )}

    {selectedTimeframeButton === 'year' && chartDataYear && (
      <Line data={chartDataYear} options={optionsYear} />
    )}
        </div>
    </div>
);
}

export default GraphTradeTile;