
import React, { useState, useEffect } from 'react';
import { getFirestore, doc, setDoc, addDoc, collection, getDoc } from 'firebase/firestore';

export const AffiliateLinkTracker = async (affiliate: string) => {
    console.log('Affiliate link tracker with', affiliate);

    const db = getFirestore();

    //increment visit
    const docRef = doc(db, 'affiliateLinkTracker', affiliate);
    try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            const updatedValue = (data?.homePageVisits || 0) + 1;
            await setDoc(docRef, {
                homePageVisits: updatedValue,
            }, { merge: true });
            console.log('Field updated successfully!');
        } else {
            console.log('Document does not exist.');
            await setDoc(docRef, {
                homePageVisits: 1,
            }, { merge: true });
            console.log('Field created successfully!');

        }
    } catch (error) {
        console.error('Error in affiliate link tracker:', error);
    }

};

