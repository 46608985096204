import React, { useState } from 'react';
import styles from '../../App.module.scss';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import closeButton from '../../assets/closeButton.png';

interface LogInModalProps {
  buttonType: 'signInSuggest' | 'headerNav';
}

export default function LogInModal(props: LogInModalProps) {

    const [showSignInModal, setShowSignInModal] = useState(false);

    const [currentUserEmail, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordResetSent, setPasswordResetSent] = useState(false);

    const [logInHovered, setlogInHovered] = useState(false);
    const handleLogInEnter = () => {
        setlogInHovered(true);
      };
      const handleLogInLeave = () => {
        setlogInHovered(false);
      };

    const toggleSignInModal = () => {
        setShowSignInModal(!showSignInModal);
      };

      const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
      };
  
      const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
      };

      const [errorMessage, setErrorMessage] = useState('');

      const removeWhitespace = (str: string) => {
        return str.replace(/\s/g, '');
      };

      const handleSignInSubmitButtonClick = async () => {
        const cleanedEmail = removeWhitespace(currentUserEmail)
        const cleanedPassword = removeWhitespace(password)
        if (cleanedEmail === '' || password === '') {
          setErrorMessage('Please fill in all fields');
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cleanedEmail)) {
            setErrorMessage('Please enter a valid email address');
        } else {
          signInWithEmailAndPassword(getAuth(), cleanedEmail, cleanedPassword)
          .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            setErrorMessage('')
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setErrorMessage('Error: invalid log in');
          });
        }
      };

      const handleResetClick = () => {
        const cleanedEmail = removeWhitespace(currentUserEmail)

        if (cleanedEmail === '') {
          setErrorMessage('Please fill in the email field');
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cleanedEmail)) {
            setErrorMessage('Please enter a valid email address for your reset link');
        } else if (passwordResetSent === true) {
          setErrorMessage('We already sent you a reset link please check your spam')
        } else {
          sendPasswordResetLink();
          setPasswordResetSent(true);
          setErrorMessage('');
        }
        
      };

      async function sendPasswordResetLink() {
        const auth = getAuth();
        
        try {
          await sendPasswordResetEmail(auth, currentUserEmail);
          console.log('Password reset email sent successfully!');
          // Additional logic after sending the email
        } catch (error) {
          console.error('Error sending password reset email:', error);
          // Handle error
        }
      }



      let startingButton;
      switch (props.buttonType) {
          case 'headerNav':
            startingButton = (
              <div>
                  <button style={{
                      background: 'none',
                      color: logInHovered ? '#FFA14A' : 'white',
                      display: 'inline-block',
                      padding: '10px 20px',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      borderRadius: '25px',
                      border: 'none',
                      marginRight: '10px',
                      cursor: 'pointer'
                  }} onClick={toggleSignInModal}
                  onMouseEnter={handleLogInEnter}
                  onMouseLeave={handleLogInLeave}>Log In</button>
              </div>
            );
            break;
          case 'signInSuggest':
            startingButton = (
              <div>
                  <button className={styles['crypto-modal-black-button']} onClick={toggleSignInModal}>Log In</button>
            </div>
            );
      }

    return (


        <div>

          {startingButton}


        {showSignInModal && (
            <div className={styles['modal-container']}>
              <div className={styles['overlay']} onClick={toggleSignInModal}></div>
              <div className={styles['crypto-modal']}>
                <div className={styles['crypto-modal-header']}>
                  <h3>Log In</h3>
                  <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={toggleSignInModal} style={{width: '24px', height: '24px'}}></img>
                </div>
                <div className={styles['crypto-modal-content']}>
                  <div className={styles['crypto-modal-textfields']}>
                    <div className={styles['crypto-modal-textfield']}>
                        <label htmlFor="email">Email</label>
                        <input id="email" type="text" value={currentUserEmail} onChange={handleEmailChange} />
                    </div>
                    <div className={styles['crypto-modal-textfield']}>
                        <label htmlFor="password">Password</label>
                        <input id="password" type="password" value={password} onChange={handlePasswordChange} />
                    </div>
                  </div>

                  <div
                    onClick={handleResetClick}
                    style={{
                      textAlign: 'center',
                      marginRight: '10px',
                      marginTop: '30px',
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }}
                  >
                    {passwordResetSent
                      ? 'Sent please cheack your spam'
                      : 'Send me a password reset link'}
                  </div>

                </div>
                <div className={styles['crypto-modal-footer']}>
                <div className={styles['crypto-modal-footer']} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <label className={styles['error-label']} style={{ textAlign: 'center', marginRight: '10px' }}>{errorMessage}</label>
                  <button className={styles['crypto-modal-continue-button']} onClick={handleSignInSubmitButtonClick}>Log In</button>
                </div>
                </div>
              </div>
            </div>
          )}

    </div>
    );
}