import React, { useState, useEffect, useContext } from 'react';
import styles from '../App.module.scss';
import CreateCryptoModals from '../components/CreateCryptoModals';
import { functions, db } from "..";
import { httpsCallable } from "firebase/functions";
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setBitcoinPriceData } from '../redux/ephemeralBitcoinPrice';
import HeaderLeft from '../components/headerLeft';
import { useNavigate } from 'react-router-dom';
import SmallScreenLanding from '../components/SmallScreenLanding';
import Header from '../components/Header';
import RocketAnimation from '../components/RocketSpinAnimation';
import { doc, getDocs, collection, setDoc, addDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { setNumCryptoAlloted } from '../redux/ephemeralUserData';

function RedirectFromPayPalCreateNewAsset() {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setloading] = useState(true);

  const [currentUserUid, setCurrentUserUid] = useState('');
  const auth = getAuth();

  const [newPaymentApproved, setnewPaymentApproved] = useState(false);
  const currentUserEmail = auth.currentUser?.email

  let currentUserCreationDate = useAppSelector((state) => state.userData.userData?.creationDate);
  let numCoinAlloted = useAppSelector((state) => state.userData.userData?.CryptosAlloted);

  useEffect(() => {
    // This listener is called whenever the user's sign-in state changes.
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, set the UID
        setCurrentUserUid(user.uid);
      } else {
        // User is signed out, reset the UID
        setCurrentUserUid('');
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {

    const fetchData = async () => {
        
        if (currentUserUid && currentUserCreationDate) {
            const unapprovedPayments = await getUnapprovedPayments();
            console.log("unapprovedPayments: ", unapprovedPayments)
            if (unapprovedPayments) {
            await checkUnapprovedPayments(unapprovedPayments);
            setloading(false)
            } else {
                setloading(false)
            }
        };
        }
    
      fetchData();
}, [currentUserUid, currentUserCreationDate]);

  const getBitcoinPrice = async () => {
    try {
      const fetchBTCPriceInUSD = httpsCallable(functions, "fetchBTCPriceInUSD");
      const response = await fetchBTCPriceInUSD({});

      dispatch(setBitcoinPriceData(1.0/(response.data as number)));
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    getBitcoinPrice();
  }, []);


  class CreateNewAssetPurchase {
    Status: string;
    UserID: string;
    ID: string;
    PricePayedInUSD: number;
  
    constructor(
        Status: string,
        UserID: string,
        ID: string,
        PricePayedInUSD: number,
    ) {
      this.Status = Status;
      this.UserID = UserID;
      this.ID = ID;
      this.PricePayedInUSD = PricePayedInUSD;
    }
  }
  
  const getUnapprovedPayments = async () => {
    const querySnapshot = await getDocs(collection(db, "createNewAssetPurchases"));

    let unapprovedPayments: CreateNewAssetPurchase[] = []; // Define as an array of CreateNewAssetPurchase
  
    querySnapshot.docs.forEach((doc) => {
      console.log(doc.data())
      const { Status, UserID, PricePayedInUSD } = doc.data();
      if (UserID === currentUserUid && Status === "UNAPPROVED") {
        // Create a new instance of CreateNewAssetPurchase with the document ID
        const newPayment = new CreateNewAssetPurchase(Status, UserID, doc.id, PricePayedInUSD);
        unapprovedPayments.push(newPayment);
      }
    });
  
    // Now unapprovedPayments contains the instances of unapproved payments for the current user
    return unapprovedPayments;
  };

  const checkUnapprovedPayments = async (payments: CreateNewAssetPurchase[]): Promise<void> => {
    const getPayPalOrderData = httpsCallable(functions, "getPayPalOrderData");
  
    for (const payment of payments) {
      const orderID = payment.ID;
      const AmountInUSD = payment.PricePayedInUSD;
      if (payment.Status === "UNAPPROVED") {
        console.log('Checking unapproved payment')
        try {
           // Assuming orderID is stored in the ID property of payment
          const result = await getPayPalOrderData({ orderID });
  
          if (result.data) {
            const responseString = result.data as string;
            // Process the response string as needed

            console.log('got Pay pal response', responseString)
            if (responseString === 'APPROVED') {
                // The payment is being captured update the DB and UI

                updateOrderData(orderID, 'APPROVED')
                updateUserData()
                updateTransactionData(AmountInUSD)
                setnewPaymentApproved(true)
            }
          } else {
            console.log('error got Pay pal response', result)
          }
        } catch (error) {
          console.error("Error calling getPayPalOrderData cloud function", error);
          // Handle any errors that occur during the function call
        }
      } else if (payment.Status === "APPROVED") {
        // Process approved payments
        updateOrderData(orderID, 'COMPLETE')
      }
    }
  };


  const updateOrderData = async (orderId: string, status: string) => {
    // Save new order
    const paymentCollectionRef = doc(db, 'createNewAssetPurchases', orderId);
    const updatePayments = setDoc(paymentCollectionRef, {
      Status: status
    }, {merge: true}); 
    await Promise.all([updatePayments]);
}

const updateUserData = async () => {

  console.log("numCoinAlloted ", numCoinAlloted)

    let updatedNumCoinAlloted = numCoinAlloted! + 1;
    console.log("updatedNumCoinAlloted ", updatedNumCoinAlloted)

    const userDocRef = doc(db, 'users', currentUserUid!);
    const updateUserData = setDoc(userDocRef, {
      CryptosAlloted: updatedNumCoinAlloted
    }, { merge: true });

    await Promise.all([updateUserData]);

    dispatch(setNumCryptoAlloted(updatedNumCoinAlloted));
}


const updateTransactionData = async (amountInUSD: number) => {
  try {
  const collectionRef = collection(db, 'transactions');

  const updateDollarTransactionData = addDoc(collectionRef, {
    Type: 'createNewAssetPurchase',
    Receiver: 'FinFriend',
    Sender: currentUserUid,
    TransactionTime: new Date().toISOString(),
    ReceiverEmail: 'finfriend@finfriend.com',
    SenderEmail: currentUserEmail,
    AssetSent: 'USD',
    AmountSent: amountInUSD,
    PlatformUsed: 'WEB'
});

  await Promise.all([updateDollarTransactionData]);
  } catch {
      console.log('Error updating transactions')
  }
}

  
  const isSmallScreen = window.innerWidth <= 800;

  return (
      
    <div className={styles.App} style={{ backgroundColor: '#222222', overflowY: 'hidden' }}>

      {currentUserUid ? <div>


        <div className={styles['dark-gray-background']}>
        <Header/>
        </div>
      <div className={styles['darker-gray-background']}>
      (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          color: 'white',
          fontSize: '25px',
          marginTop: '40px'
        }}
      >

        
{loading ? (
        <div style={{}}>
        Waiting for PayPal
      </div>
) : (
<>
    {newPaymentApproved ? (
        <div style={{}}>
        Got it! Thank you for your purchase.
      </div>
    ) : (
        <div style={{textAlign: 'center'}}>
        We could not find a payment from you.
        <br/>
        Please try again.
      </div>
    )}
</>
)}



        {loading ? (
            <div style={{height: '250px', marginTop: '40px'}}>
            <RocketAnimation loading={true} type={'large'}/>
            </div>
        ) : (

            <div style={{
                marginTop: '40px'}}>
    
    
                <div style={{height: '250px', marginTop: '40px'}}>
                {currentUserCreationDate ? (
                  <CreateCryptoModals buttonType={'Begin'} buttonTextColor={'#222222'} buttonBackgroundColor={'#FFA14A'}/>
                ) : (
                  <CreateCryptoModals buttonType={'tryItFree'} buttonTextColor={'#222222'} buttonBackgroundColor={'#FFA14A'}/>
                )}
              </div>
            </div>
        )}



      </div>
    );
      </div>


      </div> : <div>

Please log in again 
        </div>}

    </div>
  );
  }

  export default RedirectFromPayPalCreateNewAsset;
