import React, { useState, useEffect, useRef } from 'react';
import styles from '../../App.module.scss';
import { cryptoForTradingData } from '../CurrentUserInfo';
import { useAppSelector } from '../../redux/hooks';
import { getUserBalances } from '../../Helpers/GetBalance';
import { getAuth } from "firebase/auth";
import { getFirestore, collection, updateDoc, addDoc, getDoc, setDoc, doc, getDocs, query, where, } from 'firebase/firestore';
import { getMessaging } from 'firebase/messaging';
import DepositBTCDialog from '../DepositBTCDialog';
import SignUpModal from '../Authentication/SignUpModal';
import { httpsCallable } from "firebase/functions";
import { auth, functions } from "../..";
import RocketAnimation from '../../components/RocketSpinAnimation';
import fireIcon from '../../assets/fire_icon.png'
import {formatDollarNumber} from '../../Helpers/FormatNumbers'; 
interface BuySellTradeTileProps {
  cryptoData: cryptoForTradingData | null;
}

function BuySellTradeTile({ cryptoData }: BuySellTradeTileProps) {

  const db = getFirestore();
  const auth = getAuth();
  const currentUserEmail = auth.currentUser?.email // Using this allows the balances to update immediatly after the user logs in
  const screenSize = window.innerWidth;
  const bitcoinPriceInDollars = useAppSelector((state) => state.bitcoinPriceData.value)
  const comingFromCreateCrypto = useAppSelector((state) => state.comingFromCreateToTrade.value)
  const currentUserUsername = useAppSelector((state) => state.userData.userData?.username)
  const currentUserCreationDate = useAppSelector((state) => state.userData.userData?.creationDate)
  const shouldNotifyBeforeNavigatingAway = useRef(false);
  const [showDepositBTCDialog, setshowDepositBTCDialog] = useState(false);
  const [askToShowDepositBTCDialog, setaskToShowDepositBTCDialog] = useState(false);
  const [modalKey, setModalKey] = useState(0); // Initial key
  const [showSignUpModal, setshowSignUpModal] = useState(false);
  const reloadModal = () => {
    setModalKey(modalKey => modalKey + 1); // Increment key to force remount
    setshowSignUpModal(true);
  };

  const [currentUserNativeBalances, setCurrentUserNativeBalances] = useState({ balanceAvailable: 0, balanceOrder: 0 });
  const [currentUserLiquidBalances, setCurrentUserLiquidBalances] = useState({ balanceAvailable: 0, balanceOrder: 0 });

  //need these for updating balances becuase usestate caused problems w logic
  let currentUserNativeBalancesLogic = { balanceAvailable: 0, balanceOrder: 0 }
  let currentUserLiquidBalancesLogic = { balanceAvailable: 0, balanceOrder: 0 }

  let secondUserNativeBalances = { balanceAvailable: 0, balanceOrder: 0 }
  let secondUserLiquidBalances = { balanceAvailable: 0, balanceOrder: 0 }

  const [orderAmount, setOrderAmount] = useState('');
  const [orderPrice, setOrderPrice] = useState('');
  const [orderTotal, setOrderTotal] = useState('');
  //const [unfilledNativeAmount, setUnfilledNativeAmount] = useState<number>(0);
  let unfilledNativeAmount = 0;
  const [processingOrder, setprocessingOrder] = useState(false);
  const [exchangeFeePercentage, setexchangeFeePercentage] = useState(0);
  const [exchangeFeePercentageLoading, setexchangeFeePercentageLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [selectedBuySellButton, setSelectedBuySellButton] = useState('buy');

  //Get exhange fee %
  useEffect(() => {
    if (currentUserCreationDate) {
      setexchangeFeePercentageLoading(true);
      const getexchangeFeePercentage = async () => {

        try {
          const fetchPriceToCreateNewAsset = httpsCallable(functions, "getExchangeFeePercentage");
          const response = await fetchPriceToCreateNewAsset({});
      
          setexchangeFeePercentage(response.data as number);
          setexchangeFeePercentageLoading(false);
    
        } catch (error) {
          console.error(error);
        }

      };
      getexchangeFeePercentage();
    }
  }, [currentUserEmail, currentUserCreationDate, cryptoData!.Ticker]);

  // Get the initial balances
  useEffect(() => {
    if (currentUserCreationDate) {
      const fetchBalances = async () => {
        try {
          const nativeBalances = await getUserBalances(currentUserEmail!, cryptoData!.Ticker); // Fetch native balances using cryptoData.Ticker
          setCurrentUserNativeBalances(nativeBalances);
  
          const liquidBalances = await getUserBalances(currentUserEmail!, 'BTC'); // Fetch liquid balances for BTC
          setCurrentUserLiquidBalances(liquidBalances);
        } catch (error) {
          console.log('Error fetching balances:', error);
        }
      };
  
      fetchBalances();
    }
  }, [currentUserEmail, currentUserCreationDate, cryptoData!.Ticker]);


  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (shouldNotifyBeforeNavigatingAway.current) {
        const message = 'Are you sure you want to leave? Changes you made may not be saved.';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup function to remove the event listener when the component unmounts or the value changes
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldNotifyBeforeNavigatingAway]);

  const amountAvailableText = () => {
    if (selectedBuySellButton === 'buy') {
      return `Available to buy: ${currentUserLiquidBalances.balanceAvailable.toFixed(8).toString().replace(/\.?0+$/, '')} BTC`; //to do: add a deposit button when 0
    } else {
      return `Available to sell: ${currentUserNativeBalances.balanceAvailable.toFixed(8).toString().replace(/\.?0+$/, '')} ${cryptoData?.Ticker}`;
    }
  };
  

  const handleBuyButtonClick = () => {
    setSelectedBuySellButton('buy');
  };

  const handleSellButtonClick = () => {
    setSelectedBuySellButton('sell');
  };

  const handleOrderAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setaskToShowDepositBTCDialog(false);
    const amount = parseFloat(event.target.value);
    setOrderAmount(event.target.value);
    if (!isNaN(amount) && !isNaN(parseFloat(orderPrice))) {
      setOrderTotal((amount * parseFloat(orderPrice)).toFixed(8).toString().replace(/\.?0+$/, ''));
    }
  };
  
  const handleOrderPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setaskToShowDepositBTCDialog(false);
    const price = parseFloat(event.target.value);
    setOrderPrice(event.target.value);
    if (!isNaN(parseFloat(orderAmount)) && !isNaN(price)) {
      setOrderTotal((parseFloat(orderAmount) * price).toFixed(8).toString().replace(/\.?0+$/, ''));
    }
  };

  const orderTotalText = () => {
    if (orderTotal && Number(orderPrice) && Number(orderAmount.replace(/,/g, ''))) {

      return `Order Total: $${formatDollarNumber(parseFloat(orderTotal) * bitcoinPriceInDollars).toString().replace(/(\.\d*?[1-9])0+$/, '$1').replace(/\.$/, '')} / ${parseFloat(orderTotal).toFixed(8).toString().replace(/\.?0+$/, '')} BTC`; //to do: add a deposit button when 0
    } else {
      return <div style={{ visibility: 'hidden' }}>$</div>;
    }
  };

  const errorLabelText = () => {
    if (errorMessage) {
      if (errorMessage === 'Please log in') {
        return (
          <label style={{ display: 'flex', justifyContent: 'center', 
          alignItems: 'center', margin: '0 auto', marginTop: '30px',
          color: '#FFFFFF' }}>{errorMessage}</label>
          );
      }
      if (errorMessage === 'Placing Order') {
        return (
        <label style={{ display: 'flex', justifyContent: 'center', 
        alignItems: 'center', margin: '0 auto', marginTop: '30px',
        color: '#4CD964' }}>{errorMessage}</label>
        );
      }
      return (
      <label className={styles['error-label']} style={{ display: 'flex', justifyContent: 'center', 
      alignItems: 'center', margin: '0 auto', marginTop: '30px', }}>{errorMessage}</label>
      );
    } else {
      return (<div style={{ visibility: 'hidden' }}>
              <label className={styles['error-label']} style={{ display: 'flex', justifyContent: 'center', 
      alignItems: 'center', margin: '0 auto', marginTop: '30px', }}>$</label>
      </div>);
    }
  };


 //Price Hotkeys ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  const handlePriceOneButtonClick = () => {
    setErrorMessage('');
    setaskToShowDepositBTCDialog(false);
    const newPrice = cryptoData!.Price;
    setOrderPrice(cryptoData!.Price.toFixed(8).toString().replace(/\.?0+$/, ''));
    const orderTotal = (parseFloat(orderAmount) * newPrice).toFixed(8).toString().replace(/\.?0+$/, '');
    setOrderTotal(orderTotal);
  };

  const handleAmountOneButtonClick = (amount1: string) => {
    setErrorMessage('');
    setaskToShowDepositBTCDialog(false);
    setaskToShowDepositBTCDialog(false);
    setOrderAmount(amount1)
    const orderTotal = (parseFloat(orderPrice) * parseFloat(amount1.replace(/,/g, ''))).toFixed(8).toString().replace(/\.?0+$/, '');
    setOrderTotal(orderTotal);
  }

  const handleAmountTwoButtonClick = (amount2: string) => {
    setErrorMessage('');
    setaskToShowDepositBTCDialog(false);
    setOrderAmount(amount2)

    const orderTotal = (parseFloat(orderPrice) * parseFloat(amount2.replace(/,/g, ''))).toFixed(8).toString().replace(/\.?0+$/, '');
    setOrderTotal(orderTotal);
  }

  const handleAmountThreeButtonClick = (amount3: string) => {
    setErrorMessage('');
    setaskToShowDepositBTCDialog(false);
    setOrderAmount(amount3)

    const orderTotal = (parseFloat(orderPrice) * parseFloat(amount3.replace(/,/g, ''))).toFixed(8).toString().replace(/\.?0+$/, '');
    setOrderTotal(orderTotal);
  }

  const handlePriceTwoButtonClick = () => {
    setErrorMessage('');
    setaskToShowDepositBTCDialog(false);
    if (selectedBuySellButton === 'buy') {
      const newPrice = (cryptoData!.Price - (cryptoData!.Price * 0.1));
      setOrderPrice(newPrice.toFixed(8).toString().replace(/\.?0+$/, ''));
    
      if (!isNaN(parseFloat(orderAmount))) {
        setOrderTotal((parseFloat(orderAmount) * newPrice).toFixed(8).toString().replace(/\.?0+$/, ''));
      }
    } else {
      const newPrice = (cryptoData!.Price + (cryptoData!.Price * 0.1));
      setOrderPrice(newPrice.toFixed(8).toString().replace(/\.?0+$/, ''));
    
      if (!isNaN(parseFloat(orderAmount))) {
        setOrderTotal((parseFloat(orderAmount) * newPrice).toFixed(8).toString().replace(/\.?0+$/, ''));
      }
    }
  };


  const handlePriceThreeButtonClick = () => {
    setErrorMessage('');
    setaskToShowDepositBTCDialog(false);
    if (selectedBuySellButton === 'buy') {
      const newPrice = (cryptoData!.Price - (cryptoData!.Price * 0.15));
      setOrderPrice(newPrice.toFixed(8).toString().replace(/\.?0+$/, ''));
    
      if (!isNaN(parseFloat(orderAmount))) {
        setOrderTotal((parseFloat(orderAmount) * newPrice).toFixed(8).toString().replace(/\.?0+$/, ''));
      }
    } else {
      const newPrice = (cryptoData!.Price + (cryptoData!.Price * 0.15));
      setOrderPrice(newPrice.toFixed(8).toString().replace(/\.?0+$/, ''));
    
      if (!isNaN(parseFloat(orderAmount))) {
        setOrderTotal((parseFloat(orderAmount) * newPrice).toFixed(8).toString().replace(/\.?0+$/, ''));
      }
    }
  };

  const handlePriceFourButtonClick = () => {
    setErrorMessage('');
    setaskToShowDepositBTCDialog(false);
    if (selectedBuySellButton === 'buy') {
      const newPrice = (cryptoData!.Price - (cryptoData!.Price * 0.2));
      setOrderPrice(newPrice.toFixed(8).toString().replace(/\.?0+$/, ''));
    
      if (!isNaN(parseFloat(orderAmount))) {
        setOrderTotal((parseFloat(orderAmount) * newPrice).toFixed(8).toString().replace(/\.?0+$/, ''));
      }
    } else {
      const newPrice = (cryptoData!.Price + (cryptoData!.Price * 0.2));
      setOrderPrice(newPrice.toFixed(8).toString().replace(/\.?0+$/, ''));
    
      if (!isNaN(parseFloat(orderAmount))) {
        setOrderTotal((parseFloat(orderAmount) * newPrice).toFixed(8).toString().replace(/\.?0+$/, ''));
      }
    }
  };


  const renderAmountButtons = () => {
    const totalSupply = cryptoData!.Supply

    let amount1 = "50"
    let amount2 = "100"
    let amount3 = "200"

    if (totalSupply >= 10000000) {
      amount1 = "100,000"
      amount2 = "200,000"
      amount3 = "500,000"
    } else if (totalSupply >= 1000000) {
      amount1 = "10,000"
      amount2 = "20,000"
      amount3 = "50,000"
    } else if (totalSupply >= 100000) {
      amount1 = "1,000"
      amount2 = "2,000"
      amount3 = "5,000"
    } else if (totalSupply >= 10000) {
      amount1 = "100"
      amount2 = "200"
      amount3 = "500"
    } else if (totalSupply >= 1000) {
      amount1 = "10"
      amount2 = "20"
      amount3 = "50"
    } else if (totalSupply >= 100) {
      amount1 = "1"
      amount2 = "2"
      amount3 = "5"
    }
      return (
        <div className={styles['buy-sell-price-button-row']}>
        <button onClick={() => handleAmountOneButtonClick(amount1)} style={{ width: '45px' }}>{amount1}</button>
        <button onClick={() => handleAmountTwoButtonClick(amount2)} style={{ width: '45px' }}>{amount2}</button>
        <button onClick={() => handleAmountThreeButtonClick(amount3)} style={{ width: '45px' }}>{amount3}</button>
      </div>
      );
  };

  const renderPriceButtons = () => {
    if (selectedBuySellButton === 'buy') {
      return (
        <div className={styles['buy-sell-price-button-row']}>
        <button onClick={handlePriceOneButtonClick} style={{ width: '45px' }}>Market</button>
        <button onClick={handlePriceTwoButtonClick} style={{ width: '45px' }}>-10%</button>
        <button onClick={handlePriceThreeButtonClick} style={{ width: '45px' }}>-15%</button>
        <button onClick={handlePriceFourButtonClick} style={{ width: '45px' }}>-20%</button>
      </div>
      );
    } else if (selectedBuySellButton === 'sell') {
      return (
        <div className={styles['buy-sell-price-button-row']}>
          <button onClick={handlePriceOneButtonClick} style={{ width: '45px' }}>Market</button>
          <button onClick={handlePriceTwoButtonClick} style={{ width: '45px' }}>+10%</button>
          <button onClick={handlePriceThreeButtonClick} style={{ width: '45px' }}>+15%</button>
          <button onClick={handlePriceFourButtonClick} style={{ width: '45px' }}>+20%</button>
        </div>
      );
    } else {
      return null;
    }
  };
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

const handleSetOrderButtonPressed = async () => {
  setaskToShowDepositBTCDialog(false);
  const cleanedOrderAmount = orderAmount.replace(/[, ]/g, '');
  const cleanedOrderPrice = orderPrice.replace(/[, ]/g, '');

  if (!currentUserEmail) {
    reloadModal();
    setshowSignUpModal(true);
  } else {
  if (cleanedOrderAmount === '' || cleanedOrderPrice === '') {
    setErrorMessage('Please fill in all fields');
  } else if (!/^\d*\.?\d*$/.test(cleanedOrderAmount) || !/^\d*\.?\d*$/.test(cleanedOrderPrice)) {
    setErrorMessage('Please only use numbers');
  } else if (/[$()d]/.test(cleanedOrderAmount) || /[$()d]/.test(cleanedOrderPrice)) {
    setErrorMessage('Please remove any special characters');
  } else if (parseFloat(cleanedOrderPrice) < 0.00000001 || parseFloat(cleanedOrderAmount) < 0.00000001) {
    setErrorMessage('Price or amount is too small');
  } else if (parseFloat(cleanedOrderAmount) * parseFloat(cleanedOrderPrice) * bitcoinPriceInDollars < 10) {
    setErrorMessage('Order total must be more than $10');
  } else if (!currentUserCreationDate) {
    setErrorMessage('Please complete your account set up');
  } else if (currentUserEmail) {
    setprocessingOrder(true);
    shouldNotifyBeforeNavigatingAway.current = true;
    setOrderAmount('');
    setOrderPrice('');
    setErrorMessage('Placing Order');
    // Continue with the desired logic
    const numOrderAmount = parseFloat(cleanedOrderAmount)
    const numOrderPrice = parseFloat(cleanedOrderPrice)
    // Database lookup
    try {
      // Get updated current user balances and validate them
      const nativeBalancesPromise = getUserBalances(currentUserEmail, cryptoData!.Ticker); // Fetch native balances using cryptoData.Ticker
      const liquidBalancesPromise = getUserBalances(currentUserEmail, 'BTC'); // Fetch liquid balances for BTC
      
      const [nativeBalances, liquidBalances] = await Promise.all([nativeBalancesPromise, liquidBalancesPromise]);
      
      setCurrentUserNativeBalances(nativeBalances);
      setCurrentUserLiquidBalances(liquidBalances);

      currentUserNativeBalancesLogic = nativeBalances;
      currentUserLiquidBalancesLogic = liquidBalances;

      console.log('Set order / Got current users liquid: ', currentUserLiquidBalancesLogic)
      console.log('Set order / Got current users native: ', currentUserNativeBalancesLogic)
      
      if (selectedBuySellButton === 'sell') {
        if (nativeBalances.balanceAvailable < numOrderAmount) {
          setErrorMessage('Insufficient Balance');
          setprocessingOrder(false);
          shouldNotifyBeforeNavigatingAway.current = false;
        } else {
          setErrorMessage('Placing Order');
          unfilledNativeAmount = numOrderAmount;
          // Place the sell order
          handleNewOrder(numOrderPrice, numOrderAmount, true);
        }
      }
      
      if (selectedBuySellButton === 'buy') {
        if (liquidBalances.balanceAvailable < (numOrderPrice*numOrderAmount)) {
          setErrorMessage('Insufficient Balance');
          shouldNotifyBeforeNavigatingAway.current = false;
          setaskToShowDepositBTCDialog(true);
          setprocessingOrder(false);
          
        } else {
          setErrorMessage('Placing Order');
          unfilledNativeAmount = numOrderAmount;
          // Place the buy order
          handleNewOrder(numOrderPrice, numOrderAmount, false);
        }
      }

    } catch (error) {
      console.error('Error occurred during balance lookup:', error);
      setErrorMessage('We are having connection issues please try again later');
    }
  }
}
};

const handleNewOrder = async (orderPrice: number, orderAmount: number, isSellOrder: boolean) => {
  
// Clicking set order to quickly results in balance not being updated
// Make a loading animation that also blocks the button

  //save order
  try {
    const currentDate = new Date();
    const newOrder = {
      Ticker: cryptoData?.Ticker,
      Originator: currentUserEmail,
      Unfilled: orderAmount,
      Filled: 0,
      Price: orderPrice,
      OriginationDate: new Date().toISOString(),
      SellOrder: isSellOrder
    };

    const orderCollectionRef = collection(db, 'orders');
    
    const newOrderDocRef = addDoc(orderCollectionRef, newOrder);

    //update balances
    const currentUserEmailORDER: string = `${String(getAuth().currentUser?.email)}ORDER`;
    
    const emailString: string = currentUserEmail!

    if (selectedBuySellButton === 'buy') {

      //update liquid balance
      //logic
      currentUserLiquidBalancesLogic = { balanceAvailable: (currentUserLiquidBalancesLogic.balanceAvailable - (orderPrice * orderAmount)),
        balanceOrder: (currentUserLiquidBalancesLogic.balanceOrder + (orderPrice * orderAmount)) }
      //elemennts
      setCurrentUserLiquidBalances({ balanceAvailable: (currentUserLiquidBalancesLogic.balanceAvailable - (orderPrice * orderAmount)),
        balanceOrder: (currentUserLiquidBalancesLogic.balanceOrder + (orderPrice * orderAmount)) })

        console.log('Handle new order/ Updating liquid balances to ', currentUserLiquidBalancesLogic);

      const cryptoDocRef = doc(db, 'cryptos', 'BTC');
      const updateBalances = setDoc(cryptoDocRef, {
        [currentUserEmail!]: Number((currentUserLiquidBalancesLogic.balanceAvailable).toFixed(8)),
        [currentUserEmailORDER]: Number((currentUserLiquidBalancesLogic.balanceOrder).toFixed(8))
      }, { merge: true });
      
        await Promise.all([updateBalances, newOrderDocRef]);
        const newOrderId = (await newOrderDocRef).id;

        //initial call to try fill order
        tryFillOrder(orderPrice, 0, orderAmount, isSellOrder, newOrderId, 0);

     } else if (selectedBuySellButton === 'sell') {

      //update native balance
      //logic
      currentUserNativeBalancesLogic = { balanceAvailable: (currentUserNativeBalancesLogic.balanceAvailable - orderAmount),
        balanceOrder: (currentUserNativeBalancesLogic.balanceOrder + orderAmount) }
      //elements
      setCurrentUserNativeBalances({ balanceAvailable: (currentUserNativeBalancesLogic.balanceAvailable - orderAmount),
        balanceOrder: (currentUserNativeBalancesLogic.balanceOrder + orderAmount) })

      console.log('Handle new order/ Updating native balances to ', currentUserNativeBalancesLogic);

      const cryptoDocRef = doc(db, 'cryptos', cryptoData!.Ticker);
      const updateBalances = setDoc(cryptoDocRef, {
      [emailString]: Number((currentUserNativeBalancesLogic.balanceAvailable).toFixed(8)),
      [currentUserEmailORDER]: Number((currentUserNativeBalancesLogic.balanceOrder).toFixed(8))
    }, { merge: true });
    
      await Promise.all([updateBalances, newOrderDocRef]);
      const newOrderId = (await newOrderDocRef).id;

      //initial call to try fill order
      tryFillOrder(orderPrice, 0, orderAmount, isSellOrder, newOrderId, 0);

    }

    //check and save price
    if (orderPrice !== Number(cryptoData!.Price)) {
      console.log('orderPrice: ', orderPrice, ' != cryptoData!.Price: ', cryptoData!.Price);
      console.log('updating price');
      handlePriceChange(orderPrice);

    }
    setErrorMessage('');
    setprocessingOrder(false);
    setTimeout(() => {
      shouldNotifyBeforeNavigatingAway.current = false;
      window.location.reload(); // Reload the page
    }, 2200);
  } catch (error) {
    console.error('Update error:', error);
    setErrorMessage('We are having connection issues please try again later');
  }

};

const tryFillOrder = async (orderPrice: number, orderFilled: number, orderUnfilled: number, 
  isSellOrder: boolean, orderID: string, orderFee: number) => {
  // Check for orders where 'Ticker' field === cryptoData!.Ticker and 'Unfilled' field > 0 and 'SellOrder' field != isSellOrder
  // Get them back as an array of orders
  interface Order {
    Filled: number
    Unfilled: number
    OriginationDate: string
    Originator: string
    Price: number
    SellOrder: boolean
    Ticker: string
    Fee: number
  }
  try {
    const ordersRef = collection(db, 'orders');
    const querySnapshot = await getDocs(ordersRef);

    outerLoop: for (const doc of querySnapshot.docs) {
      const data = doc.data();
      if (
        data.Ticker === cryptoData!.Ticker &&
        data.Unfilled > 0.00000001 &&
        data.SellOrder !== isSellOrder
      ) {

        // Check to see which order amount is bigger
        let volumeNative = 0;
        if (orderUnfilled >= data.Unfilled) {
          volumeNative = data.Unfilled
        } else {
          volumeNative = orderUnfilled
        };

        const feeData = data.Fee || 0;

        if (selectedBuySellButton === 'buy') {

          // Current user is buying 
          // Check to see if the prices match
          if (orderPrice >= data.Price) {

            handleDealReached(volumeNative, orderPrice, orderFilled, orderUnfilled, orderID, currentUserEmail!, 
              orderFee, data.Price, data.Filled, data.Unfilled, doc.id, data.Originator, feeData
              );
            break outerLoop;

          }

        } else if (selectedBuySellButton === 'sell') {

          // Current user is selling
          // Check to see if the prices match
          if (data.Price >= orderPrice) {

            handleDealReached(volumeNative, data.Price, data.Filled, data.Unfilled, doc.id, data.Originator, 
              feeData, orderPrice, orderFilled, orderUnfilled, orderID, currentUserEmail!, orderFee);
            break outerLoop;
          }
        }
      }
    };

    
  } catch (error) {
    console.error('TryFillOrderError: ', error);
    setErrorMessage('We were able to place the order but could not try to fill it due to connection issues');
  }
};

const handleDealReached = async (volumeNative: number, buyPrice: number, buyFilled: number, buyUnfilled: number,
    buyOrderID: string, buyerEmail: string, buyFee: number, sellPrice: number, sellFilled: number, sellUnfilled: number, 
   sellOrderID: string, sellerEmail: string, sellFee: number) => {

  try {

    // updateOrders
    const buyOrderDocRef = doc(db, 'orders', buyOrderID);
    const updateBuyOrder = setDoc(buyOrderDocRef, {
      Filled: Number((buyFilled+volumeNative).toFixed(8)),
      Unfilled: Number((buyUnfilled-volumeNative).toFixed(8)),
      Fee: Number((buyFee + ((volumeNative*buyPrice)*(exchangeFeePercentage/100))).toFixed(8)),
      FeePaidIn: "BTC"
    }, { merge: true });

    const sellOrderDocRef = doc(db, 'orders', sellOrderID);
    const updateSellOrder = setDoc(sellOrderDocRef, {
      Filled: Number((sellFilled+volumeNative).toFixed(8)),
      Unfilled: Number((sellUnfilled-volumeNative).toFixed(8)),
      Fee: Number((sellFee + (volumeNative * (exchangeFeePercentage / 100))).toFixed(8)),
      FeePaidIn: cryptoData!.Ticker
    }, { merge: true });

    /*
     "Fee" to (roundTo8Decimals(buyFee + ((volumeNative * buyPrice) * (exchangeFeePercentage / 100))).toDouble()),
      "FeePaidIn" to "BTC"
    */
      
    //updateBalances

    
    if (selectedBuySellButton==='buy') {

      // get second user balances
      const sellerUserEmailORDER: string = `${sellerEmail}ORDER`;
      const currentUserEmailORDER: string = `${currentUserEmail}ORDER`;

      const nativeBalancesPromise = getUserBalances(sellerEmail, cryptoData!.Ticker); // Fetch native balances using cryptoData.Ticker
      const liquidBalancesPromise = getUserBalances(sellerEmail, 'BTC'); // Fetch liquid balances for BTC
        
      const [nativeBalances, liquidBalances] = await Promise.all([nativeBalancesPromise, liquidBalancesPromise]);
        
      console.log('Deal reached / got second user, ', sellerEmail, ' Native Balances: ', nativeBalances);
      console.log('Deal reached / got second user, ', sellerEmail, ' Liquid balances: ', liquidBalances);

      secondUserNativeBalances=nativeBalances;
      secondUserLiquidBalances=liquidBalances;



      //update current user balances
      //elements
      setCurrentUserLiquidBalances({ balanceAvailable: currentUserLiquidBalancesLogic.balanceAvailable, 
        balanceOrder: (currentUserLiquidBalancesLogic.balanceOrder-(volumeNative*buyPrice)) });
      setCurrentUserNativeBalances({ balanceAvailable: currentUserNativeBalancesLogic.balanceAvailable+volumeNative-(volumeNative*(exchangeFeePercentage/100)),
         balanceOrder: currentUserNativeBalancesLogic.balanceOrder});
      //logic
      currentUserNativeBalancesLogic.balanceAvailable = (currentUserNativeBalancesLogic.balanceAvailable+volumeNative-(volumeNative*(exchangeFeePercentage/100)));
      currentUserLiquidBalancesLogic.balanceOrder = (currentUserLiquidBalancesLogic.balanceOrder-(volumeNative*buyPrice));

      //update second user balances in doc ref


      const liquidCryptoDocRef = doc(db, 'cryptos', 'BTC');
      const updateLiquidBalances = setDoc(liquidCryptoDocRef, {
        [sellerEmail]: Number(((volumeNative*sellPrice)+secondUserLiquidBalances.balanceAvailable-(volumeNative*sellPrice*(exchangeFeePercentage/100))).toFixed(8)),
        [currentUserEmailORDER]: Number((currentUserLiquidBalancesLogic.balanceOrder).toFixed(8))
      }, { merge: true });

      const nativeCryptoDocRef = doc(db, 'cryptos', cryptoData!.Ticker);
      const updateNativeBalances = setDoc(nativeCryptoDocRef, {
        [currentUserEmail!]: Number((currentUserNativeBalancesLogic.balanceAvailable).toFixed(8)),
        [sellerUserEmailORDER]: Number((secondUserNativeBalances.balanceOrder-volumeNative).toFixed(8))
      }, { merge: true });
      
      await Promise.all([updateBuyOrder, updateSellOrder, updateNativeBalances, updateLiquidBalances]);

      const newUnfilledNativeAmount = unfilledNativeAmount - volumeNative;
      unfilledNativeAmount = newUnfilledNativeAmount;
      if (unfilledNativeAmount > 0.0000001) {
        tryFillOrder(buyPrice, buyFilled+volumeNative, buyUnfilled-volumeNative, 
          false, buyOrderID, (buyFee + ((volumeNative * buyPrice) * (exchangeFeePercentage / 100))));
        console.log('Deal reached / Loop through tryFillOrder again');
      } else {
        console.log('Deal reached / Filled do not call try fill order again');
      }

    } else if (selectedBuySellButton==='sell') {


      // get second user balances
      const buyerEmailORDER: string = `${buyerEmail}ORDER`;
      const currentUserEmailORDER: string = `${currentUserEmail}ORDER`;

      const nativeBalancesPromise = getUserBalances(sellerEmail, cryptoData!.Ticker); // Fetch native balances using cryptoData.Ticker
      const liquidBalancesPromise = getUserBalances(sellerEmail, 'BTC'); // Fetch liquid balances for BTC
        
      const [nativeBalances, liquidBalances] = await Promise.all([nativeBalancesPromise, liquidBalancesPromise]);
        
      console.log('Deal reached / got second user, ', sellerEmail, ' Native Balances: ', nativeBalances);
      console.log('Deal reached / got second user, ', sellerEmail, ' Liquid balances: ', liquidBalances);

      secondUserNativeBalances=nativeBalances;
      secondUserLiquidBalances=liquidBalances;

      //update current user balances
      //elements



      setCurrentUserLiquidBalances({ balanceAvailable: currentUserLiquidBalancesLogic.balanceAvailable+(volumeNative*buyPrice)-(volumeNative*sellPrice*(exchangeFeePercentage/100)), 
        balanceOrder: (currentUserLiquidBalancesLogic.balanceOrder) });
      setCurrentUserNativeBalances({ balanceAvailable: currentUserNativeBalancesLogic.balanceAvailable,
         balanceOrder: currentUserNativeBalancesLogic.balanceOrder-volumeNative});
      //logic
      currentUserNativeBalancesLogic.balanceOrder = (currentUserNativeBalancesLogic.balanceOrder-volumeNative);
      currentUserLiquidBalancesLogic.balanceAvailable = (currentUserLiquidBalancesLogic.balanceAvailable+(volumeNative*buyPrice)-(volumeNative*sellPrice*(exchangeFeePercentage/100)));

      //update second user balances in doc ref
        
      const liquidCryptoDocRef = doc(db, 'cryptos', 'BTC');
      const updateLiquidBalances = setDoc(liquidCryptoDocRef, {
        [buyerEmailORDER]: Number((secondUserLiquidBalances.balanceOrder-(volumeNative*sellPrice)).toFixed(8)),
        [currentUserEmail!]: Number((currentUserLiquidBalancesLogic.balanceAvailable).toFixed(8))
      }, { merge: true });
      
      const nativeCryptoDocRef = doc(db, 'cryptos', cryptoData!.Ticker);
      const updateNativeBalances = setDoc(nativeCryptoDocRef, {
        [currentUserEmailORDER]: Number((currentUserNativeBalancesLogic.balanceOrder).toFixed(8)),
        [buyerEmail]: Number((secondUserNativeBalances.balanceAvailable+volumeNative-(volumeNative*exchangeFeePercentage/100)).toFixed(8))
      }, { merge: true });
      
      await Promise.all([updateBuyOrder, updateSellOrder, updateNativeBalances, updateLiquidBalances]);

      const newUnfilledNativeAmount = unfilledNativeAmount - volumeNative;
      unfilledNativeAmount = newUnfilledNativeAmount;

      if (unfilledNativeAmount > 0.0000001) {
        //infinte loop 
        tryFillOrder(sellPrice, sellFilled+volumeNative, sellUnfilled-volumeNative, 
          true, sellOrderID, (sellFee + (volumeNative * (exchangeFeePercentage / 100))));
        console.log('Deal reached / Loop through tryFillOrder again');
      } else {
        //filled
        console.log('Deal reached / Filled do not call try fill order again');
      }
  }
      
    // send notification

  } catch (error) {
    console.error('TryFillOrderError: ', error);
    setErrorMessage('We were able to place the order but could not fill all of it due to connection issues');
  }
};

const handlePriceChange = async (newPrice: number) => {

  const currentDate = (Date.now() / 1000).toString();

  // Reference to 'priceHistories/Ticker' document
  const priceHistoriesDocRef = doc(db, 'priceHistories', cryptoData!.Ticker);
  
  const docSnapshot = await getDoc(priceHistoriesDocRef);

  let priceChanges: Record<string, number> = {};
  
  const data = docSnapshot.data();
  
  // Check if PriceChanges exists in the document data, otherwise initialize it
  if (data && data.PriceChanges) {
    priceChanges = data.PriceChanges;
  }

  // Add the current price to the map
  priceChanges[currentDate] = Number(newPrice.toFixed(8));

  // Update the nested map field without overwriting other data
  const updatePriceHistory = updateDoc(priceHistoriesDocRef, {
    'PriceChanges': priceChanges
  });

  const cryptoDocRef = doc(db, 'cryptos', cryptoData!.Ticker);
  const updatePrice = setDoc(cryptoDocRef, {
    Price: Number((newPrice).toFixed(8)),
  }, { merge: true });

  await Promise.all([updatePriceHistory, updatePrice]);

};


const handleNotification = async (receiverEmail: string, amount: string) => {

  const titleText = cryptoData!.Name + ' order hit!';
  const messageText = "Your " + cryptoData!.Name + " order was filled by " + currentUserUsername;

  const messaging = getMessaging();
  const usersCollectionRef = collection(db, 'users');

  // Query for the user document with matching email
  const querySnapshot = await getDocs(query(usersCollectionRef, where('Email', '==', receiverEmail)));

  if (!querySnapshot.empty) {
    const userDoc = querySnapshot.docs[0];
    const userId = userDoc.id;
    const fcmToken = userDoc.data().fcmToken;

  /*
    // Send the notification
    const notification = {
      token: fcmToken,
      notification: {
        title: titleText,
        body: messageText,
      },
      data: {
        amount: amount,
      },
    };

    try {
      const response = await send(messaging, notification);
      console.log('Notification sent successfully:', response);
    } catch (error) {
      console.error('Error sending notification:', error);
    }

    }

    */
    const currentDate = Date.now() / 1000;


  
  };
}



function formatNumber(number: number) {
  // Convert to string to check decimal places
  const numberString = number.toString();
  
  // Check if there is a decimal point and at least one digit after it
  if (numberString.includes('.') && numberString.split('.')[1].length >= 1) {
    return numberString; // Return the original number if it has more than one decimal place
  } else {
    return number.toFixed(1); // Otherwise, format to one decimal place
  }
}


  return (
    <div>

{showSignUpModal && <SignUpModal key={modalKey} buttonType='auto'/>}

{showDepositBTCDialog && 
<DepositBTCDialog/>}


      <div className={styles['trade-buy-sell-button-row']}>
        <button className={selectedBuySellButton === 'buy' ? styles['trade-buy-sell-button-selected-button'] : ''}
            onClick={handleBuyButtonClick}>Buy</button>
        <button className={selectedBuySellButton === 'sell' ? styles['trade-buy-sell-button-selected-button'] : ''}
            onClick={handleSellButtonClick}>Sell</button>
        </div>


        {processingOrder ? (
                  <div style={{ backgroundColor: '#222222', marginTop: '100px', maxHeight: '200px' }}>
                  <RocketAnimation loading={true} type={'small'}/>
                  </div>
        ) : (
          <div>
          <h4 style={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>
              {amountAvailableText()}
          </h4>
          <br></br>
          <div>
  
          <div style={{ color: 'white', textAlign: 'center' }}>
              {orderTotalText()}
          </div>
          <br></br>
  
          <div className={styles['buy-sell-textfield']}>
              <label htmlFor="order-amount">Amount in {cryptoData!.Ticker}</label>
              <input id="order-amount" type="text" value={orderAmount} onChange={handleOrderAmountChange} autoComplete="off"/>
           </div>
           {renderAmountButtons()}
  
            <div style={{marginTop: '20px'}}>
            <div className={styles['buy-sell-textfield']}>
                <label htmlFor="order-price">Price in Bitcoin</label>
                <input id="order-price" type="text" value={orderPrice} onChange={handleOrderPriceChange} autoComplete="off"/>
            </div>
            </div>
            {renderPriceButtons()}
  
            {(exchangeFeePercentage != 0) && (
              <div style={{display:'flex', alignItems:'center', 
              justifyContent: 'center', fontSize: '15px', marginTop:'10px'}}>
<img src={fireIcon} style={{width:'22px', height:'auto'}}></img>

<div style={{ color: 'white', marginLeft:'5px'}}>
  Fee:
</div>
<div style={{ textDecoration: 'line-through', color: '#AAAAAA',
marginLeft:'5px' }}>

  {((exchangeFeePercentage*1) === 1 ? (<>{formatNumber((exchangeFeePercentage*2))}</>) : (<>{formatNumber(exchangeFeePercentage*2)}</>))}
  
</div>
<div style={{ color: 'white', marginLeft:'5px'}}>
{(exchangeFeePercentage === 1 ? (<>{formatNumber(exchangeFeePercentage)}%</>) : (<>{formatNumber(exchangeFeePercentage)}%</>))}
</div>
              </div>
            )}

              
            
            {askToShowDepositBTCDialog ? (
                <div onClick={() => {
                  setshowDepositBTCDialog(true);
                  setaskToShowDepositBTCDialog(false);
                }} // Added onClick here
                style={{ display: 'flex', justifyContent: 'center', 
                        alignItems: 'center', margin: '20px auto', marginTop: '50px',
                        cursor: 'pointer', }}>
            <div style={{color: '#222222', backgroundColor: '#FFA14A', borderRadius: '5px', 
            padding: '10px', fontSize: '17px', fontWeight: 'bold'}}>Deposit Bitcoin Now</div>
            </div>) : (<>{errorLabelText()}</>)}

            {!askToShowDepositBTCDialog && (
<div>

            <div style={{marginTop: '20px'}}></div>
            <button className={styles['buy-sell-set-order-button']} onClick={handleSetOrderButtonPressed}>
              {selectedBuySellButton === 'buy' ? `Buy ${cryptoData!.Name}` : `Sell ${cryptoData!.Name}`}
            </button>

            </div>
            )}
                              
        </div>
      </div>
        )}




  </div>

  )
}

export default BuySellTradeTile;