import HeaderLeft from './headerLeft';
import HeaderNav from './Authentication/HeaderNav';
import styles from '../App.module.scss';
import React, { useState, useEffect } from 'react';

interface HeaderProps {
    isHomePage?: boolean;
    isTradePage?: boolean;
  }
  
const Header: React.FC<HeaderProps> = ({ isHomePage = false, isTradePage = false }) => {

  const [topPosition, setTopPosition] = useState(-120); // Initial position -50px from the top

  useEffect(() => {
    const handleScroll = () => {
      const threshold = 450;
      let newTopPosition = -120; // Start at -50px

      if (window.scrollY > threshold) {
        // Adjust the position to bring the component into view
        newTopPosition = 0; // Adjust this value as needed
      }

      setTopPosition(newTopPosition);
    };

    // Add the event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  if (isHomePage) {

return (
    <div>
<div className={styles['header']}>
          <div className={styles['header-container']}>
          <HeaderLeft isHomePage={isHomePage}/>
            <div className={styles['header-right']}>

              { isHomePage ? <>
                {<HeaderNav isHomePage={isHomePage} />}</> : <>
              {<HeaderNav isHomePage={isHomePage}/>}</>}
            
            </div>
          </div>
        </div>

  <div style={{ 
        position: 'fixed', 
        top: `${topPosition}px`,
        width: '100%',
        transition: 'top 0.4s',
        zIndex: 1000,
        backgroundColor: '#1a1a1a' // Ensure the header is above other content
      }}>
    <div className={styles['header-container']}>
      <HeaderLeft/>
      <div className={styles['header-right']}>
        <HeaderNav isHomePage={false} />
      </div>
    </div>
  </div>
</div>

)
} else {
  return (
  
    <div>


<div>
  <div style={{ 
        position: 'fixed', 
        top: 0, 
        width: '100%',
        zIndex: 900,
        backgroundColor: '#1a1a1a',  // Ensure the header is above other content
      }}>
    <div className={styles['header-container']}>
      <HeaderLeft/>
      <div className={styles['header-right']}>
        <HeaderNav isHomePage={false} />
      </div>
    </div>
  </div>
</div>
    
        </div>
          )
}
} 

export default Header;