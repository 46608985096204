import React, { useState, useEffect, useContext } from 'react';
import styles from '../App.module.scss';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import closeButton from '../../assets/closeButton.png';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setBitcoinPriceData } from '../redux/ephemeralBitcoinPrice';
import HeaderLeft from '../components/headerLeft';
import { useNavigate } from 'react-router-dom';
import SmallScreenLanding from '../components/SmallScreenLanding';
import { doc, getDocs, collection, updateDoc } from 'firebase/firestore';
import { functions, db } from "../";
import HeaderNav from '../components/Authentication/HeaderNav';
import bellicon from '../assets/bell_icon.png'

function Notifications() {

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [notificationData, setNotificationData] = useState<Notification[]>([]);

  const [currentUserEmail, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordResetSent, setPasswordResetSent] = useState(false);

  const [currentUserUid, setCurrentUserUid] = useState('');
  const auth = getAuth();

  useEffect(() => {
    // This listener is called whenever the user's sign-in state changes.
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, set the UID
        setCurrentUserUid(user.uid);
      } else {
        // User is signed out, reset the UID
        setCurrentUserUid('');
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  class Notification {
    Message: string;
    Read: boolean;
    notificationDate: string;
    userID: string;
  
    constructor(
        Message: string,
        Read: boolean,
        notificationDate: string,
        userID: string,
    ) {
      this.Message = Message;
      this.Read= Read;
      this.notificationDate = notificationDate;
      this.userID = userID
    }
  }
      
  useEffect(() => {
    const getNotificationData = async () => {
      const querySnapshot = await getDocs(collection(db, "notifications"));
      const data = await Promise.all(querySnapshot.docs.map(async (doc) => {
        const { Message, Read, notificationDate, userID } = doc.data();

        //console.log(`Crypto name: ${Name}, icon URL: ${iconUrl}`);

        return new Notification(
            Message,
            Read,
            notificationDate,
            userID, 
        );
      }).filter((notification) => notification !== null)); // remove null values


    const filteredData = data.filter(notification => notification.userID===currentUserUid);
    setNotificationData(filteredData.filter(order => order !== null) as Notification[]);

      
    };
  
    
    getNotificationData();
}, [currentUserUid, auth]);

  
  const isSmallScreen = window.innerWidth <= 800;


  if (isSmallScreen) {
    return (
    <div>
        
    {<SmallScreenLanding/>}

  </div>
    )
  };

  return (
      
    <div className={styles.App} style={{ backgroundColor: '#222222' }}>
      <div className={styles['dark-gray-background']}>
        <div className={styles['header']}>
          <div className={styles['header-container']}>
          <HeaderLeft/>

          <div className={styles['header-right']}>
              {<HeaderNav />}
              </div>

          </div>
        </div>
        </div>
      <div className={styles['darker-gray-background']}>
      (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          color: 'white',
        }}
      >
        
        <div>


          {(notificationData && currentUserUid && notificationData.length > 0) ? (
                <div>

<div style={{fontSize: '35px', marginTop: '50px', paddingBottom: '50px', textAlign: 'center'}}>
          Notifications
          </div>

            {notificationData.map((notification, index) => (

            <div style={{display:'flex', flexDirection:'column', marginBottom: '30px'}}>
            <div style={{fontSize: '30px', color:'white'}}>{notification.Message}</div>
            <div style={{fontSize: '20px', color:'white'}}>{notification.notificationDate}</div>
            </div>
            ))}
                 </div>
             ) : <div style={{display:'flex', flexDirection:'column', alignItems: 'center'}}>

<div style={{fontSize: '35px', marginTop: '-150px', paddingBottom: '50px', textAlign: 'center'}}>
          No Notifications Yet
          </div>

                 <img src={bellicon} style={{height:'150px', width:'150px', opacity:'0.5'}}></img>
                 </div>}


            <div>
            </div>


        </div>
                </div>

      </div>
    );
      </div>
  );
  }

  export default Notifications;