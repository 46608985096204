import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { formatDollarNumber } from '../Helpers/FormatNumbers';
import GavIcon from '../assets/CryptoIcons/G.png';
import DCIcon from '../assets/CryptoIcons/DC.png';
import BTCicon from '../assets/CryptoIcons/BTC.png';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { db } from "..";
import { collection, getDocs } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, FirebaseStorage } from 'firebase/storage';
import { Link } from 'react-router-dom';
import { reloadNewCryptoTestLaunch } from '../redux/ephemeralCrypto';

const HomePageDynamic: React.FC = () => {

    const navigate = useNavigate();
    const storage: FirebaseStorage = getStorage();

    const [activitySelected, setactivitySelected] = useState(false);
    const [newListingSelected, setnewListingSelected] = useState(true);
    const bitcoinPriceInDollars = useAppSelector((state) => state.bitcoinPriceData.value)
    const dispatch = useAppDispatch();
    const [newCryptoData, setNewCryptoData] = useState<Crypto[]>([]);

    const handleactivityClicked= () => {
        setactivitySelected(true);
        setnewListingSelected(false);
      };
      
      const handleNewListingClicked= () => {
        setactivitySelected(false);
        setnewListingSelected(true);
      };

      function reloadOfflineCryptoData() {
        dispatch(reloadNewCryptoTestLaunch());
      }


      class Crypto {
        name: string;
        ticker: string;
        price: number;
        InitialPrice: number;
        priceInDollars: number;
        iconUrl: string;
        originatorUsername: string;
        valuation: number;
        supply: number;
        isUsingCustomIcon: boolean;
        isTestCrypto: boolean;
        recentlyViewedBy: string[]; // Array of usernames or IDs
        likedBy: string[]; // Array of usernames or IDs
        OriginationDate: string
    
        constructor(
          name: string,
          ticker: string,
          price: number,
          InitialPrice: number,
          priceInDollars: number,
          iconUrl: string,
          originatorUsername: string,
          valuation: number,
          supply: number,
          isUsingCustomIcon: boolean,
          isTestCrypto: boolean,
          recentlyViewedBy: string[] = [], // Initialize with empty array if not provided
          likedBy: string[] = [], // Initialize with empty array if not provided
          OriginationDate: string
        ) {
          this.ticker= ticker;
          this.isUsingCustomIcon = isUsingCustomIcon;
          this.name = name;
          this.price = price;
          this.InitialPrice = InitialPrice;
          this.priceInDollars = priceInDollars;
          this.supply = supply;
          this.iconUrl = iconUrl;
          this.originatorUsername = originatorUsername;
          this.isTestCrypto = isTestCrypto;
          this.valuation = valuation;
          this.recentlyViewedBy = recentlyViewedBy;
          this.likedBy = likedBy;
          this.OriginationDate = OriginationDate;
        }
      }
    
      useEffect(() => {
        const getCryptoData = async () => {
          const querySnapshot = await getDocs(collection(db, "cryptos"));
          const data = await Promise.all(querySnapshot.docs.map(async (doc) => {
            const { Ticker, isUsingCustomIcon, Name, Price, InitialPrice, Supply, OriginatorUsername, 
              IsTestCrypto, RecentlyViewedBy, LikedBy, OriginationDate } = doc.data();
    
            let iconUrl = `../assets/CryptoIcons/${Name.charAt(0).toUpperCase()}.png`;
            if (isUsingCustomIcon) {
              const storageRef = ref(storage, `cryptoIcons/${Ticker}.png`);
              try {
                const url = await getDownloadURL(storageRef);
                iconUrl = url.toString();
              } catch (error) {
                console.log(`Error getting custom icon for ${Ticker}:`, error);
                iconUrl = `../assets/CryptoIcons/X.png`;
              }
            } else {
              iconUrl = `../assets/CryptoIcons/${Name.charAt(0).toUpperCase()}.png`;
            }
    
            console.log(`Crypto name: ${Name}, icon URL: ${iconUrl}`);
    
            return new Crypto(
              Name,
              Ticker,
              Price, 
              InitialPrice,
              Price*bitcoinPriceInDollars,
              iconUrl,
              OriginatorUsername,
              (Price * Supply),
              Supply,
              isUsingCustomIcon,
              IsTestCrypto,
              RecentlyViewedBy, 
              LikedBy,
              OriginationDate
    
            );
          }).filter((crypto) => crypto !== null)); // remove null values
      
          //TO DO: if current user is test user filter out test cryptos
    
          const filteredData = data
          .filter(crypto => crypto && crypto.ticker !== 'BTC' && !crypto.isTestCrypto)  // Assuming filtering out test cryptos
          .sort((a, b) => new Date(b.OriginationDate).getTime() - new Date(a.OriginationDate).getTime())
          .slice(0, 4);  // Get only the top 4 newest cryptos
        
        setNewCryptoData(filteredData);
    
        };
      
        getCryptoData();
      }, [bitcoinPriceInDollars]);


      const iconImage = (name: string, isUsingCustomIcon: boolean, customIconLink: string) => {
        const firstChar = name.charAt(0);
        console.log('Testing first char')
        if (/^[^A-Za-z]+$/.test(firstChar)) {
          console.log('If branch: load from first char')
          return (
            <div>
              <img
                  style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%'
                  }}
                src={
                  isUsingCustomIcon
                    ? customIconLink
                    : require(`../assets/CryptoIcons/X.png`)
                }
                alt={` `}
              />
            </div>
          );
        } else {
          console.log('Else branch: load from X.png')
            return (
              <img
              style={{
                width: '30px',
                height: '30px',
                borderRadius: '50%'
              }}
                src={
                  isUsingCustomIcon
                    ? customIconLink
                    : require(`../assets/CryptoIcons/${(name).charAt(0).toUpperCase()}.png`)
                }
                alt={` `}
              />
          );
        }
      };

      return (
        <div style={{ backgroundColor: '#1a1a1a', paddingTop: '5px' }}>

            
<div style={{ display: 'flex', justifyContent: 'space-between', 
            padding: '0 8vw', color: 'white' }}>


          <div style={{ backgroundColor: '#1a1a1a', 
            borderRadius: '10px', flex: 1, margin: '0 10px', height: '280px', marginTop: '240px', minWidth: '430px', maxWidth: '450px' }}>


<div style={{ color: '#FFA14A', margin: 0, textAlign: 'left', fontSize: '45px', fontWeight: 'bold' }}>Talk To An Engineer</div>
        <div style={{ color: 'white', margin: 0, textAlign: 'left', fontSize: '25px' }}>Schedule a free 1 on 1 to talk about your digital asset use cases</div>

<div style={{display: 'flex', justifyContent: 'space-between', marginTop: '50px', flexDirection: 'column', gap: '20px'}}>
<div style={{fontSize: '20px', color: '#777777'}}>
  What can we build for you?
</div>
<a href="https://calendly.com/finfriend/15minmeeting" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
  <div style={{
    cursor: 'pointer',
    color: '#1a1a1a',
    background: '#FFA14A',
    borderRadius: '5px',
    fontSize: '20px',
    padding: '10px',
    width: '140px',
    textAlign: 'center'
  }}>
    Select A Time
  </div>
</a>
</div>
        
          </div>

<div style={{display: 'flex', flexDirection: 'column', height: '700px'}}>
          <div style={{ backgroundColor: '#222222', 
            borderRadius: '10px', flex: 1, margin: '0 10px', height: '260px', marginTop: '130px', 
            marginLeft: '0vw', maxWidth: '480px' }}>

<div style={{padding: '20px'}}>
 
 {/* 
<div style={{display: 'flex'}}>
 <div style={{color: activitySelected ? 'white' : '#777777', 
 borderBottom: activitySelected ? '2px solid #FFA14A' : 'none',
 paddingBottom: '5px',
 cursor: 'pointer'}}
 onClick={handleactivityClicked}>Activity</div>

 <div style={{color: newListingSelected ? 'white' : '#777777', 
 borderBottom: newListingSelected ? '2px solid #FFA14A' : 'none',
 marginLeft: '40px',
 paddingBottom: '5px',
 cursor: 'pointer'}}
 onClick={handleNewListingClicked}>New Listing</div>
 </div>
*/}
<div style={{fontSize: '25px'}}>
New Listings
</div>
{activitySelected && (
<div>
<div style={{marginTop: '20px'}}>Hi</div>
 </div>
)}



{newListingSelected && (
  <div>

{newCryptoData ? (

<div>


{newCryptoData.map((crypto, index) => (
<div>
<Link to={`/trade/${crypto.ticker}`} onClick={() => {window.scrollTo(0, 0); reloadOfflineCryptoData();}} style={{ textDecoration: 'none' }}>
<div style={{display: 'flex', marginTop: '20px', alignItems: 'center', }}>
{iconImage(crypto.name, crypto.isUsingCustomIcon, crypto.iconUrl)}

<div style={{width: '205px', paddingLeft: '10px'}}>
  <span style={{color: 'white'}}>{crypto.ticker}</span>
  <span style={{color: '#666666'}}> {crypto.name}</span>
</div>
  <div style={{color: 'white', width: '50px', textAlign: 'right'}}>${formatDollarNumber(bitcoinPriceInDollars*crypto.price)}</div>

  {crypto.InitialPrice == crypto.price && (
      <div style={{color: '#666666', textAlign: 'right', width: '100px'}}>0%</div>
  )}

{crypto.InitialPrice < crypto.price && (
  <div style={{color: '#90ee90', textAlign: 'right', width: '110px'}}>
    +{((crypto.price - crypto.InitialPrice) / crypto.InitialPrice * 100).toFixed(2)}%
  </div>
)}

{crypto.InitialPrice > crypto.price && (
  <div style={{color: '#f94449', textAlign: 'right', width: '110px'}}>
    -{((crypto.InitialPrice - crypto.price) / crypto.InitialPrice * 100).toFixed(2)}%
  </div>
)}

    </div>
    </Link>
    </div>
))}
</div>
) : (
<div>

<div
  style={{
    alignItems: 'center',
    height: '90vh',
    color: 'white',
    fontSize: '2rem',
  }}
>
  <div style={{    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px'}}>
  <div style={{
    border: '16px solid #161616', // Light grey border
    borderTop: '16px solid #FFA14A', // Orange color for the spinner part
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1.0s linear infinite'
  }} />
  <style>
    {`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}
  </style>
  </div>
</div>

</div>

)}





 </div>

)}



        </div>

          </div>



          <div style={{ backgroundColor: '#222222', 
            borderRadius: '10px', flex: 1, margin: '0 10px', height: '260px', marginTop: '30px', marginLeft: '0vw', maxWidth: '480px' }}>

             <div style={{padding: '20px'}}>
              <div style={{fontSize: '25px'}}>News</div>
              <div style={{marginTop: '20px'}}>Future Token Creator Puts Up 100% Of It's Total Supply For Sale</div>
             <div style={{marginTop: '20px'}}>DomCoin Reaches 10+ Holders In The First 24 Hours Of Launch</div>
              <div style={{marginTop: '20px'}}>Gavcoin Sets Up A $5,000 Liquidity Fund To Boost Trading Volume</div>
             </div>
</div>

</div>
          </div>

        </div>
  );
}

export default HomePageDynamic;