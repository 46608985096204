import React, { useState, useRef, useEffect } from 'react';
import styles from '../../App.module.scss';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import PlacesAutocomplete from "react-places-autocomplete";
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { setStreetAddress } from '../../redux/ephemeralUserData';
import closeButton from '../../assets/closeButton.png';
import CheckmarkAnimation from '../CheckMarkAnimation';

export default function SignUp3Modal() {


    const db = getFirestore();
    const dispatch = useAppDispatch();
  const [showSignUp3Modal, setShowSignUp3Modal] = useState(true);
  const [address, setAddress] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const autocompleteRef = useRef<google.maps.places.Autocomplete>();

  const [showCheckmark, setShowCheckmark] = useState(false);

  const [animate, setAnimate] = useState(false);

  const toggleSignUp3Modal = () => {
    setShowSignUp3Modal(!showSignUp3Modal);
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };

  const removeWhitespace = (str: string) => {
    return str.replace(/\s/g, '');
  };

  const handleSignUpSubmitButtonClick = async () => {
    const cleanedAddress = removeWhitespace(address);
    if (cleanedAddress === '') {
      setErrorMessage('Please enter a valid address');
    } else if (address.length >= 120) {
      setErrorMessage('Address must be less than 120 characters');
    } else if (address.length <= 5) {
        
        setErrorMessage('Please enter a valid address');
      }else {
      // SAVE to users doc
      setErrorMessage('');

      setShowSignUp3Modal(false);
      setShowCheckmark(true);

      
      try {
        // Update user document
        const uid = getAuth().currentUser?.uid;
        const userDocRef = doc(db, 'users', uid!); // Replace 'userId' with the actual user ID
        await updateDoc(userDocRef, {
            streetAddress: address,
            CreationDate: new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
        });

    setTimeout(() => {
      dispatch(setStreetAddress(address));
    }, 2000);

      } catch (error) {
        console.error('Error updating user document:', error);
      }

    }
  };

  const [suggestions, setSuggestions] = useState<string[]>([]);
  
  const handleSelect = (address: any) => {
    setErrorMessage('');
    console.log('Setting address:', address);
    setAddress(address);
    // setAddress(address);
  };

  const handleChange = (address: string) => {
    setAddress(address);
    setSuggestions([]);
  };

    return (
        <div>

          {showSignUp3Modal && (
            <div className={styles['modal-container']}>
              <div className={styles['overlay']} onClick={toggleSignUp3Modal}></div>
              <div className={styles['crypto-modal']}>
                <div className={styles['crypto-modal-header']}>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <h3>Create Your Account</h3>
                    <div>
                        <span
                            style={{ cursor: 'pointer' }}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                        >
                            <span style={{ color: 'white' }}>Compliance Info</span>
                            <span style={{ color: '#007AFF', marginLeft: '5px' }}>
                            What Is This?
                            </span>
                        </span>
                        </div>
                  </div>
                  <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={toggleSignUp3Modal} style={{width: '24px', height: '24px'}}></img>

                </div>

                <div className={styles['crypto-modal-content']}>


                {showTooltip ? (
                    <div
                    style={{
                        backgroundColor: '#222222',
                        color: '#fff',
                        borderRadius: '6px',
                        padding: '5px',
                        marginTop: '10px',
                        width: '300px',
                        textAlign: 'center',
                    }}
                    >
                    FinFriend is a licensed Money Services Business with FinCEN. This means that we must collect Know Your Customer data, 
                    report suspicious activity, and operate with the utmost integrity. <br/><br/>In order to remain compliant, 
                    we must uphold our Anti Money Laundering Policy. You may learn more about our AML and Privacy Policies on the "Learn" page.
                    </div>
                ) : (
                    <div>
                    <div className={styles['crypto-modal-textfields']}>
                    <div className={styles['crypto-modal-textfield']}>
                          <label htmlFor="address">Address Of Primary Residence</label>


                          <div>
                <PlacesAutocomplete value={address} onChange={handleChange} >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                    <input style={{
                        width: '300px',
                        padding: '3px',
                        fontSize: '17px',
                        marginTop: '4px',
                        backgroundColor: '#222222',
                        height: '20px',
                        color: 'white',
                        border: '1px solid lightgray',
                        borderRadius: '8px',
                    }} {...getInputProps({ placeholder: "Street address" })} />
                    <div>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, index) => {
                        const props = getSuggestionItemProps({ suggestion: suggestion.description } as any);
                        return (
                            <div {...props} key={index} style={{ cursor: 'pointer', paddingTop: '10px', borderBottom: '1px solid lightgray' }} onClick={() => handleSelect(suggestion.description)} >
                            {suggestion.description}
                            </div>
                        );
                        })}
                    </div>
                    </div>
                )}
                </PlacesAutocomplete>
                </div> 

                      </div>

                </div>
                </div>

              )}

                </div>
                <div className={styles['crypto-modal-footer']} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <label className={styles['error-label']} style={{ textAlign: 'center', marginRight: '10px', marginBottom: '10px' }}>{errorMessage}</label>
                  <button className={styles['crypto-modal-continue-button']} style={{ flexGrow: 1 }} onClick={handleSignUpSubmitButtonClick}>Continue</button>
                </div>

                </div>

              </div>
          )}

{showCheckmark && (
  <div className={styles['modal-container']}>
    <div className={styles['overlay']} onClick={toggleSignUp3Modal}></div>
    <div className={styles['crypto-modal']}>
      <div className={styles['crypto-modal-header']}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <h3>Create Your Account</h3>
          <div style={{minWidth: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div className={styles['checkmark-animation']}>✓</div>
          </div>
        </div>
      </div>
    </div>
  </div>
)}
    </div>
    );
}
