import React, { useState, useEffect, ChangeEvent} from 'react';
import styles from '../../App.module.scss';
import { getStorage, ref, getDownloadURL, FirebaseStorage, uploadBytes } from 'firebase/storage';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import financeIcon from '../../assets/finance_icon.png'
import documentIcon from '../../assets/document_icon.png'
import fireIcon from '../../assets/fire_icon.png'
import historyIcon from '../../assets/history_icon.png'
import { cryptoForTradingData } from '../CurrentUserInfo';
import { db } from "../..";
import { getAuth } from "firebase/auth";
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { functions } from "../..";
import { httpsCallable } from "firebase/functions";
import { getVolumeData } from '../../Helpers/getVolumeData';
import closeButton from '../../assets/closeButton.png';
import {formatDollarNumber} from '../../Helpers/FormatNumbers'; 

interface AboutTradeTileProps {
  cryptoData: cryptoForTradingData | null;
}

function AboutTradeTile({ cryptoData }: AboutTradeTileProps) {

  //recently viewed
  const [recentlyViewedArrayCount, setRecentlyViewedArrayCount] = useState(0);
  const comingFromCreateCryptoTestLaunch = useAppSelector((state) => state.comingFromCreateToTrade.value)
  const [showTooltip, setShowTooltip] = useState(false);
  const bitcoinPriceInDollars = useAppSelector((state) => state.bitcoinPriceData.value)
  const uid = getAuth().currentUser?.uid;
  const currentUserCreationDate = useAppSelector((state) => state.userData.userData?.creationDate)
  const [showEditModal, setShowEditModal] = useState(false);
  const [volumeText, setVolumeText] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState('');
  const [editButtonIsHovered, setEditButtonHovered] = useState(false);
  const [cryptoIconLink, setIconLink] = useState('');
  const [cryptoDescription, setCryptoDescription] = useState('');
  const storage: FirebaseStorage = getStorage();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0] || null;
      setSelectedFile(file);
    };

  const handleEditButtonMouseEnter = () => {
      setEditButtonHovered(true);
  };
  const handleEditButtonMouseLeave = () => {
    setEditButtonHovered(false);
  };

  const handleModalCloseClick = () => {
    setShowEditModal(false);
   };

   const handleCryptoDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const updatedDescription = event.target.value;
    setCryptoDescription(updatedDescription);
  };
  useEffect(() => {
    const fetchData = async () => {
      if (currentUserCreationDate) {
        const volumeData = await getVolumeData(cryptoData!.Ticker); // Call the async function to get volume data

        if (!volumeData) {
          setVolumeText("0"); // Display 0 if volumeData is falsy
        } else {
          const currentDate = Date.now()/1000;
          const oneMonthAgo = currentDate - 30 * 24 * 60 * 60; // Calculate timestamp for one month ago
        
          console.log('one month ago',  oneMonthAgo)
          let monthlyVolume = 0;
          Object.entries(volumeData).forEach(([timestamp, amount]) => {
            const timestampNumber = parseFloat(timestamp);
            if (timestampNumber >= oneMonthAgo) {
              monthlyVolume += amount; // Add amounts within one month to monthlyVolume
            }
          });
        
          setVolumeText("$" + (monthlyVolume*bitcoinPriceInDollars).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).replace(/\.?0+$/, ''));
        }
      }
    };

    fetchData();
  }, [bitcoinPriceInDollars, currentUserCreationDate]);

  useEffect(() => {
    if (!comingFromCreateCryptoTestLaunch) {
    const handleRecentlyViewed = async (uid: string, cryptoData: any) => {
      const cryptoDocRef = doc(db, 'cryptos', cryptoData.Ticker);

      //console.log('recently viewed array: ', cryptoData?.RecentlyViewedBy)

      const recentlyViewedArray = cryptoData?.RecentlyViewedBy || [];
      if (cryptoData?.RecentlyViewedBy) {
        // Add to existing array if UID doesn't already exist
        if (!cryptoData.RecentlyViewedBy.includes(uid)) {
          const updatedRecentlyViewedArray = [...recentlyViewedArray, uid];

          //console.log('updating database: ', updatedRecentlyViewedArray)
          await updateDoc(cryptoDocRef, {
            RecentlyViewedBy: updatedRecentlyViewedArray
          });
          setRecentlyViewedArrayCount(updatedRecentlyViewedArray.length);
        } else {
          setRecentlyViewedArrayCount(cryptoData.RecentlyViewedBy.length);
        }
      } else {
        // Create a new array with the current UID

        console.log('updating database: ', [uid])
        await updateDoc(cryptoDocRef, {
          RecentlyViewedBy: [uid]
        });
        setRecentlyViewedArrayCount(1);
      }
    };

    
    if (uid && currentUserCreationDate) {
      console.log('Calling handle recently viewed with ', uid)
      handleRecentlyViewed(uid, cryptoData);
    } else {
      setRecentlyViewedArrayCount(cryptoData?.RecentlyViewedBy.length ?? 0);
    }
  }
  }, [comingFromCreateCryptoTestLaunch]);

    //likes
    const [likesArrayCount, setLikesArrayCount] = useState(0);

    useEffect(() => {
      const likesArray = cryptoData?.LikedBy;
      if (likesArray) {
        setLikesArrayCount(likesArray.length);
      }
    }, []);



    const handleEditButtonClickForTrial = () => {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 4000);
    };


  const handleEditButtonClick = () => {
    setShowEditModal(true);
  };

  const handleSaveButtonClick = async () => {
    const allowedFileTypes = ['image/png'];
    const cryptoDocRef = doc(db, 'cryptos', cryptoData!.Ticker);

    if (selectedFile) {
      if (selectedFile.size > (1024 * 1024 * 9)) {
        setErrorMessage('Image must be less than 9MB in size');
      } else if (!allowedFileTypes.includes(selectedFile.type)) {
        setErrorMessage('Only PNG files please');
      } else {
        console.log('Selected file:', selectedFile);
        setErrorMessage('Uploading...');
        const uid = getAuth().currentUser?.uid;
  
        const storage = getStorage();
        const fileName = `${cryptoData?.Ticker}.${selectedFile.type.split('/')[1]}`;
  
        const fileRef = ref(storage, `cryptoIcons/${fileName}`);
        try {
          const snapshot = await uploadBytes(fileRef, selectedFile);
          console.log('File uploaded successfully:', snapshot);
          // Handle success or perform additional actions
  
          if (cryptoDescription) {
            await setDoc(cryptoDocRef, {
              isUsingCustomIcon: true,
              Description: cryptoDescription,
            }, { merge: true });
          } else {
            await setDoc(cryptoDocRef, {
              isUsingCustomIcon: true,
            }, { merge: true });
          }
          setErrorMessage('');
          setShowEditModal(false);
        } catch (error) {
          console.error('Error uploading file:', error);
          // Handle error
        }
      }
    } else if (cryptoDescription) {
      setErrorMessage('Uploading...');
      await setDoc(cryptoDocRef, {
        Description: cryptoDescription,
      }, { merge: true });
      setErrorMessage('');
      setShowEditModal(false);
    } else {
      setShowEditModal(false);
    }
  };

  useEffect(() => {
    const getCryptoIconLink = async () => {
      if (cryptoData && cryptoData.isUsingCustomIcon) {
        const storageRef = ref(storage, `cryptoIcons/${cryptoData.Ticker}.png`);
        try {
          const url = await getDownloadURL(storageRef);
          setIconLink(url.toString());
        } catch (error) {
          console.log(`Error getting custom icon for ${cryptoData.Ticker}:`, error);
          setIconLink('../../assets/CryptoIcons/P.png');
        }
      } else if (cryptoData) {
        const firstChar = cryptoData!.Name.charAt(0);
        if (/^[^A-Za-z]+$/.test(firstChar)) {
        setIconLink(`../../assets/CryptoIcons/${cryptoData.Name.charAt(0).toUpperCase()}.png`);
        }
      }
    };

    getCryptoIconLink();
    
  }, [cryptoData]);

  useEffect(() => {
    console.log(`Icon URL: ${cryptoIconLink}`);
  }, [cryptoIconLink]);

  const iconImage = () => {
    const firstChar = cryptoData!.Name.charAt(0);
    //console.log('Testing first char')
    if (/^[^A-Za-z]+$/.test(firstChar)) {
      console.log('If branch: load from first char')
      return (
        <div>
          <img
            className={styles['about-crypto-icon-img']}
            src={
              cryptoData!.isUsingCustomIcon
                ? cryptoIconLink
                : require(`../../assets/CryptoIcons/X.png`)
            }
            alt={` `}
          />
        </div>
      );
    } else {
      console.log('Else branch: load from X.png')
        return (
          <img
            className={styles['about-crypto-icon-img']}
            src={
              cryptoData!.isUsingCustomIcon
                ? cryptoIconLink
                : require(`../../assets/CryptoIcons/${(cryptoData!.Name).charAt(0).toUpperCase()}.png`)
            }
            alt={` `}
          />
      );
    }
  };

  if (!cryptoData) {
    return <div>Loading...</div>; // You can show a loading indicator until the data is fetched
  }

  const editButton = () => {
    if (comingFromCreateCryptoTestLaunch) {
      return (

        <div>
        <button
          style={{
            backgroundColor: editButtonIsHovered ? 'white' : '#222222',
            color: editButtonIsHovered ? '#222222' : 'white',
            display: 'inline-block',
            padding: '5px 20px',
            fontSize: '24px',
            fontWeight: 'bold',
            textAlign: 'center',
            borderRadius: '10px',
            border: '1px solid white',
            cursor: 'pointer',
            width: '100px',
            height: '45px',
          }}
            onMouseEnter={handleEditButtonMouseEnter}
            onMouseLeave={handleEditButtonMouseLeave}
            onClick={handleEditButtonClickForTrial}
          >Edit</button>
          <div style={{      position: 'absolute',
      backgroundColor: '#222222',
      color: 'white',
      padding: '5px 10px',
      borderRadius: '5px',
      visibility: showTooltip ? 'visible' : 'hidden',
      opacity: showTooltip ? 1 : 0,
      transition: 'opacity 0.5s ease-in-out, visibility 0s 0.5s',}}>
          This feature is not available for test launches.
        </div>
        </div>
        );
    }
    if (uid && currentUserCreationDate && cryptoData.OriginatorUID) {
      if (uid === cryptoData.OriginatorUID) {
        return (
        <button
          style={{
            backgroundColor: editButtonIsHovered ? 'white' : '#222222',
            color: editButtonIsHovered ? '#222222' : 'white',
            display: 'inline-block',
            padding: '5px 20px',
            fontSize: '24px',
            fontWeight: 'bold',
            textAlign: 'center',
            borderRadius: '10px',
            border: '1px solid white',
            cursor: 'pointer',
            width: '100px',
            height: '45px',
          }}
            onMouseEnter={handleEditButtonMouseEnter}
            onMouseLeave={handleEditButtonMouseLeave}
            onClick={handleEditButtonClick}
          >Edit</button>
        );
      }
    } 
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        {iconImage()}
        <div style={{ color: 'white', paddingLeft: '20px', fontWeight:'normal', fontSize:'28px' }}>{cryptoData.Name}</div>
        <div style={{marginLeft:'auto'}}>
          {editButton()}
        </div>

      </div>
      <div style={{ display: 'flex', gap: '25px', alignItems: 'center', justifyContent: 'flex-start', marginTop: '20px' }}>
        <img src={financeIcon} alt="" style={{width: '35px', height: '35px'}} />
        <div style={{ color: 'white' }}>Monthly Volume: {volumeText}</div>
        <div style={{ color: 'white' }}>Total Supply: {(cryptoData.Supply).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).replace(/\.?0+$/, '')}</div>
        <div style={{ color: 'white' }}>Total Valuation: ${formatDollarNumber(cryptoData.Supply*cryptoData.Price*bitcoinPriceInDollars)}</div>
      </div>
      <div style={{ display: 'flex', gap: '25px', alignItems: 'center', justifyContent: 'flex-start', marginTop: '20px' }}>
      <img src={fireIcon} alt="" style={{width: '35px', height: '35px'}} />
        <div style={{ color: 'white' }}>Views: {recentlyViewedArrayCount}</div>
        <div style={{ color: 'white' }}>Likes: {likesArrayCount}</div>
      </div>
      <div style={{ display: 'flex', gap: '25px', alignItems: 'center', justifyContent: 'flex-start', marginTop: '20px' }}>
        <img src={historyIcon} alt="" style={{width: '35px', height: '35px'}} />
        <div style={{ color: 'white' }}>Made by {cryptoData.OriginatorUsername} on {cryptoData.OriginationDate}</div>
      </div>
      <div style={{ display: 'flex', gap: '25px', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '20px' }}>
        <img src={documentIcon} alt="" style={{width: '35px', height: '35px'}} />
            {cryptoData.Description ? (
              <div style={{ color: 'white', paddingBottom: '15px' }}>Description: {cryptoData.Description}</div>
            ) : (
              <div style={{ color: 'white', marginTop: '10px' }}>This asset does not have a description yet.</div>
              )}
      </div>

      {showEditModal && (
          <div className={styles['modal-container']}>
            <div className={styles['overlay']} onClick={handleModalCloseClick}></div>
            <div className={styles['crypto-modal']} style={{height: '77vh'}}>
              <div className={styles['crypto-modal-header']}>
                <h3>Edit {cryptoData.Name}</h3>
                
                <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={handleModalCloseClick} style={{width: '24px', height: '24px'}}></img>
              </div>
              <div className={styles['crypto-modal-content']}>
              <div style={{color: 'white', textAlign: 'center'}}>Upload a square or circular PNG</div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  
                {selectedFile ? <img src={URL.createObjectURL(selectedFile)} className={styles['about-crypto-icon-img']} alt="Selected Image" /> : <div>{iconImage()}</div>}
                <div>
                  <input type="file" onChange={handleFileChange} style={{
                    flex: 1,
                    padding: '5px',
                    backgroundColor: 'white',
                    color: 'black',
                    border: 'none',
                    borderRadius: '4px',
                    fontSize: '14px',
                    cursor: 'pointer',
                    }}/>
                    </div>
                </div>

              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <label htmlFor="crypto-description" style={{fontSize: '18px'}}>Description</label>
              <textarea
                id="crypto-description"
                style={{
                  width: '400px',
                  height: '200px',
                  backgroundColor: '#222222',
                  color: 'white',
                  border: '1px solid white',
                  borderRadius: '10px',
                  resize: 'none',
                  fontFamily: 'Arial, sans-serif', 
                  fontSize: '15px'
                }}
                value={cryptoDescription || cryptoData.Description}
                onChange={handleCryptoDescriptionChange}
              />
            </div>
              </div>
              <div className={styles['crypto-modal-footer']}>
              <label className={styles['error-label']} style={{ textAlign: 'center', marginRight: '10px', marginBottom: '10px' }}>{errorMessage}</label>
                <button className={styles['crypto-modal-continue-button']} onClick={handleSaveButtonClick}>Save</button>
              </div>
            </div>
          </div>
        )}

    </div>

    
  );
}

export default AboutTradeTile;