import React, { useState, useEffect, useContext } from 'react';
import styles from '../App.module.scss';
import { setBitcoinPriceInDollars } from '../components/CurrentUserInfo';
import { useParams } from 'react-router-dom';
import TradeGridSystem from '../components/TradeGridSystem';
import HeaderLeft from '../components/headerLeft';
import { functions } from "..";
import { httpsCallable } from "firebase/functions";
import HeaderNav from '../components/Authentication/HeaderNav';
import { setBitcoinPriceData } from '../redux/ephemeralBitcoinPrice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import DownloadModal from '../components/DownloadModal';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import SmallScreenLanding from '../components/SmallScreenLanding';
import Header from '../components/Header';

function Trade() {

  const navigate = useNavigate();

  const { ticker } = useParams(); //access the ticker from the react router

  const dispatch = useAppDispatch();

  const getBitcoinPrice = async () => {
    try {
      const fetchBTCPriceInUSD = httpsCallable(functions, "fetchBTCPriceInUSD");
      const response = await fetchBTCPriceInUSD({});

      dispatch(setBitcoinPriceData(1.0/(response.data as number)));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBitcoinPrice();
  }, []);

  
  const isSmallScreen = window.innerWidth <= 870;


  if (isSmallScreen) {
    return (
    <div>
        
    {<SmallScreenLanding/>}

  </div>
    )
  };
  
    return (
      
      <div className={styles.App} style={{ backgroundColor: '#222222' }}>
        <div className={styles['dark-gray-background']}>

<Header isTradePage = {true}/>


          </div>
        <div className={styles['darker-gray-background']} style={{paddingTop: '80px'}}>
        <TradeGridSystem ticker={ticker}/>
        </div>
        <Footer />
      </div>
    );
}


  export default Trade;