import React, { useState, useEffect } from 'react';
import styles from '../../App.module.scss';
import { functions } from "../..";
import { httpsCallable } from "firebase/functions";
import HeaderNav from '../../components/Authentication/HeaderNav';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setBitcoinPriceData } from '../../redux/ephemeralBitcoinPrice';
import HeaderLeft from '../../components/headerLeft';
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import SmallScreenLanding from '../../components/SmallScreenLanding';
import tieIcon from '../../assets/tieIcon.png';

function PrivacyPolicy() {

    const navigate = useNavigate();
  let currentUserCreationDate = useAppSelector((state) => state.userData.userData?.creationDate);

  console.log('rendering home page with creation date: ', currentUserCreationDate);
  const dispatch = useAppDispatch();

  const getBitcoinPrice = async () => {
    try {
      const fetchBTCPriceInUSD = httpsCallable(functions, "fetchBTCPriceInUSD");
      const response = await fetchBTCPriceInUSD({});

      dispatch(setBitcoinPriceData(1.0/(response.data as number)));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBitcoinPrice();
  }, []);


  const isSmallScreen = window.innerWidth <= 800;

  /*
  if (isSmallScreen) {
    return (
    <div>
        
    {<SmallScreenLanding/>}

  </div>
    )
  };

  */

  return (
      
    <div className={styles.App} style={{ backgroundColor: '#222222' }}>
      <div className={styles['dark-gray-background']}>
        <div className={styles['header']}>
          <div className={styles['header-container']}>
          <HeaderLeft/>
            <div className={styles['header-right']}>
                {/* HeaderNav will only be rendered if isSmallScreen is false */}
                {isSmallScreen ? null : <HeaderNav />}
              </div>
          </div>
        </div>
        </div>
        <div className={styles['darker-gray-background']} style={{ display: 'flex', justifyContent: 'center', 
        alignItems: 'center', flexDirection: 'column' }}>

<div className={styles['darker-gray-background']}>


<div style={{    display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '100px',
      gap: '0px',
      marginBottom: '100px'
      }}>
        <div 
        style={{
    textAlign: 'center',
    fontSize: '35px',
    fontWeight: 'bold',
    color: 'white',
    height: '10vh',
  }}>Privacy Policy</div>

<img src={tieIcon} style= {{

      width: '80px', height: 'auto'}}></img>
      </div>

      </div>
    <div
      style={{
        width: '85vw',
        padding: '20px',
        borderRadius: '10px',
        backgroundColor: '#222222',
        marginBottom: '30px',
      }}
    >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: 'white', margin: '12px', fontSize: '28px' }}>FinFriend

Privacy Policy

August 29th, 2022</div>
            
          </div>
        </div>
<div style={{color: 'white'}}>
        <div style={{color: 'white'}}>

This privacy policy for FinFriend LLC (“company”, “we”, “us” or “our”), describes how and why we 
might collect, store, use and/or share (“process”) your information when you use our services such 
as when you download and use our mobile application (FinFriend) and/or engage with us in other 
related ways, including sales, marketing, or events.

This privacy policy will help you understand your privacy rights and choices. If you do not agree 
with our policies and practices, please do not use our services. By using our services you are agreeing to this policy.

What personal information do we process? When you visit, use or navigate our services, we may 
process personal information depending on how you interact with FinFriend LLC and the services, 
the choices you make, and the features you use. We collect the users name, email, phone number, geo 
location, and user uploaded photos.

We do not process sensitive information such as payment information or precise location.

We do not receive information from third parties such as social media as of August 29th 2022, notice 
that we have plans to coordinate with third parties in the near future to enhance user experience and 
allow the linking of the user’s different platforms. We process your information to provide, improve and 
administer our services. We may share information in specific situations to enhance the user 
experience across different platforms.

How do we keep your information safe? We have organizational and technical procedures in place 
to protect your personal information. However, no electronic transmission over the internet or 
information storage technology can be guaranteed to be 100% secure, so we cannot promise or 
guarantee that hackers, cybercriminals or unauthorized parties will not be able to improperly 
collect, access, steal or modify your information.

What are your rights? Depending on where you are located geographically, the applicable privacy 
law will vary. 

How do you exercise your rights? The easiest way to exercise your rights and request data is to 
fill out the contact form on FinFriend.com.

</div><br/><div>
Table Of Contents
</div><br/><div>
1. What information do we collect?
</div><br/><div>
2. How do we process your information?
</div><br/><div>
3. What legal basis do we rely on to process your information?
</div><br/><div>
4. When and with whom do we share your personal information?
</div><br/><div>
5. How long do we keep your information?
</div><br/><div>
6. How do we keep your information safe?
</div><br/><div>
7. Do we collect information from minors? 
</div><br/><div>
8. What are your privacy rights?
</div><br/><div>
9. Do California residents have specific privacy rights?
</div><br/><div>
10. How do I know this policy is up to date?
</div><br/><div>
11. How can I review, update or delete personal information?

     </div><br/><div>
1. What information do we collect?
</div><br/><div>
We collect personal information that you provide to us. We collect personal information that you 
voluntarily provide to us when you register on the application or otherwise when you contact us.

The personal information that we collect depends on the context of your interactions with us and 
the service you use, the choices you make, and the products and features of use. The personal 
information we collect may include the following: names, phone numbers, email addresses, passwords, 
General location and user uploaded photos. This information is primarily needed to maintain the operations of our service.

Geo location information. 

We may request access or permission to track location based information from your mobile device, 
either continuously or while you are using our mobile application, to provide certain location-based 
services. If you wish to change or access or permissions you may do so in our devices settings.

Push notifications. 

We may request to send you push notifications regarding your account or certain features of the 
application. If you wish to opt out from these types of notices, you may turn them off in your devices settings.

KYC information.

FinFriend is a KYC platform, as such we must collect all of the necessary information upon 
registration. For more information see FinFriend’s AML policy. 

</div><br/><div>

2. How do we process your information? 

</div><br/><div>

We process your information to provide, improve, and administer our services, communicate 
with you, for security and fraud prevention, and to comply with law. We may also process your 
information for other purposes with your consent. We process your personal information for a 
variety of reasons, depending on how you interact with our services, including:

To facilitate account creation, display to other users for the purposes of discovery, 
authentication and otherwise to manage user accounts. We may process your information so 
you can create and login to your account, as well as keep your account in working order. 
We may process your information when necessary to save or protect individuals vital interests 
such as to prevent harm or when a crime is suspected.

</div><br/><div>

3. What legal bases do we rely on to process your information?

</div><br/><div>

We only process your personal information when we believe it is necessary and we have a valid 
legal reason. We do so under applicable law, like with your consent, to comply with laws, to 
provide you with services to enter into or fulfill our contract renewal obligations, to protect 
your rights, or to fulfill our legitimate business interests. 

If you are located in the EU or UK this section applies to you. 

The general data protection regulation (GDPR) and UK GDPR require us to explain the valid legal 
basis we rely on in order to process your personal information. As such we may rely on the 
following legal basis to process your personal information: 

Consent. 

We may process your information if you have given us permission to use your personal 
information for a specific purpose. You can withdraw your consent at any time by deleting your account. 

Legal obligations. 

We may process your information where we believe it is necessary for compliance with our 
legal obligations, such as to cooperate with the law enforcement body or regulatory agency, 
exercise or defend our legal rights, or disclose your information as evidence in litigation 
in which we are involved. 

Vital interests. 

We may process your information where we believe it is necessary to protect your vital 
interests for the vital interests of the third party such as situations involving potential 
threats to the safety of any person. 

</div><br/><div>

4. When and with whom do we share your personal information?

</div><br/><div>

We may share your information in specific situations described in the section and or with the 
following third parties. We may share your information in the following situations:

Business transfers. 

We may share or transfer your information in connection with, or during negotiations of, any 
merger, sale of company assets, financing, or acquisition of all or a portion of our business 
to another company. We may share your information in collaboration with another organization 
with the user’s permission to connect to another one of the user’s platforms.

</div><br/><div>

5. How long do we keep your information? 

</div><br/><div>

We keep your information for as long as necessary to fulfill the purposes outlined in 
this privacy notice unless otherwise regulated by law. we only keep your personal 
information for as long as it is necessary for the purposes set out in this privacy 
notice, unless a longer retention. Is required or permitted by law. 

When we have an ongoing legitimate business need to process your personal information, 
we will either delete or anonymized such information, or, if this is not possible for 
example because your personal information has been stored in backup archives, then we 
will securely store your personal information and isolated from any further processing 
until deletion is possible.

</div><br/><div>

6. How do we keep your information safe? 

</div><br/><div>

We aim to protect your personal information through a system of organizational and technical 
security measures. We have implemented appropriate and reasonable technical and organizational 
security measures designed to protect the security of any personal information we process. 
However, despite our safeguards and efforts to secure your information, No Electronic 
transmission over the Internet or information storage technology can be guaranteed to be 
100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other 
unauthorized third parties will not be able to defeat our security and improperly collect, 
access, steal, or modify your information. Although we do our best to protect your personal 
information, transmission of personal information to and from our services is at your own risk. 
You should only access the services with a secure environment. 

</div><br/><div>

7. Do we collect information from minors? 

</div><br/><div>

At this time minors are discouraged from using our services. We do not knowingly collect 
data from children under the age of 18. We do not knowingly solicit data from or market to 
children under 18 years of age. By using the services, you represent that you are at least 
18 or that you are the parent or guardian of such a minor and consent to such minor dependents' 
use of the services. If we learn that personal information from users less than 18 years of age 
has been collected against the parent or guardian's wishes, we will deactivate the account and take 
reasonable measures to probably delete such data from our records. If you become aware of any data we may have 
collected from children under the age of 18, please get in contact with us through FinFriend.com 
with the specific account in question’s email.

</div><br/><div>

8. What are your privacy rights? 

</div><br/><div>

In some regions, such as the European Economic Area EEA and the United Kingdom UK, you have 
rights that allow you access and control over your personal information. You may review, 
change, or terminate your account at any time. In some regions like the EEA and UK, you have 
certain rights under applicable data protection laws. These may include the right to request 
access and obtain a copy of your personal information, to request rectification or erase your 
account; to restrict the processing of your personal information. And in certain circumstances 
you may also have the right to object to the processing of your personal information. You can 
make such a request by contacting us at FinFriend.com. We will consider and act upon any request 
in accordance with applicable data protection laws. If you are located in the EEA or UK and you 
believe we are unlawfully processing your personal information, you also have the right to complain 
to your local data protection supervisory authority. 

</div><br/><div>

9. Do California residents have specific privacy rights?

</div><br/><div>

If you are a resident of California, you are granted specific rights regarding access to your personal
information. California civil code section 1798.83 also known as “Shine the Light”, permits our users 
who are California residents to request and obtain from us, once a year and free of charge, information 
about categories of personal information if any that we disclosed to third parties for direct marketing 
purposes and the names and addresses of all third parties with which we shared personal information in 
the immediately preceding calendar year. If you are a California resident and would like to make such a 
request, please fill out the contact form on FinFriend.com.

If you are under 18 years of age, reside in California, and have a registered account with FinFriend, 
you have the right to request removal of unwanted data that you publicly post on the services. To 
request removal of such data, please contact us using FinFriend.com and include the email address 
associated with your account.

CCPA Privacy Notice

The California code of regulations defined a ”resident” as: 

every individual who is in the state of California or other than a temporary transitory purpose and 
every individual who is domiciled in the state of California who is outside the state of California 
for a temporary or transitory purpose.

All other individuals are defined as non “residents”.

We have collected the following categories of personal information in last 12 months:

A. Identifiers

Contact details, such as real name, Alias, postal address, telephone or mobile contact number, unique 
personal identifiers, online identifier, Internet Protocol email address and account name YES

B. Personal information 

Categories listed in the California customer record statute. Name, contact information, education, 
employment, employment history, and financial information 

YES

C. Protected classification characteristics 

Under California or federal law. Gender and date of birth

YES

D.  Commercial information 

Transaction information purchase history, Financial details, and payment information 

NO

E.  Biometric information 

Fingerprints and voice prints 

NO

F.  Internet or similar network activity 

Browsing history, search history online Behavior, interests data, and interactions with our and 
other websites, applications, systems, and advertisements 

NO

G.  Geo-location data 

Device location 

NO

H.  Audio, electronic, visual, thermal, olfactory, or similar information images and audio, video 
or call recordings created in connection with our business activities 

YES

I. Professional or employment-related information 

Business contact details in order to provide you are services at any business level or job title, 
work history, and professional qualifications if you apply to us with for a job 

NO

J.  Education information 

Student records and directory information 

NO

K.  Inferences drawn from other personal information 

Inferences drawn from any of the collect personal information listed above to create a profile or 
summary about, for example, an individual's preferences and characteristics 

YES

We may also collect other personal information outside of these categories through instances where 
you interact with us in person, online, or by phone or mail in the context of:

receiving help through customer support channels. Participation in customer surveys or contests and 
facilitation in the delivery of our services and to respond to your inquiries. More information 
about our data collection or sharing practices can be found in this privacy notice. You may contact 
us on FinFriend.com for more information. If you're using an authorized agent to exercise your 
right to opt out we may deny a request if the authorized agent does not submit proof that they have 
been validly authorized to act on your behalf. We may disclose your personal information with our 
service providers pursuant to a written contract between us and the service provider. Each service 
provider is a for-profit entity that processes the information on our behalf.  We may use your personal 
information for our own business purposes such as for undertaking internal research for technical 
development and demonstration. This is not considered to be selling of your personal information. 
FinFriend LLC has not disclosed or sold any personal information to third parties for a business or 
commercial purpose in the preceding 12 months as of August 29th 2022. FinFriend LLC may use personal 
information in the future belonging to website visitors, users, and other consumers for the purposes 
of advertisement revenue.

Right to request deletion of the data

You can ask for the deletion of your personal information. If you ask us to delete your personal 
information, we will respect your request and delete your personal information, subject to certain 
expectations provided by law, such as but not limited to, the exercise by another consumer of his or
 her right to free speech, or compliance requirements resulting from a legal obligation, or any 
 processing that may be required to protect against illegal activities.

Right to be informed - Request to know

Depending on the circumstances, you have a right to know:

Whether we collect and use your personal information; the categories of personal information we 
collect; the purposes for which we collect that personal information come, whether we sell your 
personal information to third parties; the categories of personal information that we sold or 
disclosed for a business purpose; the categories of third parties to whom the personal information 
was sold or disclosed for business purpose; and the business or commercial purpose for collecting or 
selling personal information.

In accordance with applicable law, we are not obligated to delete consumer information that has been 
identified in response to another user’s request for information.

Right to non-discrimination for the exercize of the consumer's privacy rights

We will not discriminate against you if you exercise your privacy rights.

Verification process

Upon receiving your request, we will need to verify your identity to determine you are the same 
person about whom we have the information in our system. These verification efforts require us to
 ask you to provide information so that we can match it with the information you have previously 
 provided us. For instance, depending on the type of request you submit we may ask you to provide 
 certain information so that we can match the information you provided with what is already on 
 file, or we may contact you through a communication method that you have previously provided to 
 us. We may also use other verification methods as the circumstances dictate.

We will only use personal information provided in your request to verify the Identity or authority
 to make this request. To the extent possible, we will avoid requesting additional information from
  you for the purposes of verification. However, we cannot verify your information from the information
   already maintained by us, we may request that you provide additional information for the purposes
    of verifying your identity and for the security and fraud prevention purposes we will delete 
    such digital provided information as soon as we finish verifying you.

Other Privacy Rights include objecting to the processing of your personal information,  Requesting 
Corrections of your personal data and designating an authorized agent to make a request Under the 
CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof 
that they have been validly authorized to act on your behalf. To exercise these rights get in 
contact with us by filling out the contact form on FinFriend.com.

</div><br/><div>

10. How do I know this policy is up to date?

</div><br/><div>

We may update this privacy policy from time to time. The date of the update can be found at the 
top of the document. The most recent version of this document will be available on FinFriend.com.

</div><br/><div>

11. How can you get into contact with FinFriend to review, update or delete information that we 
collect from you?

</div><br/><div>

All users have the right to request access to the personal information that we collect from you for
 any purpose that you wish. To request to review, update, or delete your personal information, please
  visit finfriend.com and click the contact button to describe your request.</div>
  </div>
    </div>
  </div>
  <Footer />
    </div>
  );
}

export default PrivacyPolicy;
