import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface currentBitcoinPriceState {
  value: number;
}

const initialBitcoinPriceState: currentBitcoinPriceState = {
    value:0,
};


export const bitcoinPriceSlice = createSlice({
  name: 'bitcoinPriceData',
  initialState: initialBitcoinPriceState,
  reducers: {
    setBitcoinPriceData: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
  },
});


export const { setBitcoinPriceData } = bitcoinPriceSlice.actions;


export default {
    bitcoinPriceData: bitcoinPriceSlice.reducer,
};