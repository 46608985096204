import React, { useState, useEffect, useContext } from 'react';
import styles from '../App.module.scss';
import CryptoCard from '../components/BrowseCryptosClass';
import { db } from "..";
import { collection, getDocs } from "firebase/firestore";
import HeaderLeft from '../components/headerLeft';
import { getStorage, ref, getDownloadURL, FirebaseStorage } from 'firebase/storage';
import { Link } from 'react-router-dom';
import HeaderNav from '../components/Authentication/HeaderNav';
import { setBitcoinPriceData } from '../redux/ephemeralBitcoinPrice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { functions } from "..";
import { httpsCallable } from "firebase/functions";
import { getVolumeData } from '../Helpers/getVolumeData';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import SmallScreenLanding from '../components/SmallScreenLanding';
import rocketImage from '../assets/rocket.png';
import RocketAnimation from '../components/RocketSpinAnimation';
import { reloadNewCryptoTestLaunch } from '../redux/ephemeralCrypto';
import { formatDollarNumber } from '../Helpers/FormatNumbers';
import Header from '../components/Header';
import { getAuth } from "firebase/auth";

function BrowseCryptos() {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const auth = getAuth();
  const currentUserID = auth.currentUser?.uid
  const bitcoinPriceInDollars = useAppSelector((state) => state.bitcoinPriceData.value)

  const [cryptoData, setCryptoData] = useState<Crypto[]>([]);

  const [likedCryptoData, setLikedCryptoData] = useState<Crypto[]>([]);
  const [recentlyViewedCryptoData, setRecentlyViewedCryptoData] = useState<Crypto[]>([]);
  const [newCryptoData, setNewCryptoData] = useState<Crypto[]>([]);
  
  const storage: FirebaseStorage = getStorage();

  const dispatch = useAppDispatch();
  
  const getBitcoinPrice = async () => {
    try {
      const fetchBTCPriceInUSD = httpsCallable(functions, "fetchBTCPriceInUSD");
      const response = await fetchBTCPriceInUSD({});

      dispatch(setBitcoinPriceData(1.0/(response.data as number)));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBitcoinPrice();
  }, []);

  class Crypto {
    name: string;
    ticker: string;
    price: number;
    priceInDollars: number;
    iconUrl: string;
    originatorUsername: string;
    volumeData: { [key: number]: number };
    valuation: number;
    supply: number;
    isUsingCustomIcon: boolean;
    isTestCrypto: boolean;
    recentlyViewedBy: string[]; // Array of usernames or IDs
    likedBy: string[]; // Array of usernames or IDs
    CreationDate: string

    constructor(
      name: string,
      ticker: string,
      price: number,
      priceInDollars: number,
      iconUrl: string,
      originatorUsername: string,
      volumeData: { [key: number]: number },
      valuation: number,
      supply: number,
      isUsingCustomIcon: boolean,
      isTestCrypto: boolean,
      recentlyViewedBy: string[] = [], // Initialize with empty array if not provided
      likedBy: string[] = [], // Initialize with empty array if not provided
      CreationDate: string
    ) {
      this.ticker= ticker;
      this.isUsingCustomIcon = isUsingCustomIcon;
      this.name = name;
      this.price = price;
      this.priceInDollars = priceInDollars;
      this.supply = supply;
      this.iconUrl = iconUrl;
      this.originatorUsername = originatorUsername;
      this.isTestCrypto = isTestCrypto;
      this.volumeData = volumeData;
      this.valuation = valuation;
      this.recentlyViewedBy = recentlyViewedBy;
      this.likedBy = likedBy;
      this.CreationDate = CreationDate;
    }
  }

  useEffect(() => {
    const getCryptoData = async () => {
      const querySnapshot = await getDocs(collection(db, "cryptos"));
      const data = await Promise.all(querySnapshot.docs.map(async (doc) => {
        const { Ticker, isUsingCustomIcon, Name, Price, Supply, OriginatorUsername, 
          IsTestCrypto, RecentlyViewedBy, LikedBy, CreationDate } = doc.data();

        let iconUrl = `../assets/CryptoIcons/${Name.charAt(0).toUpperCase()}.png`;
        if (isUsingCustomIcon) {
          const storageRef = ref(storage, `cryptoIcons/${Ticker}.png`);
          try {
            const url = await getDownloadURL(storageRef);
            iconUrl = url.toString();
          } catch (error) {
            console.log(`Error getting custom icon for ${Ticker}:`, error);
            iconUrl = `../assets/CryptoIcons/X.png`;
          }
        } else {
          iconUrl = `../assets/CryptoIcons/${Name.charAt(0).toUpperCase()}.png`;
        }

        console.log(`Crypto name: ${Name}, icon URL: ${iconUrl}`);
  
        const volumeData = await getVolumeData(Ticker);

        return new Crypto(
          Name,
          Ticker,
          Price, 
          Price*bitcoinPriceInDollars,
          iconUrl,
          OriginatorUsername,
          volumeData,
          (Price * Supply),
          Supply,
          isUsingCustomIcon,
          IsTestCrypto,
          RecentlyViewedBy, 
          LikedBy,
          CreationDate

        );
      }).filter((crypto) => crypto !== null)); // remove null values
  
      //TO DO: if current user is test user filter out test cryptos

      const filteredData = data.filter(crypto => crypto && crypto.ticker !== 'BTC');

      setLoading(false);

      setCryptoData(filteredData.filter((crypto) => crypto !== null) as Crypto[]);

    };
  
    getCryptoData();
  }, [bitcoinPriceInDollars]);

  const isSmallScreen = window.innerWidth <= 800;

  const spinningStyle = {
    animation: 'spin 2s linear infinite',
    height: '220px', // Height of the rocket
    width: '120px'
  };

  function reloadOfflineCryptoData() {
    dispatch(reloadNewCryptoTestLaunch());
  }

  if (isSmallScreen) {
    return (
    <div>
        
    {<SmallScreenLanding/>}

  </div>
    )
  };


  if (cryptoData) {
    return (
      <div className={styles.App} style={{ backgroundColor: '#1a1a1a' }}>
        <div className={styles['dark-gray-background']}>
        
            <Header/>
          </div>
        <div className={styles['darker-gray-background']} style={{marginTop: '50px'}}>
        <div style={{        textAlign: 'center',
  fontSize: '35px',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '30vh'}}>Explore The Markets</div>


      </div>


      {loading ? (
        <div style={{ backgroundColor: '#1a1a1a' }}>
        <RocketAnimation loading={true} type={'large'}/>
        </div>
) : (

          <div>

          <div className={styles['browse-crypto-list-container']}>
      <div style={{width: '280px', fontSize: '19px'}}>Name</div>
      <p className={styles['crypto-property']}>Price</p>
      <p className={styles['crypto-property']}>Creator</p>
      { window.innerWidth > 1100 && 
      <p className={styles['crypto-property']}>Trading Volume</p>
  }
      <p className={styles['crypto-property']}>Valuation</p>
      <p style={{width: '160px', marginRight: '20px'}}>&nbsp;&nbsp;Supply</p>
    </div>
          {cryptoData.map((crypto, index) => (
            <Link to={`/trade/${crypto.ticker}`} onClick={() => {window.scrollTo(0, 0); reloadOfflineCryptoData();}} style={{ textDecoration: 'none' }}>
        <CryptoCard iconUrl={crypto.iconUrl} key={index} name={crypto.name} ticker={crypto.ticker} price={crypto.price} 
        priceInDollars={crypto.priceInDollars} originatorUsername={crypto.originatorUsername} volumeData={crypto.volumeData} 
        valuation={(crypto.price*bitcoinPriceInDollars*crypto.supply)}  supply={crypto.supply}  
        isUsingCustomIcon={crypto.isUsingCustomIcon} isTestCrypto={crypto.isTestCrypto} />
        </Link>
      ))}
    </div>
)}



        <Footer />
      </div>
      
    );
  } if (!cryptoData) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
            color: 'white',
            fontSize: '2rem',
          }}
        >Loading.
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
            color: 'white',
            fontSize: '2rem',
          }}
        >Loading.
        </div>
      );
    }
    
          }


  export default BrowseCryptos;
