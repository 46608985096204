import React, { useState, useEffect } from 'react';
import styles from '../../App.module.scss';
import { functions } from "../..";
import { httpsCallable } from "firebase/functions";
import HeaderNav from '../../components/Authentication/HeaderNav';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setBitcoinPriceData } from '../../redux/ephemeralBitcoinPrice';
import DownloadModal from '../../components/DownloadModal';
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import SmallScreenLanding from '../../components/SmallScreenLanding';
import HeaderLeft from '../../components/headerLeft';
import tieIcon from '../../assets/tieIcon.png';

function AntiMoneyLaunderingPolicy() {

    const navigate = useNavigate();

  let currentUserCreationDate = useAppSelector((state) => state.userData.userData?.creationDate);

  console.log('rendering home page with creation date: ', currentUserCreationDate);
  const dispatch = useAppDispatch();

  const getBitcoinPrice = async () => {
    try {
      const fetchBTCPriceInUSD = httpsCallable(functions, "fetchBTCPriceInUSD");
      const response = await fetchBTCPriceInUSD({});

      dispatch(setBitcoinPriceData(1.0/(response.data as number)));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBitcoinPrice();
  }, []);

  
  const isSmallScreen = window.innerWidth <= 800;


  if (isSmallScreen) {
    return (
    <div>
        
    {<SmallScreenLanding/>}

  </div>
    )
  };

  return (
      
    <div className={styles.App} style={{ backgroundColor: '#222222' }}>
      <div className={styles['dark-gray-background']}>
        <div className={styles['header']}>
          <div className={styles['header-container']}>
          <HeaderLeft/>
            <div className={styles['header-right']}>
            {<HeaderNav />}
            </div>
          </div>
        </div>
        </div>
        <div className={styles['darker-gray-background']} style={{ display: 'flex', justifyContent: 'center', 
        alignItems: 'center', flexDirection: 'column' }}>

<div className={styles['darker-gray-background']}>


<div style={{    display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '100px',
      gap: '0px',
      marginBottom: '100px'
      }}>
        <div 
        style={{
    textAlign: 'center',
    fontSize: '35px',
    fontWeight: 'bold',
    color: 'white',
    height: '10vh',
  }}>Anti Money Laundering Policy</div>

<img src={tieIcon} style= {{

      width: '80px', height: 'auto'}}></img>
      </div>
      

      </div>
    <div
      style={{
        width: '85vw',
        padding: '20px',
        borderRadius: '10px',
        backgroundColor: '#222222',
        marginBottom: '30px',
      }}
    >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: 'white', margin: '12px', fontSize: '28px' }}>FinFriend

Anti Money Laundering Policy</div>
            
          </div>
        </div>

        <div style={{color: 'white'}}>
            <div>Introduction 
            </div><br/><div>
FinFriend will always be committed to improving our KYC (Know Your Customer), AML (Anti Money Laundering) and many other user protections. The FinFriend team understands that through regulatory and legal compliance, they earn their user’s trust. Since User experience is our top priority, working with regulators to combat money laundering is paramount. Only when working carefully with them, will crypto be able to be adopted on a large scale. At FinFriend, the user's safety, security and privacy is the most important thing. That is why FinFriend is committed to growing its reputation as a trusted organization. 

Money Laundering And Crypto
</div><br/><div>

Money Laundering is the vulgar act of taking illicitly acquired money and obfuscating its origins using a legitimate organization. This is particularly important in crypto where the funds can be transmitted globally and permanently in mere minutes. Anti Money Laundering Laws take regulators, compliance teams and good companies to prevent miscreants from having these luxuries. AML is a critical defense for society. FinFriend goes above and beyond the regional regulatory requirements to make sure that no tainted or dirty coins are moving through the platform. In crypto, coins can be tracked to individuals and crimes. With chain analysis from FinFriend’s partners, the FinFriend team will be able to deny transactions and flag them for review when these coins show themselves on the platform.

Criminals Are Not Welcome
</div><br/><div>
KYC and AML policies are closely linked, as they should be. FinFriend does not allow a new user on the platform without a unique phone number, and email address. New accounts have requirements including physical address, legal name, and date of birt. Working with regulators and compliance partners, FinFriend can stop criminals from entering the platform. 

Red Flags
</div><br/><div>
FinFriend has developed proprietary technology for detecting suspicious activity regarding transactions. Security is a constant arms race and is always changing, but the security team is committed to staying one step ahead. 

AML Compliance Person Designations and Duties
</div><br/><div>
The firm has designated Gavin Milligan as the AML Compliance Officer (“AMLCO”) with full responsibility of the AML program. The duties of the AMLCO will include monitoring of the firm’s compliance with AML obligations, overseeing communications and training for employees. The AMLCO will also ensure that the firm keeps and maintains all of the required AML records and will ensure that Suspicious Activity Reports (SARs) are filed with the Financial Crimes Enforcement Network (FinCEN) when appropriate. The AMLCO is vested with full responsibility and authority to enforce the firm’s AML program. 

Checking the Office of Foreign Assets Control Listings
</div><br/><div>
Before opening an account, and on an ongoing basis, we will check to ensure that a customer does not appear on the SDN list or is not engaging in transactions that are prohibited by the economic sanctions and embargoes administered and enforced by OFAC. (See the OFAC website for the SDN list and listings of current sanctions and embargoes). Because the SDN list and listings of economic sanctions and embargoes are updated frequently, we will consult them on a regular basis and subscribe to receive any available updates when they occur. With respect to the SDN list, we may also access that list through various software programs to ensure speed and accuracy. See also FINRA’s OFAC Search Tool that screens names against the SDN list. We will also review existing accounts against the SDN list and listings of current sanctions and embargoes when they are updated and we will document the review.

In the event that we determine an applicant, or someone with or for whom the applicant is transacting, is on the SDN List or is from or engaging in transactions with a person or entity located in an embargoed country or region, we will reject the application and if there is sufficient concern we may also call the OFAC Hotline at 1-800-540-6322

Customer Identification and Verification
</div><br/><div>
Prior to opening an account, we will collect the following information for all accounts, if applicable, for any person, entity or organization that is opening a new account and whose name is on the account:  
</div><br/><div>
(1) the legal name;
</div><br/><div>
(2) date of birth;
</div><br/><div>
(3) an address, which will be a residential or business street address (for an individual), an Army Post Office (APO) or Fleet Post Office (FPO) box number, or residential or business street address of next of kin or another contact individual (for an individual who does not have a residential or business street address), or a principal place of business, local office, or other physical location (for a person other than an individual);
</div><br/><div>
(4) last 4 digits of social security number;
</div><br/><div>
(5) email and phone number; 
</div><br/><div>
</div><br/><div>
b. ​Customers Who Refuse to Provide Information  
</div><br/><div>
If a potential or existing customer either refuses to provide the information described above when requested, or appears to have intentionally provided misleading information, our firm will not open a new account and, after considering the risks involved, consider closing any existing account. In either case, our AML Compliance Person will be notified so that we can determine whether we should report the situation to FinCEN on a SAR.
</div><br/><div>
c. ​Verifying Information
</div><br/><div>
Based on the risk, and to the extent reasonable and practicable, we will ensure that we have a reasonable belief that we know the true identity of our customers by using risk-based procedures to verify and document the accuracy of the information we get about our customers. We will analyze the information we obtain to determine whether the information is sufficient to form a reasonable belief that we know the true identity of the customer (e.g., whether the information is logical or contains inconsistencies).  

We will verify customer identity through documentary means, non-documentary means or both. We will use documents to verify customer identity when appropriate documents are available. In light of the increased instances of identity fraud, we will supplement the use of documentary evidence by using the non-documentary means described below whenever necessary. We may also use non-documentary means, if we are still uncertain about whether we know the true identity of the customer. In verifying the information, we will consider whether the identifying information that we receive, such as the customer’s name, street address, zip code, telephone number (if provided), date of birth and Social Security number, allow us to determine that we have a reasonable belief that we know the true identity of the customer (e.g., whether the information is logical or contains inconsistencies).

Appropriate documents for verifying the identity of customers include the following:
</div><br/><div>
</div><br/><div>
• For a person other than an individual, documents showing the existence of the entity, such as certified articles of incorporation, a government-issued business license, a partnership agreement or a trust instrument.
</div><br/><div>
We understand that we are not required to take steps to determine whether the document that the customer has provided to us for identity verification has been validly issued and that we may rely on a government-issued identification or address as verification of a customer’s identity. If, however, we note that the document shows some obvious form of fraud, we must consider that factor in determining whether we can form a reasonable belief that we know the customer’s true identity.

We will use the following non-documentary methods of verifying identity:
</div><br/><div>
• Independently verifying the customer’s identity through the comparison of information provided by the customer with information obtained from a consumer reporting agency, public database or other source [identify reporting agency, database, etc.];
</div><br/><div>
• Checking references with other financial institutions; or
</div><br/><div>
• Obtaining a financial statement.
</div><br/><div>
We will use non-documentary methods of verification when:
</div><br/><div>
(1) the customer is unable to present an unexpired government-issued identification document with a photograph or other similar safeguard;
</div><br/><div>
(2) the firm is unfamiliar with the documents the customer presents for identification verification;
</div><br/><div>
(3) the customer and firm do not have face-to-face contact; and
</div><br/><div>
(4) there are other circumstances that increase the risk that the firm will be unable to verify the true identity of the customer through documentary means.  
</div><br/><div>
We will verify the information within a reasonable time before or after the account is opened. Depending on the nature of the account and requested transactions, we may refuse to complete a transaction before we have verified the information, or in some instances when we need more time, we may, pending verification, restrict the types of transactions or dollar amount of transactions. If we find suspicious information that indicates possible money laundering, terrorist financing activity, or other suspicious activity, we will, after internal consultation with the firm's AML Compliance Person, file a SAR in accordance with applicable laws and regulations.

We recognize that the risk that we may not know the customer’s true identity may be heightened for certain types of accounts, such as an account opened in the name of a corporation, partnership or trust that is created or conducts substantial business in a jurisdiction that has been designated by the U.S. as a primary money laundering jurisdiction, a terrorist concern, or has been designated as a non-cooperative country or territory. We will identify customers that pose a heightened risk of not being properly identified. We will also take the following additional measures that may be used to obtain information about the identity of the individuals associated with the customer when standard documentary methods prove to be insufficient
</div><br/><div>
D. ​Lack of Verification
</div><br/><div>
When we cannot form a reasonable belief that we know the true identity of a customer, we will do the following: (1) not open an account; (2) impose terms under which a customer may conduct transactions while we attempt to verify the customer’s identity; (3) close an account after attempts to verify a customer’s identity fail; and (4) determine whether it is necessary to file a SAR in accordance with applicable laws and regulations.
</div><br/><div>
E. ​Recordkeeping
</div><br/><div>
We will document our verification, including all identifying information provided by a customer, the methods used and results of verification, and the resolution of any discrepancies identified in the verification process. We will keep records containing a description of any document that we relied on to verify a customer’s identity, noting the type of document, any identification number contained in the document, the place of issuance, and if any, the date of issuance and expiration date. With respect to non-documentary verification, we will retain documents that describe the methods and the results of any measures we took to verify the identity of a customer. We will also keep records containing a description of the resolution of each substantive discrepancy discovered when verifying the identifying information obtained. We will retain records of all identification information for five years after the account has been closed; we will retain records made about verification of the customer's identity for five years after the record is made.
</div><br/><div>
F. Notice to Customers
</div><br/><div>
We will provide notice to customers that the firm is requesting information from them to verify their identities, as required by federal law. We will use the following to provide notice to customers:

Important Information About Procedures for Opening a New Account

To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account.

What this means for you: When you open an account, we will ask for your name, address, date of birth and other information that will allow us to identify you. We may also ask to see your driver’s license or other identifying documents.

​Customer Due Diligence Rule

We do not open or maintain accounts for legal entity customers within the meaning of 31 CFR 1010.230. If in the future the firm elects to open accounts for legal entity customers, we will first establish, document and ensure the implementation of appropriate CDD procedures.   

NOTE:  The app currently only asks for first and last names of individuals.  There are no fields for legal entities.

Foreign Correspondent Accounts and Foreign Shell Bans

We will not provide any services to a foreign bank or foreign financial institution in any way.

Monitoring for Suspicious Activity
</div><br/><div>
Detecting Red Flags
</div><br/><div>
Red flags that signal possible money laundering or terrorist financing include, but are not limited to:

The applicant exhibits unusual concern about FinFriend’s compliance with government reporting requirements of the AML policies or is reluctant or refuses to reveal any information concerning personal finances, or furnishes unusual or suspicious identification or documents.
The information provided by the applicant that identifies a legitimate source for funds is false, misleading, or substantially incorrect.
Upon request, the applicant refuses to identify or fails to indicate any legitimate source for his or her funds and other assets when making large early payments.
The applicant (or a person publicly associated with the applicant) has a questionable background or is the subject of news reports indicating possible criminal, civil, or regulatory violations.
The applicant has difficulty describing the nature of his or her business.
The applicant requests that a transaction be processed to avoid the FinFriend’s normal documentation requirements.
Responding to Red Flags and Suspicious Activity

When a member of FinFriend detects any red flag he or she will investigate further under the direction of the AML Compliance Officer. This may include gathering additional information internally or from third-party sources, contacting the government or filing a Form SAR.  FinFriend is obligated to report suspicious transactions that are conducted or attempted by, at or through a loan or finance company and involve or aggregate at least $5,000 in funds or other assets.  We recognize that transactions are reportable under 31 U.S.C. 5318(g) regardless of whether they involve currency.  SAR reports will be filed no later than 30 days after initial detection.

Currency Transaction Reports (CTR)

FinFriend must file a report with the Internal Revenue Service within 15 days of receiving currency of more than $10,000 in one transaction, or in two or more related transactions occurring in a 12 month period.  The term “currency” includes coins and paper money, cashier’s checks, money orders, bank drafts and traveler’s checks.  It does not include personal checks.  Contrary to the SAR confidentially requirements, anyone involved in the transaction must be notified with a written statement that a report is being filed.  The form IRS Form 8300 can be found here: http://www.irs.gov/pub/irs-pdf/f8300

Contact Information

E-Mail finfriend@finfriend.com

www.finfriend.com
</div>
</div>
    </div>

  </div>
  <Footer />
    </div>
  );
}

export default AntiMoneyLaunderingPolicy;
