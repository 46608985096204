import React, { useState, useEffect, useContext } from 'react';
import styles from '../App.module.scss';
import CreateCryptoModals from '../components/CreateCryptoModals';
import { functions } from "..";
import { httpsCallable } from "firebase/functions";
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setBitcoinPriceData } from '../redux/ephemeralBitcoinPrice';
import { useNavigate } from 'react-router-dom';
import SmallScreenLanding from '../components/SmallScreenLanding';
import Header from '../components/Header';

function NoPage() {

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const getBitcoinPrice = async () => {
    try {
      const fetchBTCPriceInUSD = httpsCallable(functions, "fetchBTCPriceInUSD");
      const response = await fetchBTCPriceInUSD({});

      dispatch(setBitcoinPriceData(1.0/(response.data as number)));
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    getBitcoinPrice();
  }, []);

  
  const isSmallScreen = window.innerWidth <= 800;


  if (isSmallScreen) {
    return (
    <div>
        
    {<SmallScreenLanding/>}

  </div>
    )
  };

  return (
      
    <div className={styles.App}>
      <div className={styles['dark-gray-background']}>
      <Header/>
        </div>
      <div className={styles['darker-gray-background']}>
      (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          color: 'white',
          fontSize: '2rem',
        }}
      >
        
        <div>
          We could not find a page at this url.

        </div>

        <div
          style={{
            marginTop: '1rem',
            color: 'white',
            fontSize: '1.5rem',
          }}
        >
          Trying to create a currency?
        </div>
        <div style={{
            marginTop: '1rem'}}>
          <>
          <CreateCryptoModals buttonType={'tryItFree'} buttonTextColor={'#222222'} buttonBackgroundColor={'#FFA14A'}/>
          </>
        </div>
      </div>
    );
      </div>
    </div>
  );
  }

  export default NoPage;
