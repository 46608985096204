import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import styles from '../App.module.scss';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js';

import GraphTradeTile from './TradeTiles/GraphTradeTile';
import BuySellTradeTile from './TradeTiles/BuySellTradeTile';
import AboutTradeTile from './TradeTiles/AboutTradeTile';
import OrderBookTradeTile from './TradeTiles/OrderBookTradeTile';
import { cryptoForTradingData } from './CurrentUserInfo';
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

interface TradeTileProps {
  type: 'graph' | 'buySell' | 'cryptoInfo' | 'orderBook';
  cryptoData: cryptoForTradingData | null;
}


function TradeTile(props: TradeTileProps) {
  const options = {
    plugins: {
      legend: {
        labels: {
          font: {
            size: 14,
          },
        },
      },
    },
    scales: {
      y: {
        min: 3,
        max: 6,
      },
    },
  };

  let content;
  switch (props.type) {
    case 'graph':
      content = (
        <div>
          <>
            <GraphTradeTile  cryptoData={props.cryptoData} />
          </>
        </div>
      );
      break;
    case 'buySell':
      content = (
        <div>
          <>
            <BuySellTradeTile cryptoData={props.cryptoData} />
          </>
        </div>
      );
      break;
    case 'cryptoInfo':
      content = (
        <div>
          <>
          <AboutTradeTile cryptoData={props.cryptoData} />
          </>
        </div>
      );
      break;
    case 'orderBook':
      content = (
        <div>
          <>
            <OrderBookTradeTile cryptoData={props.cryptoData} />
          </>
        </div>
      );
      break;
    default:
      content = (
        <div style={{ color: 'white' }}>
          No content found for tile type: {props.type}
        </div>
      );
  }

  return (
    <div
      style={{
        ...local_styles.tile,
        ...local_styles[props.type],
      }}
    >
      <div></div>
      {content}
    </div>
  );
}

const local_styles = {
  tile: {
    margin: '15px 10px',
    padding: '0 10px',
    borderRadius: '15px',
    backgroundColor: '#222222',
  },
  graph: {
    gridRowEnd: 'span 30',
    gridColumnEnd: 'span 1',
  },
  buySell: {
    gridRowEnd: 'span 30',
    gridColumnEnd: 'span 1',
  },
  cryptoInfo: {
    gridRowEnd: 'span 30',
    gridColumnEnd: 'span 1',
  },
  orderBook: {
    gridRowEnd: 'span 30',
    gridColumnEnd: 'span 1',
  },
};

export default TradeTile;