import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store'
import { cryptoForTradingData } from '../components/CurrentUserInfo';

interface comingFromCreateToTradeState {
    value: boolean;
}

interface unsavedCryptoDataState {
  cryptoData: cryptoForTradingData | null;
}

interface trialUserEmail {
  value: string;
}

const initialState: comingFromCreateToTradeState = {
  value: false,
}

const initialUnsavedCryptoDataState: unsavedCryptoDataState = {
  cryptoData: null,
};

const initialTrialUserEmail: trialUserEmail = {
  value: '',
}

export const comingFromCreateToTradeSliceWithTestLaunch = createSlice({
  name: 'comingFromCreateToTrade',
  initialState, 
  reducers: {
    createNewCryptoTestLaunch: (state) => {
      state.value = true;
    },
    reloadNewCryptoTestLaunch: (state) => {
      state.value = false;
    },
  },
});

export const unsavedCryptoDataSlice = createSlice({
  name: 'unsavedCryptoData',
  initialState: initialUnsavedCryptoDataState,
  reducers: {
    setUnsavedCryptoData: (state, action: PayloadAction<cryptoForTradingData>) => {
      state.cryptoData = action.payload;
    },
    clearUnsavedCryptoData: (state) => {
      state.cryptoData = null;
    },
  },
});

export const initialTrialUserEmailSlice = createSlice({
  name: 'initialTrialUserEmail',
  initialState: initialTrialUserEmail, 
  reducers: {
    newInitialTrialUserEmail: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

export const {
  createNewCryptoTestLaunch,
  reloadNewCryptoTestLaunch,
} = comingFromCreateToTradeSliceWithTestLaunch.actions;

export const { setUnsavedCryptoData, clearUnsavedCryptoData } = unsavedCryptoDataSlice.actions;

export const { newInitialTrialUserEmail } = initialTrialUserEmailSlice.actions;

export default {
  comingFromCreateToTrade: comingFromCreateToTradeSliceWithTestLaunch.reducer,
  unsavedCryptoData: unsavedCryptoDataSlice.reducer,
  initialTrialUserEmailSlice: initialTrialUserEmailSlice.reducer,
};
