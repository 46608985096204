import { db } from "..";
import { doc, getDoc } from "firebase/firestore";

export function isCryptoNameAvailableOffPlatform(cryptoName: string): boolean {
    const cryptoArray = ["Bitcoin", "bitcoin", "Litecoin", "litecoin", "Tron", "Ethereum", "ethereum", "Stellar", "Tether",
        "BNB", "Binance", "BinanceCoin", "bitcoin", "ethereum", "USDCoin", "Ripple",
        "BinanceUSD", "binance", "coinbase", "Coinbase", "Dogecoin", "Cardano", "Flayshen",
        "flashen", "Flashen", "flayshen", "Solana", "Polygon", "Polkadot", "Shiba Inu", "Dai",
        "Uniswap", "Avalanche", "Wrapped Bitcoin", "UNUSSEDLEO", "Cosmos", "Chainlink",
        "Ethereum Classic", "FTX Token", "Cronos", "Algorand", "Monero", "NEARProtocol",
        "BitcoinCash", "Toncoin", "Quant", "Flow", "Terra Classic", "Internet Computer",
        "Chiliz", "Hedera", "ApeCoin", "Elrond", "Aave", "Huobi Token", "The Sandbox",
        "ThetaNetwork", "Tezos", "Decentraland", "OKB", "EOS", "Chain", "KuCoin",
        "KuCoin Token", "Aptos", "Pax Dollar", "Bitcoin SV", "Axie Infinity", "BitDAO",
        "Maker", "Zcash", "IOTA", "Pancake Swap", "Gemini", "gemini", "Gemini Dollar",
        "Dash", "LooksRare", "Syscoin", "Voyager Token", "DaoMaker", "IOTA"];
        const businessArray = ["Walmart", "Amazon", "Petro China", "Apple", "apple", "android", "Apple,Inc.",
            "Apple Computer", "Apple Computers", "Berkshire Hathaway", "NVIDIA",
            "TSMC", "Microsoft", "Alphabet", "Google", "google", "Tesla", "tesla", "Tesla Motors", "United Health", "Exxon",
            "Exxon Mobil", "Johnson&Johnson", "Visa", "JPMorgan Chase", "JP Morgan Chase", "Chase Bank", "Chase", "Bank Of America", "Bank of America",
            "Chevron", "EliLilly", "Procter&Gamble", "Mastercard", "Samsung", "Toyota", "Honda",
            "Ford", "Nissan", "HomeDepot", "Pfizer", "AbbVie", "Coca-Cola", "Coca Cola", "Merck",
            "Pepsico", "Meta", "Instagram", "Meta Platforms", "Costco", "McDonald", "McDonald's",
            "Thermo Fisher", "Broadcom", "T-MobileUS", "Cisco", "Disney", "Walt Disney", "Citi Bank",
            "Danaher", "Wells Fargo", "Abbott Laboratories", "Conoco Phillips",
            "Bristol-MyersSquibb", "Verizon", "Nextera Energy", "CharlesSchwab",
            "Nike", "TexasInsturments", "Amgen", "MorganStanley", "UnitedParcelService",
            "RaytheonTechnologies", "Salesforce", "Honeywell", "PhillipMorris", "Comcast",
            "Adobe", "CVSHealth", "AT&T", "Elevance Health", "LockheedMartin", "IBM",
            "GoldmanSachs", "Intel", "Netflix", "Starbucks", "Citigroup", "Target",
            "SpaceX", "Spacex", "Nueralink", "Nuerolink", "General Motors"];
            for (let i = 0; i < cryptoArray.length; i++) {
                if (cryptoName === cryptoArray[i]) {
                  return false;
                }
              }
            
              for (let i = 0; i < businessArray.length; i++) {
                if (cryptoName === businessArray[i]) {
                  return false;
                }
              }
            
              return true;
            }



export function isCryptoTickerAvailableOffPlatform(cryptoTicker: string): boolean {
              const cryptoArray = ["BTC", "ETH", "USDT", "BNB", "USDC", "XRP", "BSUSD", "DOGE",
              "ADA", "SOL", "MATIC", "DOT", "SHIB", "DAI", "TRX", "UNI", "AVAX", "WBTC", "LTC",
              "ATOM", "LINK", "LEO", "ETC", "FTT", "CRO", "XLM", "ALGO", "XMR", "NEAR", "BCH",
              "TON", "QNT", "FIL", "FLOW", "VET", "LUNC", "CHZ", "ICP", "HBAR", "APE", "EGLD",
              "AAVE", "HT", "SAND", "THETA", "XTZ", "MANA", "OKB", "EOS", "XCN", "KCS", "APT",
              "USDP", "BSV", "AXS", "BIT", "MKR", "ZEC", "TUSD", "SNX", "BTT", "MIOTA", "XEC",
              "USDD", "CAKE", "KLAY", "ETHW", "FTM", "USDN", "GRT", "GUSD", "NEO", "MINA",
              "NEXO", "LDO", "CRV", "PAXG", "HNT", "RUNE", "LRC", "GT", "TWT", "BAT", "DASH",
              "AR", "ENJ", "OSMO", "STX", "KAVA", "ZIL", "CSPR", "FEI", "BNX", "DCR", "1INCH",
              "IMX", "WAVES", "CVX", "COMP", "RVN", "HOT", "XDC", "XEM", "USTC", "CELO", "ENS",
              "GMX", "ROSE", "TFUEL", "LUNA", "GMT", "RSR", "BTG", "GNO", "BAL", "OP", "QTUM",
              "IOTX", "YFI", "ANKR", "KSM", "ABBC", "GLM", "KDA", "ONE", "LPT", "GALA", "JST",
              "CHSB", "SUSHI", "POLY", "OMG", "ELON", "CEL", "ZRX", "WOO", "GLMR", "T", "HIVE",
              "IOST", "ICX", "XYM", "AMP", "FLUX", "AUDIO", "ONT", "SRM", "INJ", "BORA", "STORJ",
              "RBN", "BTRST", "WAXP", "RNDR", "ZEN", "XCH", "SC", "NFT", "SXP", "SKL", "UMA",
              "ASTR", "OCEAN", "SCRT", "DGB", "KNC", "MXC", "PLA", "SLP", "SYN", "EWT", "REN",
              "PEOPLE", "PUNDIX", "LSK", "CVC", "METIS", "MASK", "CELR", "COTI", "DYDX", "DAO",
              "API3", "CKB", "MED", "TRIBE", "REQ", "CEEK", "KEEP", "LOOKS", "FXS", "PYR", "SSV",
              "BTCST", "RLC", "VGX", "REEF", "ONG", "SPELL", "SYS", "HEX", "WTRX", "BTCB",
              "FRAX", "WBNB", "BTTOLD", "HBTC", "WEMIX", "XAUT", "MV", "VIDT", "NXM", "DFI",
              "CCXX", "FRTS", "LN", "RPL", "BRISE", "LUSD", "VVS", "VERI", "SOLO", "DESO",
              "EURS", "TEL", "CTC", "DEXE", "SAFE", "NU", "ERG", "MBX", "MDX", "FINF", 'FRIEND'];
                  const businessArray = ["AAPL", "MSFT", "GOOG", "GOOGL", "AMZN", "TSLA", "BRK", "UNH",
                  "XOM", "JNJ", "V", "JPM", "WMT", "NVDA", "CVX", "LLY", "PG", "MA", "BAC", "HD",
                  "PFE", "ABBV", "KO", "MRK", "PEP", "META", "COST", "ORCL", "MCD", "TMO", "AVGO",
                  "TMUS", "CSCO", "DIS", "DHR", "WFC", "ABT", "COP", "BMY", "VZ", "NEE", "SCHW",
                  "NKE", "TXN", "AMGN", "MS", "UPS", "RTX", "CRM", "HON", "PM", "CMCSA", "ADBE",
                  "CVS", "T", "ELV", "LMT", "IBM", "GS", "UNP", "QCOM", "DE", "CAT", "INTC", "NFLX",
                  "LOW", "AXP", "SBUX", "SPGI", "INTU", "GILD", "AMD", "PLD", "BLK", "CI", "ADP",
                  "BA", "AMT", "GE", "C", "PYPL", "MDLZ", "ISRG", "EOG", "TJX", "MO", "REGN", "NOC",
                  "SYK", "MMC", "VRTX", "AMAT", "SLB", "EL", "BKNG", "ADI", "PGR", "TGT", "NOW",
                  "DUK", "SO", "HUM", "MMM", "GD", "OXY", "ITW", "BX", "WM", "USB", "PNC", "BDX",
                  "ZTS", "CSX", "ABNB", "CME", "APD", "CL", "MU", "HCA", "PXD", "MRNA", "FISV",
                  "BSX", "MET", "MPC", "TFC", "KKR", "LRCX", "MCK", "CCI", "ATVI", "UBER", "DG",
                  "SHW", "D", "EQIX", "GM", "F", "CHTR", "EPD", "NSC", "ICE", "EMR", "KDP", "ADM",
                  "MNST", "ORLY", "FCX", "PSX", "VLO", "PSA", "SRE", "MAR", "MCO", "CTVA", "HSY",
                  "GIS", "VMW", "DVN", "AZO", "STZ", "KHC", "KLAC", "CNC", "AEP", "HES", "APH",
                  "AIG", "LHX", "SPG", "LNG", "TRV", "PANW", "MSI", "AFL", "EW", "SNOW", "SNPS",
                  "ROP", "ADSK", "CTAS", "SYY", "SCCO", "KMB", "BIIB", "RSG", "PAYX", "WMB", "KMI",
                  "FDX", "A", "AJG", "O", "COF", "PRU", "CDNS", "CMG", "ECL", "PH", "ENPH", "ET",
                  "IQV", "APO", "FTNT", "EXC", "MSCI", "FIS", "EA", "XEL", "SQ", "ILMN", "MCHP",
                  "HLT", "WCN", "DLTR", "HAL", "ALL", "YUM", "DOW", "PCAR", "BK", "NUE", "MPLX",
                  "WDAY", "CARR", "CMI", "ABC", "AMP", "ALB", "MRVL", "KR", "IBKR", "ROST", "NEM",
                  "CSGP", "WBA", "ED", "TDG", "AME", "DD", "IDXX", "RMD", "CEG", "LVS", "OTIS",
                  "PCG", "NDAQ"];
                      for (let i = 0; i < cryptoArray.length; i++) {
                          if (cryptoTicker === cryptoArray[i]) {
                            return false;
                          }
                        }
                      
                        for (let i = 0; i < businessArray.length; i++) {
                          if (cryptoTicker === businessArray[i]) {
                            return false;
                          }
                        }
                      
                        return true;
                      }

export function isABadWord(userString: string): boolean {
  const lowerCaseUserString = userString.toLowerCase();
  const badWordsArray = ["4r5e", "5h1t", "5hit", "a55", "anal", "anus", "ar5e", "arrse", 
    "arse", "ass", "ass-fucker", "asses", "assfucker", "assfukka", "asshole", "assholes", 
    "asswhole", "a_s_s", "b!tch", "b00bs", "b17ch", "b1tch", "ballbag", "ballsack", 
    "bastard", "beastial", "beastiality", "bellend", "bestial", "bestiality", "bi+ch", 
    "biatch", "bitch", "bitcher", "bitchers", "bitches", "bitchin", "bitching", "bloody", 
    "blow job", "blowjob", "blowjobs", "boiolas", "bollock", "bollok", "boner", "boob", "boobs",
     "booobs", "boooobs", "booooobs", "booooooobs", "breasts", "buceta", "bugger", "bunny fucker", 
     "butt", "butthole", "buttmuch", "buttplug", "c0ck", "c0cksucker", "carpet muncher", "cawk", "chink", 
     "cipa", "cl1t", "clit", "clitoris", "clits", "cnut", "cock", "cock-sucker", "cockface", "cockhead", 
     "cockmunch", "cockmuncher", "cocks", "cocksuck", "cocksucked", "cocksucker", "cocksucking", 
     "cocksucks", "cocksuka", "cocksukka", "cok", "cokmuncher", "coksucka", "coon", "cox", "crap", 
     "cum", "cummer", "cumming", "cums", "cumshot", "cunilingus", "cunillingus", "cunnilingus", 
     "cunt", "cuntlick", "cuntlicker", "cuntlicking", "cunts", "cyberfuc", 
     "cyberfuck", "cyberfucked", "cyberfucker", "cyberfuckers", "cyberfucking", "d1ck", 
     "damn", "dick", "dickhead", "dildo", "dildos", "dinks", "dirsa", "dlck", "dog-fucker", 
     "doggin", "dogging", "donkeyribber", "duche", "dyke", "ejaculate", 
     "ejaculated", "ejaculates", "ejaculating", "ejaculatings", "ejaculation", "mikehawk",
     "ejakulate", "f u c k", "f u c k e r", "f4nny", "fag", "fagging", "faggitt", "faggot", 
     "faggs", "fagot", "fagots", "fags", "fanny", "fannyflaps", "fannyfucker", "fanyy", 
     "fatass", "fcuk", "fcuker", "fcuking", "feck", "fecker", "felching", "fellate", 
     "fellatio", "fingerfuck", "fingerfucked", "fingerfucker", "fingerfuckers", "fingerfucking", 
     "fingerfucks", "fistfuck", "fistfucked", "fistfucker", "fistfuckers", "fistfucking", 
     "fistfuckings", "fistfucks", "fuck", "fucka", "fucked", 
     "fucker", "fuckers", "fuckhead", "fuckheads", "fuckin", "fucking", "fuckings", 
     "fuckingshitmotherfucker", "fuckme", "fucks", "fuckwhit", "fuckwit", "fudge packer", 
     "fudgepacker", "fuk", "fuker", "fukker", "fukkin", "fuks", "fukwhit", "fukwit",  
     "fux0r", "f_u_c_k", "gangbang", "gangbanged", "gangbangs", "gaylord", "gaysex", "goatse", 
     "god-dam", "god-damned", "goddamn", "goddamned", "hardcoresex", "hell", "heshe", "hoar", 
     "hoare", "hore", "horniest", "horny", "hotsex", "jack-off", "jackoff", 
     "jerk-off", "jism", "jiz", "jizm", "jizz", "kawk", "knob", "knobead", "knobed", 
     "knobend", "knobhead", "knobjocky", "knobjokey", "kondum", "kondums", "kummer", 
     "kumming", "kums", "kunilingus", "l3i+ch", "l3itch", "labia", "lmfao", "lust", "lusting", 
     "m0f0", "m0fo", "m45terbate", "ma5terb8", "ma5terbate", "masochist", "master-bate", "masterb8", 
     "masterbat*", "masterbat3", "masterbate", "masterbation", "masterbations", "masturbate", 
     "mo-fo", "mof0", "mofo", "mothafuck", "mothafucka", "mothafuckas", "mothafuckaz", "mothafucked", 
     "mothafucker", "mothafuckers", "mothafuckin", "mothafucking", "mothafuckings", "mothafucks", 
     "mother fucker", "motherfuck", "motherfucked", "motherfucker", "motherfuckers", "motherfuckin", 
     "motherfucking", "motherfuckings", "motherfuckka", "motherfucks", "muff", "mutha", "muthafecker", 
     "muthafuckker", "muther", "mutherfucker", "n1gga", "n1gger", "nazi", "nigg3r", "nigg4h", 
     "nigga", "niggah", "niggas", "niggaz", "nigger", "niggers", "nob", "nob jokey", "nobhead", 
     "nobjocky", "nobjokey", "numbnuts", "nutsack", "orgasim", "orgasims", "orgasm", "orgasms", 
     "p0rn", "pawn", "pecker", "penis", "penisfucker", "phonesex", "phuck", "phuk", "phuked", "phuking",
     "phukked", "phukking", "phuks", "phuq", "pigfucker", "piss", "pissed", "pisser", "pissers", 
     "pisses", "pissflaps", "pissin", "pissing", "pissoff", "poop", "porn", "porno", "pornography", 
     "pornos", "prick", "pricks", "pube", "pusse", "pussi", "pussies", "pussy", "pussys", "rectum", 
     "retard", "rimjaw", "rimming", "s hit", 
    "s.o.b.", "sadist", "schlong", "screwing", "scroat", "scrote", "scrotum", "semen", "sex", "sh!+", "sh!t", 
    "sh1t", "shag", "shagger", "shaggin", "shagging", "shemale", "shi+", "shit", "shitdick", "shite", "shited", 
    "shitey", "shitfuck", "shitfull", "shithead", "shiting", "shitings", "shits", "shitted", "shitter", 
    "shitters", "shitting", "shittings", "shitty", "skank", "slut", "sluts", "smegma", "smut", 
    "son-of-a-bitch", "spunk", "s_h_i_t", "t1tt1e5", "t1tties", "teets", "teez", "testical", 
    "testicle", "tit", "titfuck", "tits", "titt", "tittie5", "tittiefucker", "titties", "tittyfuck", 
    "tittywank", "titwank", "tosser", "turd", "tw4t", "twat", "twathead", "twatty", "twunt", "twunter", 
    "v14gra", "v1gra", "vagina", "viagra", "vulva", "w00se", "wang", "wank", "wanker", "wanky", "whoar",
     "whore", "willies", "xrated", "xxx"];
          for (let i = 0; i < badWordsArray.length; i++) {
            if (lowerCaseUserString.includes(badWordsArray[i])) {
                return true;
              }
            }
          
            return false
          }

export async function isCryptoTickerAvailableOnPlatform(ticker: string): Promise<boolean> {
  const docRef = doc(db, "cryptos", ticker);

  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      // The document exists, do something with it
      const data = docSnap.data();
      console.log("Document data:", data);
      return false;
    } else {
      // The document does not exist
      console.log("No such document!");
      return true;
    }
  } catch (error) {
    console.log("Error getting document:", error);
    return true;
  }
}