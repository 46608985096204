import React, { useState, useEffect } from 'react';
import styles from '../../App.module.scss';
import { functions } from "../..";
import { httpsCallable } from "firebase/functions";
import HeaderNav from '../../components/Authentication/HeaderNav';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setBitcoinPriceData } from '../../redux/ephemeralBitcoinPrice';
import DownloadModal from '../../components/DownloadModal';
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import SmallScreenLanding from '../../components/SmallScreenLanding';
import HeaderLeft from '../../components/headerLeft';
import tieIcon from '../../assets/tieIcon.png';

function SecuritiesPolicy() {

    const navigate = useNavigate();

  let currentUserCreationDate = useAppSelector((state) => state.userData.userData?.creationDate);

  console.log('rendering home page with creation date: ', currentUserCreationDate);
  const dispatch = useAppDispatch();

  const getBitcoinPrice = async () => {
    try {
      const fetchBTCPriceInUSD = httpsCallable(functions, "fetchBTCPriceInUSD");
      const response = await fetchBTCPriceInUSD({});

      dispatch(setBitcoinPriceData(1.0/(response.data as number)));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBitcoinPrice();
  }, []);

  
  const isSmallScreen = window.innerWidth <= 800;


  if (isSmallScreen) {
    return (
    <div>
        
    {<SmallScreenLanding/>}

  </div>
    )
  };

  return (
      
    <div className={styles.App} style={{ backgroundColor: '#222222' }}>
      <div className={styles['dark-gray-background']}>
        <div className={styles['header']}>
          <div className={styles['header-container']}>
          <HeaderLeft/>
            <div className={styles['header-right']}>
            {<HeaderNav />}
            </div>
          </div>
        </div>
        </div>
        <div className={styles['darker-gray-background']} style={{ display: 'flex', justifyContent: 'center', 
        alignItems: 'center', flexDirection: 'column' }}>

<div className={styles['darker-gray-background']}>

  <div style={{    display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '100px',
      gap: '0px',
      marginBottom: '100px'
      }}>
        <div 
        style={{
    textAlign: 'center',
    fontSize: '35px',
    fontWeight: 'bold',
    color: 'white',
    height: '10vh',
  }}>Securities Policy</div>

<img src={tieIcon} style= {{

      width: '80px', height: 'auto'}}></img>
      </div>
      </div>
    <div
      style={{
        width: '85vw',
        padding: '20px',
        borderRadius: '10px',
        backgroundColor: '#222222',
        marginBottom: '30px',
      }}
    >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: 'white', margin: '12px', fontSize: '28px' }}>FinFriend Securities And Investments Policy</div>
            

          </div>

          
        </div>

        <div style={{color: 'white'}}>
                <div>


                FINFRIEND IS NOT A REGISTERED EXCHANGE UNDER THE SECURITIES EXCHANGE ACT OF 1934, OR A REGISTERED BROKER-DEALER UNDER THE SECURITIES EXCHANGE ACT OF 1934. 
                </div><br/><div>

                </div><br/><div>
FinFriend is not affiliated with any other cryptocurrency platforms, exchanges, or projects. FinFriend does not control or influence the price, supply, or demand of any tokens created on the platform.
</div><br/><div>


FinFriend only caters to individuals. We do not support businesses, investments or otherwise crypto-securities/quasi-securities as defined by the Howey test. 
</div><br/><div>


FinFriend generated Tokens are not used as a means to represent equity or debt. Tokens that qualify as “security” under Section 2(a)(1) of the Securities Act of 1933 (the “Securities Act”), 15 U.S.C. § 77b(a)(1), as amended are removed from the platform for violating FinFriend's Terms Of Service. FinFriend maintains the capability to shut down a token for the life of the token. 
</div><br/><div>

</div><br/><div>
FinFriend conducts regular audits of the tokens created on the platform to ensure that they comply with our Terms of Service and do not qualify as securities or quasi-securities under the applicable laws and regulations. FinFriend will promptly identify and remove any tokens that violate our standards or pose legal risks to us or our users.
</div><br/><div>

</div><br/><div>
Although we do our best to protect them, FinFriend users are made aware of the risks of using the platform in the Term Of Service and agree to indemnify, and hold FinFriend harmless the SEC alleging their Token is a security. The technology that FinFriend makes available to it's users, namely cryptocurrency creation and exchanging, is not unique and is available to everyone without FinFriend. 
</div><br/><div>

</div><br/><div>
FinFriend generated tokens are not traded on secondary markets.
</div><br/><div>


Contact Information

E-Mail finfriend@finfriend.com

www.finfriend.com



                </div>
            </div>
    </div>

  </div>
  <Footer />
    </div>
  );
}

export default SecuritiesPolicy;