import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface homeStarAnimationState {
    isAnimating: boolean;
  }
  
  const initialhomeStarAnimationState: homeStarAnimationState = {
    isAnimating: true,
  };
  
  
  export const animationControlSlice = createSlice({
    name: 'animationControlData',
    initialState: initialhomeStarAnimationState,
    reducers: {
      sethomeStarAnimationState: (state, action: PayloadAction<boolean>) => {
        state.isAnimating = action.payload;
      },
    },
  });

  
  export const { sethomeStarAnimationState } = animationControlSlice.actions;

  export default {
    animationControlData: animationControlSlice.reducer,
};