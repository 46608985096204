import { doc, getDoc } from 'firebase/firestore';
import { db } from '..';

export const getUserBalances = async (email: string, ticker: string): Promise<{ balanceAvailable: number; balanceOrder: number }> => {
  try {
    const cryptoDocRef = doc(db, 'cryptos', ticker);
    const docSnap = await getDoc(cryptoDocRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const balanceAvailableKey = email;
      const balanceOrderKey = `${email}ORDER`;

      const balanceAvailable = data?.[balanceAvailableKey] || 0.0;
      const balanceOrder = data?.[balanceOrderKey] || 0.0;

      return { balanceAvailable, balanceOrder };
    } else {
      throw new Error(`Ticker document '${ticker}' does not exist.`);
    }
  } catch (error) {
    console.error('Error retrieving user balances:', error);
    throw error;
  }
};