import React, { useState, useEffect } from 'react';
import styles from '../../App.module.scss';
import { db } from "../..";
import { doc, getDocs, collection, setDoc, addDoc, getDoc, query, where } from 'firebase/firestore';
import { useAppSelector } from '../../redux/hooks';
import { getAuth } from "firebase/auth";
import CreateCryptoModals from '../../components/CreateCryptoModals';
import { getStorage, ref, getDownloadURL, FirebaseStorage } from 'firebase/storage';
import { Link } from 'react-router-dom';
import closeButton from '../../assets/closeButton.png';
import { ListenForBitcoinDeposits } from '../../Helpers/ListenForBitcoinDeposits';
import { getUserBalances } from '../../Helpers/GetBalance';
import { functions } from "../..";
import { httpsCallable } from "firebase/functions";
import pieChartIcon from '../../assets/lightGrayIcons/pieChartIconLightGray.png';
import { formatDollarNumber } from '../../Helpers/FormatNumbers';

function AssetsDashboardTile() {

  const bitcoinPriceInDollars = useAppSelector((state) => state.bitcoinPriceData.value)
  const currentUserBitcoinAddress = useAppSelector((state) => state.userData.userData?.bitcoinDepositAddress)
  const currentUserBitcoinKey = useAppSelector((state) => state.userData.userData?.bitcoinKey)
  const currentUserUsername = useAppSelector((state) => state.userData.userData?.username)
  const currentUserCreationDate = useAppSelector((state) => state.userData.userData?.creationDate)
  console.log(`currentUserCreationDate ${currentUserCreationDate}:`);
  const [loading, setLoading] = useState(true);
  const [cryptoData, setCryptoData] = useState<Crypto[]>([]);
  const [totalPortfolioValue, setTotalPortfolioValue] = useState(0);
  const [selectedCrypto, setSelectedCrypto] = useState<Crypto | null>(null);
  const [selectedCryptoTotalBalance, setSelectedCryptoTotalBalance] = useState(0);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showWithdrawInfoModal, setShowWithdrawInfoModal] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showWithdrawConfirmationModal, setShowWithdrawConfirmationModal] = useState(false);
  const [showReceiptConfirmationModal, setShowReceiptConfirmationModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [withdrawAddress, setWithdrawAddress] = useState('');


  const storage: FirebaseStorage = getStorage();

  const auth = getAuth();
  const currentUserID = auth.currentUser?.uid
  const currentUserEmail = auth.currentUser?.email
  const currentUserEmailORDER: string = `${String(getAuth().currentUser?.email)}ORDER`;
  //const currentUserEmailORDER: string = `${String('domenicleonetti8@gmail.com')}ORDER`;
  //const currentUserID =  'XpeIfUQpL9hZUq5WixT3WfnzxDF2';
  //const currentUserEmail = 'domenicleonetti8@gmail.com'

  const [isDepositButtonHovered, setisDepositButtonHovered] = useState(false);
  const handleDepositEnter = () => {
    setisDepositButtonHovered(true);
  };
  const handleDepositLeave = () => {
    setisDepositButtonHovered(false);
  };

  const [confirmsendButtonActive, setconfirmSendButtonActive] = useState(false);

  const startSendButtonActivateTimer = () => {
    setTimeout(() => {
       setconfirmSendButtonActive(true);
    }, 2000);
 };

  class Crypto {
    name: string;
    ticker: string;
    price: number;
    iconUrl: string;
    isUsingCustomIcon: boolean;
    balanceAvailable: number;
    balanceOrder: number;
    OriginatorUID: string;
  
    constructor(
      name: string,
      ticker: string,
      price: number,
      iconUrl: string,
      isUsingCustomIcon: boolean,
      balanceAvailable: number,
      balanceOrder: number,
      OriginatorUID: string

    ) {
      this.name = name;
      this.ticker= ticker;
      this.price = price;
      this.iconUrl = iconUrl;
      this.isUsingCustomIcon = isUsingCustomIcon;
      this.balanceAvailable=balanceAvailable
      this.balanceOrder = balanceOrder;
      this.OriginatorUID = OriginatorUID;

    }
  }//
      
  useEffect(() => {
    const getCryptoData = async () => {
      const querySnapshot = await getDocs(collection(db, "cryptos"));
      const data = await Promise.all(querySnapshot.docs.map(async (doc) => {
        const { Ticker, isUsingCustomIcon, Name, Price, [currentUserEmail!]: balanceAvailable, [currentUserEmailORDER]: balanceOrder, OriginatorUID  } = doc.data();
        let iconUrl = `../../assets/CryptoIcon/${Name.charAt(0).toUpperCase()}.png`;
        if (isUsingCustomIcon) {
          const storageRef = ref(storage, `cryptoIcons/${Ticker}.png`);
          try {
            const url = await getDownloadURL(storageRef);
            iconUrl = url.toString();
          } catch (error) {
            console.log(`Error getting custom icon for ${Ticker}:`, error);
            iconUrl = `../../assets/CryptoIcon/X.png`;
          }
        } else {

            if (Name === 'Bitcoin') {
                if (currentUserBitcoinAddress && currentUserBitcoinKey && currentUserID && currentUserEmail) {
                ListenForBitcoinDeposits(
                    currentUserBitcoinAddress!,
                    currentUserBitcoinKey!,
                    currentUserID!,
                    balanceAvailable ? balanceAvailable : 0,
                    currentUserEmail!
                  );
                  
                iconUrl = `../../assets/CryptoIcons/BTC.png`;
                }
            }
            iconUrl = `../../assets/CryptoIcons/${Name.charAt(0).toUpperCase()}.png`;
        }

        //console.log(`Crypto name: ${Name}, icon URL: ${iconUrl}`);
  
        return new Crypto(
          Name,
          Ticker,
          Price, 
          iconUrl,
          isUsingCustomIcon,
          balanceAvailable,
          balanceOrder,
          OriginatorUID
        );
      }).filter((crypto) => crypto !== null)); // remove null values

      const filteredData = data.filter(crypto => (crypto.balanceAvailable || crypto.balanceOrder));
      setCryptoData(filteredData.filter(crypto => crypto !== null) as Crypto[]);

      
      //get the total value 
      let balanceTotal = 0;
      for (const crypto of filteredData) {
        if (crypto.name === 'Bitcoin') {
            if (crypto.balanceAvailable) {
                balanceTotal += ((crypto.balanceAvailable) * bitcoinPriceInDollars);
            } else {
              crypto.balanceAvailable = 0.0
            }
            if (crypto.balanceOrder) {
                balanceTotal += (( crypto.balanceOrder) * bitcoinPriceInDollars);
            } else {
              crypto.balanceOrder = 0.0
            }
        } else { 
            if (crypto.balanceAvailable) {
                balanceTotal += ((crypto.balanceAvailable) * crypto.price * bitcoinPriceInDollars);
            } else {
              crypto.balanceAvailable = 0.0
            }
            if (crypto.balanceOrder) {
                balanceTotal += ((crypto.balanceOrder) * crypto.price * bitcoinPriceInDollars);
            } else {
              crypto.balanceOrder = 0.0
            }
        }
      }

      setTotalPortfolioValue(balanceTotal);
      setLoading(false);
    };
  
    
    getCryptoData();
}, [currentUserEmail, currentUserEmailORDER, bitcoinPriceInDollars]);


const AssetsTotalText = () => {
    if (totalPortfolioValue) {
        const formattedValue = formatDollarNumber(totalPortfolioValue)
      return (
        <div>
          <span style={{ fontSize: '25px' }}>$</span>
          <span style={{ fontSize: '46px' }}>{formattedValue}</span>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const handleModalCloseClick = () => {
    setShowDepositModal(false);
    setShowWithdrawModal(false);
    setShowWithdrawInfoModal(false);
    setShowWithdrawConfirmationModal(false);
    setconfirmSendButtonActive(false);
    setShowReceiptConfirmationModal(false);
    setErrorMessage('');
    setWithdrawAddress('');
    setWithdrawAmount('');
   };

   const handleDepositSelection = (crypto: Crypto) => {
    // Function logic here
    setSelectedCrypto(crypto)

    if (crypto.balanceAvailable) {

        if (crypto.balanceOrder) {
            setSelectedCryptoTotalBalance(crypto.balanceOrder+crypto.balanceAvailable);
        } else {
            setSelectedCryptoTotalBalance(crypto.balanceAvailable);
        }
    } else {
        setSelectedCryptoTotalBalance(crypto.balanceOrder);
    }
    setShowDepositModal(true);

  };

  const handleDepositBitcoinButtonPressed = () => {
    // Function logic here
  
    let bitcoinFound = false; // Variable to track if Bitcoin ticker is found
  
    if (cryptoData) {
      for (const crypto of cryptoData) {
        const ticker = crypto.ticker;
        if (ticker === 'BTC') {
          // Perform additional actions or logic for Bitcoin ticker here
  
          setSelectedCrypto(crypto);
  
          if (crypto.balanceAvailable) {
            if (crypto.balanceOrder) {
              setSelectedCryptoTotalBalance(crypto.balanceOrder + crypto.balanceAvailable);
            } else {
              setSelectedCryptoTotalBalance(crypto.balanceAvailable);
            }
          } else {
            setSelectedCryptoTotalBalance(crypto.balanceOrder);
          }
  
          setShowDepositModal(true);
  
          bitcoinFound = true; // Set the flag to true since Bitcoin was found
          break; // Exit the loop since Bitcoin was found
        }
      }
    }
  
    // Perform logic if Bitcoin was not found
    if (!bitcoinFound) {
      
      const Bitcoin = new Crypto(
        'Bitcoin',
        'BTC',
        0, 
        '',
        false,
        0,
        0,
        'Satoshi'
      );
      setSelectedCryptoTotalBalance(0);
      setSelectedCrypto(Bitcoin);
      setShowDepositModal(true);
    }
  };

  const handleWithdrawSelection = (crypto: Crypto) => {
    // Function logic here
    setSelectedCrypto(crypto)

    if (crypto.balanceAvailable) {

        if (crypto.balanceOrder) {
            setSelectedCryptoTotalBalance(crypto.balanceOrder+crypto.balanceAvailable);
        } else {
            setSelectedCryptoTotalBalance(crypto.balanceAvailable);
        }
    } else {
        setSelectedCryptoTotalBalance(crypto.balanceOrder);
    }

    setShowWithdrawInfoModal(true);
  };


  const handleSendButtonPressed = async () => {
    const cleanedSendAddress = withdrawAddress.replace(/[ ]/g, '');
    const cleanedSendAmount = withdrawAmount.replace(/[, ]/g, '');
  
        if (cleanedSendAddress === '' || cleanedSendAmount === '') {
            setErrorMessage('Please fill in all fields');
        } else if (!/^\d*\.?\d*$/.test(cleanedSendAmount)) {
            setErrorMessage('Please only use numbers for the send amount');
        } else if (/[$()d]/.test(cleanedSendAmount)) {
            setErrorMessage('Please remove any special characters from send amount');
        } else if (selectedCrypto!.balanceAvailable < parseFloat(cleanedSendAmount)) {
            setErrorMessage('Insufficeint balance');
        } else if (parseFloat(cleanedSendAmount) < 0.000001) {
          setErrorMessage('Amount must be greater than 0.000001');
        } else {

          if (selectedCrypto!.ticker === 'BTC') {
            try {
              const cryptoDocRef = doc(db, 'cryptos', 'BTC');
              const docSnap = await getDoc(cryptoDocRef);
          
              if (docSnap.exists()) {
                const data = docSnap.data();
                const balanceAvailableData = currentUserEmail!;
          
                const balanceAvailable = data?.[balanceAvailableData] || 0.0;
          
                if (balanceAvailable < parseFloat(cleanedSendAmount)) {
                  setErrorMessage('Insufficient balance please reload page')
                } else {
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cleanedSendAddress)) {
                  if ((!/^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/.test(cleanedSendAddress) && (selectedCrypto!.ticker === 'BTC'))) {
                    setErrorMessage('Please enter a valid email or Bitcoin address');
                  } else {
                    setShowWithdrawModal(false);
                    setShowWithdrawConfirmationModal(true);
      
                    startSendButtonActivateTimer();
                  }
                } else {
                  setShowWithdrawModal(false);
                  setShowWithdrawConfirmationModal(true);
      
                  startSendButtonActivateTimer();
                }
              }
              } else {
                throw new Error(`Ticker document does not exist.`);
              }
            } catch {
              setErrorMessage('Netowork error please try again later');
            }
          } else {

          if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cleanedSendAddress)) {
            if ((!/^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/.test(cleanedSendAddress) && (selectedCrypto!.ticker === 'BTC'))) {
              setErrorMessage('Please enter a valid email or Bitcoin address');
            } else {
              setShowWithdrawModal(false);
              setShowWithdrawConfirmationModal(true);

              startSendButtonActivateTimer();
            }
          } else {
            setShowWithdrawModal(false);
            setShowWithdrawConfirmationModal(true);

            startSendButtonActivateTimer();
          }
          
          }
        }
  };

  const handleConfirmSendButtonPressed = async () => {

    if (confirmsendButtonActive) {
    const cleanedSendAddress = withdrawAddress.replace(/[, ]/g, '');
    const cleanedSendAmount = withdrawAmount.replace(/[, ]/g, '');

    //update balance
    selectedCrypto!.balanceAvailable = selectedCrypto!.balanceAvailable-parseFloat(cleanedSendAmount);

    if (selectedCrypto!.ticker === 'BTC') {

        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cleanedSendAddress)) {

          if (!/^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/.test(cleanedSendAddress)) {

            setErrorMessage('Please enter a valid email or Bitcoin address');
          } else {
            //sending bitcoin on chain
            
            const cryptoDocRef = doc(db, 'cryptos', selectedCrypto!.ticker);
            const updateBalance = setDoc(cryptoDocRef, {
              [currentUserEmail!]: parseFloat((selectedCrypto!.balanceAvailable).toFixed(8))
            }, { merge: true });

            const withdrawCollectionRef = collection(db, 'withdrawls');
            const updateWithdrawls = addDoc(withdrawCollectionRef, {
              Withdrawer: currentUserID,
              WithdrawerEmail: currentUserEmail,
              SendToAddress: cleanedSendAddress,
              Amount: parseFloat(parseFloat(cleanedSendAmount).toFixed(8)),
              fullfilled: false,
              date: new Date().toISOString(),
            });
        
            await Promise.all([updateBalance, updateWithdrawls]);

            saveTransaction(parseFloat(cleanedSendAmount), cleanedSendAddress, selectedCrypto!.ticker, 'userSentBitcoinOnChain', 'PENDING');

            setShowReceiptConfirmationModal(true);
          }
        } else {
            //sending bitcoin by email

            await updateBalances(cleanedSendAddress, 'BTC', parseFloat(cleanedSendAmount));
            sendEmail(selectedCrypto!.name, cleanedSendAddress);
            const message = `${currentUserUsername} sent you ${selectedCrypto!.name}`;
            saveNotification(cleanedSendAddress, message);
            saveTransaction(parseFloat(cleanedSendAmount), cleanedSendAddress, selectedCrypto!.ticker);
            setShowReceiptConfirmationModal(true);
        };

    } else {

        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cleanedSendAddress)) {
            setErrorMessage('Please enter a valid email address');
        } else {
            //sending finfriend crypto

            await updateBalances(cleanedSendAddress, selectedCrypto!.ticker, parseFloat(cleanedSendAmount));
            sendEmail(selectedCrypto!.name, cleanedSendAddress);
            const message = `${currentUserUsername} sent you ${selectedCrypto!.name}`;
            saveNotification(cleanedSendAddress, message);
            saveTransaction(parseFloat(cleanedSendAmount), cleanedSendAddress, selectedCrypto!.ticker)
            setShowReceiptConfirmationModal(true);
        };
    };

    
  }
  };

  const sendEmail = async (cryptoName: string, email: string) => {

    const requestData = {
        emailAddress: email,
        cryptoName: cryptoName,
      };
    
      try {
        const sendEmailFn = httpsCallable(functions, 'sendEmail');
        const result = await sendEmailFn(requestData);
        console.log('Sending email', result);
      } catch (error) {
        console.error('Error calling sendEmail:', error);
      }
  };

  const updateBalances = async (receiverEmail: string, ticker: string, sendAmount: number) => {

    const receiverBalancesPromise = getUserBalances(receiverEmail, ticker);
    const [receiverBalances] = await Promise.all([receiverBalancesPromise]);
    const updatedReceiverBalance = receiverBalances.balanceAvailable+Number(sendAmount);
    const updatedSenderBalance = Number(selectedCrypto!.balanceAvailable);
    const cryptoDocRef = doc(db, 'cryptos', ticker);
    const updateBalance = setDoc(cryptoDocRef, {
      [receiverEmail]: updatedReceiverBalance,
      [currentUserEmail!]: updatedSenderBalance 
    }, { merge: true });

    await Promise.all([updateBalance]);
  };

  const saveNotification = async (receiverEmail: string, message: string) => {

    const usersCollectionRef = collection(db, 'users');
    const querySnapshot = await getDocs(query(usersCollectionRef, where('Email', '==', receiverEmail)));

    if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const receiverId = userDoc.id;

        const currentDate = new Date();
        const formatter = new Intl.DateTimeFormat('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        });
        const notificationDate = formatter.format(currentDate);
      
        const notificationCollectionRef = collection(db, 'notifications');
        const updateNotifications = addDoc(notificationCollectionRef, {
          userID: receiverId,
          notificationDate: notificationDate,
          Message: message,
          Read: false
        });
      
        const userDocRef = doc(db, 'users', receiverId);
        const updateUnread = await setDoc(userDocRef, {
          HasUnreadNotification: false
        }, { merge: true });

        await Promise.all([updateUnread, updateNotifications]);
    }
  
  };

  const saveTransaction = async (amount: number, receiverEmail: string, ticker: string, transctionType = 'userSent', transctionStatus = 'COMPLETE') => {
    try {
    const collectionRef = collection(db, 'transactions');
    const updateUserData = addDoc(collectionRef, {
        Type: transctionType,
        Sender: currentUserID,
        TransactionTime: new Date().toISOString(),
        ReceiverEmail: receiverEmail,
        SenderEmail: currentUserEmail,
        AssetSent: ticker,
        AmountSent: amount,
        PlatformUsed: 'WEB', 
        Status: transctionStatus
    });

    await Promise.all([updateUserData]);
    } catch {
        console.log('Error updating transactions')
    }
}

  const handleWithdrawInfoContinuePressed = () => {
    setShowWithdrawInfoModal(false);
    setShowWithdrawModal(true);
  }

  const handleWithdrawAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWithdrawAddress(event.target.value);
  };

  const handleWithdrawAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWithdrawAmount(event.target.value);
  };

  const DepositTipText = (name: string) => {
    if (name === 'Bitcoin') {
      return (
        <div>
            Carefully send Bitcoin to this address: 
        </div>
      );
    } else       
        return (
        <div>
            Your email address is your {name} address: 
        </div>
      );
  };

  const WithdrawTipText = (name: string) => {
    if (name === 'Bitcoin') {
      return (
        <div style={{ margin: '5px'}}>
            Email or Bitcoin address:
        </div>
      );
    } else       
        return (
        <div>
            Email address:
        </div>
      );
  };

  const maxWithdrawButtonClicked = () => {
    setWithdrawAmount((selectedCrypto!.balanceAvailable).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 8,
  }).replace(/\.?0+$/, ''));
  };

  const CopyToClipboardButton = ({ text }: { text: string }) => {
    const [copied, setCopied] = useState(false);
  
    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(text);
        setCopied(true);
      } catch (error) {
        console.error('Failed to copy text:', error);
      }
    };
  
    return (
      <div>
        <span style={{ fontSize: '15px', border: '1px solid #777777', borderRadius: '5px', 
        paddingLeft: '5px', paddingRight: '5px', paddingBottom: '3px' }}>{text}</span>
        <button onClick={copyToClipboard} style={{ fontWeight: 'bold', color: '#222222', 
                borderRadius: '10px', border: 'none', cursor: 'pointer', width: '65px' }}>
          {copied ? 'Copied!' : 'Copy'}
        </button>
      </div>
    );
  };

  const DepositAddressText = (name: string) => {
    if (name === 'Bitcoin' && currentUserBitcoinAddress) {
      return (
        <div>
          <CopyToClipboardButton text={currentUserBitcoinAddress} />
        </div>
      );
    } else {
      return (
        <div>
        <div>
          <CopyToClipboardButton text={currentUserEmail!} />
        </div>
        </div>
      );
    }
  };


  const iconImage = (name: string, isUsingCustomIcon: boolean, customIconLink: string) => {
    const firstChar = name.charAt(0);
    if (/^[^A-Za-z]+$/.test(firstChar)) {
      return (
        <div>
          <img
            style={{width: '65px', height: '65px', borderRadius: '50%'}}
            src={
              isUsingCustomIcon
                ? customIconLink
                : require(`../../assets/CryptoIcons/X.png`)
            }
            alt={` `}
          />
        </div>
      );
    } else {
        return (
          <img
            style={{width: '65px', height: '65px', borderRadius: '50%'}}
            src={
              isUsingCustomIcon
              ? customIconLink
              : name === 'Bitcoin'
              ? require(`../../assets/CryptoIcons/BTC.png`)
              : require(`../../assets/CryptoIcons/${name.charAt(0).toUpperCase()}.png`)
            }
            alt={` `}
          />
      );
    }
  };

  const errorLabelText = () => {
    if (errorMessage) {
      return `${errorMessage}`; //to do: add a deposit button when 0
    } else {
      return <div style={{ visibility: 'hidden' }}>$</div>;
    }
  };

  const withdrawInfoModalText = () => {
    if (selectedCrypto?.name === 'Bitcoin') {
      return (
        <div>
      <div style={{ width: '305px', textAlign: 'center', }}>Bitcoin sent with email is processed instantly and
      they get an alert.</div>
      <br/>
      <br/>
      <div style={{ width: '305px', textAlign: 'center', }}>Bitcoin sent on chain processes in 3 business days.</div>
      </div>)
    } else {
      return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '18px'}}>
      <div style={{ width: '305px', textAlign: 'center', }}>Just use email!</div>
      <br/>
      <div style={{ width: '255px', textAlign: 'center', }}>Once sent, they will
      instantly get the message.</div>
      <br/>
      <div style={{ width: '255px', textAlign: 'center', }}>They do not
      even need to be a FinFriend user yet.</div>
      </div>)
    }
  };

    return (
        <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: 'white', margin: '4px', fontSize: '26px' }}>Balances</div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          
          <>
            <CreateCryptoModals buttonType={'createNewCrypto'} buttonTextColor={'#222222'} buttonBackgroundColor={'#FFA14A'}/>
          </>
            
          <div>
              <button
                style={{
                  border: isDepositButtonHovered ? '2px solid #FFA14A' : '2px solid #FFA14A',
                  backgroundColor: isDepositButtonHovered ? '#222222' : '#FFA14A', //#EC770F
                  color: isDepositButtonHovered ? '#FFA14A' : '#222222',
                  display: 'inline-block',
                  padding: '10px 20px',
                  fontSize: '18px',
                  textAlign: 'center',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  marginLeft: '30px',
                  width: '185px',
                  cursor: 'pointer',
                }}

                onClick={handleDepositBitcoinButtonPressed}
                onMouseEnter={handleDepositEnter}
                onMouseLeave={handleDepositLeave}
              >
                Deposit Bitcoin
              </button>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '12px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: 'white' }}>
                {AssetsTotalText()}
              &nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </div>

        {loading ? (
        <p></p> // Display loading indicator or animation
        ) : (
        <div>
            {cryptoData && bitcoinPriceInDollars && cryptoData.length > 0 ? (
            cryptoData.map((crypto, index) => (
                <div className={styles['asset-crypto-list-container']}>

                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px'}}>
                    <div>
                    {iconImage(crypto.name, crypto.isUsingCustomIcon, crypto.iconUrl)}
                    </div>
                    <div style={{width: '140px', fontSize: '22px'}}>
                      {crypto.name}
                      </div>
</div>
                    
                <div className={styles['crypto-property']}>

<div>
                            <div style={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '2px', color: 'white' }}>
                                {isNaN(crypto.balanceAvailable+crypto.balanceOrder) ? '-' : ((crypto.balanceAvailable+crypto.balanceOrder).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 8,
                            }).replace(/\.?0+$/, ''))}</div>
                            <div style={{ marginTop: '5px', color: 'white' }}>{isNaN(crypto.balanceAvailable+crypto.balanceOrder) ? '-' : `$${((crypto.balanceAvailable+crypto.balanceOrder) * crypto.price * bitcoinPriceInDollars).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            }).replace(/\.?0+$/, '')}`}</div>
                        
                    </div>

                </div>

                
                  <p className={styles['crypto-property']}></p>
                  

                  
                  <div>

                    <div style={{display: 'flex', flexDirection: 'row', maxWidth: '180px', 
                    marginRight: '16vw', gap: '2vw'}}>

                      {crypto.OriginatorUID === currentUserID ? (
                        <Link to={`/explorer/${crypto.ticker}`} style={{ textDecoration: 'none' }}>
                          <button 
                            style={{ 
                              color: '#222222', 
                              borderRadius: '7px', 
                              border: 'none', 
                              cursor: 'pointer', 
                              fontWeight: 'bold', 
                              fontSize: '17px', 
                              background: 'linear-gradient(to right, #FFB3BA, #B9E2F5, #B2CCD6, #F5EFA7, #ABEBC6)', // Pastel rainbow gradient
                              padding: '10px 20px' // Adjust padding for better appearance
                            }}
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            Explore
                          </button>
                        </Link>

                      ) : (

                        <button 
                        style={{ color: '#222222', borderRadius: '7px', border: 'none', fontWeight: 'bold', 
                        fontSize: '17px', padding: '10px 20px',
                        backgroundColor: '#999999', opacity: 0 }}
                        >
                        Explore
                      </button>

                      )}

                      {crypto.ticker === 'BTC' ? (
                        <button 
                          style={{ color: '#222222', borderRadius: '7px', border: 'none', fontWeight: 'bold', 
                          fontSize: '17px', padding: '10px 20px',
                          backgroundColor: '#999999', opacity: 0 }}
                          >
                          Trade
                        </button>
                      ) : (

                      <Link to={`/trade/${crypto.ticker}`} style={{ textDecoration: 'none' }}>
                        <button 
                          style={{ color: '#222222', borderRadius: '7px', border: 'none', 
                          cursor: 'pointer', fontWeight: 'bold', fontSize: '17px', 
                          backgroundColor: '#999999', padding: '10px 20px', }}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          Trade
                        </button>
                      </Link>
                      )}

                <button style={{ color: 'white', 
                borderRadius: '7px', border: 'none', cursor: 'pointer', 
                fontWeight: 'bold', fontSize: '17px', backgroundColor: '#222222' }} onClick={(event) => handleDepositSelection(crypto)}>
                            Receive
                </button>

                <button style={{ color: 'white', 
                borderRadius: '7px', border: 'none', cursor: 'pointer', 
                fontWeight: 'bold', fontSize: '17px', backgroundColor: '#222222' }} onClick={(event) => handleWithdrawSelection(crypto)}>
                            Send
                </button>

                </div>
                </div>
                </div>
            ))
            ) : (
            <div style={{ color: 'white', margin: '20px', fontSize: '45px', textAlign: 'center', maxWidth: '500px' }}>
              
              <img src={pieChartIcon} alt="Close" style={{width: '84px', height: '84px'}}></img>
              </div> // Display a message when no crypto data is available


  
            )}
        </div>
        )}

        {showDepositModal && (  
          
        <div>
        {currentUserCreationDate ? (
              <div className={styles['modal-container']}>
              <div className={styles['overlay']} onClick={handleModalCloseClick}></div>
              <div className={styles['crypto-modal']}>
                <div className={styles['crypto-modal-header']}>
                  <h3>Deposit {selectedCrypto?.name} ({selectedCrypto?.ticker})</h3>
                  <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={handleModalCloseClick} style={{width: '24px', height: '24px'}}></img>
  
                </div>
  
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
  
                  <img
                      style={{ borderRadius: '50%', width:'70px', height:'70px', justifyContent: 'center', alignItems: 'center' }}
                      src={
                      selectedCrypto?.isUsingCustomIcon
                          ? selectedCrypto?.iconUrl
                          : selectedCrypto?.name === 'Bitcoin'
                          ? require(`../../assets/CryptoIcons/BTC.png`)
                          : require(`../../assets/CryptoIcons/${selectedCrypto?.name.charAt(0).toUpperCase()}.png`)
                      }
                      alt={`${selectedCrypto?.name}`}
                      />
                  <div>Balance </div>
                  <div style={{fontWeight: 'bold', fontSize: '22px'}}>{selectedCryptoTotalBalance.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 8,
                              }).replace(/\.?0+$/, '')}</div>
                  <div>${(selectedCryptoTotalBalance*selectedCrypto!.price*bitcoinPriceInDollars).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                              }).replace(/\.?0+$/, '')}</div>
  
                  <p></p>
                  {DepositTipText(selectedCrypto!.name)}
                  <br/>
                  {DepositAddressText((selectedCrypto!.name))} 
                  <p></p>
                </div>
  
                
                <div className={styles['crypto-modal-footer']}>
                  <button className={styles['crypto-modal-continue-button']} onClick={handleModalCloseClick}>Done</button>
                </div>
              </div>
              </div>
            ) : (

              <div className={styles['modal-container']}>
              <div className={styles['overlay']} onClick={handleModalCloseClick}></div>
              <div className={styles['crypto-modal']}>
                <div className={styles['crypto-modal-header']}>
                  <h3>Deposit {selectedCrypto?.name} ({selectedCrypto?.ticker})</h3>
                  <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={handleModalCloseClick} style={{width: '24px', height: '24px'}}></img>
  
                </div>
  
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
  
                  <img
                      style={{ borderRadius: '50%', width:'100px', height:'100px', justifyContent: 'center', alignItems: 'center' }}
                      src={
                      selectedCrypto?.isUsingCustomIcon
                          ? selectedCrypto?.iconUrl
                          : selectedCrypto?.name === 'Bitcoin'
                          ? require(`../../assets/CryptoIcons/BTC.png`)
                          : require(`../../assets/CryptoIcons/${selectedCrypto?.name.charAt(0).toUpperCase()}.png`)
                      }
                      alt={`${selectedCrypto?.name}`}
                      />
                  <div>Balance </div>
                  <div style={{fontWeight: 'bold', fontSize: '22px'}}>{selectedCryptoTotalBalance.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 8,
                              }).replace(/\.?0+$/, '')}</div>
                  <div>${(selectedCryptoTotalBalance*selectedCrypto!.price*bitcoinPriceInDollars).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                              }).replace(/\.?0+$/, '')}</div>
  
                  <p style={{ color: 'white', textAlign: 'center'}}>You must finish verifying your account to view your deposit address</p>
                  
                  <br/>
                  <p></p>
                </div>
                
                <div className={styles['crypto-modal-footer']}>
                  <button className={styles['crypto-modal-continue-button']} onClick={handleModalCloseClick}>Done</button>
                </div>
              </div>
              </div>


              )}
          </div>
        )}


        {showWithdrawInfoModal && (
          <div className={styles['modal-container']}>
            <div className={styles['overlay']} onClick={handleModalCloseClick}></div>
            <div className={styles['crypto-modal']}>
              <div className={styles['crypto-modal-header']}>
                <h3>Sending {(selectedCrypto?.name)} On FinFriend</h3>
                <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={handleModalCloseClick} style={{width: '24px', height: '24px'}}></img>

              </div>

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                {withdrawInfoModalText()}
                <p></p>
              </div>
              <div className={styles['crypto-modal-footer']} style={{ textAlign: 'center'}}>

                <button className={styles['crypto-modal-continue-button']} onClick={handleWithdrawInfoContinuePressed}>Continue</button>
              </div>
            </div>
          </div>
        )}


    {showWithdrawModal && (
          <div className={styles['modal-container']}>
            <div className={styles['overlay']} onClick={handleModalCloseClick}></div>
            <div className={styles['crypto-modal']} style={{height: '70vh'}}>
              <div className={styles['crypto-modal-header']}>
                <h3>Withdraw {selectedCrypto?.name} ({selectedCrypto?.ticker})</h3>
                <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={handleModalCloseClick} style={{width: '24px', height: '24px'}}></img>

              </div>

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                <img
                    style={{ borderRadius: '50%', width:'50px', height:'50px', justifyContent: 'center', alignItems: 'center' }}
                    src={
                    selectedCrypto?.isUsingCustomIcon
                        ? selectedCrypto?.iconUrl
                        : selectedCrypto?.name === 'Bitcoin'
                        ? require(`../../assets/CryptoIcons/BTC.png`)
                        : require(`../../assets/CryptoIcons/${selectedCrypto?.name.charAt(0).toUpperCase()}.png`)
                    }
                    alt={`${selectedCrypto?.name}`}
                    />
                
                <div>Balance Available</div>
                <div style={{fontWeight: 'bold', fontSize: '22px'}}>{selectedCrypto?.balanceAvailable.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 8,
                            }).replace(/\.?0+$/, '')}</div>
                <div>${(selectedCrypto!.balanceAvailable*selectedCrypto!.price*bitcoinPriceInDollars).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            }).replace(/\.?0+$/, '')}</div>

                <div className={styles['crypto-modal-textfields']} style= {{marginTop: '30px'}}>
                  <div className={styles['crypto-modal-textfield']}>
                  {WithdrawTipText(selectedCrypto!.name)}
                      <input id="withdraw-address" type="text" value={withdrawAddress} onChange={handleWithdrawAddressChange} />
                  </div>
                  <div style={{display: 'flex'}}>
                  <div className={styles['crypto-modal-textfield']}>
                      <label htmlFor="withdraw-amount">Amount:</label>

                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <input style={{  width: '240px',
                      padding: '3px',
                      fontSize: '22px',
                      marginTop: '4px',
                      backgroundColor: '#222222',
                      color: 'white',
                      border: '1px solid lightgray',
                      borderRadius: '5px'}}id="withdraw-amount" type="text" value={withdrawAmount} onChange={handleWithdrawAmountChange} />

                    <div style={{paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px', color: '#222222', 
                  fontWeight: 'bold', backgroundColor: 'white', cursor: 'pointer', 
                  marginLeft: '10px', borderRadius: '5px', height: '25px'}}
                  onClick={maxWithdrawButtonClicked}>Max</div>
                  </div>
                  </div>

                  </div>


                </div>

                <p></p>
              </div>
              <div className={styles['crypto-modal-footer']} style={{ textAlign: 'center'}}>
              <label style={{    
                display: 'flex',
                color: '#FF3B30',
                textAlign: 'center',
                wordWrap: 'break-word',
                maxWidth: '250px',
                fontSize: '18px', 
                marginBottom: '5px'}}
                >{errorLabelText()}</label>

                <button onClick={handleSendButtonPressed}
                style={{
                    backgroundColor: 'white',
                    color: '#222222',
                    display: 'inline-block',
                    padding: '10px 20px',
                    fontSize: '20px',
                    marginTop: '5px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    borderRadius: '10px',
                    border: '1px solid white',
                    cursor: 'pointer',
                  }}
                >Send</button>
              </div>
            </div>
          </div>
        )}

        {showWithdrawConfirmationModal && (
          <div className={styles['modal-container']}>
            <div className={styles['overlay']} onClick={handleModalCloseClick}></div>
            <div className={styles['crypto-modal']}>
              <div className={styles['crypto-modal-header']}>
                <h3>Confirm This Transaction</h3>
                <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={handleModalCloseClick} style={{width: '24px', height: '24px'}}></img>

              </div>

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

              <div>
                <div style={{ width: '305px', textAlign: 'center', }}>Send {withdrawAmount} {selectedCrypto?.name} to</div>
                <br/>
                <div style={{ width: '305px', textAlign: 'center', wordWrap: 'break-word' }}>{withdrawAddress}</div>
                <br/>
              </div>

              </div>
              <div className={styles['crypto-modal-footer']} style={{ textAlign: 'center'}}>

              <button onClick={handleConfirmSendButtonPressed}
                style={{
                  backgroundColor: confirmsendButtonActive ? '#FF3B30' : '#460000',
                  color: confirmsendButtonActive ? '#222222' : '#000000',
                  border: confirmsendButtonActive ? '1px solid #FF3B30' : '1px solid #460000',
                    display: 'inline-block',
                    padding: '10px 20px',
                    fontSize: '20px',
                    marginTop: '5px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    borderRadius: '10px',
                    cursor: 'pointer',
                  }}
                >Send</button>
              </div>
            </div>
          </div>
        )}


{showReceiptConfirmationModal && (
          <div className={styles['modal-container']}>
            <div className={styles['overlay']} onClick={handleModalCloseClick}></div>
            <div className={styles['crypto-modal']}>
              <div className={styles['crypto-modal-header']}>
                <h3 style={{fontSize: '30px'}}>Done!</h3>
                <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={handleModalCloseClick} style={{width: '24px', height: '24px'}}></img>

              </div>

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

              {selectedCrypto?.name == 'Bitcoin' ? (
                <>
                <div>
                <div style={{ width: '305px', textAlign: 'center', }}>{withdrawAmount} {selectedCrypto?.name} has been sent to</div>
                <div style={{ width: '305px', textAlign: 'center', wordWrap: 'break-word' }}>{withdrawAddress}</div>
                <br/>
                <div style={{ width: '305px', textAlign: 'center', wordWrap: 'break-word' }}>On chain transactions complete within 3 business days</div>
              </div>

                </>
              ) : (
                <>
                <div>
                <div style={{ width: '305px', textAlign: 'center', }}>{withdrawAmount} {selectedCrypto?.name} has been sent to</div>
                <br/>
                <div style={{ width: '305px', textAlign: 'center', wordWrap: 'break-word' }}>{withdrawAddress}</div>
                <br/>
              </div>
                </>
              )}


              </div>
              <div className={styles['crypto-modal-footer']} style={{ textAlign: 'center'}}>
              </div>
            </div>
          </div>
        )}

    </div>
);
}

export default AssetsDashboardTile;