export function getPSTDate() {
  const date = new Date();

  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: 'numeric', 
    minute: '2-digit', 
    second: '2-digit', 
    hour12: true,
    timeZone: 'America/Los_Angeles'
  }).format(date);

  // Split the string at the commas
  const parts = formattedDate.split(',');

  // Replace the second comma with ' at'
  if (parts.length > 1) {
    parts[1] = ' at' + parts[1];
  }

  // Rejoin the string
  return parts.join(',');
}