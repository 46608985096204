import React, { useState, useEffect, useContext } from 'react';
import styles from '../App.module.scss';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import closeButton from '../../assets/closeButton.png';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setBitcoinPriceData } from '../redux/ephemeralBitcoinPrice';
import HeaderLeft from '../components/headerLeft';
import { useParams, useNavigate } from 'react-router-dom';
import SmallScreenLanding from '../components/SmallScreenLanding';

function LogInToVerifyEmail() {

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [currentUserEmail, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordResetSent, setPasswordResetSent] = useState(false);

  const { email } = useParams();

  useEffect(() => {
    if (email && email !== '') {
      setEmail(email);
    }
  }, [email]);

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    };

    const [errorMessage, setErrorMessage] = useState('');

    const removeWhitespace = (str: string) => {
      return str.replace(/\s/g, '');
    };

    const handleSignInSubmitButtonClick = async () => {
      const cleanedEmail = removeWhitespace(currentUserEmail)
      const cleanedPassword = removeWhitespace(password)
      if (cleanedEmail === '' || password === '') {
        setErrorMessage('Please fill in all fields');
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cleanedEmail)) {
          setErrorMessage('Please enter a valid email address');
      } else {
        signInWithEmailAndPassword(getAuth(), cleanedEmail, cleanedPassword)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          setErrorMessage('')
          navigate('/')
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          setErrorMessage('Error: invalid log in');
        });
      }
    };

    const handleResetClick = () => {
      const cleanedEmail = removeWhitespace(currentUserEmail)

      if (cleanedEmail === '') {
        setErrorMessage('Please fill in the email field');
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cleanedEmail)) {
          setErrorMessage('Please enter a valid email address for your reset link');
      } else if (passwordResetSent === true) {
        setErrorMessage('We already sent you a reset link please check your spam')
      } else {
        sendPasswordResetLink();
        setPasswordResetSent(true);
        setErrorMessage('');
      }
      
    };

    async function sendPasswordResetLink() {
      const auth = getAuth();
      
      try {
        await sendPasswordResetEmail(auth, currentUserEmail);
        console.log('Password reset email sent successfully!');
        // Additional logic after sending the email
      } catch (error) {
        console.error('Error sending password reset email:', error);
        // Handle error
      }
    }

  
  const isSmallScreen = window.innerWidth <= 800;


  if (isSmallScreen) {
    return (
    <div>
        
    {<SmallScreenLanding/>}

  </div>
    )
  };

  return (
      
    <div className={styles.App} style={{ backgroundColor: '#222222' }}>
      <div className={styles['dark-gray-background']}>
        <div className={styles['header']}>
          <div className={styles['header-container']}>
          <HeaderLeft/>

          </div>
        </div>
        </div>
      <div className={styles['darker-gray-background']}>
      (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '90vh',
          color: 'white',
        }}
      >
        
        <div>
            <div style={{fontSize: '35px', marginTop: '-50px', paddingBottom: '50px'}}>
          Maybe the beginning of a FinFriendship...
          </div>


          <div className={styles['crypto-modal-content']}>
                    <div className={styles['sign-in-page-textfield']}>
                        <label htmlFor="email">Email</label>
                        <input id="email" type="text" value={currentUserEmail} onChange={handleEmailChange} />
                    </div>
                    <div className={styles['sign-in-page-textfield']}>
                        <label htmlFor="password">Password</label>
                        <input id="password" type="password" value={password} onChange={handlePasswordChange} />
                    </div>

                  <div
                    onClick={handleResetClick}
                    style={{
                      textAlign: 'center',
                      marginRight: '10px',
                      marginTop: '30px',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      fontSize: '17px'
                    }}
                  >
                    {passwordResetSent
                      ? 'Sent please cheack your spam'
                      : 'Send me a password reset link'}
                  </div>

                </div>
                <div className={styles['crypto-modal-footer']}>
                <div className={styles['crypto-modal-footer']} style={{ display: 'flex', 
                flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                  <label className={styles['error-label']} style={{ textAlign: 'center', marginRight: '10px', marginTop: '20px' }}>{errorMessage}</label>
                  <div style={{ marginTop: errorMessage ? '20px' : '40px'}}>
                  <button className={styles['crypto-modal-continue-button']} onClick={handleSignInSubmitButtonClick}>Log In</button>
                  </div>
                </div>
                </div>


        </div>
                </div>

      </div>
    );
      </div>
  );
  }

  export default LogInToVerifyEmail;
