
import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import LogInModal from './LogInModal';
import SignUpModal from './SignUpModal';
import SignUp2Modal from './SignUp2Modal';
import SignUp3Modal from './SignUp3Modal';
import SignUp4Modal from './SignUp4Modal';
import VerifyEmailModal from './VerifyEmail';
import VerifyPhoneModal from './VerifyPhone';
import styles from '../../App.module.scss';
import { currentUserData } from '../CurrentUserInfo';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setUserData, clearUserData } from '../../redux/ephemeralUserData';
import { useNavigate } from 'react-router-dom';


interface HeaderProps {
  isHomePage?: boolean; // Now this prop is optional
  // Add any additional props here
}

const HeaderNav: React.FC<HeaderProps> = ({ isHomePage = false }) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [loadingUserDataComplete, setLoadingUserDataComplete] = useState(false);

    const [uid, setUid] = useState<string>('');
    let creationDate = useAppSelector((state) => state.userData.userData?.creationDate);
    let phoneVerified = useAppSelector((state) => state.userData.userData?.phoneVerified);
    let firstName = useAppSelector((state) => state.userData.userData?.firstName);
    let streetAddress = useAppSelector((state) => state.userData.userData?.address);

    const [email, setEmail] = useState('');
    const [emailVerified, setEmailVerified] = useState(false);

    const [showMenu, setShowMenu] = useState(false);
    const [hoveredItem, setHoveredItem] = useState('');

    const handleMouseEnter = () => {
      setShowMenu(true);
    };
  
    const handleMouseLeave = () => {
      setShowMenu(false);
    };

    const handleItemHover = (item: string) => {
      setHoveredItem(item);
    };

    
    const auth = getAuth();

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          const currentUserID = user.uid;
          setUid(currentUserID);
          setEmail(user.email || '');
          setEmailVerified(user.emailVerified);
          getUserData(currentUserID);
          
        } else {
          // User is not signed in
          setUid('');
        }
      });
  
      return () => {
        // Cleanup the event listener when the component unmounts
        unsubscribe();
      };
    }, [auth]);


    //check if the user is done creating an account
    const db = getFirestore();

    const getUserData = async (uid: string) => {
        try {
          const usersCollectionRef = collection(db, 'users');
          const q = query(usersCollectionRef, where('uid', '==', uid));
          const querySnapshot = await getDocs(q);
      
          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const data = userDoc.data();

            let currentUserKey = '';
            const keyData = data.DepositKey

            try {
                if (keyData && keyData.bn) {
                  currentUserKey = keyData.bn;
                }
              } catch {
                currentUserKey = '';
              }

            const newCurrentUserData: currentUserData = {
              testUser: data.TestUser,
              userName: data.Username,
              bitcoinDepositAddress: data.DepositAddress,
              email: data.Email,
              bitcoinKey: currentUserKey,
              CryptosOriginated: data.CryptosOriginated,
              CryptosAlloted: data.CryptosAlloted,
              hasUnreadNotifications: data.HasUnreadNotification,
              phoneNumber: data.PhoneNumber,
              firstName: data.FirstName,
              address: data.streetAddress,
              phoneVerified: data.PhoneVerified,
              creationDate: data.CreationDate,
              username: data.Username,
            };

            creationDate = data.CreationDate;
            firstName = data.FirstName;
            phoneVerified = data.PhoneVerified;
            streetAddress = data.streetAddress;
            setLoadingUserDataComplete(true);
            dispatch(setUserData(newCurrentUserData));
          }
        } catch (error) {
          console.error('Error retrieving user document:', error);
        }
      };
      
        
    const handleLogOutClick = () => {
      signOut(auth)
      .then(() => {
        // Sign-out successful.
        dispatch(clearUserData()); 
      })
      .catch((error) => {
        // An error happened.
        console.error(error);
      });
    };

    const handleDashboardClicked = () => {
        navigate(`/dashboard`);
        window.scrollTo(0, 0); // Reset scroll position to the top of the page
      }

      const handleNotificationsClicked = () => {
        navigate(`/notifications`);
        window.scrollTo(0, 0); // Reset scroll position to the top of the page
      }

      if (loadingUserDataComplete) {
    return (
        <div>
        
        <div className={styles['header-right']}>

        {uid ? (
                <div
                  style={{ color: 'white', fontSize: '22px', position: 'relative', }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {email}
                  {showMenu && (
                    <div style={{ position: 'absolute', top: '100%', left: 0, padding:'30px', backgroundColor: '#222222', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)'}}>

                      <div style={{ color: 'white', cursor: 'pointer', 
                      marginBottom: '10px', backgroundColor: hoveredItem === 'Balances' ? '#333333' : 'transparent', 
                      fontSize: '22px', padding: '10px', borderRadius: '8px', }}
                      onMouseEnter={() => handleItemHover('Balances')}
                      onMouseLeave={() => handleItemHover('')}
                      onClick={handleDashboardClicked}
                      >Dashboard</div>

                      <div style={{ color: 'white', cursor: 'pointer', 
                      marginBottom: '10px', backgroundColor: hoveredItem === 'Notifications' ? '#333333' : 'transparent', 
                      fontSize: '22px', padding: '10px', borderRadius: '8px', }}
                      onMouseEnter={() => handleItemHover('Notifications')}
                      onMouseLeave={() => handleItemHover('')}
                      onClick={handleNotificationsClicked}
                      >Notifications</div>

                      <div onClick={handleLogOutClick} style={{ color: 'red', cursor: 'pointer', 
                      marginBottom: '10px', backgroundColor: hoveredItem === 'Log Out' ? '#333333' : 'transparent', 
                      fontSize: '22px', padding: '10px', borderRadius: '8px', }}
                      onMouseEnter={() => handleItemHover('Log Out')}
                      onMouseLeave={() => handleItemHover('')}
                      >Log Out</div>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <LogInModal buttonType={'headerNav'}/>
                </>
              )}

                {uid ? (
                emailVerified ? (
                    phoneVerified ? (
                    firstName ? (
                        streetAddress ? (

                          <></> // Render something when all conditions are truthy
                          /*
                        creationDate ? (
                            <></> // Render something when all conditions are truthy
                        ) : (
                            <SignUp4Modal/> // uid is truthy but creationDate is falsy
                        )
                        */

                        ) : (
                        <SignUp3Modal/> // uid is truthy but address is falsy
                        )
                    ) : (
                        <SignUp2Modal/> // uid is truthy but firstName is falsy
                    )
                    ) : (
                    <VerifyPhoneModal/> // uid is truthy but phoneVerified is falsy
                    )
                ) : (
                    <VerifyEmailModal/> // uid and emailVerified are truthy, but emailVerified is falsy
                )
                ) : (
                  <>
                  {isHomePage ? <>
                  
                    <SignUpModal buttonType={'headerNavHome'}/>
                    
                    </> : <>
                  
                  <SignUpModal buttonType={'headerNav'}/>
                  </>}
                </>
                )}

</div>
        </div>
    )
                } else {
                    return (
                        <div className={styles['header-right']}>
                        <LogInModal buttonType={'headerNav'}/>
                        {isHomePage ? <>
                  
                  <SignUpModal buttonType={'headerNavHome'}/>
                  
                  </> : <>
                
                <SignUpModal buttonType={'headerNav'}/>
                </>}
                      </div>
                    )
                }
  }

  export default HeaderNav;