import React, { useState, useEffect } from 'react';
import styles from '../../App.module.scss';
import { functions } from "../..";
import { httpsCallable } from "firebase/functions";
import HeaderNav from '../../components/Authentication/HeaderNav';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setBitcoinPriceData } from '../../redux/ephemeralBitcoinPrice';
import HeaderLeft from '../../components/headerLeft';
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import SmallScreenLanding from '../../components/SmallScreenLanding';
import tieIcon from '../../assets/tieIcon.png';

function TermsOfService() {

    const navigate = useNavigate();

  let currentUserCreationDate = useAppSelector((state) => state.userData.userData?.creationDate);

  console.log('rendering home page with creation date: ', currentUserCreationDate);
  const dispatch = useAppDispatch();

  const getBitcoinPrice = async () => {
    try {
      const fetchBTCPriceInUSD = httpsCallable(functions, "fetchBTCPriceInUSD");
      const response = await fetchBTCPriceInUSD({});

      dispatch(setBitcoinPriceData(1.0/(response.data as number)));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBitcoinPrice();
  }, []);

  const isSmallScreen = window.innerWidth <= 800;

/*
  if (isSmallScreen) {
    return (
    <div>
        
    {<SmallScreenLanding/>}

  </div>
    )
  };
  */

  return (
      
    <div className={styles.App} style={{ backgroundColor: '#222222' }}>
      <div className={styles['dark-gray-background']}>
        <div className={styles['header']}>
          <div className={styles['header-container']}>
          <HeaderLeft/>
            <div className={styles['header-right']}>
                {/* HeaderNav will only be rendered if isSmallScreen is false */}
                {isSmallScreen ? null : <HeaderNav />}
              </div>
          </div>
        </div>
        </div>
        <div className={styles['darker-gray-background']} style={{ display: 'flex', justifyContent: 'center', 
        alignItems: 'center', flexDirection: 'column' }}>

<div className={styles['darker-gray-background']}>


<div style={{    display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '100px',
      gap: '0px',
      marginBottom: '100px'
      }}>
        <div 
        style={{
    textAlign: 'center',
    fontSize: '35px',
    fontWeight: 'bold',
    color: 'white',
    height: '10vh',
  }}>User Agreement</div>

<img src={tieIcon} style= {{

      width: '80px', height: 'auto'}}></img>
      </div>

      </div>
    <div
      style={{
        width: '85vw',
        padding: '20px',
        borderRadius: '10px',
        backgroundColor: '#222222',
        marginBottom: '30px',
      }}
    >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: 'white', margin: '12px', fontSize: '28px' }}>FINFRIEND, LLC

TERMS OF SERVICE

March 2, 2023</div>
            
          </div>
        </div>

        <div style={{color: 'white'}}>
            
            <div>PLEASE CAREFULLY READ THESE TERMS OF SERVICE BEFORE
         DOWNLOADING OR ACCESSING THE PLATFORM OR USING THE SERVICES PROVIDED BY FINFRIEND, LLC.
         </div><br/><div>
All Users are required to accept and comply with these Terms of service. IF YOU DO NOT AGREE
 TO THE TERMS of service OR ARE NOT ELIGIBLE TO AGREE TO THE TERMS OF SERVICE, THEN YOU MAY
  NOT USE THE SERVICES OR INSTALL OR ACCESS THE PLATFORM.  
  </div><br/><div>
FINFRIEND IS NOT RESPONSIBLE FOR ANY DECISIONS OR ACTIVITIES YOU ENGAGE IN THROUGH YOUR 
ACCESS OR USE OF THE PLATFORM OR YOUR USE OF THE SERVICES.  
</div><br/><div>
If you have any questions about these Terms of Service, please email FinFriend using the 
contact information included below. 
</div><br/><div>
1.  AGREEMENT TO BE BOUND BY THE TERMS OF SERVICE
</div><br/><div>
These Terms of Service (“Terms of Service”) constitute a legally binding agreement between 
you, the user (“you,” “your,” or the “User”), and FinFriend, LLC (“FinFriend,” “Company,” 
“we,” “us,” or “our”) concerning your access to and use of the platform, whether through 
mobile application (the “App”) or through the website (the “Website,” the Website and the
 App shall be hereinafter referred to together as the “Platform), any media form, including 
 images, information, and all other and content, on the Platform (the “Content”), as well
  as any services provided by the Company to the User (the “Services”) as further defined 
  below.  

BY CLICKING THE “I ACCEPT” BUTTON, INSTALLING OR ACCESSING THE PLATFORM, OR USING THE 
SERVICES CONSTITUTES AN AGREEMENT BETWEEN YOU AND FINFRIEND TO BE BOUND BY THESE TERMS 
OF SERVICE, AND YOU AGREE AND ACKNOWLEDGE THAT: 
</div><br/><div>
(A) YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS OF SERVICE AS A
CONDITION OF USE OF THE SERVICES OR INSTALLATION OR ACCESS OF THE PLATFORM, 
</div><br/><div>
(B) YOU ARE AT LEAST 18 YEARS OF AGE AND ABLE TO FORM A LEGALLY BINDING CONTRACT WITH
 FINFRIEND, AND 
 </div><br/><div>
(C) YOU HAVE THE LEGAL RIGHT AND AUTHORITY TO ACCEPT THESE TERMS AND FORM A LEGALLY 
BINDING CONTRACT WITH FINFRIEND.
</div><br/><div>
IF YOU DO NOT AGREE TO THE TERMS OR ARE NOT ELIGIBLE TO AGREE TO THE TERMS OF SERVICE,
 THEN YOU MAY NOT USE THE SERVICES OR INSTALL OR ACCESS THE PLATFORM.  
 </div><br/><div>
2. legal disclaimer
</div><br/><div>
The Content provided on the Platform is not intended for distribution to, or use by, any
 person or entity in any jurisdiction or country where such distribution or use would 
 be contrary to law or regulation or which would subject us to any registration 
 requirement within such jurisdiction or country. Accordingly, those persons who 
 choose to access the Platform from other locations do so on their own initiative and 
 are solely responsible for compliance with local laws, if and to the extent local laws 
 are applicable.

You acknowledge and agree that any Content accessed or provided on or through the Platform 
provided for informational and entertainment purposes only. It is not intended to provide 
you or anyone else with investment, tax, insurance, legal, or any other type of professional advice. 
</div><br/><div>
3. ARBITRATION NOTICE
</div><br/><div>
THE TERMS OF SERVICE INCLUDE PROVISIONS GOVERNING HOW DISPUTES BETWEEN YOU, FINFRIEND, 
AND ANY OF FINFRIEND’S OWNERS, OFFICERS, MEMBERS, EXECUTIVES, EMPLOYEES, OR AGENTS 
(“Affiliates”) ARE RESOLVED. BY ACCEPTING THE TERMS, YOU AGREE THAT DISPUTES ARISING 
UNDER THESE TERMS OF SERVICE, INCLUDING ANY SECURITIES LAW CLAIMS, WITH LIMITED EXCEPTIONS
 NOTED HEREIN, WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION IN PALO ALTO, CALIFORNIA. 
 BY ACCEPTING THE TERMS, YOU AND FINFRIEND ARE WAIVING THE RIGHT TO: 

PURSUE DISPUTES OR CLAIMS AND SEEK RELIEF IN A COURT OF LAW, INCLUDING A TRIAL BY JURY, AND
PARTICIPATE IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING. 
YOU ACKNOWLEDGE AND AGREE THAT YOUR RIGHTS WILL BE DETERMINED BY A NEUTRAL ARBITRATOR AND NOT A JUDGE OR JURY. 
</div><br/><div>
4. UPDATES TO THE TERMS of service
</div><br/><div>
These Terms of Service are subject to change at any time at the sole discretion of FinFriend. 
When changes are made, FinFriend will make the new Terms of Service available on the Website, 
within the App, and/or through the Services, as legally required. FinFriend will update the 
“last updated date” at the top of the Terms of Services. FinFriend may require you to indicate 
agreement to the updated Terms of Service before further use of the Services is permitted. If 
you do not agree to the updated Terms of Service after receiving notice of the updates, either
 through direct communication by FinFriend to the email address, phone number, or physical 
 address you supplied to FinFriend, or through notice provided on the Website or the App,
  you must stop using the Platform and the Services. Your continued access and use of the 
  Platform and your continued use of the Services constitutes your acknowledgement and 
  agreement to the updated Terms of Service. 
  </div><br/><div>
5. ELIGIBLE users
</div><br/><div>
CURRENTLY ONLY RESIDENTS OF THE STATE OF MONTANA THAT PASS THE COMPANY’S ANTI-MONEY 
LAUNDERING/KNOW-YOUR-CUSTOMER CHECKS AND THAT ARE OVER THE AGE OF 18 MAY CURRENTLY USE THE SERVICES PROVIDED BY FINFRIEND. 
</div><br/><div>
6. Location
</div><br/><div>
FinFriend is a California limited liability company with a registered office at 11 
Buckingham Way, Rancho Mirage, CA 92270.
</div><br/><div>
7. SERVICES
</div><br/><div>
a. SERVICES
</div><br/><div>
The Services available on the Platform provide an alternative method for creating a 
cryptographic token (a “Token”) and offering it in a marketplace. Each User may create
</div><br/><div>
 a Token, trade it for other cryptographic tokens and send it via email.
 </div><br/><div>
b. ACCOUNTS AND REGISTRATION
</div><br/><div>
To access the Services, you must register for an account (your “Account”). You acknowledge
 and agree that you shall have no ownership or other property interest in your Account.
  You further acknowledge and agree that all rights in and to your Account are owned by
   and shall inure to the benefit of FinFriend and its assigns. You may be required to 
   provide information such as your name, email address, address, phone number, a copy 
   of a government issued identification, and other information to FinFriend when you 
   register your Account. You agree to provide accurate information, and you further 
   agree to update the information to keep it accurate at all times. Your Account will
    be password-protected. You are solely responsible for maintaining the confidentiality 
    of your Account and its password. You are responsible for all activities that occur 
    under your Account. If you believe your Account is no longer secure, you must notify 
    FinFriend. FinFriend may reject any registration for an Account in its discretion. 

When engaging with the services you are encouraged to deposit Bitcoin with FinFriend. 
FinFriend will keep your Bitcoin backed one to one and do it’s best to secure it. FinFriend
 will securely maintain the Bitcoin you send until you withdraw from the Platform and no 
 longer need the Services. At which Time FinFriend will send the Bitcoin to the address 
 that you specify within the app.

You will see your Bitcoin balance displayed in your Account on the Platform. If you buy 
other User’s Tokens or sell the Tokens that you create, you will see a corresponding 
decrease or increase in the Bitcoin held in your Account. Such transfers, and deposits
 and withdrawals in and out of your Account will be recorded on FinFriend’s internal 
 accounts and records. While using the service, the tokens will remain in FinFriend’s 
 custodial wallets. Users are responsible for the calculation and filing of their own capital gains taxes.
 </div><br/><div>
c. FEES
</div><br/><div>
Each User of the Services shall pay a fee for such services. Fees may be collected upon
 the creation of a Token, the trading of the Token and the withdrawal from the Platform 
 or other actions where fees are necessary. These fees are non-refundable and due immediately 
 upon the completion of one of the aforementioned actions. The current fee schedule is 
 available on the Platform and any changes will be announced and distributed prior to 
 the effectiveness of any changes. Failure to pay fees for any reason will result in 
 loss of access to Services and may further result in your Account’s being disabled, 
 blocked, or terminated, without refund or credit. 
 </div><br/><div>
d. UPDATES 
</div><br/><div>
FinFriend may require you to accept updates to the Services you have installed on your
 device as a condition of continued use of the Services. You acknowledge and agree that 
 FinFriend may update the Services with or without notifying you. In addition, you acknowledge
  and agree that FinFriend may add, change, or discontinue existing Services at any time 
  and in any region without notice to you. If you do not agree with any update or change, 
  your only remedy is to stop using the Services or affected Service or feature. 
  </div><br/><div>
e. RESTRICTIONS ON USE 
</div><br/><div>
You agree to use the Services only for the purposes allowed under the Terms of Service. You 
will not, and will not allow a third party to, make available any Tokens or other content 
on or through the Services that infringes on any trademark, patent, trade secret, copyright, 
right of publicity, or other right of any individual or entity; is unlawful, threatening, 
invasive to any individual or entity’s privacy; or is abusive, defamatory, libelous, tortious, 
offensive, obscene, profane, deceptive or fraudulent. You will not, and will not allow a third 
party to, make available any content on or through the Services that constitutes advertising, 
junk or bulk email, constitutes commercial activity (including sales and contests), or interferes 
with the Services in any way not explicitly allowed by the Terms. You may not impersonate any other 
individual or entity or falsely claim a relationship to any other individual or entity, including FinFriend. 
</div><br/><div>
By using the Services in any way, you agree and warrant that you will not use your Account to
provide or make statements regarding your eligibility to use the Services. You may not make statements suggesting that FinFriend or its Affiliates endorse, verify, guarantee, or control the accuracy or completeness of any Tokens or content you provide on or through the Services by you or any other users. You may not engage in any activity or promote any activity that would violate or assist in the violation of any law, statute, ordinance, or regulation in any state, country, or territory where FinFriend does business. You may not violate, distribute, or promote unlawful material or information on or through the Services. You may not engage in or promote any activity that operates to defraud FinFriend users or any other person or provide inaccurate, false or misleading information to FinFriend or its users. 
</div><br/><div>
FinFriend reserves the right, but has no obligation, to take any action with respect to any
Tokens or user-generated content it deems necessary or appropriate if FinFriend believes 
the content violates the Terms of Service; infringes the rights of other individuals or 
entities, including intellectual property rights; violates any laws, rules, or regulations;
 or could create liability for FinFriend, its subsidiaries, or its Affiliates. FinFriend
  may take such action at its sole discretion and without notice, consent, or explanation 
  to you or any other user. Such action may include, without limitation, refusing, 
  blocking, altering, and deleting the Tokens or content and terminating or suspending 
  your access to all or part of the Services.
  </div><br/><div>
f. “AS IS” AND “AS AVAILABLE” BASIS 
</div><br/><div>
The Services are provided on an “As Is” and “As Available” basis to the fullest extent 
permitted by law. FinFriend does not warrant that you will have uninterrupted, 
error-free access to the Services, that user data will be accurate or complete, 
or that FinFriend will maintain or store the data that you provided without any 
loss or change. FinFriend and its Affiliates make no warranties, express or implied
, statutory or otherwise, regarding the Services, including warranties of merchanta
bility, title, fitness for a particular purpose, or noninfringement. Any use of the 
Services is at your sole discretion and risk. 
</div><br/><div>
g. USERNAME
</div><br/><div>
By using any part of the Services, you grant FinFriend the right to identify you by 
username in any form, media, or technology currently known or hereafter developed, 
as the contributor of your Token on any portion of the Services and Platform. 
</div><br/><div>
h. OWNERSHIP OF TOKENS
</div><br/><div>
You retain ownership and any property rights of any Tokens you may have developed th
rough your use of the Services until you sell such Tokens to another user. Further, 
you retain ownership and any property rights of any Tokens you have purchased from o
ther users. 
</div><br/><div>
i. FINFRIEND’S LICENSE TO TOKENS
</div><br/><div>
By developing Tokens through your use of the Services, you grant FinFriend a revocab
le, royalty-free, unrestricted, assignable, sublicensable license to display your Tok
ens on the Platform and to other users of the Services. FinFriend may use any images 
of your Tokens for advertising and promotion of FinFriend, is Platform, and its Servi
ces and in any other manner authorized by these Terms of Service. By using the Servic
es, you waive any legal or moral rights to prior inspection or approval of FinFriend’s
 display of your Tokens.
 </div><br/><div>
8. USERS ARE RESPONSIBLE FOR THEIR OWN TAX FILINGS
</div><br/><div>
You are responsible for all taxes, including all federal or state income taxes on any

 income earned from the sale or transfer of your Tokens, imposed under applicable law
 s, and that arise because of your use of the Services or the Platform. FinFriend wil
 l not file any tax documents on your behalf, send you any reminders about tax filing
 s, or keep any records related to your tax responsibilities. 
 </div><br/><div>
9. Prohibited uses 
</div><br/><div>
You may not authorize another user to use the Services on your behalf or access your 
Account Further, you shall not license, sell, rent, lease, transfer, assign, reproduc
e, distribute, host, or otherwise commercially exploit the Platform and its Services.
 You may not use any intellectual property belonging to FinFriend, including trademar
 k, logo, copyright, patent, or trade secret, in any way, including adaptation and d
 erivative works, in any manner or medium currently available or available in the fut
 ure without FinFriend’s explicit written consent. You may not reverse engineer, disa
 ssemble, decompile, or otherwise alter, adapt, or make derivative works of any part 
 of the Platform, Content, or Services (including the other user’s Tokens) or act to 
 undermine the security of the Platform, Content, or Services or the anonymity of the
  users of the Platform or Services except as the foregoing restrictions are expressl
  y prohibited by applicable law. 

You may not use any means or processes currently known, or yet to be developed, to dow
nload data from the Platform or Services (with the exception of operators of public se
arch engines which FinFriend grants revocable permission to use spiders to copy materi
als from the Platform or Services for the sole purpose of and only to the extent neces
sary for creating publicly available, searchable indices of the materials, but not cac
hes or archives of such materials). 

No part of the Platform, Content, or Services may be copied, reproduced, distributed, r
epublished, posted, transmitted, or displayed except as expressly authorized elsewhere 
in the Terms of Service. You must comply with all applicable laws and regulations in re
lation to the Terms of Service, and you must not use the Platform, Content, or Services
 to facilitate insider trading, market manipulation, or any other form of fraud or abus
 e. FinFriend reserves all rights not granted under these Terms of Service. 

Further, you shall NOT:

Trick, defraud, or mislead us and other users;
Circumvent, disable, or otherwise interfere with security-related features of the Plat
form, including features that prevent or restrict the use of copying of any Content or 
enforce limitations on the use of the Platform and / or the Content contained therein;
Use any information obtained from the Platform in order to harass, abuse, or harm anot
her user or person;
Make improper use of our support services or submit false reports of abuse or miscondu
ct;
Use the Platform and the Services and a manner inconsistent with any applicable laws o
r regulations;
Engage in unauthorized framing of or linking to the Platform;
Upload or transmit viruses, Trojan horses, or other material, including excessive use 
of capital letters and spamming (continuous posting of repetitive text), that interfe
res with any user’s uninterrupted use and enjoyment of the Platform or Services, or mo
difies, impairs, disrupts, alters, or interferes with the use, features, functions, op
eration, or maintenance of the Platform or Services;
Engage in any automated use of the Platform’s and Services’ system, such as using script
s to send comments or messages, or using any data mining, robots, or similar data gather
ing and extraction tools;
Delete the copy right or other proprietary rights notice from any Content;
Attempt to impersonate another user or person or use the username of another user;
Upload or transmit (or attempt to upload or transmit) see any material that acts as a pa
ssive or active information collection or transmission mechanism, including without limi
tation, clear graphics interchange format (“gifs”) 1x1 pixels, web bugs, cookies, or oth
er similar devices (sometimes referred to as “spyware" or “passive collection mechanisms
" or “PCMS”);
Interfere with, disrupt, or create an undue burden on the Platform or the Services or the
 networks connected to the Platform or Services;
Harass, annoy, intimidate, or threaten any of our Affiliates;
Attempt to bypass any measures of the Platform designed to prevent or restrict access to 
the Platform or the Services;
Copy or adapt the Platform’s software, including but not limited to swift, php, HTML, Java
Script, or other code;
Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engine
er any of the software comprising or in any way making up a part of the Platform and Services;
Except as may be the result of standard search engine or internet browser usage, use, laun
ch, develop, or distribute any automated system, including without limitation, any spider,
 robot, cheat utility, scraper, or offline reader that accesses the Platform, or using or 
 launching any unauthorized script or other software;
Use a buying agent or purchase agent to make purchases on the Platform and to use the Services;
Make any unauthorized use of the Platform, including collecting user information and email 
addresses of users by electronic or other means for the purposes of sending unsolicited ema
il, or creating user accounts by automated means or under false pretenses;
Generate a Token or post any content that contains pornographic or adult material or offensive or obscene language; 
Use the Platform to compete with us or otherwise use the Platform, the Content, and the Serv
ices for any revenue-generating endeavor or commercial enterprise; and
Use your generated Tokens as a means to represent equity or debt or generate a Token that qua
lifies as “security” under Section 2(a)(1) of the Securities Act of 1933 (the “Securities Act
”), 15 U.S.C. § 77b(a)(1), as amended. 
Any unauthorized use of the Platform, Services, or Content terminates the licenses granted by
 FinFriend under the Terms of Service and may terminate your ability to use the Services and a
 ccess the Platform. If your access to the Platform or the Services is terminated for any viol
 ation of the Terms of Service you may not re-register with the Platform or access the Service
 s using a different username or account.
 </div><br/><div>
10. limited license 
</div><br/><div>
Subject to your complete and ongoing compliance with the Terms of Service, FinFriend grants yo
u a limited, non-exclusive, non-transferable, non-sublicensable, fully revocable license for yo
ur sole, personal, non-commercial access to the Platform and use of the Services. This limited 
license allows you to install and use one copy of the App on a device that you own or control. 
You are required to obtain the App associated with the Services from the Apple App Store or the
 Google Play Store, and must comply with the rules and terms of the marketplace from which you 
 obtained the App. You may use the Services solely for your own personal purposes. You may not 
 reproduce, distribute, publicly display, or perform the Services; make modifications to the Pl
 atform or the Services; or interfere with or circumvent any feature of the Services or Platfor
 m, including any security or access control mechanism.

 </div><br/><div>
11. intellectual property
</div><br/><div>
a. FinFriend’s INTELLECTUAL property
</div><br/><div>
FinFriend owns all rights, titles, and interests, including all intellectual property rights, t
o the Services, the Platform and FinFriend’s related websites, applications, and technology. The 
Platform, Content, and Services are our proprietary property and all the source code, databas
es, functionality, software, websites, designs, audio, video, text, photographs, and graphics o
n the platform (collectively, the “IP”) and the trademarks, service box, and logos contained th
erein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyr
ight and trademark law and various other intellectual property rights and unfair competition law
s of the United States, international copyright laws, and international conventions. The IP and 
the Marks are provided on the Platform “as is” for your information and personal use only. Excep
t as expressly provided in these terms of use, no part of the Platform no IP or Marks may be cop
ied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, and coated, tran
slated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purp
ose whatsoever, without our express prior written permission. 
</div><br/><div>
b. The Digital Millennium Copyright Act of 1998
</div><br/><div>
The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright owners 
who believe that material appearing on the Internet infringes their rights under U.S. Copyright l
aw. You may not post, distribute, or reproduce in any way any copyrighted material, trademarks, or
 other proprietary information without obtaining the prior written consent of the owner of such pr
 oprietary rights. If you do so repeatedly on or through the Platform and through use of the Servi
 ces, you will be removed as a user. Without limiting the foregoing, if you believe that your work
  has been copied and posted on the Platform in a way that constitutes copyright infringement, ple
  ase contact FinFriend using the contact information listed below.
  </div><br/><div>
12. Representations and WARRANTIES; Risks; legal DISCLAIMERS
</div><br/><div>
a. USER REPRESENTATIONS AND WARRANTIES
</div><br/><div>
By agreeing to the Terms of Service, you represent and warrant that (1) you are at least 18 years 
old and you have the legal capacity and you agree to comply with the Terms of Service; (2) you have not previously been suspended or removed from the Platform or the Services; and (3) your registration and use of the Services and the Platform is in compliance with any and all applicable laws and regulations.  

You further represent and warrant that: 

You will not generate a Token that qualifies as a “security” under Section 2(a)(1) of the Securities Act; 
All registration information you submit will be true, accurate, current, and complete and that you will maintain the accuracy of such information and update such registration information as necessary;
You will not access the Platform or the Services through automated or non-human means, weather through a bot, script or otherwise; 
You will not use the Platform or Services for any illegal or unauthorized purpose; 
Your use of the Platform or the Services will not violate any applicable law or regulation;
You will not make any unsubstantiated claims in the offer or sale of your Tokens; and
You will not use the Platform or the Services to violate any laws or commit any illegal or unlawful acts, including engaging in any activity that would violate the U.S. Bank Secrecy Act or be considered “money laundering.” 
If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your Account and refuse any and all current or future use of the Platform or Services. 
</div><br/><div>
b. ACKNOWLEDGMENT OF RISKS; WAIVER
</div><br/><div>
You acknowledge and agree that you are solely and fully responsible for all your activities and communications on and through the Platform and Services. You further accept and acknowledge that FinFriend is not responsible for and does not endorse any Token or user commentary provided or generated by users on or through the Services and Platform and that FinFriend is not responsible or liable for any actions taken by you or other users in reliance on any such content and communications. 

NEITHER FINFRIEND, THE PLATFORM, THE CONTENT, NOR THE SERVICES IS INTENDED TO PROVIDE INVESTMENT, FINANCIAL, TAX, OR LEGAL ADVICE. FINFRIEND IS NOT A REGISTERED INVESTMENT ADVISER UNDER THE INVESTMENT ADVISERS ACT OF 1940, A FINANCIAL OR TAX PLANNER, A REGISTERED EXCHANGE UNDER THE SECURITIES EXCHANGE ACT OF 1934, OR A REGISTERED BROKER-DEALER UNDER THE SECURITIES EXCHANGE ACT OF 1934. FINFRIEND DOES NOT OFFER LEGAL ADVICE TO ANY USER OF PLATFORM OR THE SERVICES. THOUGH FINFRIEND AND OTHER USERS OF THE PLATFORM OR SERVICES MAY PROVIDE INFORMATION, DATA, AND CONTENT RELATED TO CRYPTOCURRENCY AND DIGITAL ASSETS, YOU SHOULD NOT CONSTRUE ANY SUCH CONTENT AS TAX, LEGAL, FINANCIAL, OR INVESTMENT ADVICE. 

YOU ACKNOWLEDGE AND AGREE THAT (A) ENGAGING IN ANY ACTIVITY RELATED TO CRYPTOCURRENCY OR DIGITAL ASSETS IS INHERENTLY RISKY AND THAT YOU HAVE THE NECESSARY FINANCIAL MEANS AND TECHNICAL EXPERTISE AND ABILITY TO ENGAGE IN ANY ACTIVITY OR COURSE OF ACTION YOU MAY TAKE BASED UPON YOUR USE OF THE SERVICES OR PLATFORM; (B) YOU HAVE THE KNOWLEDGE, EXPERIENCE, UNDERSTANDING, PROFESSIONAL ADVICE, AND INFORMATION TO MAKE YOUR OWN EVALUATION OF THE MERITS, RISKS, AND APPLICABLE COMPLIANCE REQUIREMENTS UNDER APPLICABLE LAWS OF YOUR USE OF THE SERVICES AND PURCHASE OF ANY TOKENS THEREIN; (C) YOU KNOW, UNDERSTAND, AND ACCEPT THE RISKS ASSOCIATED WITH YOUR USE OF THE SERVICES; AND (E) YOU ARE RESPONSIBLE FOR CONDUCTING YOUR OWN INDEPENDENT ANALYSIS OF THE RISKS SPECIFIC TO EACH TOKEN. YOU AGREE THAT FINFRIEND WILL HAVE NO RESPONSIBILITY OR LIABILITY FOR SUCH RISKS. 

YOU ACKNOWLEDGE AND AGREE TO IRREVOCABLY WAIVE, RELEASE, AND DISCHARGE ALL CLAIMS, WHETHER KNOWN OR UNKNOWN TO YOU, AGAINST FINFRIEND AND ITS SUBSIDIARIES AND/OR AFFILIATES AS A RESULT OF YOUR USE OF THE PLATFORM AND THE SERVICES. THROUGH THE FOREGOING RELEASE, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR ANY LOSSES OR DAMAGES THAT YOU MAY INCUR THROUGH YOUR USE OR RELIANCE ON THE PLATFORM, THE SERVICES, AND ANY CONTENT PROVIDED ON OR THROUGH THE SERVICES OR PLATFORM, INCLUDING ANY TOKEN SALES OR PURCHASES.

YOU FURTHER ACKNOWLEDGE AND AGREE THAT FINFRIEND DOES NOT CONFIRM OR INVESTIGATE THE PURPORTED IDENTITY, BACKGROUND, OR HISTORY OF USERS OR THE ACCURACY OR COMPLETENESS OF THE CONTENT PROVIDED OR TOKENS GENERATED. YOU ARE SOLELY RESPONSIBLE FOR DETERMINING THE IDENTITY AND CREDIBILITY OF THE USER OF ANY CONTENT PROVIDED OR TOKENS GENERATED ON OR THROUGH THE SERVICES OR THE PLATFORM 

YOU ACKNOWLEDGE AND AGREE THAT OTHER USERS MAY VIEW ANY CONTENT YOU PROVIDE ON OR THROUGH THE SERVICES OR PLATFORM, INCLUDING CONTENT THAT MAY BE USED TO PERSONALLY IDENTIFY YOU AND YOUR TRADING ACTIVITIES, AND THAT YOU ASSUME ALL RISK FOR SUCH CONTENT. 
</div><br/><div>
c. DISCLAIMERS OF WARRANTIES
</div><br/><div>
YOU ASSUME ALL RISK FOR USE OF THE PLATFORM AND THE SERVICES. THE PLATFORM AND THE SERVICES ARE PROVIDED ON AN “AS IS'' AND “AS AVAILABLE” BASIS. FINFRIEND DISCLAIMS ALL WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATED TO THE PLATFORM AND SERVICES AND ALL TOKENS, CONTENT, COMMENTS, AND OTHER MATERIAL AVAILABLE ON OR THROUGH THE PLATFORM OR SERVICES, INCLUDING ANY IMPLIED WARRANTY OR CONDITION OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT, AS WELL AS ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE. 

FINFRIEND DOES NOT WARRANT THAT THE PLATFORM, THE SERVICES, OR ANY PORTION OF THE SERVICES, TOKENS, OR CONTENT AND MATERIAL AVAILABLE ON OR THROUGH THE PLATFORM OR SERVICES WILL BE UNINTERRUPTED, SECURE, ACCURATE, RELIABLE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS. 

FINFRIEND DOES NOT ENDORSE ANY USERS, USER GENERATED-CONTENT PR TOKENS. FINFRIEND DOES NOT OFFER INVESTMENT ADVICE OF ANY KIND. FINFRIEND IS NOT RESPONSIBLE FOR AND DISCLAIMS ANY AND ALL LIABILITY FOR ANY ACTIONS TAKEN IN RELIANCE ON ANY CONTENT OR MATERIAL PROVIDED ON OR THROUGH THE PLATFORM OR THE SERVICES.

NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM FINFRIEND ON OR THROUGH THE PLATFORM OR SERVICES WILL CREATE ANY WARRANTY RELATED TO FINFRIEND, THE PLATFORM, OR THE SERVICES THAT IS NOT EXPRESSLY STATED IN THESE TERMS OF SERVICE.

LIMITATIONS, EXCLUSIONS, AND DISCLAIMERS IN THIS SECTION APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. 
</div><br/><div>
13. LIABILITY AND INDEMNITY
</div><br/><div>
a. USER DATA
</div><br/><div>
Except for obligations expressly stated in FinFriend’s Privacy Policy, FinFriend assumes no responsibility for the failure to store, delete, or deliver user-generated content, including your content, communications, or personalized settings. FinFriend reserves the right to change public user data that is offensive or absurd.
</div><br/><div>
b. OTHER USERS
</div><br/><div>
You are solely responsible for all your communications, contacts, and reliance upon information provided by other users. As detailed elsewhere in these Terms or Service, FinFriend does not pre-screen user-generated content or Tokens; nor does it endorse, verify, or guarantee the completeness or accuracy of any user-generated content, Tokens, comments, and other communications.  
</div><br/><div>
c. THIRD PARTIES: 
</div><br/><div>
FinFriend is not liable for the conduct, including communications and content, of third parties, whether encountered on or through the Platform, the Services, or external sites. You agree to assume all risks related to your interactions with third parties and agree not to hold FinFriend liable for the conduct of any third parties. FinFriend makes no warranty that the information, goods, or services provided by third parties will meet your requirements or be reliable, secure, or error free. 
</div><br/><div>
d. DOWNLOADS AND OTHER CONTENT: 
</div><br/><div>
You assume all risk for any content downloaded or otherwise accessed through the Platform and the Services. You agree that FinFriend is not responsible for any damage or loss to your property, including computer systems, resulting from use of any content accessed by or through the Platform and the Services. 
</div><br/><div>
e. NO CREATION OF OTHER WARRANTIES 
</div><br/><div>
No advice or other information, either express or implied, made, communicated, or shared by FinFriend or its subsidiaries or affiliates or on or through the Platform or the Services will create any warranty not expressly made in the Terms of Service. 
</div><br/><div>
f. LIMITATIONS OF LIABILITY 
</div><br/><div>
YOU AGREE AND ACKNOWLEDGE THAT, TO THE FULLEST EXTENT PROVIDED BY LAW, FINFRIEND IS NOT AND WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR MORE THAN THE GREATER OF (A) $1000 OR (B) THE REMEDY OR PENALTY IMPOSED BY THE STATUTE UNDER WHICH SUCH CLAIMS ARISE. THIS CAP ON LIABILITY WILL NOT APPLY TO THE LIABILITY OF FINFRIEND OR ITS RELATED PARTY FOR ANY INJURY CAUSED BY FINFRIEND OR ITS RELATED PARTY’S FRAUD OR FRAUDULENT MISREPRESENTATION. TO BE CLEAR, A USER OF THE PLATFORM OR THE SERVICES IS NOT CONSIDERED A RELATED PARTY OF FINFRIEND. 

Certain US state laws and international laws do not allow limitations on implied warranties or the exclusion or limitation of certain damages. If these laws apply to you, some or all of the above disclaimers are limitations may not apply to you, and you may have additional rights.
</div><br/><div>
g. Indemnification
</div><br/><div>
You agree to defend, indemnify, and hold us harmless, FinFriend and its Affiliates against any loss, damage, liability, claim, or demand, including reasonable attorneys fees and expenses, made by any third-party due to or arising out of: your Tokens and user-generated content; use of the Platform and the Services; breach of these terms of service; any breach of your representations and warranties set forth in these Terms of Service; your violation of the rights of a third party, including but not limited to intellectual property rights; the SEC alleging your Token is a security; or any overt harmful act toward any other user of the Platform or the Services with whom you connected via the Platform.

Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
</div><br/><div>
14. COMMUNICATIONS
</div><br/><div>
a. CONSENT TO COMMUNICATION
</div><br/><div>
By using the Platform or the Services, you consent to receive communications from FinFriend, its subsidiaries, and affiliated companies by any means and in any manner currently available or yet to be developed using the contact information you provided when registering or updating your registration. You further agree that you have an established business relationship (EBR) with FinFriend and hereby waive any and all claims under the Telephone Consumer Protection Act (“TCPA”) as they relate to email, text message or fax communications from FinFriend. You hereby expressly consent to communications of any kind from FinFriend. These communications may include, but are not limited to, communications about your Account or use of the Platform or the Services, promotions by FinFriend, updates regarding the Services and the Platform, and related industry news. You agree that any communications FinFriend sends, including agreements, notices, and disclosures, will satisfy any legal communication requirements, including that communications be in writing. 
</div><br/><div>
b. OPT-OUT
</div><br/><div>
Users may opt out of receiving communications from FinFriend by selecting the opt-out options when registering and creating your Account, by contacting us using the contact information below, or by unsubscribing to marketing communications.  
</div><br/><div>
c. FEEDBACK
</div><br/><div>
Any unsolicited suggestions, ideas, proposals, or other material submitted to FinFriend by users on or through the Platform, Services, or otherwise (“Material”) will be considered non-confidential and non-proprietary, and FinFriend is not and will not be liable for the disclosure or use of such Material. If, at FinFriend’s request, any user sends Material to improve the Services or the Platform, FinFriend will also consider that Material to be non-confidential and non-proprietary, and FinFriend will not be liable for use or disclosure of the Material. Any communication by you to FinFriend is subject to these Terms of Service. You hereby grant and agree to grant FinFriend, under all of your rights in the Material, a worldwide, non-exclusive, perpetual, irrevocable, royalty-free, fully-paid, sublicensable and transferable right and license to incorporate, use, publish, and exploit such Material for any purpose whatsoever, commercial or otherwise, including but not limited to incorporating it in the API, documentation, or any product or Service, without compensation or accounting to you and without further recourse by you.
</div><br/><div>
15. DISPUTE RESOLUTION
</div><br/><div>
a. DISPUTES AND RELATIONS WITH OTHER USERS
</div><br/><div>
FinFriend reserves the right to intervene in any disputes and interactions between users on or through the Platform or the Services, including blocking access, removing Tokens, deleting content, included user-generated content, in whole or in part, editing content, or using any other means or manner not expressly disallowed under these Terms of Service. However, FinFriend is under no obligation to intervene or moderate disputes and interactions between users. You agree and acknowledge that FinFriend is not and will not be responsible for any liability or harm incurred through direct or indirect interactions on or through the Services or the Platform. 
</div><br/><div>
b. ARBITRATION AGREEMENT 
</div><br/><div>
In the event a dispute arises between you and FinFriend, please contact us using the contact information provided in these Terms of Service. Unless you opt out, any dispute arising from or relating to the subject matter of these Terms of Service shall be finally settled by arbitration in [CITY], California, using the English language in accordance with the rules and procedures for the resolution of commercial disputes of the American Arbitration Association (“AAA”) then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes, who shall be selected from the appropriate list of AAA arbitrators in accordance with the commercial dispute rules and procedures AAA. The prevailing party in any arbitration or other proceeding arising under this Agreement shall be entitled to receive reimbursement of its reasonable expenses (including reasonable attorneys’ fees, expert witness fees, and all other expenses) incurred in connection therewith.
</div><br/><div>
c. CLASS ACTION AND COLLECTIVE ACTION WAIVER
</div><br/><div>
The parties agree to arbitrate solely on an individual basis, and these Terms of Service do not permit class arbitration or any claims brought as a plaintiff or class member in any class or representative arbitration proceeding. The arbitral tribunal may not consolidate more than one person’s claims and may not otherwise preside over any form of a representative or class proceeding. Notwithstanding the tribunal’s power to rule on its own jurisdiction and the validity or enforceability of the agreement to arbitrate, the tribunal has no power to rule on the validity or enforceability of the agreement to arbitrate solely on an individual basis.
</div><br/><div>
d. ENFORCEMENT OF ARBITRATION JUDGMENT
</div><br/><div>
Judgment upon the award so rendered may be entered in a court having jurisdiction, or application may be made to such court for judicial acceptance of any award and an order of enforcement, as the case may be. Notwithstanding the foregoing, each party shall have the right to institute an action in a court of proper jurisdiction for injunctive or other equitable relief pending a final decision by the arbitrator.
</div><br/><div>
e. JURISDICTION AND VENUE
</div><br/><div>
For all purposes of any injunctive or equitable proceeding, the parties consent to exclusive jurisdiction and venue in the United States Federal Courts or state courts located in Palo Alto, California. Use of the Services and the Platform is not authorized in any jurisdiction that does not give effect to all provisions of the Terms of Service, including without limitation, this section.
</div><br/><div>
f. LIMITATIONS PERIOD
</div><br/><div>
You and FinFriend agree that any cause of action arising out of or related to the Platform, the Services, or these Terms of Service must commence within one (1) year after the cause of action arose; otherwise, such cause of action is permanently barred.
</div><br/><div>
g. ARBITRATION EXCEPTIONS
</div><br/><div>
Despite the provisions relating to arbitration in these Terms of Service, nothing in these Terms of Service will be deemed to waive, preclude, or limit the right of either party to (a) assert claims or seek relief in small claims court, (b) pursue an enforcement action through an applicable federal, state, or local agency if such action is available, or (c) seek injunctive relief in court to address intellectual property rights. 
</div><br/><div>
h. ARBITRATION OPT-OUT
</div><br/><div>
You may opt out of this agreement to arbitrate by notifying us in writing using the contact information presented below within 30 days of first becoming subject to this arbitration provision. When thus notifying us, you must provide your name, username, telephone number, email and mailing addresses, and a clear and definitive statement that you wish to opt out of the agreement to arbitrate. 
</div><br/><div>
i. SURVIVAL OF AGREEMENT TO ARBITRATION 
</div><br/><div>
Your agreement to arbitration and all terms and agreements related to it contained in the Terms of Service will survive the termination of your relationship to FinFriend and the termination of your use of the Services and the Platform.
</div><br/><div>
16. THIRD-PARTY SERVICES
</div><br/><div>
a. Third-Party Websites, Applications, and Ads 
</div><br/><div>
The Platform may contain links or integrations with, third-party websites, applications, and advertisements for third parties (“Third-Party Services”). FinFriend will not warn you that you have left the Platform if you select a third-party link or warn you that you may become subject to the terms and conditions (including privacy policies) of another service, website, or destination. FinFriend provides such links and Third-Party Services only as a convenience and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Services, or any third-party product or service. FinFriend does not control the content or policies of third-party websites or Third-Party Services. You use all third-party services and content at your own risk. 
</div><br/><div>
b. App Stores 
</div><br/><div>
The availability of the App and the Services may be dependent on a third party, such as the Apple Store, Google Play, or other third party (each, an “App Store”), from whom you received the App. You agree to pay all fees (if any) charged by the App Store in connection with the Services or the App. Your license to use the Application is conditioned upon your compliance with all terms of agreement imposed by the applicable App Store when using any aspect of the Services, including the App. You acknowledge that the App Store (and its subsidiaries) are third-party beneficiaries of the Agreement and will have the right to enforce it.

The Agreement regarding the Services is between you and FinFriend and not with the App Store. As between FinFriend and the App Store, FinFriend is solely responsible for the Services and its content, subject to the conditions detailed in these Terms of Service. 
</div><br/><div>
c. Apple App Store Specific Terms 
</div><br/><div>
You acknowledge and agree that the Terms of Service is concluded between you and FinFriend and that FinFriend is solely responsible for the App Store Sourced Application (“ASSP”) and its content. Your use of the ASSP must comply with the App Store Terms of Service. Apple Inc. (“Apple”) has no obligation whatsoever to furnish any maintenance and support services with respect to the ASSP. In the event of any failure of the ASSP to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the ASSP to you, and to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the ASSP. As between Apple and FinFriend, any other claims, losses, liabilities, damages, costs, or expenses attributable to any failure to conform to any warranty set forth in this Agreement or otherwise provided in relation to the ASSP will be the sole responsibility of FinFriend and subject to the Terms of Service.

Without limiting any other terms of the Agreement, you must comply with all applicable third-party terms of agreement when using the ASSP.

Apple is not responsible for addressing any claims you have or any claims of any third party relating to your use or possession of the ASSP, including but not limited to: (i) product liability claims; (ii) any claim that the ASSP fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation. To the extent required by the Terms of Service, FinFriend, not Apple, will be solely responsible for the investigation, defense, settlement, and discharge of any claim that your use of the App infringes upon the intellectual property rights of a third party.

Apple and Apple’s subsidiaries are third-party beneficiaries of the Terms of Service as related to your license of the ASSP, and upon your acceptance of the terms and conditions of the Terms of Service, Apple will have the right (and will be deemed to have accepted the right) to enforce the Terms of Service as related to your license of the App Store Sourced App against you as a third-party beneficiary thereof.


</div><br/><div>
17. GENERAL PROVISIONS
</div><br/><div>
 a. TERM Your agreement to the Terms of Service becomes binding when you accept them in any manner stated above. The Terms of Service and your agreement to them remain in full force and effect while you use the Platform or the Services, unless terminated earlier in accordance with a provision of the Terms. b. TERMINATION OF SERVICES FinFriend may terminate or suspend your access to the Platform or Services for any reason, including breach of the Terms of Service. FinFriend is not and will not be liable to you in any manner for any termination or suspension of your access the Platform or the Services. You may terminate the Services by notifying FinFriend at any time by providing written notice (including using electronic means) to FinFriend using the contact information provided in these Terms of Service, closing your FinFriend Account, and stopping your use of the Services. Termination of a Services results in removal of access to the Services. Your password, registration information, Account, user-generated content, and communications will also either be deleted, blocked, ormade inaccessible to you or to other users.  
 </div><br/><div>
FinFriend may, in its sole discretion and without notice or explanation to you or other users, investigate any possible violation of the Terms of Service. FinFriend may, at its sole discretion, take appropriate legal action, including, without limitation, referral to all applicable legal authorities for any illegal or unauthorized use of the Platform or Services. To the fullest extent allowed by law, FinFriend reserves the right to disclose, as it deems necessary or appropriate and at its sole discretion, user information and user activities on or through the Platform or Services in order to comply with applicable laws, rules, regulations, and legal actions; respond to the claims of third parties; aid law enforcement or government agencies or officials; respond to customer service requests; or protect the rights, property, or personal safety of others. 
</div><br/><div>
c. WAIVER OF PROVISIONS 
</div><br/><div>
Any waiver or failure to enforce any provision of the Terms of Service on one or more occasions will not be deemed a waiver of such provision or any other provision on any other occasion. 
</div><br/><div>
d. SEVERABILITY
</div><br/><div>
If any portion of the Terms of Service is held invalid or unenforceable, such portion shall be construed in a manner to reflect as nearly as possible the original intention of the parties, and the remaining portions shall remain in full force and effect.
</div><br/><div>
e. GOVERNING LAW: 
</div><br/><div>
These Terms of Service shall in all respects be interpreted and construed with and by the laws of the State of California, excluding its conflicts-of-laws rules, and of the United States of America.
</div><br/><div>
f. NOTICE AND CONTACT INFORMATION 
</div><br/><div>
You may contact FinFriend at the address below. Any notice required under the Terms of Service must be made in writing (including email) to one of the addresses provided below. Any notice given by mail or other delivery service shall be deemed conveyed when received by FinFriend at the address below.
</div><br/><div>
g. ENTIRE AGREEMENT: 
</div><br/><div>
The Terms of Service are the final, complete, and exclusive agreement of FinFriend and the you with respect to all subject matter referenced or contained in the Terms of Service and supersede and merge all prior discussions and communications between the parties with respect to such subject matter.
</div><br/><div>
h. CONTACT INFORMATION: 
</div><br/><div>
If you have any questions or comments regarding these Terms of Service, you would like to opt-out of marketing communications or the arbitration provision, or terminate your Account and cease your ability to use the Services, please email the address below. Please include your name, username, and a short description of the matter in the email. 

finfriend@finfriend.com

If you would like to contact FinFriend regarding any dispute, potential dispute, or DCMA issue, please contact [finfreind@finfriend.com].

https://finfriend.com</div>
</div>
    </div>

  </div>
  <Footer />
    </div>
  );
}

export default TermsOfService;