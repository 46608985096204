import React, { useState, useEffect } from 'react';
import styles from '../../App.module.scss';
import { getAuth, sendEmailVerification, sendSignInLinkToEmail } from "firebase/auth";
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { useAppSelector } from '../../redux/hooks';
import closeButton from '../../assets/closeButton.png';

export default function VerifyEmailModal() {

  const db = getFirestore();

  const auth = getAuth();

  const [resendEmailButtonPressed, setResendEmailButtonPressed] = useState(false);

    const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(true);

    const [address, setAddress] = useState('');

    const [errorMessage, setErrorMessage] = useState('');

    const [enableSendEmail, setEnableSendEmail] = useState(true);
   
    const email = auth.currentUser?.email

      const toggleVerifyEmailModal = () => {
        setShowVerifyEmailModal(!showVerifyEmailModal);
      };

      const actionCodeSettings = {
        url: `https://finfriend.com/log-in/${email}`,
        handleCodeInApp: true,
      };


      useEffect(() => {
        if (showVerifyEmailModal && email && enableSendEmail) {


        }
      }, [showVerifyEmailModal, email, enableSendEmail]);


      const handleResendEmailClick = async () => {
        setResendEmailButtonPressed(true);

        sendEmailVerification(auth.currentUser!, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem('emailForSignIn', email!);
          // ...
          console.log('Success sending link to: ', email);
          setEnableSendEmail(false);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log('Error sending email', errorMessage);
          // ...
        });
    }

    return (
        <div>

          {showVerifyEmailModal && (
            <div className={styles['modal-container']}>
              <div className={styles['overlay']} onClick={toggleVerifyEmailModal}></div>
              <div className={styles['crypto-modal']}>
                <div className={styles['crypto-modal-header']}>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h3>Create Your Account</h3>
                    <span>
                        Verifcation Email Sent To
                  </span>
                  <span>
                        {email}
                  </span>
                  </div>



                  <img src={closeButton} className={styles['crypto-modal-close-button']} alt="Close" onClick={toggleVerifyEmailModal} style={{width: '24px', height: '24px'}}></img>

                </div>
                <div className={styles['crypto-modal-content']}>

                <span>
                        Remember to check your spam
                  </span>

                </div>
                <div className={styles['crypto-modal-footer']} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>


                  <button style={{ 
                    backgroundColor: '#007AFF',
                    color: '#222222',
                    display: 'inline-block',
                    padding: '10px 20px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    borderRadius: '15px',
                    border: 'none',
                    cursor: 'pointer' 
                    }} onClick={handleResendEmailClick}>{resendEmailButtonPressed ? 'Sent!' : 'Resend Email'}</button>
                </div>
              </div>
            </div>
          )}

    </div>
    );
}